import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateRangeSelectComponent} from './date-range-select.component';
import {MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        DateRangeSelectComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule
    ],
    exports: [
        DateRangeSelectComponent
    ]
})
export class DateRangeSelectModule {
}
