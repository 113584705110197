import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CloneQueueComponent} from './clone-queue.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatSnackBarModule} from '@angular/material';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';

@NgModule({
    declarations: [
        CloneQueueComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSnackBarModule
    ],
    exports: [
        CloneQueueComponent,
    ],
    entryComponents: [
        ErrorDialogComponent
    ]
})
export class CloneQueueModule {
}
