export class View {

    public id: number;

    public user_id: number;

    public name: string;

    public default: boolean;

    public created_at: number;

    public updated_at: number;

    public in_tab: boolean;

    public in_tab_count: boolean;

}
