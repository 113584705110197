import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.less']
})

export class LoaderComponent implements OnInit {

    @Input() show: boolean;
    @Input() static: boolean = false;

    @Input() diameter: number = 100;
    @Input() background: boolean = false;

    constructor() {}

    ngOnInit() {}

}
