import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgentService} from '../../../services/agent/agent.service';
import {MatSnackBar} from '@angular/material';
import {TYPE_LEADS} from '../../../constants/user';
import {Permissions} from '../../../helpers/Permissions';

@Component({
  selector: 'app-suggestion-table',
  templateUrl: './suggestion-table.component.html',
  styleUrls: ['./suggestion-table.component.scss']
})
export class SuggestionTableComponent implements OnInit {

  @Input() data: any;

  @Input() stats: any;

  @Input() statsLoader: boolean = false;

  @Input() role: number;

  @Output() assignedAll: EventEmitter<any> = new EventEmitter();

  public typeLeads = TYPE_LEADS;

  constructor(
    private agentService: AgentService,
    private snack: MatSnackBar,
    public permissions: Permissions
  ) {}

  ngOnInit() {}

  public assignAll(type, data) {
    const agentId = data.value.agent._id;
    const agentName = data.value.agent.name;
    const leads = data.value[type].map(el => el.id);

    const key = +data.key;

    this.assign(agentId, agentName, leads, key, type);
  }

  public assign(agentId: number, agentName: string, leadIds: number[], key: number, type: string) {
    this.agentService.assignLead(agentId, leadIds, 'Assign AI System').subscribe((res) => {
      this.snack.open('Assign Success! Leads Was Assigned to ' + agentName, 'Ok!', {
        duration: 2000,
      });

      this.assignedAll.emit({ key, type });

    }, (err) => {
      this.snack.open('Error! Please Contact with Administrator!', 'Ok!', {
        duration: 2000,
      });
    });
  }

}
