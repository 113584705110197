import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Cookies} from '../../../helpers/Cookies';
import {MatDialog, MatDrawer, MatSnackBar} from '@angular/material';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';
import {DateConverter} from '../../../helpers/DateConverter';
import {LeadService} from '../../../services/lead/lead.service';
import {ErrorDialogComponent} from '../error-dialog/error-dialog.component';
import {AgentService} from '../../../services/agent/agent.service';
import {select, Store} from '@ngrx/store';
import * as AgentsActions from '../../../actions/agents-actions';
import {PersonalSettingsComponent} from '../../personal-settings/personal-settings.component';
import {Permissions} from '../../../helpers/Permissions';
import {filter, takeUntil} from 'rxjs/operators';
import {ViewService} from '../../../services/view/view.service';
import {BoardService} from '../../../services/board/board.service';
import {GetBoardId} from '../../../reducers/selectors/login.selector';
import {SelectBoard} from '../../../classes/selectBoard';
import {UserService} from '../../../services/user/user.service';
import * as LoginAction from '../../../actions/login.actions';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent extends SelectBoard implements OnInit {

    @Input() sideNav: MatDrawer;

    public menuLoader = false;

    public boards = null;

    public activeBoard: any = null;

    public options = [];

    public userType: number;
    public boardId: number;

    public isAdmin = false;
    public boardExist = false;

    constructor(
        private boardService: BoardService,
        private agentService: AgentService,
        private leadService: LeadService,
        public userService: UserService,
        public viewService: ViewService,
        public router: Router,
        public store: Store<any>,
        public permissions: Permissions,
        public snack: MatSnackBar,
        public dialog: MatDialog,
    ) {
        super(
            viewService,
            userService,
            router,
            store
        );
    }

    ngOnInit() {
        this.store.pipe(
            select('login'),
            takeUntil(this.destroy)
        ).subscribe((data: any) => {
            this.userType = data.type;
            this.isAdmin = data.is_super_admin;
            this.boardExist = data.board_exist;
        });

        this.store.pipe(
            select(GetBoardId),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((id: number) => {
            this.boardId = id;

            if (this.boards) {
                this.activeBoard = this.boards.find(item => item.id === this.boardId);
            }
        });

        this.getBoards();

        if (localStorage.getItem('sideNav')) {
            this.sideNav.open();
        } else {
            this.sideNav.close();
        }

        this.options = [
            {
                icon: 'manage_accounts',
                label: 'Manage Boards',
                show: this.isAdmin,
                fn: () => {
                    localStorage.removeItem('board_id');
                    localStorage.removeItem('role_type');

                    this.router.navigate(['/board/manage']);
                }
            },
            {
                icon: 'move_up',
                label: 'Auto Assign',
                show: this.isAdmin,
                fn: () => {
                    this.clearBoard();

                    this.router.navigate(['general/auto-assign']);
                }
            },
            // {
            //     icon: 'settings_applications',
            //     label: 'Personal settings',
            //     show: true,
            //     fn: () => {
            //         this.dialog.open(PersonalSettingsComponent, {
            //             width: '500px',
            //             data: {
            //                 id: 'custom',
            //                 value: {
            //                     rawEnd: DateConverter.addDays(new Date(), -1),
            //                     rawStart: new Date(),
            //                 }
            //             }
            //         });
            //     }
            // },
            // {
            //     icon: 'sync',
            //     label: 'Sync System',
            //     show: this.permissions.can('leads', 'can_sync_leads'),
            //     fn: () => {
            //         this.dialog.open(DataPicketCampaignsComponent, {
            //             width: '500px',
            //             data: {
            //                 id: 'custom',
            //                 value: {
            //                     rawEnd: DateConverter.addDays(new Date(), -1),
            //                     rawStart: new Date(),
            //                 }
            //             }
            //         }).afterClosed().subscribe((res) => {
            //             if (!res) {
            //                 return;
            //             }
            //             this.menuLoader = !this.menuLoader;
            //             this.leadService.syncLeads({
            //                 start: res.value.end,
            //                 end: res.value.start,
            //             }).subscribe(() => {
            //                 this.menuLoader = !this.menuLoader;
            //                 this.snack.open('System was synchronized!', 'Dismiss', {
            //                     duration: 3000,
            //                 });
            //             }, () => {
            //                 this.menuLoader = !this.menuLoader;
            //                 this.dialog.open(ErrorDialogComponent, {
            //                     data: {
            //                         title: 'Error',
            //                         message: 'There was an error processing your request!'
            //                     }
            //                 });
            //             });
            //         });
            //     }
            // },
            {
                icon: 'transfer_within_a_station',
                label: 'Sync Agents',
                show: this.permissions.can('agent', 'can_sync_agent'),
                fn: () => {
                    this.menuLoader = !this.menuLoader;
                    this.agentService.syncAgents().subscribe(() => {
                        this.agentService.getAllByTypeAgents().subscribe((result) => {
                            this.menuLoader = !this.menuLoader;
                            this.store.dispatch(new AgentsActions.SetAllAgents(result.data));
                            this.store.dispatch(new AgentsActions.SetAgents(result.data.filter((agent) => {
                                return !agent.hidden;
                            })));
                            this.snack.open('Agents was synchronized!', 'Dismiss', {
                                duration: 3000,
                            });
                        }, () => {
                            this.menuLoader = !this.menuLoader;
                            this.snack.open('There was an error originating the sync!', 'Dismiss', {
                                duration: 3000,
                            });
                        });

                        this.agentService.getAllAgents().subscribe((result) => {
                            this.store.dispatch(new AgentsActions.SetAllAgentsNew(result.data));
                        });
                    }, () => {
                        this.menuLoader = !this.menuLoader;
                        this.snack.open('There was an error originating the sync!', 'Dismiss', {
                            duration: 3000,
                        });
                    });
                }
            },
            {
                icon: 'settings',
                label: 'Settings',
                show: this.permissions.can('assign', ['can_reassign_pool_assign']),
                fn: () => {
                },
                children: [
                    {
                        icon: 'wifi',
                        label: 'IP Control',
                        show: this.permissions.can('assign', 'can_reassign_pool_assign'),
                        fn: () => {
                            this.router.navigate(['/ip-control']);
                        },
                    }
                ]
            },
            {
                icon: 'exit_to_app',
                label: 'Logout',
                show: true,
                fn: () => {
                    Cookies.set('token', '', -1);

                    localStorage.removeItem('board_id');
                    localStorage.removeItem('role_type');
                    // localStorage.removeItem('as-permissions');
                    localStorage.setItem('sideNav', '');

                    localStorage.clear();

                    this.sideNav.close();
                    this.router.navigate(['login']);
                }
            }
        ];
    }

    private getBoards(): void {
        if (this.boardExist || this.isAdmin) {
            this.boardService.getBoards({
                page_size: 100
            }).pipe(
                takeUntil(this.destroy)
            ).subscribe((res) => {
                this.boards = res.data;

                this.activeBoard = res.data.find(item => item.id === this.boardId);
            });
        }
    }

    openNav(): void {
        if (!this.sideNav.opened) {
            localStorage.setItem('sideNav', '1');

            this.sideNav.open();
        } else {
            localStorage.setItem('sideNav', '');

            this.sideNav.close();
        }
    }

    public select(option) {
        option.fn();
    }
}
