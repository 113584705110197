import {Component, OnInit} from '@angular/core';
import {AgentService} from '../../../services/agent/agent.service';
import {MatDialogRef} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';
import SearchResponse from '../../../search.response';
import * as FiltersActions from '../../../actions/filters-actions';
import {ViewService} from '../../../services/view/view.service';
import {select, Store} from '@ngrx/store';
import {Permissions} from '../../../helpers/Permissions';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-pending-assign-modal',
  templateUrl: './pending-assign-modal.component.html',
  styleUrls: ['./pending-assign-modal.component.scss']
})
export class PendingAssignModalComponent extends Unsubscribe implements OnInit {

  public loader = false;

  public filterData: any = {};

  public pending: SearchResponse = new SearchResponse({
    page_size: 10,
    sort: {}
  });

  public selection = new SelectionModel<any>(true, []);

  public columnsToDisplay: string[] = [
    'lead_number',
    'country_of_residence',
    'country_of_birth',
    'campaign_source',
    'assigned_user_crm_id',
    'assign_time',
    'age',
    'program',
  ];

  public allAgents = [];

  constructor(
      private self: MatDialogRef<PendingAssignModalComponent>,
      private agentsService: AgentService,
      private viewService: ViewService,
      private store: Store<any>,
      public permissions: Permissions,
  ) {
    super();
  }

  ngOnInit() {
    this.getPending();
    this.getAutoComplete();

    this.store.pipe(
      select((state) => {
        return state.agents.allAgentsNew;
      }),
      takeUntil(this.destroy)
    ).subscribe((res) => {
      this.allAgents = res;
    });
  }

  private getAutoComplete(): void {
    this.viewService.getAutoCompleteValues(this.columnsToDisplay.join()).subscribe((resp) => {
      this.store.dispatch(new FiltersActions.SetFieldsData(resp));
    });
  }

  private getPending(): void {
    this.loader = true;

    this.agentsService.getPendingAssignStats({
      type: 2,
      ...this.pending.getReqData()
    }).pipe(
        takeUntil(this.destroy)
    ).subscribe((res) => {

      this.pending = Object.assign(this.pending, res);

      this.loader = false;
    }, () => {
      this.loader = false;
    });

  }

  public changeSort(event: any) {
    this.pending.addSort(event.active, event.direction);

    this.getPending();
  }

  public changePage(event: any): void {
    this.pending.page = event.pageIndex;
    this.pending.page_size = event.pageSize;

    this.getPending();
  }

  public autocompleteFilterChange(e, type) {
    this.pending.addFilter(type, e);

    this.getPending();
  }

  public assignFilterChange(e) {
    const value = e.map(i => i.crm_id);

    this.pending.addFilter('assigned_user_crm_id', value);

    this.getPending();
  }

  public timeFilterChange(e) {
    if (e) {
      this.pending.addFilter('assign_time', e.value.start + ' ' + e.value.end);
    } else {
      this.pending.removeFilter('assign_time');
    }

    this.getPending();
  }

  public filterChange(e) {
    const event = e.source ? e.source.ngControl : e.target;

    this.pending.addFilter(event.name, event.value);

    this.getPending();
  }

  public getAgent(lead): void {
    const agent = this.allAgents.find(ag => ag.crm_id === lead.assigned_to);
    return Object.assign({}, lead, {agent: agent});
  }
}
