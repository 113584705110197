import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {Cookies} from '../../../helpers/Cookies';
import {Unsubscribe} from '../../../classes/unsubscribe';

@Component({
  selector: 'app-header-general-layout',
  templateUrl: './header-general-layout.component.html',
  styleUrls: ['./header-general-layout.component.less']
})
export class HeaderGeneralLayoutComponent extends Unsubscribe implements OnInit {
  public boardMenu: any[];

  private isAdmin = false;

  constructor(
      private router: Router,
      private store: Store<any>
  ) {
    super();

    this.store.pipe(
        select('login'),
        filter(data => !!data),
        takeUntil(this.destroy)
    ).subscribe((data: any) => {
      this.isAdmin = data.is_super_admin;
    });

    this.boardMenu = [
      {
        icon: 'manage_accounts',
        label: 'Manage Boards',
        show: this.isAdmin,
        fn: () => {
          this.router.navigate(['/board/manage']);
        }
      },
      {
        icon: 'done',
        label: 'Select Board',
        show: this.isAdmin,
        fn: () => {
          this.router.navigate(['/board/select']);
        }
      },
      {
        icon: 'move_up',
        label: 'Auto Assign',
        show: true,
        fn: () => {
          this.router.navigate(['general/auto-assign']);
        }
      },
      {
        icon: 'exit_to_app',
        label: 'Logout',
        show: true,
        fn: () => {
          localStorage.clear();

          Cookies.set('token', '', -1);
          this.router.navigate(['login']);
        }
      }
    ];
  }

  ngOnInit() {
  }

}
