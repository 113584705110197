import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DepartmentRolesComponent} from './department-roles.component';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule, MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule, MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        DepartmentRolesComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,

        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatSortModule,
        LoaderModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatListModule,
        ReactiveFormsModule
    ],
    exports: [
        DepartmentRolesComponent
    ]
})

export class DepartmentRolesModule {
}
