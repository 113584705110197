import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {GetActiveBoard, GetRoleType} from '../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_APPLICATIONS} from '../../../constants/user';
import {PROGRESS_BARS, PROGRESS_BARS_RETENTION, WIZARD_TYPES} from '../../../helpers/constants';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {AgentService} from '../../../services/agent/agent.service';
import {MatDialog, MatSidenav} from '@angular/material';
import {SectionInfoModalComponent} from '../section-info-modal/section-info-modal.component';
import {Permissions} from '../../../helpers/Permissions';
import {PendingAssignModalComponent} from '../pending-assign-modal/pending-assign-modal.component';

@Component({
  selector: 'app-leads-section-info',
  templateUrl: './leads-section-info.component.html',
  styleUrls: ['./leads-section-info.component.less']
})
export class LeadsSectionInfoComponent extends Unsubscribe implements OnInit {

  @Input() filter: MatSidenav;

  @Output() apply = new EventEmitter();

  @Input() set statsAgents(val) {
    if (val) {
      this._statsAgents = val;

      this.calcStats();
    }
  };

  public _statsAgents: any[];

  public loader = false;
  public pendingLoader = false;

  private assignStats = null;

  private agents = null;

  public leadSections = null;

  public agentsStats = null;

  public pendingAssignStatsCount = 0;

  private progressToColor = {
    24: 'info-progress',
    50: 'success-progress',
    99: 'danger-progress',
    100: 'warning-progress'
  };

  private roleType: number;

  constructor(
      private agentsService: AgentService,
      private dialog: MatDialog,
      private store: Store<any>,
      public permissions: Permissions
  ) {
    super();

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.store.pipe(
        select(GetActiveBoard),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((board: any) => {
      this.leadSections = WIZARD_TYPES[board.wizard_type];
    });

    this.store.pipe(select((state) => {
      return state.agents.allAgents;
    })).subscribe((res) => {
      this.agents = res;
    });
  }

  ngOnInit() {
  }

  public init(): void {
    this.getPendingAssignStats();

    this.getAgentsStats();
  }

  public showInfo(type = ''): void {
    const agentIds = this.agents.map(agent => agent.crm_id);

    this.dialog.open(SectionInfoModalComponent, {
      width: '1200px',
      data: {
        agentIds:  this._statsAgents && this._statsAgents.length ? this._statsAgents : agentIds,
        type: type !== 'all' ? type : '',
      }
    });
  }

  private getPendingAssignStats(): void {
    this.pendingLoader = true;

    this.agentsService.getPendingAssignStats({type: 1}).subscribe((res: any) => {

      this.pendingAssignStatsCount = res;

      if (this.agentsStats) {
        this.calcStats();
      }

      this.pendingLoader = false;
    }, () => {
      this.pendingLoader = false;
    });
  }

  public showPendingAssign(): void {
    this.dialog.open(PendingAssignModalComponent, {
      width: '1200px',
      data: {}
    });
  }

  private getAgentsStats(): void {
    this.loader = true;

    this.agentsService.getAssignStats({new_system: 1}).subscribe((res: any) => {

      this.assignStats = res;

      const agents = Object.keys(res).map(key => res[key].crm_id);

      this.apply.emit(agents);

      this.calcStats();

      this.loader = false;
    });
  }

  private calcStats(): void {
    const generalStats = {
      _all_total: this.pendingAssignStatsCount,
      _all_called: 0,
    };

    this.leadSections.forEach(section => {
      generalStats[section.total] = 0;
      generalStats[section.called] = 0;
    });

    Object.keys(this.assignStats).forEach(key => {
      Object.keys(this.assignStats[key]).forEach(k => {
        if (generalStats.hasOwnProperty(k)) {
          if (this._statsAgents && this._statsAgents.length && !this._statsAgents.includes(this.assignStats[key].crm_id)) {
            return;
          }

          generalStats[k] += this.assignStats[key][k];

          if (k.indexOf('blocked') === -1 && !['all', 'all_called'].includes(k)) {
            if (k.indexOf('_called') !== -1) {
              generalStats._all_called += this.assignStats[key][k];
            } else {
              generalStats._all_total += this.assignStats[key][k];
            }
          }

        }
      });
    });

    this.leadSections.forEach(section => {
      generalStats[section.progress] = this.setProgress(generalStats[section.called], generalStats[section.total]);
    });

    generalStats['_all_progress'] = this.setProgress(generalStats._all_called, generalStats._all_total);

    this.agentsStats = generalStats;
  }

  private setProgress(called, total): number {
    if (called === undefined || total === undefined) return 0;
    if (total === 0 && called === 0) return 100;
    if (called === 0 || total === 0) return 0;

    const percent = ((called / total) * 100);

    return Math.round(percent);
  }

  public refresh(): void {
    this.getAgentsStats();
  }

  public getProgressColor(progress: number): any {
    if (progress === undefined) {
      return null;
    }

    for (const percent in this.progressToColor) {
      if (progress <= parseInt(percent, 10)) {
        return {[this.progressToColor[percent]]: true};
      }
    }

    return {[this.progressToColor['25']]: true};
  }

  public openFilter(): void {
    this.filter.toggle();
  }

}
