import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShiftsComponent} from './shifts.component';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {LoaderModule} from '../general/loader/loader.module';
import {AddGroupComponent} from './add-group/add-group.component';
import {AddGroupModule} from './add-group/add-group.module';
// import {CompleteModalModule} from '../../general/complete-modal/complete-modal.module';
// import {ConfirmModalModule} from '../../general/confirm-modal/confirm-modal.module';
// import {ConfirmModalComponent} from '../../general/confirm-modal/confirm-modal.component';
// import {CompleteModalComponent} from '../../general/complete-modal/complete-modal.component';

@NgModule({
    declarations: [
        ShiftsComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        LoaderModule,
        CdkTableModule,
        AddGroupModule
    ],
    exports: [
        ShiftsComponent
    ],
    entryComponents: [
        AddGroupComponent
    ]
})
export class ShiftsModule {}
