import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
// import {AddNewAgentComponent} from '../../agents/add-new-agent/add-new-agent.component';
import {IconSelectModalComponent} from '../icon-select-modal/icon-select-modal.component';
import {RolesService} from '../../../services/roles/roles.service';
import {select, Store} from '@ngrx/store';
import {GetBoardId, GetRoleType} from '../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_APPLICATIONS} from '../../../constants/user';
import {PROGRESS_BARS, PROGRESS_BARS_RETENTION} from '../../../helpers/constants';
import {Unsubscribe} from '../../../classes/unsubscribe';
// import slugify from 'slugify';

@Component({
    selector: 'app-add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent extends Unsubscribe implements OnInit, OnDestroy {

    public loader: any = false;

    public title = 'Add New Role';

    public subscriber: any;

    public action: any = {
        create: (params) => {
            return this.rolesService.addRole(params);
        },
        update: (params) => {
            return this.rolesService.updateRole(params);
        },
    };

    public newRole: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        icon: new FormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100)
        ]),
        description: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        board_id: new FormControl('')
    });

    constructor(
        private self: MatDialogRef<AddRoleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private rolesService: RolesService,
        private dialog: MatDialog,
        private snack: MatSnackBar,
        public store: Store<any>
    ) {
        super();

        this.store.pipe(
            select(GetBoardId),
            takeUntil(this.destroy),
            // tslint:disable-next-line:no-shadowed-variable
            filter(data => !!data)
        ).subscribe((id: number) => {
            this.newRole.get('board_id').setValue(id);
        });
    }

    ngOnInit() {
        if (this.data) {
            this.title = 'Edit Role';

            for (const name in this.data) {

                if (!this.data.hasOwnProperty(name)) {
                    continue;
                }

                const control = this.newRole.get(name);

                if (!control) {
                    continue;
                }

                control.patchValue(this.data[name]);
            }
        }
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    public addNewRole() {

        const controls = this.newRole.controls;
        const fieldsToValidate = ['name', 'id'];

        for (const n in controls) {
            if (controls.hasOwnProperty(n) && controls[n] && fieldsToValidate.includes(n)) {
                if (!controls[n].value.trim()) {
                    controls[n].markAsTouched();
                    controls[n].setErrors({required: true});
                }
            }
        }

        if (this.newRole.valid) {
            this.loader = true;

            let action = this.action.create;

            let id = '';

            if (this.data) {
                action = this.action.update;
                id = this.data.id;
            }

            this.subscriber = action({
                payload: Object.assign({}, this.newRole.value),
                id
            }).subscribe(result => {
                if (result) {
                    this.self.close(result);
                }
            }, error => {

                if (error.error.error) {
                    this.snack.open(error.error.error, 'Dismiss', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });
                }
                for (const name in error.error) {
                    if (error.error.hasOwnProperty(name) && this.newRole.get(name)) {
                        this.newRole.get(name).setErrors({
                            name: error.error[name]
                        });
                    }
                }
                this.loader = false;
            });
        }
    }

    public openSelectIconModal() {
        this.dialog.open(IconSelectModalComponent, {
            width: '600px',
            height: '600px',
            hasBackdrop: true,
            data: {
                title: 'Select icon',
                selectedIcon: this.newRole.get('icon').value || ''
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.newRole.get('icon').patchValue(result);
            }
        });
    }

}
