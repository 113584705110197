import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../base/base.component';
import {Permissions} from 'src/app/helpers/Permissions';
import {AssignSettingService} from '../../services/assign-settings/assign-setting.service';

@Component({
  selector: 'app-bulk-assign',
  templateUrl: './bulk-assign.component.html',
  styleUrls: ['./bulk-assign.component.less']
})
export class BulkAssignComponent extends BaseComponent implements OnInit {

  public activeId = 0;

  public group: any = null;

  public subscribers: any = {
    get: null
  };

  public initReload = false;

  constructor(
    private service: AssignSettingService,
    private route: ActivatedRoute,
    private snack: MatSnackBar,
    public permissions: Permissions
  ) {
    super();
  }

  ngOnInit() {

  }

  public onLoaded() {
    this.initReload = true;
    setTimeout(() => {
      this.initReload = false;
    }, 10);
  }

}
