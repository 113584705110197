import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OpportunitiesService {

    constructor(private api: ApiService) {
    }

    public getAgentStatistics(id: number, data: any): Observable<any> {
        return this.api.get('v1/opportunities/' + id + '/agent-statistics', {
            params: data
        });
    }

    public contactReport(data: any): Observable<any> {
        return this.api.get('v1/opportunities/contact-report', {
            params: data
        });
    }
}
