import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AssignSuggestionService} from '../../../services/assign-suggestion/assign-suggestion.service';
import {select, Store} from '@ngrx/store';
import {ViewCountryInfoComponent} from '../../view-country-info/view-country-info.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {TYPE_APPLICATIONS} from '../../../constants/user';
import {Permissions} from '../../../helpers/Permissions';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {ViewService} from '../../../services/view/view.service';

@Component({
  selector: 'app-suggestion-settings-retention',
  templateUrl: './suggestion-settings-retention.component.html',
  styleUrls: ['./suggestion-settings-retention.component.scss']
})
export class SuggestionSettingsRetentionComponent extends Unsubscribe implements OnInit, OnDestroy {

  @Output() onsubmit = new EventEmitter();

  @Input() role: number;

  @Input() set error(error: string) {
    if (error) {
      this.handleBackendError(error);
    }
  }

  public loader: any = false;

  public formGroup: FormGroup;

  public call_types = [
    'Attempt',
    'Answered',
    'Effective',
  ];

  public programs = [
    {
      value: 'Student Visa',
      label: 'Student Visa',
    },
    {
      value: 'Express Entry',
      label: 'Express Entry',
    },
    {
      value: 'Spousal Sponsorship',
      label: 'Spousal Sponsorship',
    },
    {
      value: 'Working Holiday',
      label: 'Working Holiday',
    },
    {
      value: 'Tourist Visa',
      label: 'Tourist Visa',
    },
    {
      value: 'Ontario Masters Graduate Stream Applicant Checklist',
      label: 'Ontario Masters Graduate Stream Applicant Checklist',
    },
    {
      value: 'Ontario PhD Graduate Stream Applicant Checklist',
      label: 'Ontario PhD Graduate Stream Applicant Checklist',
    },
    {
      value: 'Manitoba PNP Skilled Workers Overseas Applicant Checklist',
      label: 'Manitoba PNP Skilled Workers Overseas Applicant Checklist',
    },
    {
      value: 'Manitoba PNP Graduate Internship Pathway Applicant Checklist',
      label: 'Manitoba PNP Graduate Internship Pathway Applicant Checklist',
    },
    {
      value: 'Newfoundland and Labrador PNP Programs Skilled Worker Category',
      label: 'Newfoundland and Labrador PNP Programs Skilled Worker Category',
    },
    {
      value: 'Not eligible',
      label: 'Not eligible',
    },
    {
      value: 'Canada-Ukraine authorization for emergency travel (CUAET)',
      label: 'Canada-Ukraine authorization for emergency travel (CUAET)',
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ];

  public moneyRange = [
    {
      label: 'Less then 13,310 - not enough settlement funds',
      value: 'Less then 13,310 - not enough settlement funds'
    },
    {
      label: 'At least 13,310 CAN',
      value: 'At least 13,310 CAN'
    },
    {
      label: 'At least 16,570 CAN',
      value: 'At least 16,570 CAN'
    },
    {
      label: 'At least 20,371 CAD',
      value: 'At least 20,371 CAD'
    },
    {
      label: 'At least 24,733 CAD',
      value: 'At least 24,733 CAD'
    },
    {
      label: 'At least 28,052 CAD',
      value: 'At least 28,052 CAD'
    },
    {
      label: 'At least 31,638 CAD',
      value: 'At least 31,638 CAD'
    },
    {
      label: 'At least 35,224 CAD or more',
      value: 'At least 35,224 CAD or more'
    }
  ];

  public productTypes = [
    {
      value: 'Upgrade',
      label: 'Upgrade'
    },
    {
      value: 'Up Sale',
      label: 'Up Sale'
    },
    {
      value: 'WHV',
      label: 'WHV'
    },
    {
      value: 'Interview',
      label: 'Interview'
    },
    {
      value: 'Upgrade Partial',
      label: 'Upgrade Partial'
    },
    {
      value: 'Up Sale Partial',
      label: 'Up Sale Partial'
    }
  ];

  public marital_status = [
    {
      label: 'Married',
      value: 'Married',
    },
    {
      label: 'Single',
      value: 'Single',
    }
  ];

  public genders = [
    {
      label: 'Female',
      value: 'Female'
    },
    {
      label: 'Male',
      value: 'Male'
    },
    {
      label: 'Other',
      value: 'Other'
    }
  ];

  public speak_english = [
    {
      label: 'Basic',
      value: 'Basic'
    },
    {
      label: 'Moderate',
      value: 'Moderate'
    },
    {
      label: 'Poor',
      value: 'Poor'
    },
    {
      label: 'Proficient',
      value: 'Proficient'
    }
  ];

  public range_of_monthly_salary = [
    {
      label: 'Less than $300 000',
      value: 'Less than $300 000'
    },
    {
      label: '$300 000 - $1.599 999',
      value: '$300 000 - $1.599 999'
    },
    {
      label: '$1 600 000 or more',
      value: '$1 600 000 or more'
    }
  ];

  public bestTime = [
    {
      value: 'low',
      label: 'Low'
    },
    {
      value: 'medium',
      label: 'Medium'
    },
    {
      value: 'high',
      label: 'High'
    },
  ];

  public types = [
    'Today',
    'Days',
    'Weeks',
    'Months',
    'Quartals',
  ];

  public settingGmt = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Mid',
      value: 'mid',
    },
    {
      label: 'Low',
      value: 'low',
    }
  ];

  public countries = [];
  public nationalities = [];
  public agents = [];
  public agentsAll = [];
  public shifts = [];
  public regions = [];

  public audience = {
    freshEn: 0,
    reassignedEn: 0,
  };

  public selectedGmts = [];

  public viewTypeNew = [];

  private subs = {
    settings: null,
    audience: null,
  };

  constructor(
      private assignSuggestionService: AssignSuggestionService,
      private viewService: ViewService,
      private store: Store<any>,
      private snack: MatSnackBar,
      public dialog: MatDialog,
      public permissions: Permissions
  ) {
    super();

    this.initForm();

  }

  ngOnInit() {
    this.store.pipe(select('agents')).subscribe((res) => {
      if (this.agents.length > 0) {
        return;
      }
      this.agents = res.allAgents;
    });

    this.viewService.getAutoCompleteValues('nationality').pipe(
        takeUntil(this.destroy)
    ).subscribe((res: any) => {
      this.nationalities = res.nationality;
    });

    const type = this.role === TYPE_APPLICATIONS ? 'suggestion_views_retention' : 'suggestion_views';

    this.subs.settings = this.assignSuggestionService.getSetting(`countries,shifts,regions,agents,${type}`).subscribe((res) => {
      this.loader = false;
      this.countries = res.countries;
      this.shifts = res.shifts;
      this.regions = res.regions;
      this.agentsAll = res.agents;
      this.viewTypeNew = res[type].map(item => {
        return {
          value: item.prefix,
          label: item.title
        };
      });

      if (this.viewTypeNew.length) {
        this.formGroup.get('view_type').setValue(this.viewTypeNew[0].value);
      }

    }, (err) => {
      this.loader = false;
    });
  }

  ngOnDestroy(): void {
    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        this.subs[name].unsubscribe();
      }
    }
  }

  private initForm(): void {
    this.formGroup = new FormGroup({
      pageSize: new FormControl(20, []),
      page: new FormControl(0, []),
      ageFresh: new FormControl('0m-30d', []),
      ageReassign: new FormControl('0m-30d', []),
      view_type: new FormControl('', []),
      suggestionMode: new FormControl(2, []),
      suggestionFresh: new FormControl(2, []),
      suggestionReassign: new FormControl(2, []),
      agents: new FormControl([], []),
      shifts: new FormControl([], []),
      country_limit_fresh: new FormControl('', []),
      country_limit_reassign: new FormControl('', []),
      agentScoresFrom: new FormControl('', []),
      agentScoresTo: new FormControl('', []),
      englishLevel: new FormControl([], []),
      englishCondition: new FormControl(0, []),
      genders: new FormControl([], []),
      marital_status: new FormControl([], []),
      callStatus: new FormControl([], []),
      countries: new FormGroup({
        exclude: new FormControl(0, []),
        countries: new FormControl([], []),
        regions: new FormControl([], []),
      }),
      countries_birth: new FormGroup({
        exclude: new FormControl(0, []),
        countries: new FormControl([], []),
        regions: new FormControl([], []),
      }),
      call_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
        call_count: new FormControl('', []),
        condition: new FormControl(1, []),
        call_type: new FormControl('', []),
      }),
      availability_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', [Validators.min(0), Validators.max(100)]),
        enable: new FormControl(0, []),
      }),
      recycle_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
        recycle_count: new FormControl('', []),
        condition: new FormControl(0, []),
      }),
      duplicate_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
      }),
      duplicate: new FormControl(0, []),
      range_of_monthly_salary: new FormControl([], []),
      age_from: new FormControl('', []),
      age_to: new FormControl('', []),
      how_much_money: new FormControl('', []),
      product_type: new FormControl('', []),
      programs: new FormControl(['Student Visa', 'Express Entry'], []),
      gmt_settings: new FormGroup({
        gmts: new FormControl([], []),
        high: new FormControl(0, []),
        mid: new FormControl(0, []),
        low: new FormControl(0, []),
      }),
      nationality: new FormControl('', [])
    });
  }

  public selectGmts(e): void {
    this.selectedGmts = e;
  }

  public getBackendError(form: FormGroup, control: string): void {
    if (form && form.get(control) && form.get(control).errors) {
      return form.get(control).errors['backend'];
    }
    return null;
  }

  public showListOfCountry(data: any): void {
    const obj = this.formGroup.value;
    obj['list'] = data;

    const dialog = this.dialog.open(ViewCountryInfoComponent, {
      width: '600px',
      data: Object.assign({}, obj)
    });

    dialog.afterClosed().subscribe();
  }

  public handleBackendError(error): void {
    for (const name in error.error) {
      if (error.error.hasOwnProperty(name) && this.formGroup.get(name)) {
        this.formGroup.get(name).setErrors({backend: error.error[name]});
      }
    }
  }

  public getAudience(): void {
    this.loader = true;
    const data = Object.assign({}, this.formGroup.value, {audience: 1});
    this.subs.settings = this.assignSuggestionService.getLeadsSuggestion(data).subscribe((res) => {
      this.loader = false;
      this.audience = res;
    }, (error) => {
      this.handleBackendError(error);
      this.loader = false;
    });
  }

  public submit(): void {
    this.onsubmit.emit(this.formGroup.value);
  }

  public reset(): void {
    this.initForm();
  }

  public onAgentsRemove(evt): void {
    // remove selected shift when agent was removed
    const crm_id = evt.value;
    const shifts = this.formGroup.get('shifts').value;
    const agent = this.agentsAll.find(el => el.crm_id === crm_id);
    if (!agent) {
      return;
    }
    const agentShiftsId = agent.shifts.map(el => el.id);
    if (!agentShiftsId.length) {
      return;
    }
    agentShiftsId.forEach(shiftId => {
      let index: number;
      while (index !== -1) {
        index = shifts.findIndex(el => el === shiftId);
        if (index !== -1) {
          shifts.splice(index, 1);
        }
      }
    });
    this.formGroup.get('shifts').patchValue(shifts);
  }

  public onShiftsChange(evt: any): void {
    const agents = this.agentsAll;
    const selectedAgents = [];
    const shifts = evt;
    const selAg = [];

    for (const shift of shifts) {
      for (let i = 0; i < agents.length; i++) {
        const ci = i;
        const index = agents[ci].shifts.findIndex((el) => {
          return el.id === shift;
        });
        if (index !== -1 && selectedAgents.findIndex(el => el.crm_id === agents[ci].crm_id) === -1) {
          selectedAgents.push(agents[ci]);
        }
      }
    }

    for (let i = 0; i < selectedAgents.length; i++) {
      const elemI = selectedAgents[i];
      for (let j = 0; j < this.agents.length; j++) {
        const elemJ = this.agents[j];
        if (elemI.crm_id === elemJ.crm_id) {
          selAg.push(elemJ);
        }
      }
    }

    this.formGroup.get('agents').patchValue(selAg.map(el => el.crm_id));
  }
}

