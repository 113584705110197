import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactReportComponent} from './contact-report.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule
} from '@angular/material';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';

@NgModule({
    declarations: [ContactReportComponent],
    imports: [
        CommonModule,
        AmChartsModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule,
        MatMenuModule,
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
    entryComponents: [
        DataPicketCampaignsComponent,
    ],
    exports: [ContactReportComponent]
})
export class ContactReportModule {
}
