import {Action} from '@ngrx/store';

export enum FiltersActionTypes {
    SET_FILTERS_DATA = '[Filters] setFilters',
}


export class SetFieldsData implements Action {
    readonly type = FiltersActionTypes.SET_FILTERS_DATA;
    constructor(public payload: any) {}
}


export type FiltersActionsUnion = SetFieldsData;
