import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MatBottomSheet} from '@angular/material';
import {MultipleSelectorComponent} from '../../general/multiple-selector/multiple-selector.component';

@Component({
    selector: 'app-disqualified',
    templateUrl: './disqualified.component.html',
    styleUrls: ['./disqualified.component.less']
})
export class DisqualifiedComponent implements OnInit, OnChanges {

    public selected: any[] = [];

    public autoComplete: any[] = [];

    @Input() model: any;

    @Output() modelChange = new EventEmitter<any>();

    @Output() public change = new EventEmitter();

    constructor(private store: Store<any>,
                private bottomSheet: MatBottomSheet) {
        this.store.pipe(select('filters')).subscribe((data: any) => {
            if (data && data['disqualified'] && (this.autoComplete.length <= 0)) {
                this.autoComplete = data.disqualified;
            }
        });
    }

    ngOnInit() {
        if (this.model) {
            this.selected = this.model.split(',');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model'] && !changes.model.currentValue) {
            this.selected = [];
        }
    }

    public openSelector() {

        const bottomSheet = this.bottomSheet.open(MultipleSelectorComponent, {
            data: {
                selected: this.selected,
                autoComplete: this.autoComplete
            }
        });

        const selectedNow = Object.assign([], this.selected).sort().join();

        bottomSheet.afterDismissed().subscribe((result) => {
            this.selected = result ? result : [];
            this.model = Object.assign([], result ? result : []).sort().join();
            this.modelChange.emit(this.model);
            if (this.model !== selectedNow) {
                this.change.emit(result);
            }
        });
    }

}
