import {Action} from '@ngrx/store';

export enum ViewsProgressActionTypes {
    SET_DATA = '[ViewsProgress] setLeads',
    CLEAR_DATA = '[ViewsProgress] clearLeads'
}

export class SetData implements Action {
    readonly type = ViewsProgressActionTypes.SET_DATA;
    constructor(public payload: any) {}
}

export class ClearData implements Action {
    readonly type = ViewsProgressActionTypes.CLEAR_DATA;
    constructor(public payload: any) {}
}

export type ViewsProgressActionsUnion = SetData | ClearData;
