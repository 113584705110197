import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Lead} from '../../services/view/Lead';
import {select, Store} from '@ngrx/store';
import {Agent} from '../../services/agent/Agent';
import {AgentService} from '../../services/agent/agent.service';
import {MatBottomSheet, MatDialog, MatSnackBar} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import * as ViewsProgressActions from '../../actions/views-progress-actions';
import {AgentsSheetComponent} from '../agents-sheet/agents-sheet.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {Marker} from '../../helpers/Marker';
import AssignBlocker from '../../helpers/AssignBlocker';
import {getAvatar} from '../../helpers/Avagar';
import {AssignStatus} from './assign-status.interface';
import {AssignStatusModalComponent} from '../assign-status-modal/assign-status-modal.component';

@Component({
    selector: 'app-agents-assign',
    templateUrl: './agents-assign.component.html',
    styleUrls: ['./agents-assign.component.less']
})

export class AgentsAssignComponent implements OnInit, OnDestroy {

    public getAvatar = getAvatar;

    @Input() canAssign = true;
    @Input() lead: Lead;
    @Input() selection: SelectionModel<any>;

    @Output() refresh: EventEmitter<any> = new EventEmitter();

    public agents: Agent[] = [];

    public progress: any = false;

    public selectedAgent: any = {
        id: 0,
        crm_username: 'Not assigned',
        avatar: '/assets/images/droid.png'
    };

    private agentsFilter: any[] = [];

    constructor(
        private store: Store<any>,
        private agentService: AgentService,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        private confirmModal: MatDialog
    ) {
        this.store.pipe(select('agents')).subscribe((value: any) => {
            this.agents = value.allAgentsNew;
        });

        this.store.pipe(select('views')).subscribe((value: any) => {
            if (value && value['filter'] && value.filter['filter[assigned_to]']) {
                this.agentsFilter = value.filter['filter[assigned_to]'].split(',').map((val) => {
                    return parseInt(val, 10);
                });
            }
        });
    }

    ngOnInit() {
        if (this.lead && this.lead.agent) {
            this.selectedAgent = this.lead.agent;
        }

        this.store.pipe(select('progress')).subscribe((value: any) => {
            const agent: any = value[this.lead.id];
            this.progress = !!agent;
            if (agent) {
                this.selectedAgent = agent;
            }
        });
    }

    ngOnDestroy() {
    }

    public setAgents(): void {
        const bottomSheet = this.bottomSheet.open(AgentsSheetComponent, {
            data: {
                agents: this.agents,
                selected: this.selectedAgent,
                multiple: false
            }
        });

        bottomSheet.afterDismissed().subscribe((result) => {
            if (result) {

                const data = {
                    title: 'Are you sure?',
                    description: 'Are you want to assign ' + this.lead.first_name + ' to ' + result.crm_username + '?'
                };

                if (this.selection.selected.length > 1 && this.selection.selected.findIndex(l => l.id === this.lead.id) !== -1) {
                    data.description = 'Are you want assign ' + this.selection.selected.length
                        + ' leads to ' + result.crm_username + '?';
                }

                const confirmModal = this.confirmModal.open(ConfirmModalComponent, {
                    data: data
                });

                confirmModal.afterClosed().subscribe((response) => {
                    if (response) {
                        this.selectAgent(result);
                    }
                });
            }
        });
    }

    private selectAgent(agent: Agent) {

        if (AssignBlocker.block(agent)) {
            this.snackBar.open('Agent \'' + agent.crm_username + '\' on a call assign blocked!', 'Dismiss', {
                duration: 3000,
            });
            return;
        }

        let data: any = [this.lead.id];

        const assignData: any = {[this.lead.id]: agent};
        const leadNumbers = {};

        const selected = this.selection.selected.filter((value) => {
            return value.id === this.lead.id;
        });

        if (selected.length > 0) {
            data = this.selection.selected.map((value) => {
                assignData[value.id] = agent;
                leadNumbers[value.lead_number] = value.id;
                return value.id;
            });
        }

        if ((selected.length > 0) || agent.id !== this.selectedAgent.id) {
            this.agentService.assignLead(agent.id, data, 'Assign System').subscribe((res) => {

                this.showAssignStatusModal(res);

                this.selection.clear();

                const markLeads = [];
                res.forEach(item => {
                    if (item.status) {
                        markLeads.push(leadNumbers[item.lead_number]);
                    }
                });

                for (let item in assignData) {
                    if (!markLeads.includes(+item)) {
                        delete assignData[item];
                    }
                }

                this.store.dispatch(new ViewsProgressActions.SetData(assignData));

                Marker.mark('newLeads', markLeads, 're-assign');

                if (this.agentsFilter.length > 0 && !this.agentsFilter.includes(agent.crm_id)) {
                    for (let i = 0; i < data.length; i++) {
                        const elem = document.getElementById('lead-row-' + data[i]);
                        if (elem) {
                            elem.setAttribute('style', 'display: none');
                        }
                    }
                }

                setTimeout(() => {
                    this.store.dispatch(new ViewsProgressActions.ClearData(assignData));
                }, 500);
            });
        }

        // this.selectedAgent = agent;
    }

    private showAssignStatusModal(res: AssignStatus[]): void {
        this.confirmModal.open(AssignStatusModalComponent, {
            data: res
        });
    }

}
