import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from './side-nav.component';
import {MatExpansionModule, MatIconModule, MatListModule} from '@angular/material';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        SideNavComponent
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        RouterModule,
        MatExpansionModule,
    ],
    exports: [
        SideNavComponent
    ]
})

export class SideNavModule {
}
