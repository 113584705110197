import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import BaseComponent from '../base/base.component';
import {Agent} from '../../services/agent/Agent';
import {DateConverter} from '../../helpers/DateConverter';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {MatDialog} from '@angular/material';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {Permissions} from '../../helpers/Permissions';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _findLast from 'lodash/findLast';

@Component({
    selector: 'app-conditions',
    templateUrl: './conditions.component.html',
    styleUrls: ['./conditions.component.less']
})
export class ConditionsComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() public formArrayName: string;

    @Input() public formGroup: FormGroup;

    @Input() public settings: any;

    @Input() public agents: Array<Agent>;

    @Input() public conditions: Array<any>;

    @Input() public audienceLoader: boolean;

    @Input() public audience: any;

    private init = false;

    protected nonValueOperators: string[] = ['is_empty', 'is_not_empty'];

    public boolean = [
        {
            name: true,
            label: 'True'
        },
        {
            name: false,
            label: 'False'
        }
    ];

    public groupsCount = 1;

    constructor(
        private dialog: MatDialog,
        public permissions: Permissions
    ) {
        super();
    }

    ngOnInit() {
        if (this.conditions && !this.init) {
            for (let i = 0; i < this.conditions.length; i++) {
                this.addNewCondition(this.conditions[i]);
            }
            this.getGroupCount();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['conditions'] && changes['conditions'].currentValue !== undefined) {
            for (let i = 0; i < this.conditions.length; i++) {
                this.init = true;
                this.addNewCondition(this.conditions[i]);
            }
            this.getGroupCount();
        }
    }

    public getAudience(condition: any): number | string {
        if (this.audience) {
            const cond = _get(_find(this.audience.fields, (i) => {
                return _isEqual(i.conditions[i.conditions.length - 1], condition);
            }), 'group_key', '');

            return this.audience.result[cond];
        }

        return '';
    }

    public getVewConditions(): FormArray {
        return this.formGroup.get(this.formArrayName) as FormArray;
    }

    public getFieldOperators(fieldName: string, index: number): any[] {

        if (!fieldName) {
            return [];
        }
        const field = this.findFieldByName(fieldName);

        if (!field) {
            return [];
        }

        return field.operators;
    }

    public getFieldType(fieldName: string, index: number): string {

        if (!fieldName) {
            return '';
        }

        const field = this.findFieldByName(fieldName);

        if (!field) {
            return '';
        }

        return field.type;
    }

    protected getFieldOperatorOptions(fieldName: string, operatorName: string) {

        if (!fieldName) {
            return null;
        }

        const field = this.findFieldByName(fieldName);

        if (!field) {
            return null;
        }

        for (const operator of field.operators) {
            if (operator.name === operatorName) {
                return operator.options.length > 0 ? operator.options : null;
            }
        }

        return null;
    }

    protected getGroupCount() {

        this.groupsCount = 1;

        const conditions = this.getVewConditions().controls;

        const groups = {
            '-1': [],
            '0': []
        };

        for (let i = 0; i < conditions.length; i++) {
            if (!groups[conditions[i].get('group').value]) {
                groups[conditions[i].get('group').value.toString()] = [];
            }
            groups[conditions[i].get('group').value].push(i);
        }

        this.groupsCount = Object.keys(groups).length - 1;

        let newGroup = 1;

        for (const gName in groups) {
            if (groups.hasOwnProperty(gName) && !['-1', '0'].includes(gName)) {
                for (const index of groups[gName]) {
                    conditions[parseInt(index, 10)].get('group').patchValue(newGroup);
                }
                newGroup++;
            }
        }
    }

    protected findFieldByName(name: string): null | any {

        if (!name) {
            return null;
        }

        for (let i = 0; i < this.settings.fields.length; i++) {
            if (name === this.settings.fields[i].name) {
                return this.settings.fields[i];
            }
        }

        return null;
    }

    public addNewCondition(data?: any): void {
        this.getVewConditions().insert(0, this.addCondition(data));
    }

    protected conditionSelect(event: any, index: number): void {
        const condition = this.getVewConditions().controls[index];
        if (['or', 'and'].includes(event.value) && condition.get('group').value !== event.value) {
            condition.get('group').patchValue(event.value === 'or' ? 1 : 0);
            this.getGroupCount();
        }
    }

    protected addCondition(data?: any): FormGroup {
        return new FormGroup({
            condition: new FormControl(this.getDefaultValue(data, 'condition'), [
                Validators.required
            ]),
            field_name: new FormControl(this.getDefaultValue(data, 'field_name'), [
                Validators.required
            ]),
            operator: new FormControl(this.getDefaultValue(data, 'operator'), [
                Validators.required
            ]),
            value: new FormControl(this.getDefaultValue(data, 'value'), []),
            group: new FormControl(this.getGroupValue(data), [])
        }, {
            validators: this.validateField.bind(this)
        });
    }

    protected getGroupValue(condition) {

        if (!condition) {
            return -1;
        }

        if (condition['group']) {
            return condition.group;
        }

        return condition.condition === 'or' ? 1 : 0;
    }

    protected validateField(control: FormGroup): null | void {
        if (!control.get('operator').value) {
            return null;
        }
        if (!this.nonValueOperators.includes(control.get('operator').value) && !control.get('value').value) {
            control.get('value').setErrors({required: 'Value is required!'});
        } else if (control.get('value').errors && control.get('value').errors.required) {
            control.get('value').updateValueAndValidity();
        }
    }

    public deleteCondition(index, group: number): void {
        this.getVewConditions().removeAt(index);
        this.getGroupCount();
    }

    public getAutocomplete(fieldName) {
        if (!fieldName) {
            return [];
        }

        const field = this.findFieldByName(fieldName);

        if (!field) {
            return [];
        }

        return field.options;
    }

    public dateChange(event, index): void {

        const viewConditions = this.formGroup.get(this.formArrayName) as FormArray;

        const viewConditionRow = viewConditions.controls[index] as FormGroup;

        viewConditionRow.get('value').patchValue(DateConverter.toDateString(new Date(event.value)));
    }

    public getGroupsArray() {
        const groups = [-1];

        for (let i = 0; i < this.groupsCount + 1; i++) {
            groups.push(i);
        }

        return groups;
    }

    public getGroupClass(group: number) {
        return {
            'and-group': group === 0,
            'or-group': group > 0,
        };
    }

    public getGroupLastItem(arr): number {
        return _get(_findLast(arr, i => i), 'value', {});
    }

    public getGroupIndex(arr): number {
        return arr.findlIndex(i => i);
    }

    public getGroupConditions(group: number) {
        const conditions = [];

        const formConditions = this.getVewConditions().controls;

        for (let i = 0; i < formConditions.length; i++) {
            if (formConditions[i].get('group').value === group) {
                conditions[i] = formConditions[i];
            }
        }

        return conditions;
    }

    public getGroupsConnect() {
        const groups = [];

        for (let i = 0; i < this.groupsCount + 1; i++) {
            groups.push('drop-area' + i);
        }

        return groups;
    }

    public drop(event: CdkDragDrop<string[]>, group) {

        if (group === -1) {
            return;
        }

        if (event.previousContainer !== event.container) {
            const control = this.getVewConditions().controls[event.item.data.index];
            control.get('group').patchValue(group);
            if (group === 0) {
                control.get('condition').patchValue('and');
            } else {
                control.get('condition').patchValue('or');
            }
            this.getGroupCount();
        }
    }

    public operatorChange(event: any, index: number) {

        const viewConditionRow = this.getVewConditions().controls[index] as FormGroup;

        viewConditionRow.get('value').patchValue('');

    }

    public openDatePicker(index: number): void {

        const viewConditionRow = this.getVewConditions().controls[index] as FormGroup;

        const val = viewConditionRow.get('value').value ? viewConditionRow.get('value').value.split('/') : [];

        viewConditionRow.get('value').updateValueAndValidity();

        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: {
                id: 'custom',
                value: {
                    rawStart: val.length === 2 ? new Date(val[1]) : '',
                    rawEnd: val.length === 2 ? new Date(val[0]) : ''
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                viewConditionRow.get('value').patchValue(result.value.end + '/' + result.value.start);
            }
        });
    }

    public customFormChange(event, control) {
        control.get('value').patchValue(event.value);
    }

    public agentSelection(agents, control) {
        control.get('value').patchValue(JSON.stringify(agents.map((agent: Agent) => {
            return agent.crm_id;
        })));
    }

    public getAgentsValue(control) {
        if (!control.get('value').value) {
            return '';
        }
        return JSON.parse(control.get('value').value).join(',');

    }
}
