import store from 'store';

export class Permissions {

    constructor() {}

    public can(group: string, action: string | Array<string>): boolean {
        let permissions = store.get('as-permissions');
        const isAdmin = store.get('is_admin');

        if (isAdmin) {
            return true;
        }

        if (permissions) {

            permissions = Object.values(permissions);

            if (Array.isArray(action)) {
                return action.some((item) => {
                    const perm = permissions.find((rule) => {
                        return rule.type === group && rule.permission === item;
                    });

                    return perm ? perm.can : false;
                });
            }

            if (group && action && permissions) {
                const item = permissions.find((rule) => {
                    return rule.type === group && rule.permission === action;
                });

                return item ? item.can : true;
            }
        }

        return false;
    }
}
