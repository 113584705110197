import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-view-table-skeleton',
    templateUrl: './view-table-skeleton.component.html',
    styleUrls: ['./view-table-skeleton.component.less']
})
export class ViewTableSkeletonComponent implements OnInit {

    @Input() public pageSize: number;

    @Input() public numberOfRows: number;

    @Input() public fieldsLength: number;

    @Input() public active: any;

    public headerRows: Array<null> = Array.from({
        length: 2
    }, () => {
        return null;
    });

    constructor() {

    }


    ngOnInit() {
    }


    public getRowsArray(): Array<null> {
        return Array.from({
            length: this.numberOfRows ? this.numberOfRows : this.pageSize
        }, () => {
            return null;
        });
    }

    public getTableWidth(): any {
        const table = document.getElementById('view-table');
        const header = document.getElementById('header-row');
        if (table && header) {
            return {
                'width.px': header.offsetWidth > table.offsetWidth ? header.offsetWidth : table.offsetWidth
            };
        }
        return {};
    }
}
