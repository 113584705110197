import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallReportTableComponent} from './call-report-table.component';
import {MatDividerModule, MatPaginatorModule, MatSortModule, MatTableModule, MatTooltipModule} from '@angular/material';

@NgModule({
    declarations: [CallReportTableComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatDividerModule,
        MatTooltipModule
    ],
    exports: [CallReportTableComponent]
})

export class CallReportTableModule {
}
