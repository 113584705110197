import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Cookies} from '../../helpers/Cookies';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
    }

    private getRequest(data) {
        const type = localStorage.getItem('role_type');
        const board_id = localStorage.getItem('board_id');
        const token = Cookies.get('token');

        let headers = {};
        let params = {};

        if (type) {
            params['type'] = type || '';
        }

        if (board_id) {
            params['board_id'] = board_id || '';
        }

        if (token.length > 0) {
            headers = new HttpHeaders({
                'Authorization': 'Bearer ' + token,
                'Timezone': ((new Date()).getTimezoneOffset() * 60).toString(),
                'Board': board_id || ''
            });
        }

        params = Object.assign(params, data.params ? data.params : {});

        let httpParams = new HttpParams();
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) {
                params[key].forEach(item => {
                    httpParams = httpParams.append(`${key}[]`, item);
                });
                // httpParams = httpParams.append(key, JSON.stringify(params[key]));
            } else {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        return {
            params: httpParams,
            headers: Object.assign(headers, data.headers ? data.headers : {}),
            body: Object.assign({}, data.body ? data.body : {}),
        };
    }

    public get(url, data) {
        return new Observable((observer) => {

            const req = this.http.get(
                environment.apiURL + url,
                this.getRequest(data)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        localStorage.setItem('sideNav', '');
                        location.href = '/login';
                        observer.error(e);
                        break;
                    case 403:
                        this.router.navigate(['access-denied']).then(() => {
                        });
                        observer.error(e);
                        break;
                    default:
                        observer.error(e);
                        break;
                }

                observer.complete();
            });

            return {
                unsubscribe() {
                    if (req) {
                        req.unsubscribe();
                    }
                    observer.complete();
                }
            };
        });
    }

    public post(url, data) {
        return new Observable((observer) => {

            const req = this.http.post(
                environment.apiURL + url,
                data.body ? data.body : {},
                this.getRequest(data)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        localStorage.setItem('sideNav', '');
                        location.href = '/login';
                        observer.error(e);
                        break;
                    case 403:
                        this.router.navigate(['access-denied']).then(() => {
                        });
                        observer.error(e);
                        break;
                    default:
                        observer.error(e);
                        break;
                }
                observer.complete();

            });

            return {
                unsubscribe() {
                    if (req) {
                        req.unsubscribe();
                    }
                    observer.complete();
                }
            };
        });
    }

    public put(url, data) {
        return new Observable((observer) => {

            const sub = this.http.put(
                environment.apiURL + url,
                data.body ? data.body : {},
                this.getRequest(data)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        localStorage.setItem('sideNav', '');
                        location.href = '/login';
                        observer.error(e);
                        break;
                    case 403:
                        this.router.navigate(['access-denied']).then(() => {
                        });
                        observer.error(e);
                        break;
                    default:
                        observer.error(e);
                        break;
                }
                observer.complete();

            });
            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }

    public delete(url, data) {
        return new Observable((observer) => {

            const sub = this.http.delete(
                environment.apiURL + url,
                this.getRequest(data)
            ).subscribe((r) => {
                observer.next(r);
                observer.complete();
            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        localStorage.setItem('sideNav', '');
                        location.href = '/login';
                        observer.error(e);
                        break;
                    case 403:
                        this.router.navigate(['access-denied']).then(() => {
                        });
                        observer.error(e);
                        break;
                    default:
                        observer.error(e);
                        break;
                }
                observer.complete();

            });

            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }
}


