import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';

@Component({
    selector: 'app-call-report-chart',
    templateUrl: './call-report-chart.component.html',
    styleUrls: ['./call-report-chart.component.less']
})
export class CallReportChartComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public data: any = [];

    private chart: AmChart;

    constructor(
        private AmCharts: AmChartsService
    ) {

    }

    ngOnInit() {
        this.build();
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.data && !change.data.firstChange) {
            this.build();
        }
    }

    build() {

        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }

        this.chart = this.AmCharts.makeChart('call-report-chart', {
            'type': 'serial',
            'theme': 'none',
            'marginRight': 70,
            'dataProvider': this.data,
            'valueAxes': [{
                'axisAlpha': 0,
                'position': 'left',
                'title': ''
            }],
            'startDuration': 1,
            'graphs': [{
                'balloonText': '<b>[[category]]: [[value]]</b>',
                'fillColorsField': 'color',
                'fillAlphas': 0.9,
                'lineAlpha': 0.2,
                'type': 'column',
                'valueField': 'value'
            }],
            'chartCursor': {
                'categoryBalloonEnabled': false,
                'cursorAlpha': 0,
                'zoomable': false
            },
            'categoryField': 'label',
            'categoryAxis': {
                'gridPosition': 'start',
                'labelRotation': 45
            },
            'export': {
                'enabled': false
            }
        });
    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
    }
}
