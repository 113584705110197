import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FunnelStatusReasonComponent} from './funnel-status-reason.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        FunnelStatusReasonComponent
    ],
    imports: [
        CommonModule,
        MatBadgeModule,
        MatButtonModule
    ],
    exports: [
        FunnelStatusReasonComponent
    ]
})
export class FunnelStatusReasonModule {
}
