import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateEditModalComponent} from './create-edit-modal.component';
import {ErrorDialogModule} from '../../general/error-dialog/error-dialog.module';
import {ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatError,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';

@NgModule({
    declarations: [
        CreateEditModalComponent
    ],
    imports: [
        CommonModule,
        ErrorDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule
    ],
    exports: [
        CreateEditModalComponent
    ],
    entryComponents:[
        CreateEditModalComponent
    ]
})
export class CreateEditModalModule {
}
