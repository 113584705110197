import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AgentService} from '../../services/agent/agent.service';
import {Agent} from '../../services/agent/Agent';
import {select, Store} from '@ngrx/store';
import {getAvatar} from '../../helpers/Avagar';

@Component({
    selector: 'app-agent-statistics',
    templateUrl: './agent-statistics.component.html',
    styleUrls: ['./agent-statistics.component.less']
})
export class AgentStatisticsComponent implements OnInit {
    public getAvatar = getAvatar;

    public formGroup: FormGroup;

    public agents: Agent[] = [];

    public products: any = [];

    public lists: any = {
        agents: [],
        products: []
    };

        public toolbarMenu = [
        {
            id: 'clear_list',
            label: 'Clear List',
            icon: 'loop',
            fn: (name) => {
                if (this.lists[name].length > 0) {
                    this.lists[name] = [];
                }
            }
        },

    ];

    public dataSource: any = [
        {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
        {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
        {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
        {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
        {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
        {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
        {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
        {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
        {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
        {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
        {position: 11, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
        {position: 12, name: 'Helium', weight: 4.0026, symbol: 'He'},
        {position: 13, name: 'Lithium', weight: 6.941, symbol: 'Li'},
        {position: 14, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
        {position: 15, name: 'Boron', weight: 10.811, symbol: 'B'},
        {position: 16, name: 'Carbon', weight: 12.0107, symbol: 'C'},
        {position: 17, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
        {position: 18, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
        {position: 19, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
        {position: 20, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    ];

    public displayedColumns: any = ['position', 'name', 'weight', 'symbol'];

    constructor(private agentService: AgentService,
                private store: Store<any>) {
    }

    ngOnInit() {

        this.formGroup = new FormGroup({
            agents: new FormControl('', []),
            products: new FormControl('', [])
        });

        this.getAgents();
        this.getProducts();
    }

    public getAgents() {
        this.store.pipe(select((state) => {
            return state.agents.allAgents;
        })).subscribe((res) => {
            this.agents = res;

        });
    }

    public getProducts() {
        this.products = [{id: 1, name: 'product 1'},{id: 2,name: 'product 2'}, {id: 3,name: 'product 3'}];
    }


    public selectEntity(event, name) {

        if (!event.value) {
            return;
        }

        const duplicates = this.lists[name].find((entity) => {
            return parseInt(event.value, 10) === entity.id;
        });

        if (!duplicates) {
            const fEntity = this[name].find((entity) => {
                return parseInt(event.value, 10) === entity.id;
            });

            if (fEntity) {
                this.lists[name].push(fEntity);
            }
        }

        this.formGroup.get(name).patchValue('');
    }

    public removeAgent(index, name) {
        this.lists[name].splice(index, 1);
    }


    public choseMenu(option: any, name): void {
        option.fn(name);

    }

    public showStatistic() {
        console.log('products: ',this.lists.products);
        console.log('agents: ',this.lists.agents);

    }
}
