import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhoneListComponent} from './phone-list.component';
import {
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule, MatProgressSpinnerModule, MatSnackBarModule
} from '@angular/material';
import {ConfirmModalModule} from '../../confirm-modal/confirm-modal.module';
import {ErrorDialogModule} from '../../general/error-dialog/error-dialog.module';
import {CreateEditModalModule} from '../create-edit-modal/create-edit-modal.module';
import {LoaderModule} from '../../general/loader/loader.module';

@NgModule({
    declarations: [
        PhoneListComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatPaginatorModule,
        MatButtonModule,
        MatMenuModule,
        ConfirmModalModule,
        ErrorDialogModule,
        MatSnackBarModule,
        CreateEditModalModule,
        LoaderModule,
        MatProgressSpinnerModule
    ],
    exports: [
        PhoneListComponent
    ],
    entryComponents: [
        PhoneListComponent
    ]
})
export class PhoneListModule {
}
