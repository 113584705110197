import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentSettingsComponent } from './agent-settings.component';
import {
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSlideToggleModule,
    MatSnackBarModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';

@NgModule({
  declarations: [
      AgentSettingsComponent
  ],
  imports: [
    CommonModule,
      MatButtonModule,
      MatCardModule,
      MatIconModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatInputModule,
      MatDividerModule,
      MatListModule,
      LoaderModule
  ],
    exports:[
        AgentSettingsComponent
    ]
})
export class AgentSettingsModule { }
