import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeStr'
})
export class TimeStrPipe implements PipeTransform {

  transform(elemT: any): string {
    const h = elemT.h < 10 ? '0' + elemT.h : '' + elemT.h;
    const m = elemT.m < 10 ? '0' + elemT.m : '' + elemT.m;
    return h + ':' + m;
  }

}
