import {Component, OnInit} from '@angular/core';
import BaseComponent from '../../base/base.component';
import {DateConverter} from '../../../helpers/DateConverter';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {MatDialog} from '@angular/material';
import {AssignReportService} from '../../../services/assign-report/assign-report.service';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';

@Component({
    selector: 'app-fresh-chart',
    templateUrl: './fresh-chart.component.html',
    styleUrls: ['./fresh-chart.component.less']
})
export class FreshChartComponent extends BaseComponent implements OnInit {

    public first: any = {};

    public chartInfoFreshUnique: any = {
        'type': 'pie',
        'theme': 'light',
        'dataProvider': [],
        'valueField': 'value',
        'titleField': 'leads',
        'outlineAlpha': 0.4,
        'depth3D': 15,
        'balloonText': '[[title]]<br><span style="font-size:16px"><b>[[value]]</b> ([[percents]]%)</span>',
        'angle': 30,
        'marginRight': 0,
        'marginLeft': 0,
        'marginTop': 60,
        'marginBottom': 0,
        'autoMarginOffset': 0,
        'export': {
            'enabled': false
        },
        'legend': {
            'align': 'center',
            'marginRight': 100,
            'autoMargins': false
        },
    };

    public chartInfoFreshNotUnique: any = {
        'type': 'pie',
        'theme': 'light',
        'dataProvider': [],
        'valueField': 'value',
        'titleField': 'leads',
        'outlineAlpha': 0.4,
        'depth3D': 15,
        'balloonText': '[[title]]<br><span style="font-size:16px"><b>[[value]]</b> ([[percents]]%)</span>',
        'angle': 30,
        'marginRight': 0,
        'marginLeft': 0,
        'marginTop': 0,
        'marginBottom': 0,
        'autoMarginOffset': 0,
        'export': {
            'enabled': false
        },
        'legend': {
            'align': 'center',
            'marginRight': 100,
            'autoMargins': false
        },
    };

    public chartCampaigns: any = {
        'type': 'pie',
        'theme': 'light',
        'dataProvider': [],
        'valueField': 'value',
        'titleField': 'label',
        'outlineAlpha': 0.4,
        'depth3D': 15,
        'balloonText': '[[title]]<br><span style="font-size:16px">Leads : <b>[[value]]</b> ([[percents]]%)</span>',
        'angle': 30,
        'marginRight': 0,
        'marginLeft': 0,
        'marginTop': 0,
        'marginBottom': 0,
        'autoMarginOffset': 0,
        'export': {
            'enabled': false
        },
        'legend': {
            'enabled': false,
            'align': 'center',
            'marginRight': 100,
            'autoMargins': false
        },
    };

    public chartCountries: any = {
        'type': 'pie',
        'theme': 'light',
        'dataProvider': [],
        'valueField': 'value',
        'titleField': 'label',
        'outlineAlpha': 0.4,
        'depth3D': 15,
        'balloonText': '[[title]]<br><span style="font-size:16px">Leads : <b>[[value]]</b> ([[percents]]%)</span>',
        'angle': 30,
        'marginRight': 0,
        'marginLeft': 0,
        'marginTop': 0,
        'marginBottom': 0,
        'autoMarginOffset': 0,
        'export': {
            'enabled': false
        },
        'legend': {
            'enabled': false,
            'align': 'center',
            'marginRight': 100,
            'autoMargins': false
        },
    };


    public selectedRange: any = {
        id: 'today',
        name: 'Today',
        icon: 'calendar_today',
        value: {
            start: DateConverter.addDays(new Date, 0, true),
            end: DateConverter.addDays(new Date, 0, true)
        }
    };

    public dateRangeSelect: any[] = [
        {
            id: 'today',
            name: 'Today',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, 0, true)
            }
        },
        {
            id: 'yesterday',
            name: 'Yesterday',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, -1, true),
                end: DateConverter.addDays(new Date, -1, true)
            }
        },
        {
            id: 'last_7',
            name: 'Last 7 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -7, true)
            }
        },
        {
            id: 'last_14',
            name: 'Last 14 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -14, true)
            }
        },
        {
            id: 'last_30',
            name: 'Last 30 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -30, true)
            }
        },
        {
            id: 'last_60',
            name: 'Last 60 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -60, true)
            }
        },
        {
            id: 'last_90',
            name: 'Last 90 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -90, true)
            }
        },
        {
            id: 'this_month',
            name: 'This Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date, 1, 0, true),
                end: DateConverter.addMonth(new Date, 0, 1, true)
            }
        },
        {
            id: 'last_month',
            name: 'Last Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date, 0, 0, true),
                end: DateConverter.addMonth(new Date, -1, 1, true)
            }
        },
    ];

    public sections = {
        general: {
            fn: (res) => {
                this.leadsAge = res;
                this.uniqGgraph = res.unique_graph;
                this.NotUniqueGraph = res.not_unique_graph;
                this.getChartDataUnique();
                this.getChartDataNotUnique();
                this.toggleLoader('general');
            },
            loader: false
        },
        countries: {
            loader: false,
            fn: (res) => {
                this.countriesData = res;
                this.countryGraph = res.countriesGraph;
                this.getCountriesChart();
                this.toggleLoader('countries');
            }
        },
        campaigns: {
            loader: false,
            fn: (res) => {
                this.campaignsData = res;
                this.campaignGraph = res.campaignsGraph;
                this.getCampaignChart();
                this.toggleLoader('campaigns');
            }
        }
    };

    private chartUnique: AmChart;

    private chartNotUnique: AmChart;

    private campaignsChart: AmChart;

    private countriesChart: AmChart;


    public chartDataFresh: any = {};

    public leadsAge: any = {};

    public countriesData: any = {};

    public campaignsData: any = {};

    public uniqGgraph: any = null;
    public NotUniqueGraph: any = null;

    public countryGraph: any = null;
    public campaignGraph: any = null;


    public displayedColumns_table: string[] = ['diff', 'unique', 'not_unique'];
    public displayedColumns_countries: string[] = ['country_of_residence', 'created', 'created_assign', 'assigned', 'assign_share'];
    public displayedColumns_campaigns: string[] = ['campaign_source', 'created', 'created_assign', 'assigned', 'assign_share'];

    constructor(private AmCharts: AmChartsService,
                private dialog: MatDialog,
                private AssignReport: AssignReportService) {
        super();


    }

    ngOnInit() {
        this.getAssignInfo();
    }

    public toggleLoader(section?: string) {
        if (!section || !this.sections[section]) {
            return;
        }
        this.sections[section].loader = !this.sections[section].loader;
    }

    public tabChanged(event) {
        if (event.index === 0) {
            this.getChartDataUnique();
        } else if (event.index === 1) {
            this.getChartDataNotUnique();
        }
    }

    public tabChangedTable(event) {
        if (event.index === 0) {
            this.getCampaignChart();
        } else if (event.index === 1) {
            this.getCountriesChart();
        }
    }

    public selectDate(range: any): void {
        this.selectedRange = range;
        this.getAssignInfo();
    }

    public openDatePicker(): void {
        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: this.selectedRange.id === 'custom' ? this.selectedRange : null
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedRange = result;
                this.getAssignInfo();
            }
        });
    }

    private getAssignInfo(): void {
        console.log('here');
        this.chartDataFresh.filter = {
            'start': this.selectedRange.value.end,
            'end': this.selectedRange.value.start,
            'type': 'leads'
        };

        for (const name in this.sections) {
            if (!this.sections.hasOwnProperty(name)) {
                continue;
            }
            this.toggleLoader(name);
            this.AssignReport.getAssignReport(Object.assign({
                section: name
            }, this.chartDataFresh.filter)).subscribe(this.sections[name].fn);

        }


    }

    private getCampaignChart(): void {

        const campaignChart = this.campaignGraph;

        if (this.campaignsChart) {
            this.AmCharts.destroyChart(this.campaignsChart);
            this.campaignsChart = null;
        }

        this.campaignsChart = this.AmCharts.makeChart('campaigns', Object.assign(this.chartCampaigns, {
            dataProvider: campaignChart
        }));

    }

    private getCountriesChart(): void {

        if (!this.first['countriesChart']) {
            this.first['countriesChart'] = true;
            return;
        }

        const countriesChart = this.countryGraph;

        if (this.countriesChart) {
            this.AmCharts.destroyChart(this.countriesChart);
            this.countriesChart = null;
        }

        this.countriesChart = this.AmCharts.makeChart('countries', Object.assign(this.chartCountries, {
            dataProvider: countriesChart
        }));

    }


    private getChartDataUnique(): void {

        const uniqueGraph = this.uniqGgraph;

        if (this.chartUnique) {
            this.AmCharts.destroyChart(this.chartUnique);
            this.chartUnique = null;
        }

        this.chartUnique = this.AmCharts.makeChart('unique-chart', Object.assign(this.chartInfoFreshUnique, {
            dataProvider: uniqueGraph
        }));

    }

    private getChartDataNotUnique(): void {

        if (!this.first['chartNotUnique']) {
            this.first['chartNotUnique'] = true;
            return;
        }

        if (this.chartNotUnique) {
            this.AmCharts.destroyChart(this.chartNotUnique);
            this.chartNotUnique = null;
        }

        this.chartNotUnique = this.AmCharts.makeChart('all-chart', Object.assign(this.chartInfoFreshNotUnique, {
            dataProvider: this.NotUniqueGraph
        }));

    }

}
