import {Component, OnDestroy, OnInit} from '@angular/core';
import * as AgentsActions from '../../actions/agents-actions';
import {AgentService} from '../../services/agent/agent.service';
import {Store} from '@ngrx/store';
import {CentrifugeService} from '../../services/centrifuge/centrifuge.service';
import * as LoginAction from '../../actions/login.actions';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit, OnDestroy {

    constructor(
        private agentService: AgentService,
        private store: Store<any>,
        private centrifugeService: CentrifugeService,
    ) {
        const board_id = parseInt(localStorage.getItem('board_id'), 10);
        const role_type = parseInt(localStorage.getItem('role_type'), 10);

        this.store.dispatch(new LoginAction.SetRoleType(role_type));
        this.store.dispatch(new LoginAction.SetBoardId(board_id));
    }

    ngOnInit() {
        this.agentService.getAllByTypeAgents().subscribe((result) => {
            this.store.dispatch(new AgentsActions.SetAgents(result.data.filter((agent) => {
                return !agent.hidden;
            })));
            this.store.dispatch(new AgentsActions.SetAllAgents(result.data));
        });

        this.agentService.getAllAgents().subscribe((result) => {
            this.store.dispatch(new AgentsActions.SetAllAgentsNew(result.data));
        });

        // console.log('--- centrifugo is off ---');
        this.centrifugeService.connect().subscribe(res => {});
    }

    ngOnDestroy(): void {
        this.store.dispatch(new AgentsActions.SetAllAgents([]));
        this.store.dispatch(new AgentsActions.SetAllAgentsNew([]));
    }
}
