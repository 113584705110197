import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LessThanPeriodInputComponent} from './less-than-period-input.component';
import {MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTooltipModule} from '@angular/material';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [LessThanPeriodInputComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        FormsModule,
        MatTooltipModule
    ],
    exports: [LessThanPeriodInputComponent]
})
export class LessThanPeriodInputModule {
}
