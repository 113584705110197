import {Component, Input, OnInit} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import BaseComponent from '../base/base.component';
import {Agent} from '../../services/agent/Agent';
import {AgentService} from '../../services/agent/agent.service';
import {UserStorageServiceService} from '../../services/uset-storage-service/user-storage-service.service';
import {select, Store} from '@ngrx/store';
import {MatDialog, MatSnackBar} from '@angular/material';
import {CallActivityInfoComponent} from '../call-activity-info/call-activity-info.component';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';
import {PROGRESS_BARS, PROGRESS_BARS_RETENTION, WIZARD_TYPES} from '../../helpers/constants';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_APPLICATIONS} from '../../constants/user';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import AssignBlocker from '../../helpers/AssignBlocker';
import {Marker} from '../../helpers/Marker';
import * as ViewsProgressActions from '../../actions/views-progress-actions';
import {AssignStatus} from '../agents-assign/assign-status.interface';
import {AssignStatusModalComponent} from '../assign-status-modal/assign-status-modal.component';
import {GetActiveBoard} from '../../reducers/selectors/login.selector';

@Component({
    selector: 'app-agent-list-item',
    templateUrl: './agent-list-item.component.html',
    styleUrls: ['./agent-list-item.component.less'],
    providers: [DecimalPipe],
})
export class AgentListItemComponent extends BaseComponent implements OnInit {

    @Input() public agent: Agent;
    @Input() public status: any;
    @Input() public role: any;

    displayedColumns: string[] = ['item', 'value'];

    public progressBars = null;

    public getAvatar = getAvatar;

    public loader = false;

    public isOpen = false;

    public skeleton: Array<null> = Array.from({
        length: 4
    }, () => {
        return null;
    });

    private agentsFilter: any = [];

    constructor(
        private agentsService: AgentService,
        private userStorageService: UserStorageServiceService,
        private store: Store<any>,
        private snackBar: MatSnackBar,
        private confirmModal: MatDialog,
        private dialog: MatDialog,
        public permissions: Permissions
    ) {
        super();

        this.store.pipe(
            select('views'),
            takeUntil(this.destroy)
        ).subscribe((value: any) => {
            if (value && value['filter'] && value.filter['filter[assigned_to]']) {
                this.agentsFilter = value.filter['filter[assigned_to]'].split(',').map((val) => {
                    return parseInt(val, 10);
                });
            }
        });
    }

    ngOnInit() {
        this.store.pipe(
            select(GetActiveBoard),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((board: any) => {
            this.progressBars = WIZARD_TYPES[board.wizard_type];
        });
    }

    public drop(event, agent: Agent): void {
        event.preventDefault();

        if (!this.permissions.can('agent', ['can_assign_lead_agent', 'can_assign_application_agent'])) {
            return;
        }

        const lead = JSON.parse(event.dataTransfer.getData('lead'));

        const data = {
            title: 'Are you sure?',
            description: 'Are you want to assign ' + lead[0].first_name + ' to ' + agent.crm_username + '?'
        };

        if (lead.length > 1) {
            data.description = 'Are you want assign ' + lead.length + ' leads to ' + agent.crm_username + '?';
        }

        const confirmModal = this.confirmModal.open(ConfirmModalComponent, {
            data: data
        });

        confirmModal.afterClosed().subscribe((response) => {
            if (response) {

                if (AssignBlocker.block(agent)) {
                    this.snackBar.open('Agent \'' + agent.crm_username + '\' on a call assign blocked!', 'Dismiss', {
                        duration: 3000,
                    });
                    return;
                }

                this.assign(lead, agent);
            }
        });
    }

    private assign(lead, agent: Agent): void {

        const assignData = {};
        const leadNumbers = {};

        const leads = lead.map((value) => {
            assignData[value.id] = agent;
            leadNumbers[value.lead_number_sort] = value.id;
            return value.id;
        });

        this.agentsService.assignLead(agent.id, leads, 'Assign System').subscribe((res) => {
            document.dispatchEvent(new Event('clearselection'));

            const markLeads = [];
            res.forEach(item => {
                if (item.status) {
                    markLeads.push(leadNumbers[item.lead_number]);
                }
            });

            for (let item in assignData) {
                if (!markLeads.includes(+item)) {
                    delete assignData[item];
                }
            }

            Marker.mark('newLeads', markLeads, 're-assign');

            this.store.dispatch(new ViewsProgressActions.SetData(assignData));

            this.showAssignStatusModal(res);

            if (this.agentsFilter.length > 0 && !this.agentsFilter.includes(agent.crm_id)) {
                for (let i = 0; i < leads.length; i++) {
                    const elem = document.getElementById('lead-row-' + leads[i]);
                    if (elem) {
                        elem.setAttribute('style', 'display: none');
                    }
                }
            }

            setTimeout(() => {
                this.store.dispatch(new ViewsProgressActions.ClearData(assignData));
            }, 500);
        }, ({error}) => {
            if (error) {
                for (const name in error) {
                    this.snackBar.open(error[name], 'Dismiss', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });
                }
            } else {
                this.snackBar.open('Something Went Wrong!', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            }
        });
    }

    private showAssignStatusModal(res: AssignStatus[]): void {
        this.confirmModal.open(AssignStatusModalComponent, {
            data: res
        });
    }

    public viewInfo(agent: Agent): void {
        if (!this.permissions.can('agent', 'can_lead_info_agent')) {
            return;
        }

        this.dialog.open(CallActivityInfoComponent, {
            width: '1200px',
            data: {
                agent: agent,
                id: 'assign-data'
            }
        });
    }
}
