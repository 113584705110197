import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../classes/unsubscribe';
import {getAvatar} from '../../helpers/Avagar';
import {FormControl} from '@angular/forms';
import {AgentService} from '../../services/agent/agent.service';
import SearchResponse from '../../search.response';
import {GetBoardId} from '../../reducers/selectors/login.selector';
import {PermissionsService} from '../../services/permissions/permissions.service';
import {Permissions} from '../../helpers/Permissions';

@Component({
  selector: 'app-department-roles',
  templateUrl: './department-roles.component.html',
  styleUrls: ['./department-roles.component.less']
})
export class DepartmentRolesComponent extends Unsubscribe implements OnInit {

  public getAvatar = getAvatar;

  public availableRoles = null;

  public departmentRoles = new FormControl();

  public groups: SearchResponse = new SearchResponse();

  public columnsToDisplay: string[] = ['avatar', 'full_name', 'email', 'role', 'hidden', 'isDialer'];

  public filterData: any = {};

  public rolesFilter = [{value: '', label: 'All'}];

  public hiddenFilter = [{value: '', label: 'All'}, {value: '1', label: 'Yes'}, {value: '0', label: 'No'}];

  public roleType: number;
  public boardId: number;

  public loader = false;

  constructor(
      private agentService: AgentService,
      private store: Store<any>,
      public permissions: Permissions
  ) {
    super();

    this.store.pipe(
        select('login'),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((data: any) => {
        this.roleType = data.role_type;
    });

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        // tslint:disable-next-line:no-shadowed-variable
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.boardId = id;
    });
  }

  ngOnInit() {
    this.agentService.getAvailableRoles().pipe(
        takeUntil(this.destroy)
    ).subscribe(res => {
      this.availableRoles = res;

      const options = [];
      const roles = [];

      this.availableRoles.forEach(role => {
        roles.push({
          label: role.role_name.replace('_', ' '),
          value: role.role_name
        });

        if (role.saved) {
          options.push(role.role_name);
        }
      });

      this.rolesFilter.push(...roles);

      this.departmentRoles.setValue(options);

      if (options.length > 0) {
        this.getUsers();
      }
    });
  }

  public save(): void {
    this.loader = true;

    const data = {
      roles: this.departmentRoles.value,
      type: this.roleType,
      board_id: this.boardId
    };

    this.agentService.sendAgentRoles(data).pipe(
        takeUntil(this.destroy)
    ).subscribe(res => {
      this.getUsers();
    }, err => {
      this.loader = false;
    });
  }

  private getUsers() {
    this.loader = true;

    this.agentService.filterAgents(this.groups.getReqData()).pipe(
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.loader = false;
      this.groups = Object.assign(this.groups, res);
    }, err => {
      this.loader = false;
    });
  }

  public changePage(event: any): void {
    this.groups.page = event.pageIndex;
    this.groups.page_size = event.pageSize;
    this.getUsers();
  }

  public changeSort(event: any) {
    this.groups.addSort(event.active, event.direction);
    this.getUsers();
  }

  public filterChange(e) {
    const event = e.source ? e.source.ngControl : e.target;

    this.groups.addFilter(event.name, event.value);

    this.getUsers();
  }

}
