import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suggestionStatus'
})
export class SuggestionStatusPipe implements PipeTransform {

  transform(val: number): string {
    switch (val) {
      case 0: return 'Finished';
      case 1: return 'In Progress';
      default: return '' + val;
    }
  }

}
