import {Observable, of, ReplaySubject} from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import {Unsubscribe} from './unsubscribe';
import {UserService} from '../services/user/user.service';
import {catchError, takeUntil} from 'rxjs/operators';
import * as LoginAction from '../actions/login.actions';
import {select, Store} from '@ngrx/store';
import {ViewService} from '../services/view/view.service';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';

@Component({
    template: ``
})

// tslint:disable-next-line:component-class-suffix
export class SelectBoard extends Unsubscribe {

    private userId: number;

    constructor(
        public viewService: ViewService,
        public userService: UserService,
        public router: Router,
        public store: Store<any>
    ) {
        super();

        this.store.pipe(
            select('login'),
            takeUntil(this.destroy)
        ).subscribe((data: any) => {
            this.userId = data.id;
        });
    }

    private getPermissions(): Observable<any> {
       return  this.userService.getUserPermission({
           user_id: this.userId
       });
    }

    private getDefaultView(): Observable<any> {
        return this.viewService.getDefaultView();
    }

    public clearBoard(): void {
        this.store.dispatch(new LoginAction.SetRoleType(''));
        this.store.dispatch(new LoginAction.SetBoardId(''));
        this.store.dispatch(new LoginAction.SetActiveBoard(''));

        localStorage.setItem('board_id', '');
        localStorage.setItem('role_type', '');
        localStorage.setItem('active_board', '');
    }

    public selectBoard(board: BoardInterface): void {
        this.store.dispatch(new LoginAction.SetRoleType(board.type));
        this.store.dispatch(new LoginAction.SetBoardId(board.id));
        this.store.dispatch(new LoginAction.SetActiveBoard(board));

        localStorage.setItem('board_id', board.id.toString());
        localStorage.setItem('role_type', board.type.toString());
        localStorage.setItem('active_board', JSON.stringify(board));

        this.getPermissions().pipe(
            takeUntil(this.destroy)
        ).subscribe((permissions) => {
            localStorage.setItem('as-permissions', JSON.stringify(permissions));

            const login = JSON.parse(localStorage.getItem('user'));

            if (login.is_super_admin || permissions['v1/view/get-default'] && permissions['v1/view/get-default'].can) {

                this.getDefaultView().pipe(
                    takeUntil(this.destroy)
                ).subscribe((view) => {
                    localStorage.setItem('default_view', JSON.stringify(view));

                    this.redirect();

                }, () => {

                    this.redirect();

                });

            } else {

                this.redirect();

            }
        });
    }

    private redirect(): void {
        if (window.location.href.indexOf('/board/') !== -1) {
            this.router.navigate(['/dashboard']);
        } else {
            window.location.reload();
        }
    }

}
