import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddAgentComponent} from './add-agent.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule, MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule
} from '@angular/material';
import {LoaderModule} from '../../../general/loader/loader.module';
import {ReactiveFormsModule} from '@angular/forms';
// import {AddGroupComponent} from '../../add-group/add-group.component';
// import {CompleteModalComponent} from '../../../../general/complete-modal/complete-modal.component';
import {ConfirmModalComponent} from '../../../confirm-modal/confirm-modal.component';

@NgModule({
    declarations: [
        AddAgentComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        LoaderModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule
    ],
    exports: [
        AddAgentComponent
    ],
    entryComponents: [
        ConfirmModalComponent
    ]
})
export class AddAgentModule {
}
