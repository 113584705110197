import {Component, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import SearchResponse from '../../search.response';
import {AgentService} from '../../services/agent/agent.service';
import {MatDialog} from '@angular/material';

import storage from 'store';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DateRangePickerComponent} from "../general/date-range-picker/date-range-picker.component";
import {DateConverter} from "../../helpers/DateConverter";
import {SelectionModel} from '@angular/cdk/collections';

import _ from 'lodash'


interface KpiSettingColorValue {
    red_from: number;
    red_to: number;
    yellow_from: number;
    yellow_to: number;
    green_from: number;
    green_to: number;
}

interface KpiSettingColors {
    benchmarks: KpiSettingColorValue;
    overall: KpiSettingColorValue;
    attempt: KpiSettingColorValue;
    effective: KpiSettingColorValue;
    effective_amount: KpiSettingColorValue;
    productivity: KpiSettingColorValue;
    monthly_goal: KpiSettingColorValue;
    swiped_to_sale: KpiSettingColorValue;
}

interface KpiSettings {
    last_amount_type: string;
    colors: KpiSettingColors;
}

@Component({
    selector: 'app-agents-kpi',
    templateUrl: './agents-kpi.component.html',
    styleUrls: ['./agents-kpi.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AgentsKPIComponent extends BaseComponent implements OnInit, OnDestroy {

    readonly TotalsInitial = {
        attempt: 0,
        effective: 0,
        effective_amount: 0,
        productivity: 0,
        overall: 0,
        swiped_to_sale: 0,
        sales_goal: 0,
        sales_goal_gross: 0,
        attempt_gross: 0,
        effective_gross: 0,
        effective_amount_gross: 0,
        productivity_gross: 0,
        overall_gross: 0,
    };

    readonly InitialGroup = {
        id: 0,
        name: 'All',
        icon: 'group_work',
    };

    public calculationTypes = [
        {
            id: 'working_days',
            mg: 'working_days_in_goal_period',
            type: '',
            icon: 'work',
            label: 'Net',
            additional: 'working_days_to_the_end_of_goal',
        },
        {
            id: 'working_days_planned',
            mg: 'working_days_in_goal_period_planned',
            type: '_gross',
            icon: 'work_outline',
            label: 'Gross',
            additional: ''
        },
    ];

    public calculationType = this.calculationTypes[0];

    public selection = new SelectionModel<any>(true, []);

    public agentsKPI: SearchResponse = new SearchResponse({
        page_size: 50,
    });

    private $kpiSettings: KpiSettings;

    public set kpiSettings(val: KpiSettings) {
        _.keys(_.get(val, ['colors'], {})).forEach(key => {
            let cacheKey: string = key;
            const defaultModalKey = 'user_colors_';

            switch (key) {
                case 'benchmarks':
                    cacheKey = defaultModalKey + 'column';
                    break;
                case'overall':
                    cacheKey = defaultModalKey + 'overall';
                    break;
                case'attempt':
                case'swiped_to_sale':
                case'effective':
                case'effective_amount':
                case'productivity':
                case'monthly_goal':
                    cacheKey = defaultModalKey + 'column_' + key;
                    break;
            }
            storage.set(cacheKey, _.get(val, ['colors', key], {
                red_from: null,
                red_to: null,
                yellow_from: null,
                yellow_to: null,
                green_from: null,
                green_to: null,
            }));
        });


        this.$kpiSettings = val;
    }

    public get kpiSettings(): KpiSettings {
        return this.$kpiSettings;
    }

    public group = storage.get('group') ? storage.get('group') : Object.assign({}, this.InitialGroup);

    public totals: any = this.TotalsInitial;

    public displayedColumns: any = [
        'full_name',
        'attempt',
        'effective',
        'effective_amount',
        'swiped_to_sale',
        'productivity',
        'monthly_goal',
        'overall',
        'working_days'
    ];

    public columns: any = ['attempt', 'effective', 'effective_amount', 'productivity'];

    public listButtons: any = {
        data: []
    };

    public legends: any = {
        default: (column) => {
            return 'KPI Value / (' + this.agentsKPI.additional.goals[column] + ' * Working Days)';
        },
        attempt: (column) => {
            return 'Attempts / (' + this.agentsKPI.additional.goals[column] + ' * Working Days)';
        },
        effective: (column) => {
            return '(Effective / Answered) / ' + this.agentsKPI.additional.goals[column] + '';
        },
        swiped_to_sale: () => {
            return '(Swiped / Sale)';
        },
        effective_amount: (column) => {
            return 'Effective calls / (' + this.agentsKPI.additional.goals[column] + ' * Working Days)';
        },
        productivity: (column) => {
            return 'Productivity / ' + (this.agentsKPI.additional.goals[column] * 100);
        },
        overall: () => {
            return '(Attempts + Effective + Effective Amount + Productivity + Monthly Goal) / 5';
        },
        monthly_goal: () => {
            return 'Amount Of Sales / (Monthly Goal / Working Days)';
        }
    };

    public options: any = [];

    public loader: any = true;

    public subscriber: any;

    public date: any = {
        from: 0,
        to: 0,
    };

    public raw: any = {
        from_at: null,
        to_at: null
    };

    public menuIcons = {
        monthly_goal: 'rowing',
        attempt: 'call_missed',
        effective: 'call_made',
        effective_amount: 'call_split',
        productivity: 'timelapse',
    };

    constructor(
        private agentsService: AgentService,
        private dialog: MatDialog,
    ) {
        super();
        this.getAgentsKpiList();
        // this.getGroupList();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    public select(option, element) {
        option.fn(option, element);
    }

    public changePage(event: any): void {
        this.agentsKPI.page = event.pageIndex;
        this.agentsKPI.page_size = event.pageSize;
        this.toggleLoader();
        this.getAgentsKpiList();
    }

    private getAgentsKpiList() {

        this.subscriber = this.agentsService.getAgentsKpi(Object.assign({},
            this.agentsKPI.getReqData(),
            this.date.from > 0 ? this.date : {},
            this.group.id > 0 ? {user_groups_id: this.group.id} : {})).subscribe((res) => {

            this.agentsKPI = Object.assign(this.agentsKPI, Object.assign(res, {
                data: _.map((_.get(res, ['data'], []) || []), (user) => ({
                    ...user,
                    kpi: {
                        attempt: _.get(user, 'kpi.attempt', 0) || 0,
                        productivity: _.get(user, 'kpi.productivity', 0) || 0,
                        effective_amount: _.get(user, 'kpi.effective_amount', 0) || 0,
                        effective: _.get(user, 'kpi.effective', 0) || 0,
                        swiped_amount: _.get(user, 'kpi.swiped_amount', 0) || 0,
                        sale_amount: _.get(user, 'kpi.sale_amount', 0) || 0,
                        sale: parseInt(String(Math.round(_.get(user, 'kpi.sale', 0))), 10) || 0,
                        big_sale: _.get(user, 'kpi.big_sale', 0) || 0,
                        effective_vs_swipe: _.get(user, 'kpi.effective_vs_swipe', 0) || 0,
                        monthly_goal: parseInt(String(Math.round(_.get(user, 'kpi.monthly_goal', 0))), 10) || 0,
                        working_days: _.get(user, 'kpi.working_days', 0) || 0,
                        working_days_planned: _.get(user, 'kpi.working_days_planned', 0) || 0,
                        working_days_in_goal_period: _.get(user, 'kpi.working_days_in_goal_period', 0) || 0,
                        working_days_in_goal_period_planned: _.get(user, 'kpi.working_days_in_goal_period_planned', 0) || 0,
                        working_days_to_the_end_of_goal: _.get(user, 'kpi.working_days_to_the_end_of_goal', 0) || 0,
                    }
                }))
            }));

            this.kpiSettings = Object.assign({}, this.kpiSettings, _.get(res, ['additional', 'settings']));

            this.totals = Object.assign({}, this.TotalsInitial);

            // const days = this.agentsKPI.additional.days + 1;

            for (const user of this.agentsKPI.data) {

                let sum = 0;

                let sumGross = 0;

                let mg = 0;

                let mgGross = 0;

                if (user.kpi.working_days_in_goal_period > 0 && user.kpi.monthly_goal > 0) {
                    mg = user.kpi.monthly_goal / (user.kpi.working_days_in_goal_period + user.kpi.working_days_to_the_end_of_goal);
                }

                if (user.kpi.working_days_in_goal_period_planned > 0 && user.kpi.monthly_goal > 0) {
                    mgGross = user.kpi.monthly_goal / user.kpi.working_days_in_goal_period_planned;
                }

                if (mg > 0) {
                    user.kpi.sales_goal = ((user.kpi.sale / (user.kpi.working_days ? user.kpi.working_days : 1)) / mg) * 100;
                } else {
                    user.kpi.sales_goal = 0;
                }

                const saleAmount = _.get(user.kpi, ['sale_amount'], 0);
                const swipedAmount = _.get(user.kpi, ['swiped_amount'], 0);

                if (saleAmount > swipedAmount) {
                    user.kpi.swiped_to_sale_gross = user.kpi.swiped_to_sale = 100;
                } else {
                    user.kpi.swiped_to_sale_gross = user.kpi.swiped_to_sale = 100 * Math.abs(
                        (swipedAmount - saleAmount) / (swipedAmount + saleAmount) / 2
                    ) || 0;
                }

                if (mgGross > 0) {
                    user.kpi.sales_goal_gross = ((user.kpi.sale / (user.kpi.working_days ? user.kpi.working_days : 1)) / mgGross) * 100;
                } else {
                    user.kpi.sales_goal_gross = 0;
                }

                if (user.kpi.sales_goal > 100) {
                    user.kpi.sales_goal = 100;
                }

                if (user.kpi.sales_goal_gross > 100) {
                    user.kpi.sales_goal_gross = 100;
                }

                sum += user.kpi.sales_goal;

                sumGross += user.kpi.sales_goal_gross;

                for (const k of this.columns) {

                    if (!user.kpi[k]) {
                        user.kpi[k] = 0;
                    }

                    user.kpi[k + '_gross'] = user.kpi[k];

                    let goal = this.agentsKPI.additional.goals[k];

                    let goalGross = this.agentsKPI.additional.goals[k];

                    if (!['productivity', 'effective'].includes(k)) {
                        goal = goal * user.kpi.working_days;
                        goalGross = goal * user.kpi.working_days_planned;
                    }

                    if (goal > 0) {
                        user.kpi[k] = (user.kpi[k] / goal) * 100;
                    } else {
                        user.kpi[k] = 0;
                    }

                    if (goalGross > 0) {
                        user.kpi[k + '_gross'] = (user.kpi[k + '_gross'] / goalGross) * 100;
                    } else {
                        user.kpi[k + '_gross'] = 0;
                    }

                    if (user.kpi[k] > 100) {
                        user.kpi[k] = 100;
                    }

                    if (user.kpi[k + '_gross'] > 100) {
                        user.kpi[k + '_gross'] = 100;
                    }

                    sum += user.kpi[k];

                    sumGross += user.kpi[k + '_gross'];
                }

                user.overall = sum / (this.columns.length + 1);

                user.overall_gross = sumGross / (this.columns.length + 1);

            }


            for (const user of this.agentsKPI.data) {

                for (const k in this.totals) {

                    if (!this.totals.hasOwnProperty(k)) {
                        continue;
                    }

                    if (user.kpi[k]) {
                        this.totals[k] += user.kpi[k];
                    }

                    if (user.kpi[k + '_gross']) {
                        this.totals[k + '_gross'] += user.kpi[k + '_gross'];
                    }
                }

                this.totals.overall += user.overall;

                this.totals.overall_gross += user.overall_gross;
            }

            for (const k in this.totals) {

                if (!this.totals.hasOwnProperty(k)) {
                    continue;
                }

                this.totals[k] = this.totals[k] / this.agentsKPI.data.length;

                if (!this.totals[k]) {
                    this.totals[k] = 0;
                }

                this.totals[k + '_gross'] = this.totals[k + '_gross'] / this.agentsKPI.data.length;

                if (!this.totals[k + '_gross']) {
                    this.totals[k + '_gross'] = 0;
                }
            }

            this.toggleLoader();
        });
    }

    public changeSort(event: any) {

    }

    public getColor(percent, name, col) {

        let color = storage.get('user_colors' + '_' + name + '_' + col);


        if (!color) {
            color = storage.get('user_colors' + '_' + name);
        }

        if (!color) {
            return;
        }

        if (percent >= color.green_from && percent <= color.green_to) {
            return 'green';
        }

        if (percent >= color.yellow_from && percent <= color.yellow_to) {
            return '#ff9400';
        }

        if (percent >= color.red_from && percent <= color.red_to) {
            return 'red';
        }
    }

    public openList(group) {
        this.group = group;
        storage.set('group', group);
        this.agentsKPI.page = 0;
        this.toggleLoader();
        this.getAgentsKpiList();
    }

    public refresh() {
        this.toggleLoader();
        this.getAgentsKpiList();
    }

    public setDate() {
        this.dialog.open(DateRangePickerComponent, {
            width: '500px',
            panelClass: 'open-range-modal',
            data: this.raw,
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.date = {
                    from: (DateConverter.startOfDay(result.from_at).getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000)) / 1000,
                    to: (DateConverter.endOfDay(result.to_at).getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000)) / 1000,
                };
                this.raw = result;
                this.agentsKPI.page = 0;
                this.getAgentsKpiList();
                this.toggleLoader();
            }
        });
    }

    public isAllSelected(): boolean {

        return this.selection.selected.length === this.agentsKPI.data.length;
    }

    public masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.agentsKPI.data.forEach(reward => this.selection.select(reward));
    }
}
