import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/internal/operators';

@Component({
    selector: 'app-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.less']
})

export class MultipleSelectorComponent implements OnInit {

    public selected: string[] = [];

    public autoCompleteData: string[] = [];

    public autoComplete: FormControl;

    public filteredOptions: Observable<string[]>;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private self: MatBottomSheetRef<MultipleSelectorComponent>
    ) {
        this.selected = this.data.selected;
        this.autoCompleteData = this.data.autoComplete || [];
        this.autoComplete = new FormControl();
        this.self.backdropClick().subscribe(() => {
            this.self.dismiss(this.selected);
        });
    }

    ngOnInit() {
        this.filteredOptions = this.autoComplete.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }

    public onEnter(event) {
        event.preventDefault();
        if (event.keyCode === 13) {
            this.addItem();
        }
    }

    public addItem(): void {

        if (!this.selected.includes(this.autoComplete.value) && this.autoComplete.value) {
            this.selected.push(this.autoComplete.value);
        }

        this.autoComplete.patchValue('');
    }

    public clearFilter(): void {
        this.selected = [];
    }

    public deleteItem(index: number) {
        this.selected.splice(index, 1);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.autoCompleteData.filter((option) => {
            if (typeof option === 'string') {
                return option.toLowerCase().includes(filterValue);
            } else {
                return option;
            }
        });
    }
}
