import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditViewComponent} from './edit-view.component';
import {RouterModule} from '@angular/router';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConditionsModule} from '../conditions/conditions.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        EditViewComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        LoaderModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        RouterModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        ConditionsModule,
        MatTooltipModule,
        MatProgressSpinnerModule
    ],
    exports: [
        EditViewComponent
    ]
})

export class EditViewModule {
}
