import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ViewService} from '../../../services/view/view.service';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {TYPE_APPLICATIONS, TYPE_LEADS} from '../../../constants/user';

@Component({
  selector: 'app-create-view-control',
  templateUrl: './create-view-control.component.html',
  styleUrls: ['./create-view-control.component.less']
})
export class CreateViewControlComponent implements OnInit {

  public loader = false;

  public viewControlForm: FormGroup;

  constructor(
      public self: MatDialogRef<CreateViewControlComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private service: AssignSettingService,
      private snack: MatSnackBar,
  ) { }

  ngOnInit() {
    this.viewControlForm = new FormGroup({
      freshEn: new FormControl(null, [Validators.required]),
      reassignedEn: new FormControl(null, [Validators.required]),
      title: new FormControl('', [Validators.required]),
      prefix: new FormControl('', [Validators.required]),
      board_id: new FormControl(this.data.boardId, []),
      type: new FormControl(this.data.role),
    });

    if (this.data.view) {
      this.viewControlForm.patchValue(this.data.view);

      if (this.data.view.freshLeads !== undefined) {
        this.viewControlForm.get('freshEn').setValue(this.data.view.freshLeads);
        this.viewControlForm.get('reassignedEn').setValue(this.data.view.reassignedLeads);
      }
    }
  }

  public submit(): void {
    if (this.viewControlForm.valid) {
      this.loader = true;

      const data = this.viewControlForm.getRawValue();

      if (this.data.index !== null) {
        this.data.list.splice(this.data.index, 1, data);
      } else {
        this.data.list.push(data);
      }

      this.data.list.forEach(item => {
        if (item.freshLeads !== undefined) {
          item.freshEn = item.freshLeads;
          item.reassignedEn = item.reassignedLeads;

          delete item.freshLeads;
          delete item.reassignedLeads;
        }
      });

      const body = {
        setting_slug: this.data.slug,
        setting: {
          views: this.data.list
        }
      };

      this.service.updateAssignSetting(body).subscribe((res) => {
        const message = this.data.index !== undefined ? 'View control was updated successfully!' : 'View control was created successfully!';

        this.snack.open(message, 'Ok!', {
          duration: 1000,
        });

        this.self.close(this.data.list);

        this.loader = false;
      }, err => {
        this.snack.open('Something Went Wrong!', 'Ok!', {
          duration: 2000,
        });

        this.loader = false;
      });
    }
  }

}
