import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AssignStatusModalComponent} from './assign-status-modal.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        AssignStatusModalComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: [
        AssignStatusModalComponent
    ]
})
export class AssignStatusModalModule {
}
