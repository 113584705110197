import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {Agent} from './Agent';
import {SseService} from '../sse/sse.service';
import {TYPE_APPLICATIONS} from '../../constants/user';

@Injectable({
  providedIn: 'root'
})

export class AgentService {

  constructor(
      private api: ApiService,
      private sse: SseService,
  ) {}

  public getAgents(page, page_size, sort, filter, add?, statistics?, hide_admin?, withCount?): Observable<any> {
    return this.api.get('v1/agent', {
      params: Object.assign({}, {
        page: page,
        page_size: page_size,
        add: add ? 1 : 0,
        statistics: statistics ? 1 : 0,
        hide_admin: hide_admin ? 1 : 0,
        withCount: typeof withCount === 'undefined' ? 1 : withCount,
        sort: sort,
        filter: filter
      })
    });
  }

  public filterAgents(params): Observable<any> {
    return this.api.get('v1/agent', {
      params: params
    });
  }

  public changeShift(id, shift): Observable<any> {
    return this.api.post('v1/agent/' + id + '/change-shift', {
      body: {
        shift: shift
      }
    });
  }

  public assignLead(id, leadIds: any, system): Observable<any> {
    const viewId: number = parseInt(localStorage.getItem('currentView'), 10);
    const viewName: string = localStorage.getItem('currentViewName');

    const roleType = parseInt(localStorage.getItem('role_type'), 10);
    let type = 'assign-lead';
    if (roleType === TYPE_APPLICATIONS) {
      type = 'assign-application';
    }

    return this.api.post('v1/agent/' + id + '/' + type, {
      body: {
        leadIds: leadIds,
        additional: {
          ...(Boolean(viewName) ? {view_name: viewName} : {}),
          ...(Boolean(viewId) ? {view_id: viewId} : {}),
          ...(Boolean(system) ? {assign_from_system: system} : {}),
        }
      }
    });
  }

  public getUpdates(): Observable<any> {
    return this.sse.get('events/agent');
  }

  public getCallUpdates(): Observable<any> {
    return this.sse.get('events/call_activity');
  }

  public getLeadStatistics(id: number): Observable<any> {
    return this.api.get('v1/agent/' + id + '/lead-statistics', {
      params: {}
    });
  }

  public syncAgents(): Observable<any> {
    return this.api.post('v1/agent/sync', {
      body: {}
    });
  }

  public getAllAgents(): Observable<any> {
    const params = {
      page: 0,
      page_size: 1000,
      add: 0,
      statistics: 0,
      hide_admin: 0,
      withCount: 0,
      type: ''
    };

    return this.filterAgents(params);
  }

  public getAllByTypeAgents(): Observable<any> {
    const roleType = parseInt(localStorage.getItem('role_type'), 10);

    const params = {
      page: 0,
      page_size: 1000,
      add: 0,
      statistics: 0,
      hide_admin: 0,
      withCount: 0,
      'filter[type]': roleType
    };

    return this.filterAgents(params);
  }

  public changeSettings(id: number, params): Observable<any> {
    return this.api.post('v1/agent/' + id + '/settings', {
      body: params
    });
  }

  public getAssignStats(params?: any) {
    return this.api.get('v1/agent/stats/0', {
      params: params
    });
  }

  public getPendingAssignStats(params?: any) {
    return this.api.get('v1/pending-assign/stats', {
      params
    });
  }

  public getKpiPerAgent(agentId) {
    return this.api.get('v1/agent/kpi/' + agentId, {});
  }

  public getAgentsKpi(params, from?, to?) {
    return this.api.get('v1/agent/kpi', {
      params: params
    });
  }

  public getAvailableRoles(params?) {
    return this.api.get('v1/agent/roles-list', {params});
  }

  public sendAgentRoles(body?) {
    return this.api.post('v1/agent/roles', {body});
  }

  public getUsers(params): Observable<any> {
    return this.api.get('v1/user', {params});
  }

  public getLeadInfo(params): Observable<any> {
    return this.api.get('v1/agent/lead-info', {params});
  }

  public getAssignReport(params): Observable<any> {
    return this.api.get('v1/agent/assign-report', {params});
  }

}
