import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateConverter} from '../../../helpers/DateConverter';

@Component({
    selector: 'app-less-than-period-input',
    templateUrl: './less-than-period-input.component.html',
    styleUrls: ['./less-than-period-input.component.less']
})
export class LessThanPeriodInputComponent implements OnInit, OnChanges {

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    @Input() public value: string;

    public dateInput: any;

    constructor() {
    }

    ngOnInit() {
        this.setValue();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.firstChange && changes.value.currentValue !== undefined) {
            this.setValue();
        }
    }

    public dateChange() {

        const cur = this.dateInput as Date;

        const days = Math.floor(((new Date()).getTime() - cur.getTime()) / (1000 * 60 * 60 * 24));

        this.change.emit({
            value: days !== 0 ? (days * -1).toString() : days.toString()
        });
    }


    private setValue() {
        if (this.value !== undefined && this.value !== null) {
            this.dateInput = DateConverter.addDays(new Date(), parseInt(this.value, 10));
        }
    }
}
