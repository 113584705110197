import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionModalComponent} from './action-modal.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule, MatSlideToggleModule, MatSnackBarModule,
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {LoaderModule} from '../../general/loader/loader.module';
import {ConditionsModule} from '../../conditions/conditions.module';
import {DatePeriodModule} from '../../filters/date-period/date-period.module';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {NgSelectModule} from '@ng-select/ng-select';
import {TimeFieldModule} from '../../filters/time-field/time-field.module';

@NgModule({
    declarations: [
        ActionModalComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatCardModule,
        ReactiveFormsModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        LoaderModule,
        MatSnackBarModule,
        ConditionsModule,
        MatExpansionModule,
        DatePeriodModule,
        OwlDateTimeModule,
        NgSelectModule,
        TimeFieldModule
    ],
    exports: [
        ActionModalComponent
    ],
    entryComponents: [
        ErrorDialogComponent
    ]
})
export class ActionModalModule {
}
