import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgentService } from 'src/app/services/agent/agent.service';

@Component({
  selector: 'app-assign-all-modal',
  templateUrl: './assign-all-modal.component.html',
  styleUrls: ['./assign-all-modal.component.scss']
})
export class AssignAllModalComponent implements OnInit {

  public progress = {
    current: 0,
    total: 0,
  }

  public matSpinnerSettings = {
    mode: 'indeterminate'
  }

  public text = 'Assigning in progress, please wait...';
  public secondsToClose = 5;

  constructor(
    private agentService: AgentService,
    private self: MatDialogRef<AssignAllModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let count = 0;
    for (const key in data.agents_suggestions) { if (data.agents_suggestions.hasOwnProperty(key)) { count++ } };
    this.progress.total = count;
  }

  ngOnInit() {
    this.onAssignAll();
  }

  public async onAssignAll() {
    for (const agent_id in this.data.agents_suggestions) {
      const suggestion = this.data.agents_suggestions[agent_id];
  
      const agentId = suggestion.agent._id;
      const agentName = suggestion.agent.name;

      let freshLeads = [];
      let retentionLeads = [];
      if (suggestion.freshLeads.length && !suggestion.assigned.freshLeads) {
        freshLeads = suggestion.freshLeads.map(el => el.id);
      }
      if (suggestion.reassignLeads.length && !suggestion.assigned.reassignLeads) {
        retentionLeads = suggestion.reassignLeads.map(el => el.id);
      }
      const leads = freshLeads.concat(retentionLeads);

      if (!suggestion.assigned.reassignLeads || !suggestion.assigned.freshLeads) {
        await this.assign(agentId, agentName, leads);
      }

      this.progress.current++;
    }
    this.matSpinnerSettings.mode = 'determinate';
    this.text = `Assigned All. Close at ${this.secondsToClose} second${this.secondsToClose === 1 ? '' : 's'}`;
    this.data.afterAssignAll();
    let onCloseInterval = setInterval(() => {
      this.secondsToClose--;
      if (this.secondsToClose === 0) {
        clearInterval(onCloseInterval);
        this.self.close();
        return;
      }
      this.text = `Assigned All. Close at ${this.secondsToClose} second${this.secondsToClose === 1 ? '' : 's'}`;
    }, 1000);
  }

  public async assign(agentId: number, agentName: string, leadIds: number[]) {
    localStorage.setItem('assign_system', '1');
    await new Promise((resolve, reject) => {
      this.agentService.assignLead(agentId, leadIds, 'Assign AI System').subscribe((result) => {
        localStorage.setItem('assign_system', '0');
        resolve(result);
      }, (error) => {
        localStorage.setItem('assign_system', '0');
        reject(error);
      });
    })
  }

}
