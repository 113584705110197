import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable, of} from 'rxjs';
import {Cookies} from '../../helpers/Cookies';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(private api: ApiService) {}

    public login(data): Observable<any> {
        Cookies.set('token', '', -1);
        return this.api.post('v1/user/login', data);
    }

    public getUser(id): Observable<any> {
        return this.api.get('v1/user/' + id, {});
    }

    public getUserPermission(params): Observable<any> {
        return this.api.get(`v1/user/permissions`, {params});
    }

    public getUsers(page, page_size, sort, filter): Observable<any> {
        return this.api.get('v1/user', {
            params: Object.assign({}, {
                page: page,
                page_size: page_size
            }, sort, filter)
        });
    }

    public createUser(data, params?): Observable<any>  {
        return this.api.post('v1/user/create', {
            body: data,
            params
        });
    }

    public editUser(id, data, params?): Observable<any> {
        return this.api.post('v1/user/' + id + '/edit', {
            body: data,
            params
        });
    }

    public deleteUser(id): Observable<any> {
        return this.api.delete('v1/user/' + id + '/delete', {});
    }
}
