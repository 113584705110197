import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaritalStatusComponent} from './marital-status.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        MaritalStatusComponent
    ],
    imports: [
        CommonModule,
        MatBadgeModule,
        MatButtonModule
    ],
    exports: [
        MaritalStatusComponent
    ]
})
export class MaritalStatusModule {
}
