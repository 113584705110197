import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RolesComponent} from './roles.component';
import {
    MatButtonModule, MatCheckboxModule, MatDialogModule, MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoaderModule} from '../general/loader/loader.module';
import {CdkTableModule} from '@angular/cdk/table';
import {AddRoleComponent} from './add-role/add-role.component';
import {AddRoleModule} from './add-role/add-role.module';
import {PermissionsModule} from './permissions/permissions.module';

// import {CompleteModalModule} from '../../general/complete-modal/complete-modal.module';
// import {ConfirmModalModule} from '../../general/confirm-modal/confirm-modal.module';
// import {CompleteModalComponent} from '../../general/complete-modal/complete-modal.component';
// import {ConfirmModalComponent} from '../../general/confirm-modal/confirm-modal.component';
// import {FieldsModule} from './fields/fields.module';
// import {PermissionsModule} from './permissions/permissions.module';
// import {GeneralModule} from './general/general.module';

@NgModule({
    declarations: [
        RolesComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        CdkTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        LoaderModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        PermissionsModule,
        MatToolbarModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatTooltipModule,
        AddRoleModule,
        // ConfirmModalModule,
        // CompleteModalModule,
        // FieldsModule,
        // PermissionsModule,
        // GeneralModule
    ],
    exports: [
        RolesComponent
    ],
    entryComponents: [
        AddRoleComponent,
        // ConfirmModalComponent,
        // CompleteModalComponent
    ]
})
export class RolesModule {
}
