import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentStatisticsComponent} from './agent-statistics.component';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        AgentStatisticsComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: [
        AgentStatisticsComponent
    ]
})
export class AgentStatisticsModule {
}
