import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-assign-report',
    templateUrl: './assign-report.component.html',
    styleUrls: ['./assign-report.component.less']
})
export class AssignReportComponent implements OnInit {


    constructor() {

    }

    ngOnInit() {
    }

}
