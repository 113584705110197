import {Component, OnInit} from '@angular/core';
import * as LoginAction from './actions/login.actions';
import {Store} from '@ngrx/store';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {

    public constructor(
        private store: Store<any>
    ) {}

    public ngOnInit() {
        const user = localStorage.getItem('user');
        if (user) {
            this.store.dispatch(new LoginAction.SetLogin(JSON.parse(user)));
        }

        const active_board = JSON.parse(localStorage.getItem('active_board'));

        if (active_board) {
            this.store.dispatch(new LoginAction.SetActiveBoard(active_board));
        }
    }
}
