import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditUserComponent} from './edit-user.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule, MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../general/loader/loader.module';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
    declarations: [
        EditUserComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        RouterModule,
        ReactiveFormsModule,
        LoaderModule,
        MatButtonModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatChipsModule
    ],
    exports: [
        EditUserComponent
    ]
})
export class EditUserModule {
}
