import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-agent-goals-edit',
    templateUrl: './agent-goals-edit.component.html',
    styleUrls: ['./agent-goals-edit.component.less']
})
export class AgentGoalsEditComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        monthly: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ]),
        weekly: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ]),
        daily: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private self: MatDialogRef<AgentGoalsEditComponent>,
    ) {}

    ngOnInit() {
        if (this.data) {
            for (const name in this.data) {

                if (!this.data.hasOwnProperty(name)) {
                    continue;
                }

                const control = this.form.get(name);

                if (!control) {
                    continue;
                }

                control.patchValue(this.data[name]);
            }
        }
    }

    edit() {
        if (this.form.valid) {
            this.self.close(this.form.value);
        }
    }
}
