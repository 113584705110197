import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Cookies} from '../../helpers/Cookies';
import {environment} from '../../../environments/environment';
import TokenGenerator from '../../helpers/TokenGenerator';

@Injectable({
    providedIn: 'root'
})
export class SseService {

    constructor() {
    }

    public get(url: string, params?: any) {
        return new Observable((observer) => {

            const eventSource = new EventSource(environment.eventURL + url + '?token=' + Cookies.get('token') + '&update_token=' + TokenGenerator.getToken() + (params ? '&' + params : ''));

            eventSource.addEventListener('message', (event: any) => {

                if (!event || !(event.data.length > 0)) {
                    observer.error({error: 'No data from the server'});
                    observer.complete();
                }

                try {
                    const data = JSON.parse(event.data);

                    if (!data) {
                        observer.error({error: 'No data from the server!'});
                        observer.complete();
                    }

                    observer.next(data);
                } catch (e) {
                    observer.error({error: e});
                    observer.complete();
                }

            });

            return {
                unsubscribe() {
                    eventSource.close();
                    observer.complete();
                }
            };
        });
    }
}
