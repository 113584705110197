import { NgModule } from '@angular/core';
import { ElemLeftPosPipe } from './elem-left-pos.pipe';

@NgModule({
  declarations: [ElemLeftPosPipe],
  exports: [
    ElemLeftPosPipe
  ]
})
export class ElemLeftPosPipeModule { }
