import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

interface Progress {
  percent: number;
  message: string;
  mode: string;
}

const defaultProgress = {
  percent: 0,
  message: 'Please wait ...',
  mode: 'determinate'
};

@Component({
  selector: 'app-reassign-progress',
  templateUrl: './reassign-progress.component.html',
  styleUrls: ['./reassign-progress.component.less']
})
export class ReassignProgressComponent implements OnInit, OnDestroy {

  @Input() count: number;

  @Output() close = new EventEmitter();

  public PROGRESS_DURATION = 4000;

  // public reassignLoader = false;

  private reassignTimer = null;

  public progress: Progress = defaultProgress;

  constructor() { }

  ngOnInit() {
    this.initProgress();
  }

  ngOnDestroy(): void {
    this.closeAssignProgress();
  }

  private initProgress(): void {
    // this.reassignLoader = true;
    let left = 0;

    const delay = this.PROGRESS_DURATION / this.count;

    this.reassignTimer = setInterval(() => {
      left += 1;

      this.progress.percent = Math.round(100 / this.count * left);

      if (left <= this.count) {

        this.progress.message = 'Assigned ' + left + ' of ' + this.count + ' leads!';

      } else {

        this.progress.message = 'Working on it ...';
        this.progress.mode = 'indeterminate';

        clearInterval(this.reassignTimer);
      }

    }, delay);

    localStorage.setItem('reassignChanel', JSON.stringify({
      // chanel: chanel,
      count: this.count
    }));

  }

  public closeAssignProgress(): void {

    this.progress.percent = 0;
    this.progress.message = 'Please wait ...';
    this.progress.mode = 'determinate';

    // this.progress = defaultProgress;

    clearInterval(this.reassignTimer);

    localStorage.removeItem('reassignChanel');

    this.close.emit();

    // this.reassignLoader = false;
  }

}
