import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListViewsComponent} from './list-views.component';
import {
    MatButtonModule, MatDialogModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule,
    MatPaginatorModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {DeleteViewComponent} from '../delete-view/delete-view.component';
import {NoResultModule} from '../general/no-result/no-result.module';
import {RouterModule} from '@angular/router';
import {CloneViewComponent} from '../clone-view/clone-view.component';

@NgModule({
    declarations: [
        ListViewsComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatListModule,
        MatButtonModule,
        LoaderModule,
        MatDividerModule,
        MatIconModule,
        MatPaginatorModule,
        NoResultModule,
        RouterModule,
        MatInputModule,
        MatFormFieldModule,
        MatMenuModule
    ],
    exports: [
        ListViewsComponent
    ],
    entryComponents: [
        DeleteViewComponent,
        CloneViewComponent
    ]
})
export class ListViewsModule {
}
