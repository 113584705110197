import {Component, Input, OnInit} from '@angular/core';
import {AssignSettingService} from '../../../../services/assign-settings/assign-setting.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {countries} from '../../../../constants/country';

@Component({
  selector: 'app-ai-result-review',
  templateUrl: './ai-result-review.component.html',
  styleUrls: ['./ai-result-review.component.less']
})
export class AiResultReviewComponent implements OnInit {

  @Input() set data(val) {
    if (val) {
      this.form.get('settings').patchValue(val)
    }
  }

  public countries = countries;

  public products = [
    {
      'is_bundle': false,
      'label': 'Canadian Immigration Evaluation Package',
      'value': 'Canadian Immigration Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'Gold Evaluation Package',
      'value': 'Gold Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'IELTS Online Course',
      'value': 'IELTS Online Course'
    },
    {
      'is_bundle': false,
      'label': 'Platinum Evaluation Package',
      'value': 'Platinum Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'Initial Consultation',
      'value': 'Initial Consultation'
    },
    {
      'is_bundle': false,
      'label': 'Tourist Visa Professional Fees',
      'value': 'Tourist Visa Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'Working Holiday Professional Fees',
      'value': 'Working Holiday Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'FR Canadian Immigration Evaluation Package',
      'value': 'FR Canadian Immigration Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'FR Working Holiday Professional Fees',
      'value': 'FR Working Holiday Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'FR Tourist Visa Professional Fees',
      'value': 'FR Tourist Visa Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'FR RCIC Fees Payment',
      'value': 'FR RCIC Fees Payment'
    },
    {
      'is_bundle': false,
      'label': 'FR Platinum Evaluation Package',
      'value': 'FR Platinum Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'FR Gold Evaluation Package',
      'value': 'FR Gold Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'FR IELTS Online Course',
      'value': 'FR IELTS Online Course'
    },
    {
      'is_bundle': false,
      'label': 'AR IELTS Online Course',
      'value': 'AR IELTS Online Course'
    },
    {
      'is_bundle': false,
      'label': 'AR Gold Evaluation Package',
      'value': 'AR Gold Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'AR Platinum Evaluation Package',
      'value': 'AR Platinum Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'AR RCIC Fees Payment',
      'value': 'AR RCIC Fees Payment'
    },
    {
      'is_bundle': false,
      'label': 'AR Tourist Visa Professional Fees',
      'value': 'AR Tourist Visa Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'AR Canadian Evaluation Package',
      'value': 'AR Canadian Evaluation Package'
    },
    {
      'is_bundle': false,
      'label': 'RCIC Fees Payment - UPGRADE',
      'value': 'RCIC Fees Payment - UPGRADE'
    },
    {
      'is_bundle': false,
      'label': 'Registration Fee',
      'value': 'Registration Fee'
    },
    {
      'is_bundle': false,
      'label': 'Canada Online Evaluation',
      'value': 'Canada Online Evaluation'
    },
    {
      'is_bundle': false,
      'label': 'Student Visa - Professional Fees',
      'value': 'Student Visa - Professional Fees'
    },
    {
      'is_bundle': false,
      'label': 'Online Business Immigration Evaluation',
      'value': 'Online Business Immigration Evaluation'
    },
    {
      'is_bundle': false,
      'label': 'Permanent Residency (Express Entry)',
      'value': 'Permanent Residency (Express Entry)'
    },
    {
      'is_bundle': false,
      'label': 'Documents Verification',
      'value': 'Documents Verification'
    },
    {
      'is_bundle': false,
      'label': 'Product Special Offer 19.99',
      'value': 'Product Special Offer 19.99'
    },
    {
      'is_bundle': false,
      'label': 'IELTS Ashton - Retention',
      'value': 'IELTS Ashton - Retention'
    },
    {
      'is_bundle': false,
      'label': 'File Opening',
      'value': 'File Opening'
    },
    {
      'is_bundle': false,
      'label': 'IELTS Ashton - Retention - General Course',
      'value': 'IELTS Ashton - Retention - General Course'
    },
    {
      'is_bundle': false,
      'label': 'IELTS Ashton - Retention - Academic Course',
      'value': 'IELTS Ashton - Retention - Academic Course'
    },
    {
      'is_bundle': false,
      'label': 'Online Evaluation and eBook',
      'value': 'Online Evaluation and eBook'
    },
    {
      'is_bundle': false,
      'label': 'Online Evaluation and IELTS Course',
      'value': 'Online Evaluation and IELTS Course'
    },
    {
      'is_bundle': false,
      'label': 'Online Evaluation and eBook NY and CR',
      'value': 'Online Evaluation and eBook NY and CR'
    },
    {
      'is_bundle': false,
      'label': 'File Opening Referral',
      'value': 'File Opening Referral'
    },
    {
      'is_bundle': false,
      'label': 'Registration Fee Evergreen',
      'value': 'Registration Fee Evergreen'
    },
    {
      'is_bundle': false,
      'label': 'First Year Tuition Fee',
      'value': 'First Year Tuition Fee'
    },
    {
      'is_bundle': false,
      'label': 'Government fees',
      'value': 'Government fees'
    },
    {
      'is_bundle': false,
      'label': 'Job Interview Preparation',
      'value': 'Job Interview Preparation'
    },
    {
      'is_bundle': false,
      'label': 'Student Visa Gold Package Academic',
      'value': 'Student Visa Gold Package Academic'
    },
    {
      'is_bundle': false,
      'label': 'Express Entry Gold Package General',
      'value': 'Express Entry Gold Package General'
    },
    {
      'is_bundle': false,
      'label': 'RCIC Additional Fees',
      'value': 'RCIC Additional Fees'
    },
    {
      'is_bundle': false,
      'label': 'Canada Working Holiday Bundle',
      'value': 'Canada Working Holiday Bundle'
    },
    {
      'is_bundle': false,
      'label': 'Online Interview Meeting',
      'value': 'Online Interview Meeting'
    },
    {
      'is_bundle': false,
      'label': 'RCIC Immigration Evaluation',
      'value': 'RCIC Immigration Evaluation'
    },
    {
      'is_bundle': true,
      'label': 'Single Student Visa Standard English Package',
      'value': 'Single Student Visa Standard English Package'
    },
    {
      'is_bundle': true,
      'label': 'Single Student Premium English Package',
      'value': 'Single Student Premium English Package'
    },
    {
      'is_bundle': true,
      'label': 'Couple Student Visa Standard English Package',
      'value': 'Couple Student Visa Standard English Package'
    },
    {
      'is_bundle': true,
      'label': 'Couple Student Premium English Package',
      'value': 'Couple Student Premium English Package'
    },
    {
      'is_bundle': true,
      'label': 'Single Express Entry Standard English Package',
      'value': 'Single Express Entry Standard English Package'
    },
    {
      'is_bundle': true,
      'label': 'Single Express Entry Premium English Package',
      'value': 'Single Express Entry Premium English Package'
    },
    {
      'is_bundle': true,
      'label': 'Couple Express Entry Standard English Package',
      'value': 'Couple Express Entry Standard English Package'
    },
    {
      'is_bundle': true,
      'label': 'Couple Express Entry Premium English Package',
      'value': 'Couple Express Entry Premium English Package'
    },
    {
      'is_bundle': false,
      'label': 'Resubmission',
      'value': 'Resubmission'
    },
    {
      'is_bundle': false,
      'label': 'Working Holiday Professional Fees Christmas',
      'value': 'Working Holiday Professional Fees Christmas'
    },
    {
      'is_bundle': false,
      'label': 'Expression Of Interest',
      'value': 'Expression Of Interest'
    },
    {
      'is_bundle': false,
      'label': 'Gold Evaluation Package Christmas',
      'value': 'Gold Evaluation Package Christmas'
    },
    {
      'is_bundle': false,
      'label': 'Reconsideration',
      'value': 'Reconsideration'
    }
  ];

  public gender = [
    {
      'label': 'Male',
      'value': 'Male'
    },
    {
      'label': 'Female',
      'value': 'Female'
    }
  ];

  public maritalStatus = [
    {
      'label': 'Single',
      'value': 'Single'
    },
    {
      'label': 'Married',
      'value': 'Married'
    }
  ];

  public types = [
    {
      'label': 'Online',
      'value': 'online'
    },
    {
      'label': 'Offline',
      'value': 'offline'
    }
  ];

  public typeModelOptions = [
    {
      label: 'Combo',
      value: 'combo'
    },
    {
      label: 'Separate',
      value: 'sep'
    }
  ];

  public loader = false;

  public result: any = null;

  public form = new FormGroup({
    fields: new FormGroup({
      visa_types: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      country_of_residence: new FormControl('', [Validators.required]),
      country_of_birth: new FormControl('', [Validators.required]),
      client_age: new FormControl('', [Validators.required]),
      client_gender: new FormControl('', [Validators.required]),
      lifetime: new FormControl('', [Validators.required]),
      marital_stat: new FormControl('', [Validators.required]),
    }),
    settings: new FormGroup({
      data_set_from: new FormControl('', [
        Validators.required
      ]),
      data_set_to: new FormControl('', [
        Validators.required
      ]),
      quantile: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      type_model: new FormControl('', [
        Validators.required
      ]),
      min_count_agents_output: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1000)
      ]),
      risk: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      disq: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      bonus_points: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(90)
      ]),
      weights: new FormGroup({
        conversion_rate: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        avg_of_sales: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        eff_call_to_swipe: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        answer_to_eff_call: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        index: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ])
      }),
      weights_of_features: new FormGroup({
        country_of_residence: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        country_of_birth: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        visa_types: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        type: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        client_age: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        client_gender: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        lifetime: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        marital_stat: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ])
      }),
    })
  });

  constructor(
      private service: AssignSettingService,
  ) { }

  ngOnInit() {
  }

  public submit(): void {
    this.loader = true;

    const formData = {
        request: {
          lead: {
            id: 222,
            fields: this.form.getRawValue().fields
          },
          settings: this.form.getRawValue().settings
        }
    };

    this.service.AITest(formData).subscribe((res) => {
      this.loader = false;

      this.result = res;
    }, (error) => {
      this.loader = false;

      for (const name in error.error) {
        if (error.error.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('fields').get(name)) {
          this.form.get('fields').get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('settings').get('weights').get(name)) {
          this.form.get('weights').get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('settings').get('weights_of_features').get(name)) {
          this.form.get('weights_of_features').get(name).setErrors({
            name: error.error[name]
          });
        }
      }
    });
  }

}
