import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import BaseComponent from '../base/base.component';
import {AbstractControl, Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DateConverter} from '../../helpers/DateConverter';


@Component({
    selector: 'app-data-picket-campaigns',
    templateUrl: './data-picket-campaigns.component.html',
    styleUrls: ['./data-picket-campaigns.component.less']
})
export class DataPicketCampaignsComponent extends BaseComponent implements OnInit {


    public data: FormGroup = new FormGroup({
        started_at: new FormControl('', [
            Validators.required,
            this.startAtValidator.bind(this)
        ]),
        end_at: new FormControl('', [
            Validators.required,
            this.endAtValidator.bind(this)
        ])
    });

    constructor(
        private self: MatDialogRef<DataPicketCampaignsComponent>,
        @Inject(MAT_DIALOG_DATA) public inputData: any
    ) {
        super();

        if (this.inputData && this.inputData.value && this.inputData.value.rawEnd && this.inputData.value.rawStart) {
            this.data.get('started_at').patchValue(this.inputData.value.rawEnd);
            this.data.get('end_at').patchValue(this.inputData.value.rawStart);
        }
    }

    ngOnInit() {
    }

    private startAtValidator(control: AbstractControl) {

        if (!this.data) {
            return;
        }

        const to = this.getDateFromControl(this.data.get('end_at'));

        const from = this.getDateFromControl(control);

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"from\" must be smaller that \"too\"'
            };
        } else if (this.data.get('end_at').invalid) {
            this.data.get('end_at').updateValueAndValidity();
        }
    }

    private endAtValidator(control: AbstractControl) {

        if (!this.data) {
            return;
        }

        const to = this.getDateFromControl(control);

        const from = this.getDateFromControl(this.data.get('started_at'));

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"To\" must be bigger that \"From\"'
            };
        } else if (this.data.get('started_at').invalid) {
            this.data.get('started_at').updateValueAndValidity();
        }
    }

    saveData() {
        if (!this.data.valid) {
            return;
        }

        this.self.close({
            id: 'custom',
            value: {
                end: DateConverter.toDateString(this.data.get('started_at').value),
                start: DateConverter.toDateString(this.data.get('end_at').value),
                rawEnd: this.data.get('started_at').value,
                rawStart: this.data.get('end_at').value
            }
        });
    }


    private getDateFromControl(control: AbstractControl): null | Date {
        if (!control || !control.value) {
            return null;
        }
        return control.value as Date;
    }

}
