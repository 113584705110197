import {Agent} from '../agent/Agent';
export class User {

    public id: number;

    public email: string;

    public username: string;

    public full_name: string;

    public updated_at: number;

    public created_at: number;

    public crm_username: number;

    public crm_id: number;

    public agent: Agent;

    public role: string;

    public role_id: number;

    public ips: string[];
}
