import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TotalSalesComponent} from './total-sales.component';
import {MatCardModule, MatIconModule} from '@angular/material';

@NgModule({
    declarations: [
        TotalSalesComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule
    ],
    exports: [
        TotalSalesComponent
    ]

})
export class TotalSalesModule {
}
