import {Component, OnInit} from '@angular/core';
import {AssignSuggestionService} from '../../services/assign-suggestion/assign-suggestion.service';
import _ from 'lodash';
import {AgentService} from '../../services/agent/agent.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import { AssignAllModalComponent } from './suggestion-table/assign-all-modal/assign-all-modal.component';
import {select, Store} from '@ngrx/store';
import {GetActiveBoard, GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../classes/unsubscribe';
import {TYPE_APPLICATIONS, TYPE_LEADS} from '../../constants/user';
import {PROGRESS_BARS, PROGRESS_BARS_RETENTION, WIZARD_TYPES} from '../../helpers/constants';
import {Permissions} from '../../helpers/Permissions';

@Component({
  selector: 'app-assign-suggestion',
  templateUrl: './assign-suggestion.component.html',
  styleUrls: ['./assign-suggestion.component.scss']
})
export class AssignSuggestionComponent extends Unsubscribe implements OnInit {

  public loader: any = false;

  public statsLoader = false;

  public progressBars = null;

  public agentStats: any;

  public error = null;

  public data = null;

  public roleType: number;

  public typeLeads = TYPE_LEADS;

  public statsAgents = null;

  public totalCount = {};

  constructor(
    private matDialog: MatDialog,
    private assignSuggestionService: AssignSuggestionService,
    private agentService: AgentService,
    private snack: MatSnackBar,
    private store: Store<any>,
    public permissions: Permissions
  ) {
    super();

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.store.pipe(
        select(GetActiveBoard),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((board: any) => {
      this.progressBars = WIZARD_TYPES[board.wizard_type];
    });
  }

  ngOnInit() {
  }

  private setData(res): void {
    for (const key in res.agents_suggestions) {
      res.agents_suggestions[key].assigned = {
        freshLeads: !res.agents_suggestions[key].freshLeads.length,
        reassignLeads: !res.agents_suggestions[key].reassignLeads.length,
      };
    }

    this.data = res;
  }

  public getSuggestion(data: object): void {
    this.loader = true;

    this.assignSuggestionService.getLeadsSuggestion(data).subscribe((res) => {
      this.loader = false;

      this.getStats();

      this.setData(res);
    }, (error) => {
      this.error = error;

      this.loader = false;
    });
  }

  private getStats(): void {
    if (!this.permissions.can('agent', 'can_stats_agent')) {
      return;
    }

    this.statsLoader = true;

    this.agentService.getAssignStats().pipe(
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.agentStats = res;

      const totalCount = {};

      Object.keys(res).forEach(key => {
        this.progressBars.forEach(item => {
          if (!totalCount[res[key].crm_id]) {
            totalCount[res[key].crm_id] = {
              total: 0,
              called: 0
            };
          }

          if (item.total !== 'blocked') {
            totalCount[res[key].crm_id].total += res[key][item.total];
            totalCount[res[key].crm_id].called += res[key][item.called];
          }
        });
      });

      this.totalCount = totalCount;

      this.statsLoader = false;
    }, () => {
      this.statsLoader = false;
    });
  }

  public assignAll() {
    if (this.data) {

      this.matDialog.open(AssignAllModalComponent, {
        data: {
          agents_suggestions: this.data.agents_suggestions,
          afterAssignAll: () => { this.onAfterReasignAll(); }
        },
      });

    } else {

      this.snack.open('Error! Please select Agents!', 'Ok!', {
        duration: 2000,
      });

    }

    setTimeout(() => {
      localStorage.setItem('assign_from_system', '0');
    }, 3000);
  }

  public assign(agentId: number, agentName: string, leadIds: number[]) {
    setTimeout(() => {
      this.agentService.assignLead(agentId, leadIds, 'Assign AI System').subscribe(res => {
        this.snack.open('Assign Success! Leads Was Assigned to ' + agentName, 'Ok!', {
          duration: 2000,
        });
      }, err => {
        this.snack.open('Error! Please Contact with Administrator!', 'Ok!', {
          duration: 2000,
        });
      });
    }, 200);
  }

  public onAssignAllOnTable(evt) {
    const key = evt.key;
    const type = evt.type;
    this.data.agents_suggestions[key].assigned[type] = true;
  }

  public onAfterReasignAll() {
    for (const key in this.data.agents_suggestions) {
      this.data.agents_suggestions[key].assigned = {
        freshLeads: true,
        reassignLeads: true,
      };
    }
  }

  public applyAgents(data): void {
    this.loader = true;

    this.statsAgents = data;

    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

}
