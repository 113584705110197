import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.less']
})
export class FilterModalComponent implements OnInit {

    public filter: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.maxLength(255)
        ]),
        value: new FormControl('', [
            Validators.required,
            Validators.maxLength(255)
        ]),
    });

    public activeFilter: any = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private self: MatDialogRef<FilterModalComponent>
    ) {
    }

    ngOnInit() {
        if (this.data.filter) {

            for (const name in this.data.filter) {

                if (!this.data.filter.hasOwnProperty(name)) {
                    continue;
                }

                const control = this.filter.get(name);

                if (!control) {
                    continue;
                }

                control.patchValue(this.data.filter[name]);

                this.active({
                    value: this.data.filter[name],
                });
            }
        }
    }

    public active(event) {
        for (const f of this.data.names) {
            if (event.value === f.name) {
                this.activeFilter = f;
            }
        }
    }

    public submit() {

        if (!this.filter.valid) {
            return;
        }

        const f = this.data.names.find(fil => fil.name === this.filter.value.name);

        this.self.close(Object.assign({
            label: f.label,
            valueLabel: f.value ? f.value.find(fil => fil.value === this.filter.value.value).label : null,
        }, this.filter.value));
    }

}
