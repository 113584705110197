import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignSettingService {

  constructor(
    private api: ApiService
  ) {}

  public getAssignSetting(param): Observable<any> {
      return this.api.get('v1/assign/setting', {
        params: param
      });
  }

  public AITest(body, params?): Observable<any> {
      return this.api.post('v1/assign/ai-test', {
        body,
        params
      });
  }

  public updateAssignSetting(param): Observable<any> {
    return this.api.post('v1/assign/settings', {
      body: param
    });
  }

  public reshuffle(body): Observable<any> {
    return this.api.post('v1/assign/reshuffle', {body});
  }
}
