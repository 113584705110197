import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFieldComponent } from './time-field.component';
import {MatFormFieldModule, MatIconModule, MatInputModule} from '@angular/material';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [TimeFieldComponent],
    exports: [
        TimeFieldComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        OwlDateTimeModule,
        MatIconModule,
        ReactiveFormsModule
    ]
})
export class TimeFieldModule { }
