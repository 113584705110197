import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateRangePickerComponent} from './date-range-picker.component';
import {
    MatButtonModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
    MatInputModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        DateRangePickerComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule
    ],
    exports: [
        DateRangePickerComponent
    ]
})
export class DateRangePickerModule {
}
