import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import SearchResponse from '../../../../search.response';
import {ShiftsService} from '../../../../services/shifts/shifts.service';
import BaseComponent from '../../../base/base.component';
import {IconSelectModalComponent} from '../../../general/icon-select-modal/icon-select-modal.component';
import {select, Store} from '@ngrx/store';
import {GetBoardId, GetRoleType} from '../../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent extends BaseComponent implements OnInit {

    public groups: SearchResponse = new SearchResponse({
        page_size: 5
    });

    public group: any = null;

    public ngClasses: any = {
        active: false
    };

    public loader: any = false;

    public subscribers: any = {
        get: null,
        edit: null
    };

    public editGroupForm: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        icon: new FormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100)
        ]),
        description: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        active: new FormControl('0', []),
        type: new FormControl('', []),
        board_id: new FormControl('', []),
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private shiftsService: ShiftsService,
        private snack: MatSnackBar,
        private dialog: MatDialog,
        private store: Store<any>
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.editGroupForm.get('type').setValue(type);
        });

        this.store.pipe(
            select(GetBoardId),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((id: number) => {
            this.editGroupForm.get('board_id').setValue(id);
        });

        this.subscribers.get = this.shiftsService.get(this.groups.getReqData()).subscribe((res) => {
            if (res.data.length) {
                const group = res.data.find((item) =>  item.id === parseInt(this.route.snapshot.params.id, 10));

                this.group = Object.assign({}, group, {
                    active: group.active ? '1' : ''
                });
                this.setValues(this.group);
            }
        }, err => {
            this.snack.open('Problem', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.ngClasses.active = true;
        }, 1);
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    public editGroup() {
        this.loader = true;

        this.subscribers.edit = this.shiftsService.edit(this.group.id, Object.assign({}, this.editGroupForm.value, {
            active: !!this.editGroupForm.value.active
        })).subscribe(result => {
            if (result) {
                this.snack.open('Shift successfully updated!', 'Dismiss', {
                    duration: 3000
                });
                this.loader = false;
            }
        }, error => {
            for (const name in error.error) {
                if (error.error.hasOwnProperty(name) && this.editGroupForm.get(name)) {
                    this.editGroupForm.get(name).setErrors({
                        name: error.error[name]
                    });
                }
            }
            this.loader = false;
        });
    }

    public openSelectIconModal() {
        this.dialog.open(IconSelectModalComponent, {
            width: '600px',
            height: '600px',
            hasBackdrop: true,
            data: {
                title: 'Select icon',
                selectedIcon: this.editGroupForm.get('icon').value || ''
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.editGroupForm.get('icon').patchValue(result);
            }
        });
    }

    private setValues(params) {
        for (const name in params) {
            if (params.hasOwnProperty(name) && this.editGroupForm.get(name)) {
                this.editGroupForm.get(name).patchValue(params[name]);

                if (!params[name]) {
                    this.editGroupForm.get(name).markAsTouched();
                }
            }
        }
    }

}
