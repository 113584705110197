import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';

@Component({
  selector: 'app-ai-setting',
  templateUrl: './ai-setting.component.html',
  styleUrls: ['./ai-setting.component.less']
})
export class AiSettingComponent extends BaseComponent implements OnInit, OnDestroy {

  public ngClasses: any = {
    active: false
  };

  public subscribers: any = {
    settings: null
  };

  public loader: any = false;

  public form: FormGroup;

  constructor(private route: ActivatedRoute,
              private service: AssignSettingService,
              private snack: MatSnackBar) {
    super();

    this.subscribers.settings = this.service.getAssignSetting({
      'setting': 'ai_configuration'
    }).subscribe((res) => {

      this.setValues(res);

    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      quantile: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      weight_conv_rate: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      weight_index: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      min_count_agents_output: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      sales_min_by_all_restrict: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      sales_min_by_country_restrict: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ])
    });
  }

  private setValues(params) {

    for (const name in params) {
      if (params.hasOwnProperty(name) && this.form.get(name)) {
        this.form.get(name).patchValue(params[name]);
        if (!params[name]) {
          this.form.get(name).markAsTouched();
        }
      }
    }
  }

  public submit(event) {

    event.preventDefault();

    this.loader = true;

    let params = {
      'setting_slug': 'ai_configuration',
      'setting': this.form.value,
    };

    this.subscribers.edit = this.service.updateAssignSetting(params).subscribe(result => {
      if (result) {
        this.snack.open('Settings Successfully Updated!', 'Dismiss', {
          duration: 3000
        });
        this.loader = false;
      }
    }, error => {
      for (const name in error.error) {
        if (error.error.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).setErrors({
            name: error.error[name]
          });
        }
      }

      this.loader = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
