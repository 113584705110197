import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QueueService} from '../../services/queue/queue.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ErrorDialogComponent} from '../general/error-dialog/error-dialog.component';
import {ViewService} from '../../services/view/view.service';

@Component({
    selector: 'app-clone-view',
    templateUrl: './clone-view.component.html',
    styleUrls: ['./clone-view.component.less']
})
export class CloneViewComponent implements OnInit {

    public loader = false;

    public clone: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[a-zA-z ]{3,100}$/)
        ])
    });

    constructor(
        private viewService: ViewService,
        @Inject(MAT_DIALOG_DATA) public view: any,
        private self: MatDialogRef<any>,
        private snack: MatSnackBar,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }


    public submit() {
        if (!this.clone.valid) {
            return;
        }
        this.loader = !this.loader;
        this.viewService.cloneView(this.view.id, this.clone.value).subscribe(() => {
            this.loader = !this.loader;
            this.snack.open('List ' + this.clone.get('name').value + ' was created!', 'Dismiss', {
                duration: 3000,
            });
            this.self.close(true);
        }, () => {
            this.self.close(false);
            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: 'Cant clone the view!',
                    message: 'Please contact your Administrator!'
                }
            });
        });
    }


}
