import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-general-settings-number-edit',
    templateUrl: './general-settings-number-edit.component.html',
    styleUrls: ['./general-settings-number-edit.component.less']
})
export class GeneralSettingsNumberEditComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        value: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private self: MatDialogRef<GeneralSettingsNumberEditComponent>
    ) {
    }

    ngOnInit() {
        if (this.data.value) {
            this.form.get('value').patchValue(this.data.value);
        }
    }

    edit() {
        if (this.form.valid) {
            this.data.value = this.form.value.value.toString();
            this.self.close(this.data);
        }
    }

}
