import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {

  constructor(private api: ApiService) {
  }

  public get(param): Observable<any> {
    return this.api.get('v1/shift?expands=agents_shifts', {
      params: param
    });
  }

  public edit(id, data): Observable<any> {
    return this.api.post(`v1/shift/${id}/edit`, {
      body: data
    });
  }

  public add({ group, user }): Observable<any> {
    return this.api.post(`v1/shift/${group}/add-agent`, {
      body: {
        agent_crm_id: user
      }
    });
  }

  public move(from, to, id): Observable<any> {
    return this.api.post(`v1/shift/${from}/move-agent`, {
      body: {
        to_shift_id: to,
        agent_crm_id: id,
      }
    });
  }

  public hide({ group, user, hide}): Observable<any> {
    return this.api.post(`v1/shift/${group}/hide-agent`, {
      body: {
        agent_crm_id: user,
        is_hidden: hide
      }
    });
  }

  public delete({ group, user }): Observable<any> {
    return this.api.post(`v1/shift/${group}/delete-agent`, {
        body: {
            agent_crm_id: user
        }
    });
  }

  public addShift(params): Observable<any> {
    return this.api.post('v1/shift/create', {
      body: params
    });
  }

  public deleteShift(id): Observable<any> {
    return this.api.delete(`v1/shift/${id}/delete`, {});
  }

  public deleteShiftArray(idsArray: Array<number>): Observable<any> {
    return this.api.post(`v1/shift/mass-delete`, {
      body: {
        shift_ids: idsArray
      }
    });
  }

  public getShifts(params?): Observable<any> {
      return this.api.get('v1/shift', {params});
  }

}
