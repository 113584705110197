import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeleteViewComponent} from './delete-view.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';

@NgModule({
    declarations: [
        DeleteViewComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        LoaderModule
    ],
    exports: [
        DeleteViewComponent
    ]
})

export class DeleteViewModule {
}
