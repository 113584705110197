import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AssignSuggestionComponent } from './assign-suggestion.component';
// import { SuggestionSettingsModule } from './suggestion-settings/suggestion-settings.module';
// import { LoaderModule } from '../general/loader/loader.module';

import { MatButtonModule } from '@angular/material/button';
// import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { SuggestionTableComponent } from './suggestion-table/suggestion-table.component';
import { MatTableModule } from '@angular/material/table';
import { AgentTableComponent } from '../../suggestion-table/agent-table/agent-table.component';
import { AssignedToModule } from '../../../filters/assigned-to/assigned-to.module';
// import { BiStatisticComponent } from './bi-statistic/bi-statistic.component';
//
// import { SuggestionStatusPipeModule } from 'src/app/pipes/suggestion-status-pipe/suggestion-status.pipe.module';
// import { SecondsToHumanPipeModule } from 'src/app/pipes/seconds-to-human-pipe/seconds-to-human.pipe.module';
// import { AssignAllModalModule } from './suggestion-table/assign-all-modal/assign-all-modal.module';
//
import { CallStatusModule } from '../../suggestion-table/call-status/call-status.module';
// import { FreshLeadsAvailableComponent } from './fresh-leads-available/fresh-leads-available.component';
// import {SuggestionSettingsRetentionModule} from './suggestion-settings-retention/suggestion-settings-retention.module';
// import { LeadsSectionInfoComponent } from './leads-section-info/leads-section-info.component';
//
// import {MatProgressBarModule} from '@angular/material/progress-bar';
// import {MatCardModule} from '@angular/material/card';
// import { SectionInfoModalComponent } from './section-info-modal/section-info-modal.component';
// import {LoadsTableModule} from '../call-activity-info/loads-table/loads-table.module';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import { NgSelectModule } from '@ng-select/ng-select';
// import {MatSidenavModule} from '@angular/material/sidenav';
// import { AgentsFilterComponent } from './agents-filter/agents-filter.component';
// import {MatTabsModule} from '@angular/material/tabs';
// import {AssignInfoTableModule} from '../call-activity-info/assign-info-table/assign-info-table.module';

@NgModule({
    declarations: [
        AgentTableComponent
    ],
    imports: [
        CommonModule,
        // SuggestionSettingsModule,
        // SuggestionSettingsRetentionModule,
        // LoaderModule,
        //
        // MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,

        AssignedToModule,
        CallStatusModule,
        // MatExpansionModule,
        // MatProgressBarModule,
        // MatCardModule,
        // MatFormFieldModule,
        // MatSidenavModule,
        // MatTabsModule,
        //
        // SuggestionStatusPipeModule,
        // SecondsToHumanPipeModule,
        //
        // AssignAllModalModule,
        // LoadsTableModule,
        //
        // FormsModule,
        // ReactiveFormsModule,
        // NgSelectModule,
        // AssignInfoTableModule,
    ],
    exports: [
        AgentTableComponent
    ]
})
export class AgentTableModule { }
