import {Component, Input, OnInit} from '@angular/core';
import {getAvatar} from '../../../helpers/Avagar';

@Component({
    selector: 'app-agent-info',
    templateUrl: './agent-info.component.html',
    styleUrls: ['./agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit {

    public getAvatar = getAvatar;

    @Input() row: any;
    @Input() online: any = undefined;

    constructor() {
    }

    ngOnInit() {
        console.log(this.row)
    }

}
