import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorDialogComponent} from './error-dialog.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        ErrorDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule
    ],
    exports: [
        ErrorDialogComponent
    ]
})

export class ErrorDialogModule {
}
