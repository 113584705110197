import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionsComponent} from './permissions.component';

import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {RouterModule} from '@angular/router';
import {LoaderModule} from '../../general/loader/loader.module';
import {ConfirmModalModule} from '../../confirm-modal/confirm-modal.module';

@NgModule({
    declarations: [
        PermissionsComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        ConfirmModalModule,
        CdkTableModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        RouterModule
    ],
    exports: [
        PermissionsComponent
    ]
})
export class PermissionsModule {}
