import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReassignProgressComponent} from './reassign-progress.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        ReassignProgressComponent
    ],
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        ReassignProgressComponent
    ]
})
export class RolesModule {
}
