import {ChatActionsUnion, ChatActionsType} from '../actions/chat.actions';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';

const initiaState = {
    isOpen: false,
    activeAgent: null,
    agents: [],
    bubbles: [],
    messages: null,
}

function customizer(objValue, srcValue) {
    if (_isArray(objValue)) {
        return objValue.concat(srcValue);
    }
}

export function chatReducer(state: any = initiaState, action: ChatActionsUnion) {
    switch(action.type) {
        case ChatActionsType.TOGGLE_CHAT: {
            return {
                ...state,
                isOpen: typeof action.payload !== 'undefined' ? action.payload : !state.isOpen
            };
        }
        case ChatActionsType.SET_AGENTS: {
            return {
                ...state,
                agents: action.payload
            };
        }
        case ChatActionsType.SET_ACTIVE_AGENT: {
            return {
                ...state,
                activeAgent: _find(state.agents, {user_id: action.payload})
            };
        }
        case ChatActionsType.SET_BUBBLE_MESSAGE: {
            return {
                ...state,
                bubbles: [...state.bubbles, action.payload]
            };
        }
        case ChatActionsType.REMOVE_BUBBLE_MESSAGE: {
            _remove(state.bubbles, (o) => o.message.id === action.payload)
            return {
                ...state,
                bubbles: [...state.bubbles]
            };
        }
        case ChatActionsType.SET_MESSAGE_HISTORY: {
            let newState = {
                items: [],
                _meta: null
            };
            if (action.payload) {
                if (action.payload.meta.page === 1) {
                    newState = action.payload;
                } else {
                    newState = _mergeWith(state.messages, action.payload, customizer);
                }
            }

            return {
                ...state,
                messages: newState
            };
        }
        case ChatActionsType.SET_MESSAGE: {
            state.messages.items.unshift(action.payload);
            return {
                ...state,
                messages: Object.assign({}, state.messages)
            };
        }
        case ChatActionsType.UPDATE_AGENT: {
            return {
                ...state,
                agents: state.agents.map((agent) => {
                    if (agent.user_id === action.payload.user_id) {
                        if (action.payload.is_online) {
                            agent['is_online'] = action.payload.is_online;
                        } else if (agent.is_online) {
                            delete agent['is_online'];
                        }
                        if (action.payload.not_viewed) {
                            agent['not_viewed'] = action.payload.not_viewed;
                        } else if (agent.not_viewed) {
                            delete agent['not_viewed'];
                        }
                    }
                    return agent;
                })
            };
        }
        default: {
            return state;
        }
    }
}
