import {ViewsProgressActionTypes, ViewsProgressActionsUnion} from '../actions/views-progress-actions';

const initialState: any = {};

export function viewsProgressReducer(state: any = initialState, action: ViewsProgressActionsUnion) {
    switch (action.type) {
        case ViewsProgressActionTypes.SET_DATA: {
            return Object.assign({}, state, action.payload);
        }
        case ViewsProgressActionTypes.CLEAR_DATA: {
            for (const name in state) {
                if (state.hasOwnProperty(name) && action.payload[name]) {
                    delete state[name];
                }
            }
            return Object.assign({}, state);
        }
        default: {
            return state;
        }
    }
}
