import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material';

@Component({
    selector: 'app-assign',
    templateUrl: './assign.component.html',
    styleUrls: ['./assign.component.less']
})
export class AssignComponent implements OnInit {


    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public self: MatSnackBarRef<AssignComponent>,
    ) {
    }

    ngOnInit() {
        const now = parseInt(String(Date.now() / 1000), 10);

        this.data.schedule_time = parseInt(String((this.data.schedule_time - now) / 60), 10);
    }

}
