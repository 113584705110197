
import {NgModule} from '@angular/core';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatCardModule,
  MatIconModule,
  MatListModule,
  MatToolbarModule,
  MatSelectModule,
  MatDatepickerModule, 
  MatDialogModule, 
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule, MatProgressSpinnerModule, MatSnackBarModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../general/loader/loader.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BulkAssignComponent } from './bulk-assign.component';
import { BulkAssignFormComponent } from './bi-statistic/bulk-assign-form.component';
import { BulkAssignSheetsComponent } from './bulk-sheets/bulk-sheets.component';
import { FileStatusPipeModule } from 'src/app/pipes/file-status-pipe/file-status.pipe.module';

@NgModule({
  declarations: [
    BulkAssignComponent,
    BulkAssignFormComponent,
    BulkAssignSheetsComponent
  ],
  imports: [
    MatToolbarModule,
    PortalModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    DragDropModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    FileStatusPipeModule
  ],
  exports: [
  ],
  entryComponents: [
    BulkAssignComponent
  ]
})
export class BulkAssignModule {
}
