import { NgModule } from '@angular/core';
import { TimeStrPipe } from './timestr.pipe';

@NgModule({
  declarations: [TimeStrPipe],
  exports: [
    TimeStrPipe
  ]
})
export class TimeStrPipeModule { }
