import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class BulkAssignService {

    constructor(private api: ApiService) {
    }

    
    public sendFile(file): Observable<any> {
        return this.api.post(`v1/agent/assign-from-file`, {
            body: file
        });
    }

    public getData(params?: any): Observable<any> {
        return this.api.get(`v1/assign/request-status`, { params });
    }
}
