import {ActionReducerMap} from '@ngrx/store';
import {agentsReducer} from './agents-reducer';
import {viewsReducer} from './views-reducer';
import {viewsProgressReducer} from './views-progress-reducer';
import {filtersReducer} from './filters-reducer';
import {chatReducer} from './chat.reducer';
import {LoginReducer} from './login.reducer';

export const reducers: ActionReducerMap<any> = {
    agents: agentsReducer,
    views: viewsReducer,
    progress: viewsProgressReducer,
    filters: filtersReducer,
    chat: chatReducer,
    login: LoginReducer,
};
