import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditShiftComponent} from './edit-shift.component';
import {MatIconModule, MatListModule, MatToolbarModule} from '@angular/material';
import {PortalModule} from '@angular/cdk/portal';
import {AddAgentComponent} from './add-agent/add-agent.component';
import {InfoComponent} from './info/info.component';
import {AddAgentModule} from './add-agent/add-agent.module';
import {InfoModule} from './info/info.module';

@NgModule({
    declarations: [
        EditShiftComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        PortalModule,
        MatListModule,
        MatIconModule,
        AddAgentModule,
        InfoModule
    ],
    exports: [
        EditShiftComponent
    ],
    entryComponents: [
        AddAgentComponent,
        InfoComponent
    ]
})
export class EditShiftModule {
}
