import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {DateConverter} from '../../helpers/DateConverter';

@Injectable({
    providedIn: 'root'
})
export class HolidaysService {

    constructor(
        private api: ApiService
    ) {
    }

    public get(): Observable<any> {
        return this.api.get('v1/public-holidays', {});
    }

    public create(params): Observable<any> {
        return this.api.post('v1/public-holidays/create', {
            body: {
                label: params.label,
                from_at: Math.round(DateConverter.startOfDayUTC(params.from_at) / 1000),
                to_at: Math.round(DateConverter.endOfDayUTC(params.to_at) / 1000)
                // from_at: Math.round((DateConverter.startOfDay(params.from_at).getTime() / 1000) + ((new Date()).getTimezoneOffset() * 60)),
                // to_at: Math.round((DateConverter.endOfDay(params.to_at).getTime() / 1000) + ((new Date()).getTimezoneOffset() * 60)),
                // from_at: Date.UTC(params.from_at.getUTCFullYear(), params.from_at.getUTCMonth(), params.from_at.getUTCDate(), params.from_at.getUTCHours(), params.from_at.getUTCMinutes(), params.from_at.getUTCSeconds(), params.from_at.getUTCMilliseconds()) / 1000
            }
        });
    }

    public update(params): Observable<any> {
        return this.api.post('v1/public-holidays/' + params.id + '/update', {
            body: {
                label: params.label,
                from_at: Math.round(DateConverter.startOfDayUTC(params.from_at) / 1000),
                to_at: Math.round(DateConverter.endOfDayUTC(params.to_at) / 1000)
            }
        });
    }

    public delete(params): Observable<any> {
        return this.api.delete('v1/public-holidays/' + params.id + '/delete', {
            body: {}
        });
    }
}
