import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {AgentService} from '../../../services/agent/agent.service';
import {takeUntil} from 'rxjs/operators';
import {Agent} from '../../../services/agent/Agent';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {DateConverter} from '../../../helpers/DateConverter';

enum Assign {
    'assign' = 1,
    'assigners' = 2,
    'assignee' = 3
}

@Component({
    selector: 'app-assign-info-table',
    templateUrl: './assign-info-table.component.html',
    styleUrls: ['./assign-info-table.component.less']
})
export class AssignInfoTableComponent extends Unsubscribe implements OnInit {

    @Input() type: 'assign' | 'assigners' | 'assignee';

    @Input() set agent(val) {
        if (val) {
            this._agents = val;

            setTimeout(() => {
                this.getAssignData();
            }, 700);
        }
    }

    public _agents: number[];

    public filterFrom = new FormGroup({
        from_time: new FormControl(
            DateConverter.startOfDay(new Date()), [ this.startAtValidator.bind(this) ]
        ),
        to_time: new FormControl(
            DateConverter.endOfDay(new Date()), [ this.endAtValidator.bind(this) ]
        ),
    });

    public loader = true;

    public dataSource: MatTableDataSource<any> = new MatTableDataSource();

    public assign = null;

    public columnsToDisplay: string[] = [
        'title',
        'assigned',
        'assigned_u',
        'fresh',
        'reassign',
        'attempts',
        'attempts_u',
        'answers_u',
        'effective_u',
        'sales',
        'revenue',
        'cr',
        'ri',
        'avg_age'
    ];

    public titles = {
        title: 'Assign From',
        assigned: 'Assigned All',
        assigned_u: 'Assigned <br><small>[unique]</small>',
        fresh: 'Fresh <br><small>[unique]</small>',
        reassign: 'Reassign <br><small>[unique]</small>',
        attempts: 'Attempts All',
        attempts_u: 'Attempts <br><small>[unique]</small>',
        answers_u: 'Answer <br><small>[unique]</small>',
        effective_u: 'Effective <br><small>[unique]</small>',
        sales: 'Sales Count',
        revenue: 'Revenue',
        cr: 'CR',
        ri: 'Assign Revenue',
        avg_age: 'Age AVG',
    };

    constructor(
        private agentService: AgentService
    ) {
        super();
    }

    ngOnInit() {
        this.titles.title = this.type === 'assign' ? 'Assign From' : this.type === 'assigners' ? 'Assign By' : 'Assign To';
    }

    public get minDate(): Date {
        if (this.filterFrom.get('to_time').value) {
            const date = new Date(this.filterFrom.get('to_time').value);

            date.setDate(date.getDate() - 4);

            const newDate = DateConverter.startOfDay(new Date(date));

            return newDate;
        }

        return null;
    }
    public get maxDate(): Date {
        if (this.filterFrom.get('from_time').value) {
            const date = new Date(this.filterFrom.get('from_time').value);

            date.setDate(date.getDate() + 4);

            const newDate = DateConverter.endOfDay(new Date(date));

            return newDate;
        }

        return null;
    }

    private startAtValidator(control: AbstractControl) {

        if (!this.filterFrom) {
            return;
        }

        const from = this.getDateFromControl(control);

        const to = this.getDateFromControl(this.filterFrom.get('to_time'));

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                startDate: 'Date "From" must be smaller than "To"'
            };
        } else if (this.filterFrom.get('to_time').invalid) {
            this.filterFrom.get('to_time').updateValueAndValidity();
        }
    }

    private endAtValidator(control: AbstractControl) {

        if (!this.filterFrom) {
            return;
        }

        const from = this.getDateFromControl(this.filterFrom.get('from_time'));

        const to = this.getDateFromControl(control);

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'Date "To" must be bigger than "From"'
            };
        } else if (this.filterFrom.get('from_time').invalid) {
            this.filterFrom.get('from_time').updateValueAndValidity();
        }
    }

    private getDateFromControl(control: AbstractControl): null | Date {
        if (!control || !control.value) {
            return null;
        }
        return control.value as Date;
    }

    private getAssignData(): void {
        this.loader = true;

        this.agentService.getAssignReport({
            agent_ids: this._agents,
            report_type: Assign[this.type],
            from_time: new Date(this.filterFrom.get('from_time').value).getTime() / 1000,
            to_time: new Date(this.filterFrom.get('to_time').value).getTime() / 1000
        }).pipe(
            takeUntil(this.destroy)
        ).subscribe((res) => {
            if (res.data) {
                this.dataSource.data = Object.keys(res.data).map(key => res.data[key]);

                this.assign = res;
            } else {
                this.dataSource.data = [];
            }

            this.loader = false;
        }, () => {
            this.loader = false;
        });
    }

    public submit(): void {
        if (this.filterFrom.invalid) {
            return;
        }

        this.getAssignData();
    }

}
