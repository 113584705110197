import {Component, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {MatSnackBar} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {Agent} from '../../services/agent/Agent';
import {AgentService} from '../../services/agent/agent.service';
import * as AgentsActions from '../../actions/agents-actions';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';

@Component({
    selector: 'app-agent-settings',
    templateUrl: './agent-settings.component.html',
    styleUrls: ['./agent-settings.component.less']
})
export class AgentSettingsComponent extends BaseComponent implements OnInit {

    public getAvatar = getAvatar;

    public agentsSearch = {
        hidden: false,
        dialer: false
    };

    public agents: Agent[] = [];

    public filter: any = {
        hidden: [],
        dialer: []
    };

    public update: any = {
        dialer: (agent, value) => {
            agent.is_dialer = value;
            this.agentsService.changeSettings(agent.id, {
                setting: 'is_dialer',
                value: value
            }).subscribe(() => {
                this.snackBar.open('Agent \'' + agent.crm_username + '\' was ' + (value ? 'enabled' : 'disabled') + ' on Dialer!', 'Dismiss', {
                    duration: 3000,
                });
            });
            this.store.dispatch(new AgentsActions.SetAllAgents(this.agents));
        },
        hidden: (agent, value) => {
            agent.hidden = value ? Agent.HIDDEN : Agent.VISIBLE;
            this.agentsService.changeSettings(agent.id, {
                setting: 'hidden',
                value: value ? Agent.HIDDEN : Agent.VISIBLE
            }).subscribe(() => {
                this.snackBar.open('Agent \'' + agent.crm_username + '\' became ' + (value ? 'hidden' : 'visible') + '!', 'Dismiss', {
                    duration: 3000,
                });
            });
            this.store.dispatch(new AgentsActions.SetAllAgents(this.agents));
        }
    };

    public sortFn = function (a, b) {
        if (a.crm_username > b.crm_username) {
            return 1;
        }
        if (a.crm_username < b.crm_username) {
            return -1;
        }
        return 0;
    };

    constructor(private snackBar: MatSnackBar,
                private store: Store<any>,
                private agentsService: AgentService,
        public permissions: Permissions
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(select('agents')).subscribe((res) => {
            if (this.agents.length > 0) {
                return;
            }
            this.agents = res.allAgents.sort(this.sortFn);

        });
    }

    public searchChange(event, name) {
        this.filter[name] = this.agents.filter((agent: Agent) => {
            return !agent.crm_username.toLowerCase().startsWith(event.target.value.toLowerCase());
        }).map((agent: Agent) => {
            return agent.id;
        });
    }


    public changeSetting(event, agent, setting) {
        this.update[setting](agent, event.checked);
    }

    public toggleSearch(name) {
        this.agentsSearch[name] = !this.agentsSearch[name];
    }

}
