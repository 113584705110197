import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {FormControl, FormGroup} from '@angular/forms';
import {ShiftsService} from '../../../services/shifts/shifts.service';
import {select, Store} from '@ngrx/store';
import {GetActiveBoard, GetBoardId, GetRoleType} from '../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_APPLICATIONS} from '../../../constants/user';
import {LEADS_SECTION_TYPES, RETENTION_SECTION_TYPES, WIZARD_TYPES_FILTER} from '../../../helpers/constants';
import {MAT_DIALOG_DATA, MatSnackBar, MatTabGroup} from '@angular/material';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {AgentService} from '../../../services/agent/agent.service';

@Component({
  selector: 'app-reshuffle-modal',
  templateUrl: './reshuffle-modal.component.html',
  styleUrls: ['./reshuffle-modal.component.less']
})
export class ReshuffleModalComponent extends Unsubscribe implements OnInit {

  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  @Input() ids: number[];

  public loader = false;

  public progressLoader = false;

  public shifts = [];

  public agents = [];

  public roleType: number;

  public filterData: any = {
    assigned_to: [],
    type: []
  };

  public leadsTypes = [];

  public reshuffle = null;
  public notEligible = null;

  public autocomplete = null;

  public notEligibleData = null;
  public notEligibleAssignTo = [];

  public filtersList = [
    'full_name',
    'lead_number',
    'program',
    'assign_time',
    'assigned_to',
    'country_of_birth',
    'country_of_residence',
    'type'
  ];

  public reshuffleForm = new FormGroup({
    full_name: new FormControl(''),
    lead_number: new FormControl(''),
    program: new FormControl(''),
    assign_time: new FormControl(''),
    assigned_to: new FormControl(''),
    country_of_birth: new FormControl(''),
    country_of_residence: new FormControl(''),

    type: new FormControl(''),

    numbers: new FormControl([]),
    agents: new FormControl([]),
    equally: new FormControl(false),
    period: new FormControl('1d'),
    board_id: new FormControl(''),
  });

  public NotEligibleForm = new FormGroup({
    board_id: new FormControl(''),
    numbers: new FormControl([]),
    agents: new FormControl([]),
    period: new FormControl(''),
    equally: new FormControl(true)
  });

  constructor(
      private store: Store<any>,
      private agentService: AgentService,
      private shiftsService: ShiftsService,
      private assignSettingService: AssignSettingService,
      private snack: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();

    this.store.pipe(
        select('filters'),
        takeUntil(this.destroy)
    ).subscribe((res: any) => {
      this.autocomplete = res;
    });

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        filter(id => !!id)
    ).subscribe((id: number) => {
      this.reshuffleForm.get('board_id').setValue(id);
    });

    this.store.pipe(
        select(GetRoleType),
        filter(res => !!res),
        takeUntil(this.destroy)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.store.pipe(
        select(GetActiveBoard),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((board: any) => {
      this.leadsTypes = WIZARD_TYPES_FILTER[board.wizard_type];
    });

    this.store.pipe(
        select('agents'),
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.agents = res.allAgents;
    });
  }

  ngOnInit() {
    Object.keys(this.data.filters).forEach(key => {
      if (this.reshuffleForm.get(key)) {
        if (['assigned_to'].includes(key)) {
          const agents = this.data.filters[key].split(',');

          if (agents.length) {
            this.reshuffleForm.get(key).setValue(agents.map(i => parseInt(i, 10)));
          }
        } else if (['country_of_birth', 'country_of_residence', 'program', 'type'].includes(key)) {
          if (this.data.filters[key]) {
            const agents = this.data.filters[key].split(',');

            if (agents.length) {
              this.reshuffleForm.get(key).setValue(agents);
            }
          }
        } else {
          this.reshuffleForm.get(key).setValue(this.data.filters[key]);
        }
      }
    });

    this.getShifts();
  }

  public filterLeads(): any[] {
    let leads = this.data.leads;

    const data = this.reshuffleForm.getRawValue();

    this.filtersList.forEach(key => {
      if (data[key]) {
        if (key === 'assign_time') {

          const time = data[key].split(' ');

          if (time.length > 1) {
            const endDate = new Date(time[1]).setUTCHours(23, 59, 59, 999);
            const startDate = new Date(time[0]);
            leads = leads.filter(lead => {
              return new Date(lead[key] * 1000) >= new Date(startDate) && new Date(lead[key] * 1000) <= new Date(endDate);
            });
          }

        } else if (
            key === 'country_of_residence' ||
            key === 'country_of_birth' ||
            key === 'assigned_to' ||
            key === 'program' ||
            key === 'type'
        ) {
          const val = data[key];

          if (val.length && val[0]) {
            leads = leads.filter(lead => val.includes(lead[key]));
          }
        } else {
          const value = data[key];
          leads = leads.filter(lead => lead[key].toLowerCase().indexOf(value) !== -1);
        }
      }
    });

    return leads;
  }

  public getShifts() {
    this.shiftsService.getShifts({
      'filter[type]': this.roleType
    }).subscribe((res) => {
      this.shifts = res.data.filter(shift => shift.active === true);
    });
  }

  public submit(): void {
    const data = this.filterLeads();

    if (data.length) {
      this.reshuffleForm.get('numbers').setValue(data.map(i => i.lead_number));
    } else {
      this.reshuffleForm.get('numbers').setErrors({empty: true});
    }

    if (this.reshuffleForm.invalid) return;

    this.loader = true;

    this.assignSettingService.reshuffle(this.reshuffleForm.getRawValue()).pipe(
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.reshuffle = res;

      this.loader = false;
    }, ({error}) => {
      this.handleErrors(error);

      this.loader = false;
    });
  }

  public submitNotEligible(): void {
    this.NotEligibleForm.get('numbers').setValue(this.notEligibleData.list_clients.map(i => i.lead_number));
    this.NotEligibleForm.get('equally').setValue(true);

    this.loader = true;

    this.assignSettingService.reshuffle(this.NotEligibleForm.getRawValue()).pipe(
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.notEligible = res;

      this.loader = false;
    }, ({error}) => {
      this.handleErrors(error);

      this.loader = false;
    });
  }

  private handleErrors(errors): void {
    for (const name in errors) {
      if (this.reshuffleForm.get(name)) {
        this.reshuffleForm.get(name).setErrors({name: errors[name]});
      }
    }
  }

  public onShiftsChange(evt: any, control: any): void {
    const agents = [];

    this.shifts.forEach(shift => {
      if (evt.includes(shift.id)) {
        shift.agents.forEach(agent => agents.push(agent.agent_crm_id));
      }
    });

    control.patchValue(agents);
  }

  public assignAllLeads(agent, message = true, all = false) {
    if (agent.agent_id === 0) {
      if (all) return;

      this.NotEligibleForm.patchValue(this.reshuffleForm.getRawValue());

      this.notEligibleData = agent;

      this.tabs.selectedIndex = 1;
    } else {
      this.agentService.assignLead(agent.agent_id, agent.id_clients, 'Reshuffle').subscribe((res) => {
        if (message) {
          this.snack.open('Assign Success! Leads Was Assigned to ' + agent.agent_name, 'Ok!', {
            duration: 2000,
          });
        }
      }, (err) => {
        if (message) {
          this.snack.open('Error! Please Contact with Administrator!', 'Ok!', {
            duration: 2000,
          });
        }
      });
    }
  }

  public assignAll(data) {
    this.progressLoader = true;

    data.forEach(agent => {
      this.assignAllLeads(agent, false, true);
    });
  }

  public cancel(): void {
    this.tabs.selectedIndex = 0;
  }
}
