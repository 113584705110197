import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HistoryComponent} from './history.component';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule, MatRippleModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {ViewLeadInfoComponent} from '../view-lead-info/view-lead-info.component';
import {AgentsSheetComponent} from '../agents-sheet/agents-sheet.component';
import {FilterModalComponent} from '../filters/filter-modal/filter-modal.component';
import {FilterModalModule} from '../filters/filter-modal/filter-modal.module';

@NgModule({
    declarations: [HistoryComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatButtonModule,
        MatExpansionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatTooltipModule,
        FilterModalModule
    ],
    exports: [HistoryComponent],
    entryComponents: [ViewLeadInfoComponent, AgentsSheetComponent, FilterModalComponent]
})
export class HistoryModule {
}
