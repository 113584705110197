import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentsKPIComponent} from './agents-kpi.component';
import {
    MatButtonModule, MatCardModule, MatCheckboxModule, MatDividerModule, MatExpansionModule, MatExpansionPanelTitle,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {LoaderModule} from "../general/loader/loader.module";
import {DateRangePickerModule} from "../general/date-range-picker/date-range-picker.module";
import {DateRangePickerComponent} from "../general/date-range-picker/date-range-picker.component";
import {AgentInfoModule} from "../general/agent-info/agent-info.module";

@NgModule({
    declarations: [
        AgentsKPIComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        CdkTableModule,
        MatPaginatorModule,
        LoaderModule,
        MatButtonModule,
        MatMenuModule,
        MatCardModule,
        MatExpansionModule,
        DateRangePickerModule,
        MatTooltipModule,
        MatDividerModule,
        AgentInfoModule,
        MatCheckboxModule
    ],
    exports: [
        AgentsKPIComponent
    ],
    entryComponents: [
        DateRangePickerComponent,
    ]
})
export class AgentsKPIModule {
}
