import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BulkAssignIdService {

  constructor(
    private api: ApiService
  ) {}

  public get(id: string | number, rawParam?: string): Observable<any> {
    return this.api.get(`v1/assign/${id}/request-process-status${rawParam}`, {});
  }
}