import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {LeadService} from '../../services/lead/lead.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import {JSON_FIELDS} from '../../helpers/constants';

@Component({
    selector: 'app-view-lead-info',
    templateUrl: './view-lead-info.component.html',
    styleUrls: ['./view-lead-info.component.less']
})

export class ViewLeadInfoComponent extends BaseComponent implements OnInit, OnDestroy {

    public loader: any = true;

    public jsonFields = JSON_FIELDS;

    public leadData: any = {
        main: [],
        additional: []
    };

    public subscriber: Subscription;

    public listener: any;

    constructor(
        private leadService: LeadService,
        @Inject(MAT_DIALOG_DATA) public lead: any,
        private cdr: ChangeDetectorRef
    ) {
        super();
        this.subscriber = this.leadService.getLead(this.lead.id).subscribe((resp) => {
            this.leadData = resp;
            this.toggleLoader();
            this.cdr.detectChanges();
        });
    }

    ngOnInit() {
        document.addEventListener('click', this.listener = () => {
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
        if (this.listener) {
            document.removeEventListener('click', this.listener);
        }
    }

    log(val) { console.log(val); }
}
