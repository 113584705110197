import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Cookies} from '../../helpers/Cookies';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    private token;

    constructor(private router: Router) {
        this.token = Cookies.get('token');
    }

    canActivate() {
        const auth  = !!this.token;

        if (!auth) {
            this.router.navigate(['login']);
        }

        return auth;
    }

}
