import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusReasonComponent} from './status-reason.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        StatusReasonComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [
        StatusReasonComponent,
    ]
})
export class StatusReasonModule {
}
