import {Component, Inject, OnInit} from '@angular/core';
import {ViewService} from '../../services/view/view.service';
import BaseComponent from '../base/base.component';
import {View} from '../../services/view/View';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DeleteViewComponent} from '../delete-view/delete-view.component';
import {Router} from '@angular/router';
import {CloneViewComponent} from '../clone-view/clone-view.component';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-list-views',
    templateUrl: './list-views.component.html',
    styleUrls: ['./list-views.component.less']
})

export class ListViewsComponent extends BaseComponent implements OnInit {

    public loader = true;

    public notFound = false;

    public searchActive = false;

    public options = [
        {
            icon: 'edit',
            label: 'Edit',
            show: this.permissions.can('view', 'can_edit_view'),
            fn: (view) => {
                this.toggleLoader();
                this.router.navigate(['edit-view/' + view.id]).then(() => {
                    this.toggleLoader();
                    this.self.close();
                });
            }
        },
        {
            icon: 'file_copy',
            label: 'Clone',
            show: this.permissions.can('view', 'can_clone_view'),
            fn: (view) => {
                this.dialog.open(CloneViewComponent, {
                    width: '450px',
                    data: view
                }).afterClosed().subscribe((res) => {
                    if (res) {
                        this.getViews();
                    }
                });
            }
        },
        {
            icon: 'delete',
            label: 'Delete',
            show: this.permissions.can('view', 'can_delete_view'),
            fn: (view) => {
                this.dialog.open(DeleteViewComponent, {
                    width: '350px',
                    data: view
                }).afterClosed().subscribe((result) => {
                    if (result && result.length > 0) {
                        this.dialogData.deletedViews.push(view.id);
                        if (this.views.data.length < 2) {
                            this.views.page = (this.views.page - 1) > 0 ? this.views.page - 1 : 0;
                        }
                        this.getViews();
                    }
                });
            }
        }
    ];

    public views: any = {
        data: [],
        page_size: 10,
        pages: 0,
        page: 0,
        rows: 0,
        sort: {},
        filter: {}
    };

    private dialogData: any = {
        deletedViews: [],
        selectedView: null
    };

    constructor(
        private viewService: ViewService,
        private self: MatDialogRef<any>,
        private dialog: MatDialog,
        private router: Router,
        public permissions: Permissions,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
        this.viewService.getViews({
            page_size: this.views.page_size,
            'filter[type]': this.data.type
        }).subscribe((response) => {
            this.views = Object.assign(this.views, response);
            if (response.rows <= 0) {
                this.notFound = true;
            }
            this.toggleLoader();
        });
    }

    ngOnInit() {
        this.self.beforeClose().subscribe(() => {
            this.self.close(this.dialogData);
        });


    }

    public selectView(view: View): void {
        this.dialogData.selectedView = view;
        this.self.close();
    }

    public changePage(event: any): void {
        this.views.page = event.pageIndex;
        this.getViews();
    }

    public searchChange(event: any): void {
        this.views.filter = {};
        if (event.target.value) {
            this.views.filter = {['filter[' + event.target.name + ']']: event.target.value};
        }
        this.getViews();
    }

    public toggleSearch(): void {
        this.searchActive = !this.searchActive;
    }

    private getViews(): void {
        this.toggleLoader();
        this.viewService.getViews(Object.assign({
            page: this.views.page,
            page_size: this.views.page_size,
            'filter[type]': this.data.type
        }, this.views.sort, this.views.filter)).subscribe((response) => {
            this.views = Object.assign(this.views, response);
            this.toggleLoader();
        });
    }

    public select(option, view) {
        option.fn(view);
    }
}
