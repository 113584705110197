import { Component } from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import BaseComponent from '../../base/base.component';
import {NewBlandComponent} from './new-bland/new-bland.component';

@Component({
  selector: 'app-bland',
  templateUrl: './bland.component.html',
  styleUrls: ['./bland.component.less']
})
export class BlandComponent extends BaseComponent {

  public rolesMenu: any = [
    {
      icon: 'edit',
      label: 'Edit',
      show:  true,
      fn: (bland, index) => {
        this.create(bland, index);
      }
    },
    {
      icon: 'delete',
      label: 'Delete',
      show:  true,
      fn: (bland, index) => {
        this.delete(index);
      }
    }
  ];

  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  public columnsToDisplay: string[] = ['title', 'min', 'max', 'ageFresh', 'ageReassign', 'actions'];

  public settingSlug: string = 'lead_scoring_bland';

  constructor(
      private service: AssignSettingService,
      private snack: MatSnackBar,
      private dialog: MatDialog
  ) {
    super();

    this.getSetting();
  }

  private getSetting(): void {
    this.loader = true;

    this.service.getAssignSetting({setting: this.settingSlug}).subscribe(res => {
      this.dataSource.data = res;

      this.loader = false;
    }, err => {
      this.snack.open('Something Went Wrong!', 'Ok!', {
        duration: 1000
      });

      this.loader = false;
    });
  }

  public delete(index: number) {
    const data = [...this.dataSource.data];
    data.splice(index, 1);
    this.dataSource.data = data;

    const body = {
      setting_slug: this.settingSlug,
      setting: {
        bland: this.dataSource.data
      }
    };

    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('Bland was deleted successfully!', 'Ok!', {
        duration: 3000,
      });
    });
  }

  public create(bland = null, index = null): void {
    this.dialog.open(NewBlandComponent, {
      width: '550px',
      data: {
        index: index,
        bland: bland,
        slug: this.settingSlug,
        list: this.dataSource.data,
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.getSetting();
      }
    });
  }
}
