import {Action} from '@ngrx/store';
import {Agent} from '../services/agent/Agent';

export enum AgentsActionTypes {
    SET_AGENTS = '[Agents] setAgents',
    SET_SHIFT_ID = '[Agents] setShiftId',
    SET_SHIFT_AGENTS = '[Agents] setShiftAgents',
    UPDATE_SHIFT_AGENTS = '[Agents] updateShiftAgents',
    SET_ALL_AGENTS = '[Agents] setAllAgents',
    SET_ALL_AGENTS_NEW = '[Agents] setAllAgentsNew'
}

export class SetAgents implements Action {
    readonly type = AgentsActionTypes.SET_AGENTS;
    constructor(public payload: Agent[]) {}
}

export class SetShiftAgents implements Action {
    readonly type = AgentsActionTypes.SET_SHIFT_AGENTS;
    constructor(public payload: Agent[]) {}
}

export class SetShiftId implements Action {
    readonly type = AgentsActionTypes.SET_SHIFT_ID;
    constructor(public payload: any) {}
}

export class UpdateShiftAgents implements Action {
    readonly type = AgentsActionTypes.UPDATE_SHIFT_AGENTS;
    constructor(public payload: any) {}
}

export class SetAllAgents implements Action {
    readonly type = AgentsActionTypes.SET_ALL_AGENTS;
    constructor(public payload: any) {}
}

export class SetAllAgentsNew implements Action {
    readonly type = AgentsActionTypes.SET_ALL_AGENTS_NEW;
    constructor(public payload: any) {}
}

export type AgentsActionsUnion = SetAgents | SetShiftId | SetShiftAgents | UpdateShiftAgents | SetAllAgents | SetAllAgentsNew;
