import {Component, Input, OnInit} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {DateConverter} from '../../../../helpers/DateConverter';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.less']
})
export class SettingsFormComponent implements OnInit {

  @Input() set errors(error) {
    if (error) {
      for (const name in error) {
        if (error.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).setErrors({
            name: error[name]
          });
        }

        if (this.form.get('weights').get(name)) {
          this.form.get('weights').get(name).setErrors({
            name: error[name]
          });
        }

        if (this.form.get('weights_of_features').get(name)) {
          this.form.get('weights_of_features').get(name).setErrors({
            name: error[name]
          });
        }
      }
    }
  }

  public typeModelOptions = [
    {
      label: 'combo',
      value: 'combo'
    },
    {
      label: 'sep',
      value: 'sep'
    }
  ];

  public DefaultValueStart = null;

  public DefaultValueEnd = null;

  public form = new FormGroup({
    data_set_from: new FormControl('', [
      Validators.required
    ]),
    data_set_to: new FormControl('', [
      Validators.required
    ]),
    quantile: new FormControl('', [
      Validators.required,
      Validators.minLength(0),
      Validators.maxLength(1)
    ]),
    type_model: new FormControl('', [
      Validators.required
    ]),
    min_count_agents_output: new FormControl('', [
      Validators.required,
      Validators.minLength(0),
      Validators.maxLength(1000)
    ]),
    risk: new FormControl('', [
      Validators.required,
      Validators.minLength(0),
      Validators.maxLength(1)
    ]),
    disq: new FormControl('', [
      Validators.required,
      Validators.minLength(0),
      Validators.maxLength(1)
    ]),
    bonus_points: new FormControl('', [
      Validators.required,
      Validators.minLength(0),
      Validators.maxLength(90)
    ]),
    weights: new FormGroup({
      conversion_rate: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ]),
      avg_of_sales: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ]),
      eff_call_to_swipe: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ]),
      answer_to_eff_call: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ]),
      index: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10000)
      ])
    }),
    weights_of_features: new FormGroup({
      country_of_residence: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      country_of_birth: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      visa_types: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      type: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      client_age: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      client_gender: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      lifetime: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      marital_stat: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ])
    }),
  });

  constructor() { }

  ngOnInit() {}

  public setStartDate(e): void {
    const date = DateConverter.toDateString(e.value);

    this.form.get('data_set_from').setValue(date + ' 00:00:00');
  }

  public setEndDate(e): void {
    const date = DateConverter.toDateString(e.value);

    this.form.get('data_set_to').setValue(date + ' 59:59:59');
  }

}
