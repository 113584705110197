import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {Agent} from '../../services/agent/Agent';
import {AgentService} from '../../services/agent/agent.service';
import {UserStorageServiceService} from '../../services/uset-storage-service/user-storage-service.service';
import {select, Store} from '@ngrx/store';
import * as AgentsActions from '../../actions/agents-actions';
import {MatSnackBar, MatDialog} from '@angular/material';
import {ShiftsService} from '../../services/shifts/shifts.service';
import {WIZARD_TYPES} from '../../helpers/constants';
import {GetActiveBoard, GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {CentrifugeService} from '../../services/centrifuge/centrifuge.service';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.less']
})
export class AgentsComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() public agent: Agent;

    public loader = true;

    public sort: any = {
        name: 'productivity',
        icon: 'bar_chart',
        label: 'Productivity'
    };

    public selectedShiftName = 'All';

    public activeShift = {
        id: 0,
        icon: 'group',
    };

    public agents: Agent[] = [];

    public allAgents: Agent[] = null;

    public shifts = [];

    public sortSelect: any[] = [
        {
            name: 'productivity',
            icon: 'bar_chart',
            label: 'Productivity'
        },
        {
            name: 'assigned',
            icon: 'show_chart',
            label: 'Assigned'
        },
        {
            name: 'assigned_desc',
            icon: 'multiline_chart',
            label: 'Assigned (desc)'
        },
        {
            name: 'call_status',
            icon: 'phone_callback',
            label: 'Call status',
        }
    ];

    private sorts: any = {
        call_status: (a: Agent, b: Agent) => {

            if (a.call_status < b.call_status) {
                return -1;
            }

            if (a.call_status > b.call_status) {
                return 1;
            }

            return 0;
        },
        assigned: (a: Agent, b: Agent) => {

            if (a.status.all_called < b.status.all_called ) {
                return -1;
            }

            if (a.status.all_called > b.status.all_called ) {
                return 1;
            }

            return 0;
        },
        assigned_desc: (a: Agent, b: Agent) => {

            if (a.status.all_called < b.status.all_called ) {
                return 1;
            }

            if (a.status.all_called > b.status.all_called ) {
                return -1;
            }

            return 0;
        },
        productivity: (a: Agent, b: Agent) => {

            if (this.getProgress(a) === 100 && this.getProgress(b) !== 100) {
                return -1;
            }

            if (this.getProgress(a) !== 100 && this.getProgress(b) === 100) {
                return 1;
            }

            if (a.status.all_called > b.status.all_called ) {
                return -1;
            }

            if (a.status.all_called < b.status.all_called ) {
                return 1;
            }

            if (a.status.all > b.status.all) {
                return -1;
            }

            if (a.status.all < b.status.all) {
                return 1;
            }

            return 0;
        }
    };

    public skeleton: Array<null> = Array.from({
        length: 11
    }, () => {
        return null;
    });

    public listener: any = false;

    private roleType: number;

    public progressBars = null;

    public dragstart = null;

    public agentsStatus = {};

    constructor(
        private agentsService: AgentService,
        private userStorageService: UserStorageServiceService,
        private store: Store<any>,
        private snackBar: MatSnackBar,
        private confirmModal: MatDialog,
        private dialog: MatDialog,
        private shiftsService: ShiftsService,
        public permissions: Permissions,
        private centrifugeService: CentrifugeService
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.roleType = type;
        });

        this.store.pipe(
            select(GetActiveBoard),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((board: any) => {
            this.progressBars = WIZARD_TYPES[board.wizard_type];
        });

        this.store.pipe(
            select('agents'),
            takeUntil(this.destroy)
        ).subscribe((value: any) => {
            if (this.allAgents === null && value.allAgents.length > 0) {

                this.allAgents = value.allAgents.filter(agent => agent.hidden === 0);

                const selectedShift = parseInt(localStorage.getItem(`selectedShift_${this.roleType}`), 10);
                if (selectedShift > 0) {

                    this.getShifts(selectedShift);

                } else {
                    this.getAgents(this.allAgents);

                    this.getShifts();
                }
            }

            this.sortAgents();
        });
    }


    ngOnInit() {
        this.subscribeToCentrifugo();

        document.addEventListener('dragstart', this.dragstart = (event) => {
            const lead = JSON.parse(event.dataTransfer.getData('lead'));
            if (lead && lead.length > 0 && lead[0].ai_prediction) {

                const data = JSON.parse(lead[0].ai_prediction);
                const predictionData = {};

                data.forEach(i => predictionData[i.agent_id] = i);

                const newAgents = [];

                this.agents.forEach(agent => {
                    newAgents.push({
                        ...agent,
                        ai_prediction: predictionData[agent.crm_id] || {}
                    });
                });

                this.agents = newAgents;
            }
        });
    }

    ngOnDestroy() {
        this.centrifugeService.unsubscribe('CallActivity');

        document.removeEventListener('dragstart', this.dragstart);
    }

    private subscribeToCentrifugo(): void {
        this.centrifugeService.listen('CallActivity').pipe(
            takeUntil(this.destroy)
        ).subscribe(({data}) => {
            this.agentsStatus[data.agent_crm_id] = data.status;
        });
    }

    public selectShift(value: any): void {
        this.selectedShiftName = value.name;
        this.activeShift = value;
        this.toggleLoader();
        if (value.id === 0) {
            this.getAgents(this.allAgents);
        } else {
            value.agents = value.agents.filter(ag => ag.is_hidden !== true);
            const agents = value.agents.map(agent => agent.agent.id);

            this.getAgents(this.allAgents.filter(agent => agents.indexOf(agent.id) >= 0));
        }
        localStorage.setItem(`selectedShift_${this.roleType}`, value.id);
    }

    public selectSort(value: any): void {
        this.sort = value;
        this.sortAgents();
    }

    private setInitialFilter(id: any): void {
        if (id) {
            for (let i = 0; i < this.shifts.length; i++) {
                if (this.shifts[i].id === parseInt(id, 10)) {
                    this.activeShift = Object.assign({}, this.shifts[i]);
                    this.store.dispatch(new AgentsActions.SetShiftId(this.shifts[i].id));
                    let res = this.shifts[i].agents.filter(agent => agent.agent.hidden === 0);
                    res = res.filter(agent => agent.is_hidden !== true);
                    res = res.map(ag => ag.agent);
                    this.getAgents(res);
                    this.selectedShiftName = this.shifts[i].name;
                    break;
                }
            }
        }
    }

    private setProgress(called, total): number {
        if (called === undefined || total === undefined) return 0;
        if (total === 0 && called === 0) return 100;
        if (called === 0 || total === 0) return 0;

        const percent = ((called / total) * 100);

        return Math.round(percent);
    }

    private getAgents(agents): void {
        if (this.permissions.can('agent', 'can_stats_agent')) {
            this.agentsService.getAssignStats({new_system: 1}).subscribe((res: any) => {

                const newAgents = [];

                Object.keys(res).forEach(key => {
                    const ag = Object.keys(agents).find(agent => agents[agent].crm_id === res[key].crm_id);
                    if (ag) {
                        const data = res[key];
                        const newAgent = {
                            ...agents[ag],
                            status: {}
                        };

                        for (const pr of this.progressBars) {
                            if (data && data[pr.total] !== undefined) {
                                newAgent.status[pr.total] = data && data[pr.total] ? data[pr.total] : 0;
                                newAgent.status[pr.called] = data && data[pr.total] ? data[pr.called] : 0;
                                newAgent.status[pr.progress] = data && data[pr.total] ? this.setProgress(data[pr.called], data[pr.total]) : 0;
                            }
                        }

                        newAgents.push(newAgent);
                    }
                });

                this.agents = newAgents;

                this.store.dispatch(new AgentsActions.SetShiftAgents(this.agents.filter((agent) => {
                    return !agent.hidden && (this.activeShift.id <= 0 ? true : agent.shift === this.activeShift.id);
                })));

                this.toggleLoader();

                if (!this.listener) {
                    this.listener = true;

                    document.dispatchEvent(new CustomEvent('toggleSubscription', {
                        detail: {
                            enable: true,
                            stream: 'agent'
                        }
                    }));
                }

            }, () => {
                const newAgents = [];

                Object.keys(agents).forEach(key => {
                    const newAgent = {
                        ...agents[key],
                        status: {}
                    };

                    for (const pr of this.progressBars) {
                        newAgent.status[pr.total] = 0;
                        newAgent.status[pr.called] = 0;
                        newAgent.status[pr.progress] = 0;
                    }

                    newAgents.push(newAgent);
                });

                this.agents = newAgents;

                this.store.dispatch(new AgentsActions.SetShiftAgents(this.agents.filter((agent) => {
                    return !agent.hidden && (this.activeShift.id <= 0 ? true : agent.shift === this.activeShift.id);
                })));

                this.toggleLoader();

                if (!this.listener) {
                    this.listener = true;

                    document.dispatchEvent(new CustomEvent('toggleSubscription', {
                        detail: {
                            enable: true,
                            stream: 'agent'
                        }
                    }));
                }

            });
        } else {
            const newAgents = [];

            Object.keys(agents).forEach(key => {
                const newAgent = {
                    ...agents[key],
                    status: {}
                };

                for (const pr of this.progressBars) {
                    newAgent.status[pr.total] = 0;
                    newAgent.status[pr.called] = 0;
                    newAgent.status[pr.progress] = 0;
                }

                newAgents.push(newAgent);
            });

            this.agents = newAgents;

            this.store.dispatch(new AgentsActions.SetShiftAgents(this.agents.filter((agent) => {
                return !agent.hidden && (this.activeShift.id <= 0 ? true : agent.shift === this.activeShift.id);
            })));

            this.toggleLoader();

            if (!this.listener) {
                this.listener = true;

                document.dispatchEvent(new CustomEvent('toggleSubscription', {
                    detail: {
                        enable: true,
                        stream: 'agent'
                    }
                }));
            }
        }
    }

    private sortAgents(): void {
        this.agents.sort(this.sorts[this.sort.name]);
    }

    public getShifts(id?: number) {
        this.shiftsService.getShifts({
            'filter[type]': this.roleType
        }).subscribe((res) => {
            this.shifts = Object.assign(this.shifts, res.data.filter(shift => shift.active === true));
            this.shifts.unshift({
                id: 0,
                active: true,
                agents: this.allAgents,
                icon: 'group',
                name: 'All'
            });

            if (id) {
                this.setInitialFilter(id);
            }
        });
    }

    public getProgress(agent: Agent): number {

        if (!agent.status.all || agent.status.all <= 0) {
            return 0;
        }

        agent.progress = Math.round((agent.status.all_called / agent.status.all) * 100);

        return agent.progress;
    }

    public trackByIdentity(index: number, item: any): any {
        return item.crm_id;
    }
}
