import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})

export class CampaignStatisticService {

    constructor(
        private api: ApiService
    ) {}

    public getStatistics(data: any): Observable<any> {
        return this.api.get('v1/campaign-statistic', {
            params: Object.assign({
                page_size: data.page_size,
                page: data.page,
            }, data.filter, data.sort)
        });

    }
}
