import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReAssignPoolComponent} from './re-assign-pool.component';
import {
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatAutocompleteModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ProgressLoaderModule} from '../general/progress-loader/progress-loader.module';
import {ConditionsModule} from '../conditions/conditions.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
    declarations: [
        ReAssignPoolComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        LoaderModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDividerModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatTableModule,
        MatSortModule,
        ProgressLoaderModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        ConditionsModule,
        MatProgressBarModule

    ],
    exports: [
        ReAssignPoolComponent
    ]
})
export class ReAssignPoolModule {
}
