import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AmChartsService} from '@amcharts/amcharts3-angular';
import {finalize, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';

@Component({
  selector: 'app-country-split-modal',
  templateUrl: './country-split-modal.component.html',
  styleUrls: ['./country-split-modal.component.less']
})
export class CountrySplitModalComponent extends Unsubscribe implements OnInit {

  public loader = false;

  public countrySplit = {
    'type': 'serial',
    'theme': 'none',
    'startDuration': 0,
    'dataProvider': [],
    'valueAxes': [ {
      'gridColor': '#FFFFFF',
      'gridAlpha': 0.2,
      'dashLength': 0
    } ],
    'gridAboveGraphs': true,
    'graphs': [ {
      'balloonText': '[[category]]: <b>[[value]]</b>',
      'fillAlphas': 0.8,
      'lineAlpha': 0.2,
      'type': 'column',
      'valueField': 'value'
    } ],
    'chartCursor': {
      'categoryBalloonEnabled': false,
      'cursorAlpha': 0,
      'zoomable': false
    },
    'categoryField': 'label',
    'categoryAxis': {
      'gridPosition': 'start',
      'gridAlpha': 0,
      'labelRotation': 45,
      'tickPosition': 'start',
      'tickLength': 20
    }
  };

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public self: MatDialogRef<CountrySplitModalComponent>,
      private AmCharts: AmChartsService,
  ) {
    super();
  }

  ngOnInit() {
  }

  public getData(): void {
    this.loader = true;

    // this.aiAssignService.audience(this.data.id).pipe(
    //     takeUntil(this.destroy),
    //     finalize(() => this.loader = false)
    // ).subscribe((res) => {
    //   this.audience = res;
    //
    //   this.buildCharts(res);
    // }, ({error}) => {
    //   this.handleErrors(error);
    // });
  }

  private buildCharts(data): void {
    if (data.country_split && !Array.isArray(data.country_split)) {
      this.countrySplit.dataProvider = Object.keys(data.country_split).map(key => ({
        label: key,
        value: data.country_split[key]
      }));
    }

    setTimeout(() => {
      this.AmCharts.makeChart('country-split', this.countrySplit);
    }, 100);
  }

}
