import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallStatusComponent } from './call-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TimeStrPipeModule } from './timestr-pipe/timestr.pipe.module';
import { ElemLeftPosPipeModule } from './elem-left-pos-pipe/elem-left-pos.pipe.module';
import { ElemRightPosPipeModule } from './elem-right-pos-pipe/elem-right-pos.pipe.module';

@NgModule({
  declarations: [
    CallStatusComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,

    TimeStrPipeModule,
    ElemLeftPosPipeModule,
    ElemRightPosPipeModule,
  ],
  exports: [
    CallStatusComponent
  ],
  entryComponents: []
})

export class CallStatusModule {
}
