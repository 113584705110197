import {Cookies} from '../../helpers/Cookies';
import {FormGroup} from '@angular/forms';
import {Unsubscribe} from '../../classes/unsubscribe';

class BaseComponent extends Unsubscribe {

    public loader = false;

    public subscribers: any = {};

    public showLoader() {
        this.loader = true;
    }

    public hideLoader() {
        this.loader = false;
    }

    public toggleLoader(params?: any) {
        this.loader = !this.loader;
    }

    public isAuthenticated() {
        return !!Cookies.get('token');
    }

    public getBackendError(form: FormGroup, control: string) {
        if (form && form.get(control) && form.get(control).errors) {
            return form.get(control).errors['backend'];
        }
        return null;
    }

    public getDefaultValue(data: any, key): any {
        if (!data) {
            return '';
        }

        if (!data[key]) {
            return '';
        }

        return data[key];
    }

    unsubscribe() {
        if (typeof this.subscribers === 'object') {
            for (const name in this.subscribers) {
                if (this.subscribers.hasOwnProperty(name) && this.subscribers[name]) {
                    this.subscribers[name].unsubscribe();
                }
            }
        }
    }
}

export default BaseComponent;
