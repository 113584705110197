import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.less']
})
export class GeneralComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
