import {AgentsActionTypes, AgentsActionsUnion} from '../actions/agents-actions';
import {Agent} from '../services/agent/Agent';

const initialSate = {
    agents: [],
    shiftAgents: [],
    allAgents: [],
    allAgentsNew: [],
    shiftId: 0,
};

export function agentsReducer(state: any = initialSate, action: AgentsActionsUnion) {
    switch (action.type) {
        case AgentsActionTypes.SET_AGENTS: {
            return {
                ...state,
                agents: action.payload
            };
        }
        case AgentsActionTypes.SET_SHIFT_ID: {
            return {
                ...state,
                shiftId: action.payload
            };
        }
        case AgentsActionTypes.SET_SHIFT_AGENTS: {
            return {
                ...state,
                shiftAgents: action.payload
            };
        }
        case AgentsActionTypes.UPDATE_SHIFT_AGENTS: {
            const newState = Object.assign([], state.shiftAgents);
            newState.map((agent: Agent, index) => {
                if (agent.id === action.payload.id) {
                    newState[index] = action.payload;
                }
            });
            return {
                ...state,
                shiftAgents: newState
            };
        }
        case AgentsActionTypes.SET_ALL_AGENTS: {
            return {
                ...state,
                allAgents: action.payload
            };
        }
        case AgentsActionTypes.SET_ALL_AGENTS_NEW: {
            return {
                ...state,
                allAgentsNew: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
