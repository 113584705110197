import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentsSheetComponent} from './agents-sheet.component';
import {
    MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule,
    MatMenuModule
} from '@angular/material';

@NgModule({
    declarations: [
        AgentsSheetComponent
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatMenuModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule
    ],
    exports: [
        AgentsSheetComponent
    ]
})

export class AgentsSheetModule {
}
