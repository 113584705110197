import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FilterModalComponent} from '../../filters/filter-modal/filter-modal.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-call-report-filter',
    templateUrl: './call-report-filter.component.html',
    styleUrls: ['./call-report-filter.component.less']
})
export class CallReportFilterComponent implements OnInit, OnDestroy {

    @Input() public columnsToDisplay: any;

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    public filters: any = [];

    private reqFilters: any = {};

    public modal = null;

    constructor(
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.modal) {
            this.modal.unsubscribe();
        }
    }

    add() {

        if (this.modal) {
            this.modal.unsubscribe();
        }

        this.modal = this.dialog.open(FilterModalComponent, {
            width: '600px',
            data: {
                names: Object.assign([], this.columnsToDisplay.filter(c => !['bool', 'datetime'].includes(c.type))),
                filter: null,
            }
        }).afterClosed().subscribe((res) => {

            if (!res) {
                return;
            }

            this.filters.push(res);

            let filters = [];

            if (this.reqFilters['filter[' + res.name + ']']) {
                filters = JSON.parse(this.reqFilters['filter[' + res.name + ']']);
            }

            filters.push(res.value);

            this.reqFilters['filter[' + res.name + ']'] = JSON.stringify(filters);

            this.change.emit(this.reqFilters);
        });
    }

    remove(i) {

        let filters = [];

        if (this.reqFilters['filter[' + this.filters[i].name + ']']) {
            filters = JSON.parse(this.reqFilters['filter[' + this.filters[i].name + ']']);
        }

        const index = filters.indexOf(this.filters[i].value);

        if (index === -1) {
            return;
        }

        filters.splice(index, 1);

        this.reqFilters['filter[' + this.filters[i].name + ']'] = JSON.stringify(filters);

        this.filters.splice(i, 1);

        this.change.emit(this.reqFilters);
    }

}
