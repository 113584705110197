import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress-loader',
    templateUrl: './progress-loader.component.html',
    styleUrls: ['./progress-loader.component.less']
})
export class ProgressLoaderComponent implements OnInit {

    @Input() show: boolean;

    @Input() percent: number;

    @Input() title: string;

    constructor() {
    }

    ngOnInit() {
    }

}
