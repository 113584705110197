export class Agent {

    public static readonly DAY_SHIFT = 1;

    public static readonly NIGHT_SHIFT = 2;

    public static readonly VISIBLE = 0;

    public static readonly HIDDEN = 1;

    public static readonly ADMIN_ID = 1;

    public static readonly RETENTION_REASSIGN = 383;

    public id: number;

    public is_dialer: boolean;

    public crm_id: number;

    public email: string;

    public username: string;

    public user_hash: string;

    public first_name: string;

    public last_name: string;

    public role: string;

    public hidden: number;

    public shift: number;

    public created_at: number;

    public updated_at: number;

    public crm_username: string;

    public avatar: string;

    public assigned: number | null;

    public touched: number | null;

    public progress: number | null;

    public call_status: string | null;

    public is_sip_blocked: boolean;

    public status?: any;

    public ai_prediction?: any;
}
