import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConditionsComponent} from './conditions.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatAutocompleteModule, MatButtonModule,
    MatDatepickerModule, MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {InRangeInputModule} from './in-range-input/in-range-input.module';
import {DatePeriodInputModule} from './date-period-input/date-period-input.module';
import {AssignedToModule} from '../filters/assigned-to/assigned-to.module';
import {LessThanPeriodInputModule} from './less-than-period-input/less-than-period-input.module';
import {GreaterThanOrEqualToTimeModule} from './greater-than-or-equal-to-time/greater-than-or-equal-to-time.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    declarations: [ConditionsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        DragDropModule,
        MatDialogModule,
        InRangeInputModule,
        DatePeriodInputModule,
        AssignedToModule,
        LessThanPeriodInputModule,
        GreaterThanOrEqualToTimeModule,
        MatProgressSpinnerModule
    ],
    exports: [ConditionsComponent],
    entryComponents: [
        DataPicketCampaignsComponent
    ]
})
export class ConditionsModule {
}
