import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TouchedComponent} from './touched.component';
import {MatSelectModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        TouchedComponent,
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        TouchedComponent,
    ]
})
export class TouchedModule {
}
