import {Component, Inject, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {UserService} from '../../services/user/user.service';
import {User} from '../../services/user/user';

@Component({
    selector: 'app-delete-user',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.less']
})
export class DeleteUserComponent extends BaseComponent implements OnInit {

    constructor(
        private self: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public user: User,
        private userService: UserService,
        private snackBar: MatSnackBar
    ) {
        super();
    }

    ngOnInit() {}

    deleteUser() {
        this.showLoader();
        this.userService.deleteUser(this.user.id).subscribe((response) => {
            this.self.close('Updated!');
            this.snackBar.open('User have been deleted!', 'Dismiss', {
                duration: 3000,
            });
            this.hideLoader();
        }, (error) => {
            let message = 'There was an error contact administration!';
            if (error.error && error.error['id']) {
                message = error.error['id'];
            }
            this.self.close();
            this.snackBar.open(message, 'Dismiss', {
                duration: 3000,
            });
            this.hideLoader();
        });
    }

}
