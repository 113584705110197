import {Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {Agent} from '../../services/agent/Agent';
import {MatDialog} from '@angular/material';
import {CallActivityInfoComponent} from '../call-activity-info/call-activity-info.component';
import {CentrifugeService} from '../../services/centrifuge/centrifuge.service';
import {Unsubscribe} from '../../classes/unsubscribe';
import {takeUntil} from 'rxjs/operators';


const callStatuses = {
    'call:end': {
        icon: 'local_phone',
        title: 'Not active',
        statuses: [
            'call:end',
            null
        ],
        color: '#B3E5FC',
    },
    'call:ring': {
        icon: 'phone_forwarded',
        title: 'Ringing',
        statuses: [
            'call:ring',
        ],
        color: '#FFF9C4',
    },
    'call:answer': {
        icon: 'phone_in_talk',
        title: 'On call',
        statuses: [
            'call:answer',
            'call:start'
        ],
        color: '#C8E6C9',
    },
    'call:start': {
        icon: 'phone_in_talk',
        title: 'On call',
        statuses: [
            'call:answer',
            'call:start'
        ],
        color: '#C8E6C9',
    },
};

@Component({
    selector: 'app-call-activity',
    templateUrl: './call-activity.component.html',
    styleUrls: ['./call-activity.component.less']
})
export class CallActivityComponent extends Unsubscribe {

    @Input() public agent: Agent;

    @Input() public set status(val) {
        if (val && callStatuses[val]) {
            this.callStatus = callStatuses[val];
        }
    };

    public callStatus = callStatuses['call:end'];

    get existsCallBlocked(): boolean {
        if (this.agent && this.agent.hasOwnProperty('is_sip_blocked') && this.agent.is_sip_blocked) {
            return true;
        }

        return false;
    }

    constructor(
        private dialog: MatDialog,
    ) {
        super();
    }

    public viewInfo(): void {
        const dialogRef = this.dialog.open(CallActivityInfoComponent, {
            width: '1200px',
            data: {
                agent: this.agent,
                id: 'call-chart'
            }
        });

        const sub = dialogRef.componentInstance.onUnblockAllLeads.subscribe(agentId => {
            if (this.agent && this.agent.id === agentId && this.agent.is_sip_blocked) {
                this.agent.is_sip_blocked = false;
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }
}
