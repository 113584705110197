import { NgModule } from '@angular/core';
import { FileStatusPipe } from './file-status.pipe';

@NgModule({
  declarations: [FileStatusPipe],
  exports: [
    FileStatusPipe
  ]
})
export class FileStatusPipeModule { }
