import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QueueTableSkeletonComponent} from './queue-table-skeleton.component';

@NgModule({
    declarations: [QueueTableSkeletonComponent],
    imports: [
        CommonModule
    ],
    exports: [
        QueueTableSkeletonComponent
    ]
})
export class QueueTableSkeletonModule {
}
