import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateUserComponent} from './create-user.component';
import {
    MatAutocompleteModule,
    MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
    MatSelectModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../general/loader/loader.module';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
    declarations: [
        CreateUserComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        RouterModule,
        ReactiveFormsModule,
        LoaderModule,
        MatButtonModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatChipsModule
    ],
    exports: [
        CreateUserComponent
    ]
})
export class CreateUserModule {
}
