import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {PermissionsService} from '../../../services/permissions/permissions.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Permissions} from '../../../helpers/Permissions';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden', padding: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PermissionsComponent extends BaseComponent implements OnInit {

  @Input() permissionsList: any;

  public fieldsLoaders: any = {};

  public rowData: any = {};

  public groups: any = {};

  public role: any = '';
  public title = ''

  public loader: any = false;

  public displayedColumns = ['Name', 'Count Permissions'];

  public subscribers: any = {
    getGroups: null,
    updatePermission: null
  };
  public expandedElement: any;

  constructor(
      private route: ActivatedRoute,
      private permissionsService: PermissionsService,
      private snack: MatSnackBar,
      public permissions: Permissions
  ) {
    super();
    this.role = this.route.snapshot.params.id;
    this.title = this.route.snapshot.queryParams.name;
  }

  ngOnInit() {
    this.getGroupsTable();
  }

  public toggleExpand(row) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  public getGroupsTable() {
    this.showLoader();

    this.subscribers.getGroups = this.permissionsService.getPermissions(this.role).subscribe(res => {
      let groups: any = {};
      Object.values(res).forEach((item: any) => {
        if (typeof groups[item.type] === 'undefined') {
          groups[item.type] = {
            name: item.type,
            items: [item]
          };
        } else {
          groups[item.type].items.push(item);
        }
      });

      groups = Object.values(groups);

      groups.map((item) => {
        item['chanks'] = this.chunkArray(item.items, Math.ceil(item.items.length / 2))
      })

      this.groups = groups;

      this.hideLoader();
    });
  }

  public chunkArray(arr, chunkSize) {
    if (arr) {
      const index = 0;
      const arrayLength = arr.length;
      const tempArray = [];
      for (let index = 0; index < arrayLength; index += chunkSize) {
        const myChunk = arr.slice(index, index + chunkSize);
        tempArray.push(myChunk);
      }
      return tempArray;
    } else {
      return [];
    }
  }

  public changePermission(row) {
    if (!this.permissions.can('role', 'can_update_permissions_role')) {
      return;
    }

    row.can = !row.can;
    this.subscribers.updatePermission = this.permissionsService.updateFieldPermission(this.role, row).subscribe(res => {
      this.snack.open('The action was successful!', 'Dismiss', {
        duration: 3000
      });
    }, err => {
      for (let e in err.error) {
        if (err.error.hasOwnProperty(e) && err.error[e]) {
          this.snack.open(err.error[e], 'Dismiss', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          break;
        }
      }
    });
  }

  public formatLabel(str: string) {
    return str.split('_').map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(' ');
  }
}
