import { NgModule } from '@angular/core';
import {ProgressColorPipe} from './progress-color.pipe';

@NgModule({
    declarations: [ProgressColorPipe],
    exports: [
        ProgressColorPipe
    ]
})
export class ProgressColorPipeModule { }
