import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InRangeInputComponent} from './in-range-input.component';
import {MatBadgeModule, MatBottomSheetModule, MatButtonModule, MatChipsModule, MatRippleModule, MatTooltipModule} from '@angular/material';
import {MultipleSelectorComponent} from '../../general/multiple-selector/multiple-selector.component';

@NgModule({
    declarations: [
        InRangeInputComponent
    ],
    imports: [
        CommonModule,
        MatBottomSheetModule,
        MatBadgeModule,
        MatButtonModule,
        MatChipsModule,
        MatTooltipModule,
        MatRippleModule
    ],
    exports: [
        InRangeInputComponent
    ],
    entryComponents: [
        MultipleSelectorComponent
    ]
})
export class InRangeInputModule {
}
