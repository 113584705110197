import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';
import {MatDialog} from '@angular/material';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-date-range-select',
    templateUrl: './date-range-select.component.html',
    styleUrls: ['./date-range-select.component.less'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangeSelectComponent),
        multi: true
    }]
})

export class DateRangeSelectComponent implements OnInit, OnChanges {

    @Input() placeholder = 'Select Date Range';

    public loader = true;

    public selectedRange: any = {
        id: 'custom',
        value: {
            start: '',
            end: ''
        }
    };

    public date: FormControl = new FormControl();

    @Input() model: any;

    @Output() modelChange = new EventEmitter<any>();

    @Output() public change = new EventEmitter();

    constructor(private dialog: MatDialog) {}

    private onChange: any = (value: []) => {};
    private onTouched: any = (value: []) => {};

    ngOnInit() {
        if (this.model) {
            const filterData = this.model.split(' ');
            this.date.patchValue(filterData[0] + ' / ' + filterData[1]);
            this.selectedRange.value.rawEnd = new Date(filterData[0]);
            this.selectedRange.value.rawStart = new Date(filterData[1]);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model'] && !changes.model.currentValue) {
            this.clearValues();
        }
    }

    public openDatePicker(): void {
        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: this.selectedRange
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.model = result.value.end + ' ' + result.value.start;
                this.modelChange.emit(this.model);
                if (this.model !== this.selectedRange.value.end + ' ' + this.selectedRange.value.start) {
                    this.change.emit(result);
                }
                this.selectedRange = result;
                this.date.patchValue(result.value.end + ' / ' + result.value.start);

                this.onChange(result.value.end + ' / ' + result.value.start);
                this.onTouched();
            }
        });
    }

    public clearInput(): void {

        const oldValue = this.model;
        this.clearValues();
        this.onChange('');
        if (oldValue) {
            this.change.emit();
        }

    }

    private clearValues(): void {
        this.date.patchValue('');
        this.model = '';
        this.modelChange.emit(this.model);
        this.selectedRange = {
            id: 'custom',
            value: {
                start: '',
                end: ''
            }
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    writeValue(value: string): void {
        this.date.setValue(value);
    }

}
