import { NgModule } from '@angular/core';
import { SuggestionStatusPipe } from './suggestion-status.pipe';

@NgModule({
  declarations: [SuggestionStatusPipe],
  exports: [
    SuggestionStatusPipe
  ]
})
export class SuggestionStatusPipeModule { }
