import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {MultipleSelectorComponent} from '../../general/multiple-selector/multiple-selector.component';

@Component({
    selector: 'app-in-range-input',
    templateUrl: './in-range-input.component.html',
    styleUrls: ['./in-range-input.component.less']
})
export class InRangeInputComponent implements OnInit, OnChanges {

    @Input() public options: Array<string>;

    @Input() public value: string;

    @Input() public label: string;

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    public selected = [];

    public selectedLength = 0;

    constructor(private bottomSheet: MatBottomSheet) {
    }

    ngOnInit() {
        this.setValue(this.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.firstChange && changes.value.currentValue) {
            this.setValue(changes.value.currentValue);
        }
    }

    public dispatchChange() {
        this.change.emit({
            value: this.selected.length > 0 ? JSON.stringify(this.selected) : null,
            original: this.selected
        });
    }

    public openSelector() {
        const bottomSheet = this.bottomSheet.open(MultipleSelectorComponent, {
            data: {
                selected: Object.assign([], this.selected),
                autoComplete: this.options
            }
        });

        const value = JSON.stringify(this.selected);

        bottomSheet.afterDismissed().subscribe((res) => {
            this.selected = res;
            this.selectedLength = this.selected.length;
            if (JSON.stringify(res) !== value) {
                this.dispatchChange();
            }
        });
    }

    private setValue(value) {
        if (!value) {
            return;
        }
        switch (typeof value) {
            case 'string':
                this.selected = JSON.parse(value);
                break;
            default:
                this.selected = value ? value : [];
                break;
        }
        this.selectedLength = this.selected.length;
    }
}
