import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileStatus'
})
export class FileStatusPipe implements PipeTransform {

  transform(val: number): string {
    switch (val) {
      case 0: return 'Uploaded';
      case 1: return 'Processing';
      case 2: return 'Processed';
      default: return '' + val;
    }
  }

}
