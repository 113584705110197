import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewCountryInfoComponent} from './view-country-info.component';
import {MatDividerModule, MatListModule, MatTabsModule} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';

@NgModule({
    declarations: [
        ViewCountryInfoComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        LoaderModule,
        MatListModule,
        MatDividerModule
    ],
    exports: [
        ViewCountryInfoComponent
    ],
    entryComponents: [
        ViewCountryInfoComponent
    ]
})

export class ViewCountryInfoModule {}
