import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})
export class AssignReportService {

    constructor(
        private api: ApiService
    ) {}

    public getAssignReport(data:any): Observable<any> {
        return this.api.get('v1/report/assign', {
            params: data
        });
    }
}
