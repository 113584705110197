import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elemRightPos'
})
export class ElemRightPosPipe implements PipeTransform {

  transform(elem: any): string {
    return 100 - (100 * (elem.to.h + elem.to.m / 60) / 24) + '%'
  }

}
