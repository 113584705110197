import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactChartComponent} from './contact-chart.component';
import {
  MatButtonModule, MatCardModule, MatChipsModule, MatIconModule, MatMenuModule, MatTableModule, MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';
import {AmChartsModule} from '@amcharts/amcharts3-angular';

@NgModule({
  declarations: [
    ContactChartComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    LoaderModule,
    AmChartsModule,
    MatButtonModule,
    MatToolbarModule,
    MatTableModule,
    MatTabsModule
  ],
  exports: [
    ContactChartComponent
  ]
})
export class ContactChartModule {
}
