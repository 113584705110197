import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateViewComponent} from './create-view.component';
import {
    MatAutocompleteModule,
    MatButtonModule, MatCardModule, MatChipsModule, MatDatepickerModule, MatDividerModule, MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatSelectModule, MatSlideToggleModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../general/loader/loader.module';
import {RouterModule} from '@angular/router';
import {ErrorDialogComponent} from '../general/error-dialog/error-dialog.component';
import {ConditionsModule} from '../conditions/conditions.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        CreateViewComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        LoaderModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        RouterModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        ConditionsModule,
        MatProgressSpinnerModule
    ],
    exports: [
        CreateViewComponent
    ],
    entryComponents: [
        ErrorDialogComponent
    ]
})
export class CreateViewModule {
}
