import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_APPLICATIONS, TYPE_LEADS} from '../../constants/user';
import {PROGRESS_BARS, PROGRESS_BARS_RETENTION} from '../../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class AssignSuggestionService {

  private roleType: number;

  constructor(
      private api: ApiService,
      private store: Store<any>
  ) {
    this.store.pipe(
        select(GetRoleType),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

  }

  public getLeadsSuggestion(data): Observable<any> {
    const type = this.roleType === TYPE_LEADS ? 'leads-suggestion' : 'applications-suggestion';

    return this.api.post(`v1/assign/${type}`, {
      body: data
    });
  }

  public getApplicationsSuggestion(data): Observable<any> {
    return this.api.post('v1/assign/applications-suggestion', {
      body: data
    });
  }

  public saveConditionToReport(data): Observable<any> {
    return this.api.post('v1/assign/report-condition-save', {
      body: data
    });
  }

  public runCalculation(data): Observable<any> {
    return this.api.post('v1/assign/report-run', {
      body: data
    });
  }

  public getSetting(data: string): Observable<any> {
    return this.api.get('v1/assign/setting', {
      params: {
        setting: data
      }
    });
  }

  public getCountries(data): Observable<any> {
    const type = this.roleType === TYPE_LEADS ? 'leads-suggestion-countries' : 'applications-suggestion-countries';

    return this.api.post(`v1/assign/${type}`, {
      body: data
    });
  }

  public getStatickInfo(): Observable<any> {
    return this.api.post('v1/assign/report-status',{})
  }

  public getAssignableStats(): Observable<any> {
    return this.api.get('v1/assign/assignable-stats',{})
  }

}
