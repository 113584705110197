import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisqualifiedComponent} from './disqualified.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        DisqualifiedComponent
    ],
    imports: [
        CommonModule,
        MatBadgeModule,
        MatButtonModule
    ],
    exports: [
        DisqualifiedComponent
    ]
})
export class DisqualifiedModule {
}
