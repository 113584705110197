import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CallActivityService {

    public report: any = {
        table: (params): Observable<any> => {
            return this.api.get('v1/call-activity/report/table', {
                params: params
            });
        },
        chart: (params): Observable<any> => {
            return this.api.get('v1/call-activity/report/chart', {
                params: params
            });
        }
    };

    constructor(private api: ApiService) {
    }

    public getCallActivity(id: number): Observable<any> {
        return this.api.get('v1/call-activity/' + id, {
            params: {}
        });
    }
}
