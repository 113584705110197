import {ViewsActionsUnion, ViewsActionTypes} from '../actions/views-actions';

export const initialState = {
    id: 0,
    name: '',
    fields: [],
    data: [],
    page_size: 100,
    pages: 0,
    page: 0,
    rows: 0,
    sort: {},
    filter: {}
};

export function viewsReducer(state: any = initialState, action: ViewsActionsUnion) {
    switch (action.type) {
        case ViewsActionTypes.SET_DATA: {
            localStorage.setItem('currentViewName', action.payload.name);

            return {
                ...state,
                ...action.payload
            };
        }
        case ViewsActionTypes.UPDATE_DATA: {
            return {
                ...state,
                data: action.payload
            };
        }
        case ViewsActionTypes.DELETE_LEAD: {
            return {
                ...state,
                data: state.data.filter((lead) => {
                    return !action.payload.includes(lead.id);
                })
            };
        }
        default: {
            return state;
        }
    }
}
