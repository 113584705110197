import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignLeadsModalComponent} from './assign-leads-modal.component';
import {MatButtonModule, MatDialogModule, MatProgressSpinnerModule, MatSelectModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {ConfirmModalModule} from '../confirm-modal/confirm-modal.module';

@NgModule({
    declarations: [
        AssignLeadsModalComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatButtonModule,
        ConfirmModalModule,
        MatProgressSpinnerModule
    ],
    exports: [
        AssignLeadsModalComponent
    ]
})
export class AssignLeadsModalModule {
}
