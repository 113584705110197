import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralSettingsComponent} from './general-settings.component';
import {
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GeneralSettingsNumberModule} from './general-settings-number/general-settings-number.module';

@NgModule({
    declarations: [GeneralSettingsComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatListModule,
        GeneralSettingsNumberModule,
        MatProgressSpinnerModule
    ],
    exports: [GeneralSettingsComponent]
})
export class GeneralSettingsModule {
}
