import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterModalComponent} from './filter-modal.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [FilterModalComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule
    ],
    exports: [FilterModalComponent]
})
export class FilterModalModule {
}
