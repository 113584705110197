import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentShiftsComponent} from './agent-shifts.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatProgressSpinnerModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatTooltipModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        AgentShiftsComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatGridListModule,
        MatListModule,
        MatIconModule,
        LoaderModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        DragDropModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        FormsModule,
        MatProgressSpinnerModule
    ],
    exports: [
        AgentShiftsComponent
    ]
})
export class AgentShiftsModule {
}
