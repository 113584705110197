import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {

    constructor(
        private api: ApiService
    ) {
    }

    public get(params): Observable<any> {
        return this.api.get('v1/history', {
            params: Object.assign({}, params),
        });
    }
}
