import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState, LOGIN_FEATURE_NAME } from '../login.reducer';

const getFeature = createFeatureSelector<LoginState>(LOGIN_FEATURE_NAME);

export const GetBoardId = createSelector(
    getFeature,
    state => state.board_id !== undefined && state.board_id
);

export const GetRoleType = createSelector(
    getFeature,
    state => state.role_type !== undefined && state.role_type
);

export const GetType = createSelector(
    getFeature,
    state => state.type !== undefined && state.type
);

export const GetActiveBoard = createSelector(
    getFeature,
    state => !!state.active_board && state.active_board
);
