import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-assign-status-modal',
  templateUrl: './assign-status-modal.component.html',
  styleUrls: ['./assign-status-modal.component.less']
})
export class AssignStatusModalComponent implements OnInit {

  constructor(
      public self: MatDialogRef<AssignStatusModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}

}
