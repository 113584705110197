import { NgModule } from '@angular/core';
import { SecondsToHumanPipe } from './seconds-to-human.pipe';

@NgModule({
  declarations: [SecondsToHumanPipe],
  exports: [
    SecondsToHumanPipe
  ]
})
export class SecondsToHumanPipeModule { }
