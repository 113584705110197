import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconSelectModalComponent} from './icon-select-modal.component';
import {MatAutocompleteModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';

@NgModule({
    declarations: [IconSelectModalComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        LoaderModule,
        MatIconModule,
        MatInputModule,
        MatAutocompleteModule
    ],
    exports: [
        IconSelectModalComponent
    ]
})
export class IconSelectModalModule {
}
