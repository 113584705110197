import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-reverse',
    templateUrl: './reverse.component.html',
    styleUrls: ['./reverse.component.less']
})

export class ReverseComponent implements OnInit {

    @Output() public toggleReverse = new EventEmitter();

    public icons = {
        'on': 'sync',
        'off': 'sync_disabled'
    };

    public switchIcon = 'on';

    constructor() {
    }

    ngOnInit() {
    }

    public toggleSwitch() {
        this.switchIcon = this.switchIcon === 'on' ? 'off' : 'on';
        this.toggleReverse.emit({
            value: this.switchIcon
        });
    }
}
