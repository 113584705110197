import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReverseComponent} from './reverse.component';
import {MatButtonModule, MatIconModule} from '@angular/material';

@NgModule({
    declarations: [
        ReverseComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        ReverseComponent
    ],
})
export class ReverseModule {
}
