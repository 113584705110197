import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Agent} from '../../../services/agent/Agent';
import {select, Store} from '@ngrx/store';
import {MatBottomSheet} from '@angular/material';
import {AgentsSheetComponent} from '../../agents-sheet/agents-sheet.component';
// import {NgModel} from '@angular/forms';
import { getAvatar } from '../../../helpers/Avagar';

@Component({
    selector: 'app-assigned-to',
    templateUrl: './assigned-to.component.html',
    styleUrls: ['./assigned-to.component.less']
})

export class AssignedToComponent implements OnInit, OnChanges {

    public getAvatar = getAvatar;

    public agents: Agent[] = [];

    public selectedAgents: Agent[] = [];

    public loading = true;

    public allAgents: any = false;

    @Input() model: any;

    @Input() agentsPerBoard: boolean = false;

    @Output() modelChange = new EventEmitter<any>();

    @Output() public agentsSelected = new EventEmitter();

    constructor(
        private store: Store<any>,
        private bottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {
        this.store.pipe(select('agents')).subscribe((value: any) => {
            if (!value.allAgents.length) { return; }
            if (this.agents.length) { this.loading = true; return; }

            this.agents = this.agentsPerBoard ? value.agents : value.allAgentsNew;

            if (this.model && this.model.length) {
                let agentIds: any;
                if (typeof this.model[0] === 'string') {
                    agentIds = this.model.split(',');
                } else {
                    agentIds = this.model.map(el => el.crm_id.toString());
                }

                this.selectedAgents = this.agents.filter((agent) => {
                    return agentIds.includes(agent.crm_id.toString());
                });
            }

            this.loading = false;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model'] && !changes.model.currentValue) {
            this.selectedAgents = [];
        }
    }

    public setAgents(): void {
        const bottomSheet = this.bottomSheet.open(AgentsSheetComponent, {
            data: {
                agents: Object.assign([], this.agents),
                selected: Object.assign([], this.selectedAgents),
                multiple: true,
                all: this.allAgents
            }
        });

        const selectedNow = Object.assign([], this.selectedAgents).sort(this.sortFn).map(a => a.crm_id).join();

        bottomSheet.afterDismissed().subscribe((result) => {
            if (result.all && result.selected.length) {
                this.allAgents = true;
                this.modelChange.emit([0]);
            } else {
                this.allAgents = false;
                this.selectedAgents = Object.assign([], result.selected);
                this.modelChange.emit(this.selectedAgents);
            }

            this.selectedAgents = Object.assign([], result);
            this.model = Object.assign([], result).sort(this.sortFn).map(a => a.crm_id).join();
            this.modelChange.emit(this.model);
            if (this.model !== selectedNow) {
                this.agentsSelected.emit(result);
            }
        });
    }

    private sortFn(a, b): number {
        if (a.crm_id > b.crm_id) {
            return -1;
        }
        if (a.crm_id < b.crm_id) {
            return 1;
        }
        return 0;
    }

}
