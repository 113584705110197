import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallReportFilterComponent} from './call-report-filter.component';
import {MatButtonModule, MatChipsModule, MatDialogModule, MatIconModule, MatTooltipModule} from '@angular/material';
import {FilterModalComponent} from '../../filters/filter-modal/filter-modal.component';

@NgModule({
    declarations: [CallReportFilterComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatChipsModule,
        MatDialogModule
    ],
    exports: [CallReportFilterComponent],
    entryComponents: [FilterModalComponent]
})
export class CallReportFilterModule {
}
