import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    }
  ]
})
export class TimeFieldComponent extends Unsubscribe implements OnInit {

  @Input() set errors(err) {
    if (err) {
      this.timeControl.setErrors(err);
    }
  }

  @Input() showLabel: boolean = true;
  @Input() isDefault: boolean = false;
  @Input() label: string;
  @Input() max: string = null;
  @Input() min: string = null;

  public periodControl: FormControl = new FormControl('', []);
  public timeControl: FormControl = new FormControl('', []);

  private onChange: any = (value: []) => {};
  private onTouched: any = (value: []) => {};

  constructor() {
    super();
  }

  ngOnInit() {
  }

  private convertToDate(time: string): Date | null {
    if (time) {
      const timeStr = time.split(':');
      const newDate = new Date();
      newDate.setHours(parseInt(timeStr[0], 10) || 0);
      newDate.setMinutes(parseInt(timeStr[1], 10) || 0);

      return newDate;
    }

    return null;
  }

  public setValue(e): void {
    const time = this.timeControl.value;
    if (time) {
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const hoursFrom = hours <= 9 ? '0' + hours : hours;
      const minutesFrom = minutes <= 9 ? '0' + minutes : minutes;

      const newValue = hoursFrom + ':' + minutesFrom;

      this.periodControl.setValue(newValue);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.periodControl.invalid ? {invalid: true} : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.periodControl.setValue(value);
    this.timeControl.setValue(this.convertToDate(value));

    this.periodControl.valueChanges.pipe(
        takeUntil(this.destroy),
        distinctUntilChanged()
    ).subscribe(val => {
        this.onChange(val);
        this.onTouched();
    });
  }

}
