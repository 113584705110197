import {Action} from '@ngrx/store';

export enum ChatActionsType {
    TOGGLE_CHAT = '[Chat] toggleChat',
    SET_AGENTS = '[Chat] setAgemt',
    SET_ACTIVE_AGENT = '[Chat] setActiveAgent',
    SET_BUBBLE_MESSAGE = '[Chat] setBubbleMessage',
    REMOVE_BUBBLE_MESSAGE = '[Chat] removeBubbleMessage',
    SET_MESSAGE_HISTORY = '[Chat] setMessageHistory',
    SET_MESSAGE = '[Chat] setMessage',
    UPDATE_AGENT = '[Chat] updataChat'
}

export class ToggleChat implements Action {
    readonly type = ChatActionsType.TOGGLE_CHAT;
    constructor(public payload?: any) {}
}

export class SetAgents implements Action {
    readonly type = ChatActionsType.SET_AGENTS;
    constructor(public payload: any) {}
}

export class SetActiveAgent implements Action {
    readonly type = ChatActionsType.SET_ACTIVE_AGENT;
    constructor(public payload: any) {}
}

export class SetBubbleMessage implements Action {
    readonly type = ChatActionsType.SET_BUBBLE_MESSAGE;
    constructor(public payload: any) {}
}

export class RemoveBubbleMessage implements Action {
    readonly type = ChatActionsType.REMOVE_BUBBLE_MESSAGE;
    constructor(public payload: any) {}
}

export class SetMessageHistory implements Action {
    readonly type = ChatActionsType.SET_MESSAGE_HISTORY;
    constructor(public payload: any) {}
}

export class SetMessage implements Action {
    readonly type = ChatActionsType.SET_MESSAGE;
    constructor(public payload: any) {}
}

export class UpdateAgent implements Action {
    readonly type = ChatActionsType.UPDATE_AGENT;
    constructor(public payload: any) {}
}

export type ChatActionsUnion = ToggleChat | SetAgents | SetActiveAgent | SetBubbleMessage | RemoveBubbleMessage  | SetMessage | SetMessageHistory | UpdateAgent;
