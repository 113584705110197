import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderModule} from '../general/loader/loader.module';
import {DashboardComponent} from './dashboard.component';
import {ViewModule} from '../view/view.module';
import {TotalSalesModule} from '../total-sales/total-sales.module';
import {ReassignComponent} from '../notifications/reassign/reassign.component';
import {AssignComponent} from '../notifications/assign/assign.component';

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        LoaderModule,
        ViewModule,
        TotalSalesModule,
    ],
    entryComponents: [
        ReassignComponent,
        AssignComponent
    ],
})

export class DashboardModule {}
