import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HolidaysComponent} from './holidays.component';
import {
    MatButtonModule, MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule, MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {HolidaysEditModule} from './holidays-edit/holidays-edit.module';
import {HolidaysEditComponent} from './holidays-edit/holidays-edit.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';

@NgModule({
    declarations: [HolidaysComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        HolidaysEditModule,
        MatDialogModule,
        MatSnackBarModule
    ],
    exports: [HolidaysComponent],
    entryComponents: [HolidaysEditComponent, ConfirmModalComponent]
})
export class HolidaysModule {
}
