import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import BaseComponent from '../base/base.component';
import {Agent} from '../../services/agent/Agent';
import {MatDialog, MatSnackBar} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ShiftsService} from '../../services/shifts/shifts.service';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {FormControl, FormGroup} from '@angular/forms';
import _filter from 'lodash/filter';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';
import {GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-agent-shifts',
    templateUrl: './agent-shifts.component.html',
    styleUrls: ['./agent-shifts.component.less']
})
export class AgentShiftsComponent extends BaseComponent implements OnInit {

    public getAvatar = getAvatar;

    @ViewChild('firstSearch') firstSearch: ElementRef;
    @ViewChild('secondSearch') secondSearch: ElementRef;

    public agentsSearch = {
        first: false,
        second: false,
        addFirst: false,
        addSecond: false
    };

    public loader = false;

    public selectedFirst = 0;
    public selectedSecond = 0;

    public hide = false;

    public shiftId: number;

    public agentsPerShift = [];

    public highlightNew: any = {};

    public f = [];
    public s = [];

    public sortedFirst = false;

    public sortedSecond = false;

    public autoCompleteLoader: any = false;

    public shifts = [];

    public agents: Agent[] = [];

    public day: Agent[] = [];

    public addAgentFormFirst: FormGroup = new FormGroup({
        addAgentFirst: new FormControl('', [])
    });

    public addAgentFormSecond: FormGroup = new FormGroup({
        addAgentSecond: new FormControl('', [])
    });

    public tooltip: string[] = [
        'Hide Agent from Shift',
        'Remove Agent from Shift',
        'Sort Agents'
    ];
    public toggleColor = '4DFF9B';

    public night: Agent[] = [];

    public filter: any = {
        first: [],
        second: []
    };

    public options = [];

    public loaders = {
        day: false,
        night: false
    };

    private roleType: number;

    constructor(
        private store: Store<any>,
        private shiftService: ShiftsService,
        private snack: MatSnackBar,
        private dialog: MatDialog,
        public permissions: Permissions
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.roleType = type;
        });
    }

    ngOnInit() {
        this.loader = true;
        this.store.pipe(select('agents')).pipe(
            takeUntil(this.destroy)
        ).subscribe((res) => {
            if (res) {
                if ((res.allAgents && !res.allAgents.length) || this.agents.length) {
                    return;
                }

                this.agents = res.allAgents;

                this.getShifts(true);
            }
        });
    }

    public getShifts(firstTime = false) {
        this.shiftService.getShifts({
            'filter[type]': this.roleType
        }).subscribe((res) => {
            this.shifts = res.data;

            if (firstTime) {
                this.selectedFirst =  this.shifts[0] ? this.shifts[0].name : null;
                this.selectedSecond = this.shifts[1] ? this.shifts[1].name : null;
            }

            this.getShiftToAgent();

            if (firstTime) {
                this.onSubscribeChanges();
            } else {
                this.loader = false;
            }
        });
    }

    public getShiftToAgent() {
        this.shifts.forEach((shift) => {
            this.agentsPerShift[shift.name] = shift.agents;
        });

        if (this.highlightNew[this.selectedFirst] && this.highlightNew[this.selectedFirst].length) {
            const currHShift = this.highlightNew[this.selectedFirst];
            this.agentsPerShift[this.selectedFirst].forEach((elApS) => {
                const index = currHShift.findIndex(elCurrH => elCurrH.crm_id === elApS.agent.crm_id);
                if (index !== -1) {
                    elApS.agent.markedAsNew = true;
                }
            });
        }

        if (this.highlightNew[this.selectedSecond] && this.highlightNew[this.selectedSecond].length) {
            const currHShift = this.highlightNew[this.selectedSecond];
            this.agentsPerShift[this.selectedSecond].forEach((elApS) => {
                const index = currHShift.findIndex(elCurrH => elCurrH.crm_id === elApS.agent.crm_id);
                if (index !== -1) {
                    elApS.agent.markedAsNew = true;
                }
            });
        }

        this.autoCompleteChangeFirst('');
        this.autoCompleteChangeSecond('');
    }

    public onSubscribeChanges() {
        this.addAgentFormFirst.valueChanges.subscribe((result) => {
            if (result && result.addAgentFirst) {
                this.autoCompleteChangeFirst(result.addAgentFirst);
            }
        });

        this.addAgentFormSecond.valueChanges.subscribe((result) => {
            if (result && result.addAgentSecond) {
                this.autoCompleteChangeSecond(result.addAgentSecond);
            }
        });

        this.loader = false;
    }

    public autoCompleteChangeFirst(result): void {
        this.autoCompleteLoader = true;
        if (result) {
            this.f = this.optionsFirst(this.agentsPerShift[this.selectedFirst], result);
        } else {
            this.f = this.optionsFirst(this.agentsPerShift[this.selectedFirst], '');
        }
    }

    public autoCompleteChangeSecond(result): void {
        this.autoCompleteLoader = true;
        if (result) {
            this.s = this.optionsSecond(this.agentsPerShift[this.selectedSecond], result);
        } else {
            this.s = this.optionsSecond(this.agentsPerShift[this.selectedSecond], '');
        }
    }

    public searchChange(event, list) {
        if (list === 1) {
            this.filter['first'] = this.agentsPerShift[this.selectedFirst].filter((agent) => {
                return !agent.agent.crm_username.toLocaleLowerCase().match(event.target.value.toLocaleLowerCase());
            }).map((agent) => {
                return agent.agent.id;
            });
        } else {
            this.filter['second'] = this.agentsPerShift[this.selectedSecond].filter((agent) => {
                return !agent.agent.crm_username.toLocaleLowerCase().match(event.target.value.toLocaleLowerCase());
            }).map((agent) => {
                return agent.agent.id;
            });
        }
    }

    public drop(event: CdkDragDrop<any>, list) {
        if (!this.permissions.can('shift', 'can_move_agent_shift')) {
            this.snack.open('You does not have permissions for move agents between shifts!', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
            return;
        }

        const agent = event.previousContainer.data[event.previousIndex];
        if (event.previousContainer === event.container) {
            return;
        }

        if (event.container.data.filter(shift => shift.agent.id === agent.agent.id).length > 0) {
            this.snack.open('The agent already exists on this shift!', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
            return;
        }

        const f = this.shifts.filter(shift => shift.name === this.selectedFirst);
        const t = this.shifts.filter(shift => shift.name === this.selectedSecond);

        if (list === 1) {
            this.agentsPerShift[this.selectedFirst] = this.agentsPerShift[this.selectedFirst].filter(ag => ag.agent.id !== agent.agent.id);
            this.agentsPerShift[this.selectedSecond].push(agent);
            this.shiftService.move(f[0].id, t[0].id, agent.agent.crm_id).subscribe((res) => {
                this.getShifts();
                this.snack.open('Agent move to shift', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            }, (err) => {
                this.snack.open('Problem', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            });
        } else {
            this.agentsPerShift[this.selectedSecond] = this.agentsPerShift[this.selectedSecond].filter(ag => ag.agent.id !== agent.agent.id);
            this.agentsPerShift[this.selectedFirst].push(agent);
            this.shiftService.move(t[0].id, f[0].id, agent.agent.crm_id).subscribe((res) => {
                this.getShifts();
                this.snack.open('Agent move to shift', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            }, (err) => {
                this.snack.open('Problem. Please refresh page', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            });
        }
    }

    public toggleLoader(shift) {
        this.loaders[shift] = !this.loaders[shift];
    }

    public toggleSearch(shift) {
        if (shift === 'first') {
            if (this.agentsSearch.first) {
                this.filter['first'] = [];
                this.firstSearch.nativeElement.value = '';
            }
            this.firstSearch.nativeElement.focus();
        } else {
            if (this.agentsSearch.second) {
                this.filter['second'] = [];
                this.secondSearch.nativeElement.value = '';
            }
            this.secondSearch.nativeElement.focus();
        }
        this.agentsSearch[shift] = !this.agentsSearch[shift];
    }

    public getFirstDropdown() {
        return this.shifts.filter(shift => shift.name !== this.selectedSecond);
    }

    public getSecondDropdown() {
        return this.shifts.filter(shift => shift.name !== this.selectedFirst);
    }

    public deleteAgent(agent, list) {
        this.dialog.open(ConfirmModalComponent, {
            width: '400px',
            hasBackdrop: true,
            data: {
                title: 'Delete Agent',
                description: `Are you sure want to remove agent ${agent.crm_username} from shift?`
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                if (list === 1) {
                    const q = this.shifts.filter(shift => shift.name === this.selectedFirst);
                    this.shiftId = q[0].id;
                } else {
                    const q = this.shifts.filter(shift => shift.name === this.selectedSecond);
                    this.shiftId = q[0].id;
                }
                this.shiftService.delete({
                    group: this.shiftId,
                    user: agent.crm_id
                }).subscribe((res) => {
                    this.snack.open('Agent deleted from shift', 'Dismiss', {
                        duration: 3000
                    });
                    if (list === 1) {
                        this.agentsPerShift[this.selectedFirst] = this.agentsPerShift[this.selectedFirst].filter(a => a.agent.crm_id !== agent.crm_id);
                    } else {
                        this.agentsPerShift[this.selectedSecond] = this.agentsPerShift[this.selectedSecond].filter(a => a.agent.crm_id !== agent.crm_id);
                    }
                }, (err) => {
                    this.snack.open('Problem', 'Dismiss', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });
                });
            }
        });
    }

    public addAgent(event, list) {
        const value = event.option.value;
        const currShift = list === 1 ? this.selectedFirst : this.selectedSecond;

        this.addAgentFormFirst.reset();
        this.addAgentFormSecond.reset();

        if (list === 1) {
            const q = this.shifts.filter(shift => shift.name === this.selectedFirst);
            this.shiftId = q[0].id;
        } else {
            const q = this.shifts.filter(shift => shift.name === this.selectedSecond);
            this.shiftId = q[0].id;
        }

        this.shiftService.add({
            group: this.shiftId,
            user: event.option.value.crm_id
        }).subscribe((res) => {
            this.snack.open('Agent added to shift', 'Dismiss', {
                duration: 3000
            });

            if (!this.highlightNew[currShift]) {
                this.highlightNew[currShift] = [];
            }
            this.highlightNew[currShift].push(value);

            this.getShifts(false);
            this.autoCompleteLoader = false;
        }, (err) => {
            this.snack.open('This agent has already been added to the shift!', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
            this.addAgentFormFirst.reset();
            this.addAgentFormSecond.reset();
        });
    }

    public hideAgent(event, list, id) {
        this.hide = true;
        if (list === 1) {
            const q = this.shifts.filter(shift => shift.name === this.selectedFirst);
            this.shiftId = q[0].id;
        } else {
            const q = this.shifts.filter(shift => shift.name === this.selectedSecond);
            this.shiftId = q[0].id;

        }
        this.shiftService.hide({
            group: this.shiftId,
            user: id,
            hide: event.checked
        }).subscribe((res) => {
            if (event.checked) {
                this.snack.open('You hide agent', 'Dismiss', {
                    duration: 1000,
                    panelClass: ['error-snackbar']
                });
            }
        }, (err) => {
            this.snack.open('Something went wrong', 'Dismiss', {
                duration: 1000,
                panelClass: ['error-snackbar']
            });
        });
    }

    public optionsFirst(shift, value: any = '') {
        const agentsNotInShift = _filter(this.agents, (item) => {
            if(!shift || shift.length === 0) return true;

            return !shift.some((el) => {
                return item.crm_id === el.agent_crm_id;
            });
        });

        this.autoCompleteLoader = false;

        if (value) {
            let val: string;

            if (typeof value === 'string') {
                val = value.toLocaleLowerCase();
            } else if (typeof value === 'object') {
                const crm_username = value.crm_username;
                val = crm_username.toLocaleLowerCase();
            }

            return agentsNotInShift.filter(agent => agent.crm_username.toLocaleLowerCase().includes(val));
        }

        return agentsNotInShift;
    }

    public optionsSecond(shift, value: any = '') {
        const agentsNotInShift = _filter(this.agents, (item) => {
            if(!shift || shift.length === 0) return true;

            return !shift.some((el) => {
                return item.crm_id === el.agent_crm_id;
            });
        });
        this.autoCompleteLoader = false;
        if (value) {
            let val: string;
            if (typeof value === 'string') {
                val = value.toLocaleLowerCase();
            } else if (typeof value === 'object') {
                const crm_username = value.crm_username;
                val = crm_username.toLocaleLowerCase();
            }
            return agentsNotInShift.filter(agent => agent.crm_username.toLocaleLowerCase().includes(val));
        }
        return agentsNotInShift;
    }

    public sort(list) {
        if (this.hide) {
            this.loader = true;
            this.getShifts();
            this.hide = false;
        }
        if (list === 1) {
            if (this.sortedFirst) {
                this.sortedFirst = false;
                this.agentsPerShift[this.selectedFirst].reverse();
            } else {
                this.sortedFirst = true;
                this.agentsPerShift[this.selectedFirst].sort((a, b) => (a.is_hidden > b.is_hidden) ? 1 : -1);

            }
        } else {
            if (this.sortedSecond) {
                this.sortedSecond = false;
                this.agentsPerShift[this.selectedSecond].reverse();
            } else {
                this.sortedSecond = true;
                this.agentsPerShift[this.selectedSecond].sort((a, b) => (a.is_hidden > b.is_hidden) ? 1 : -1);

            }
        }
    }
}
