import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';

import { BulkAssignIdComponent } from './bulk-assing-id.component';
import { LoaderModule } from '../general/loader/loader.module';
import { FileStatusPipeModule } from 'src/app/pipes/file-status-pipe/file-status.pipe.module';
import { InRangeInputModule } from '../conditions/in-range-input/in-range-input.module';
import { AssignedToModule } from '../filters/assigned-to/assigned-to.module';

@NgModule({
  declarations: [
    BulkAssignIdComponent,
  ],
  imports: [
    CommonModule,
  
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,

    FileStatusPipeModule,
    LoaderModule,
    InRangeInputModule,
    AssignedToModule
  ],
  exports: [
    BulkAssignIdComponent
  ],
})
export class BulkAssignIdModule {
}
