import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderModule} from '../general/loader/loader.module';
import {
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule, MatMenuModule,
    MatTooltipModule
} from '@angular/material';
import {LeadsInfoAmchartComponent} from './leads-info-amchart.component';
import {AmChartsModule} from "@amcharts/amcharts3-angular";
import {DataPicketCampaignsModule} from '../data-picket-campaigns/data-picket-campaigns.module';

@NgModule({
    declarations: [
        LeadsInfoAmchartComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        AmChartsModule,
        DataPicketCampaignsModule,
        MatChipsModule,
        MatButtonModule,
        MatMenuModule,


    ],
    exports: [
        LeadsInfoAmchartComponent
    ],
})
export class LeadsInfoAmchartModule {
}
