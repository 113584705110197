import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/index';
import {SseService} from '../sse/sse.service';

@Injectable({
    providedIn: 'root'
})
export class PhoneListService {

    constructor(private api: ApiService,
                private sse: SseService) {
    }

    public getPhoneLists(queue_id: number, params: any): Observable<any> {
        return this.api.get('v1/queue/' + queue_id + '/phone-lists', {
            params: params
        });
    }

    public createPhoneList(params: any): Observable<any> {
        return this.api.post('v1/phone-list/create', {
            body: params
        });
    }

    public updatePhoneList(id: number, params: any): Observable<any> {
        return this.api.post('v1/phone-list/' + id + '/update', {
            body: params
        });
    }

    public deletePhoneList(id: number): Observable<any> {
        return this.api.delete('v1/phone-list/' + id + '/delete', {
        });
    }

    public syncSelected(id: number, params: any): Observable<any> {
        return this.api.post('v1/phone-list/' + id + '/sync-selected', {
            body: params
        });
    }

    public syncAll(id: number): Observable<any> {
        return this.api.post('v1/phone-list/' + id + '/sync', {
        });
    }

    public clearList(id: number): Observable<any> {
        return this.api.post('v1/phone-list/' + id + '/clear', {});
    }

    public synchronizeAll(token: string): Observable<any> {
        return this.sse.get('events/phone-list', 'pool=' + token);
    }

}
