import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QueueViewsComponent} from './queue-views.component';
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
  MatListModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDialogModule, MatSnackBarModule
} from '@angular/material';
import {ActionModalComponent} from '../action-modal/action-modal.component';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {CloneQueueComponent} from '../clone-queue/clone-queue.component';
import {CloneQueueModule} from '../clone-queue/clone-queue.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';


@NgModule({
  declarations: [
    QueueViewsComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    CloneQueueModule,
    MatChipsModule,
    MatTabsModule
  ],
  exports: [
    QueueViewsComponent
  ],
  entryComponents: [
    ActionModalComponent,
    ConfirmModalComponent,
    ErrorDialogComponent,
    CloneQueueComponent,
  ]
})
export class QueueViewsModule {
}
