import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class UserStorageServiceService {

    constructor(private api: ApiService) {}

    public getString(key: string): Observable<any> {
        return this.api.get('v1/user/storage', {
            params: {
                action: 'get',
                key: key,
                type: 'string'
            }
        });
    }

    public getObject(key: string): Observable<any> {
        return this.api.get('v1/user/storage', {
            params: {
                action: 'get',
                key: key,
                type: 'object'
            }
        });
    }

    public setObject(key: string, value: Object): Observable<any> {
        return this.api.post('v1/user/storage', {
            body: {
                action: 'set',
                key: key,
                type: 'object',
                value: JSON.stringify(value)
            }
        });
    }

    public setString(key: string, value: any): Observable<any> {
        return this.api.post('v1/user/storage', {
            body: {
                action: 'set',
                key: key,
                type: 'string',
                value: value
            }
        });
    }

    public deleteKey(key: string): Observable<any> {
        return this.api.post('v1/user/storage', {
            body: {
                action: 'delete',
                type: 'string',
                key: key,
            }
        });
    }

}
