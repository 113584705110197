import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
// import {AddNewAgentComponent} from '../../agents/add-new-agent/add-new-agent.component';
import {ShiftsService} from '../../../services/shifts/shifts.service';
import {IconSelectModalComponent} from '../../general/icon-select-modal/icon-select-modal.component';

@Component({
    selector: 'app-add-group',
    templateUrl: './add-group.component.html',
    styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit, OnDestroy {

    public loader: any = false;

    public subscriber: any;

    public newGroup: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        icon: new FormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100)
        ]),
        description: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
        ]),
        active: new FormControl(0, []),
        type: new FormControl(this.data.type, []),
        board_id: new FormControl(this.data.board_id, [])
    });

    constructor(
        private self: MatDialogRef<AddGroupComponent>,
        private shiftsService: ShiftsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
    }

    ngOnDestroy(): void {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    public addNewGroup() {
        this.loader = true;
        this.subscriber = this.shiftsService.addShift(Object.assign({}, this.newGroup.value, {
            active: !!this.newGroup.value.active
        })).subscribe(result => {
            if (result) {
                this.self.close(result);
            }
        }, error => {
            for (const name in error.error) {
                if (error.error.hasOwnProperty(name) && this.newGroup.get(name)) {
                    this.newGroup.get(name).setErrors({
                        name: error.error[name]
                    });
                }
            }
            this.loader = false;
        });
    }

    public openSelectIconModal() {
        this.dialog.open(IconSelectModalComponent, {
            width: '600px',
            height: '600px',
            hasBackdrop: true,
            data: {
                title: 'Select icon',
                selectedIcon: this.newGroup.get('icon').value || ''
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.newGroup.get('icon').patchValue(result);
            }
        });
    }

}
