import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {SelectionModel} from '@angular/cdk/collections';
import {ActionModalComponent} from '../action-modal/action-modal.component';
import {QueueService} from '../../../services/queue/queue.service';
import BaseComponent from '../../base/base.component';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {Agent} from '../../../services/agent/Agent';
import {AgentService} from '../../../services/agent/agent.service';
import {CloneQueueComponent} from '../clone-queue/clone-queue.component';
import {select, Store} from '@ngrx/store';
import {Permissions} from '../../../helpers/Permissions';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-queue-views',
  templateUrl: './queue-views.component.html',
  styleUrls: ['./queue-views.component.less']
})
export class QueueViewsComponent extends BaseComponent implements OnInit, OnChanges {

  public loader = true;

  public agents: Array<Agent>;

  public selection = new SelectionModel<any>(true, []);

  public tabs = [
    {
      title: 'Default Queue',
      id: 0
    },
    {
      title: 'Direct Queue',
      id: 1
    }
  ];

  public activeTab = this.tabs[0];

  @Input() public queueId: number;

  @Output() public change = new EventEmitter<any>();

  public views: any = {
    data: [],
    page_size: 20,
    pages: 0,
    page: 0,
    rows: 0,
    sort: {},
    filter: {}
  };

  public activeId = 0;

  public skeleton: Array<null> = Array.from({
    length: 10
  }, () => {
    return null;
  });

  public activeOption = {
    id: 0,
    icon: 'more_horiz',
  };

  public sort: any = {
    name: 'name_asc',
    icon: 'text_rotate_up',
    label: 'Name (asc)',
    value: {
      'sort[name]': 'asc'
    }
  };

  public sortSelect: any[] = [
    {
      name: 'name_asc',
      icon: 'text_rotate_up',
      label: 'Name (asc)',
      value: {
        'sort[name]': 'asc'
      }
    },
    {
      name: 'name_desk',
      icon: 'text_rotation_down',
      label: 'Name (desc)',
      value: {
        'sort[name]': 'desc'
      }
    },
    {
      name: 'time_ask',
      icon: 'trending_up',
      label: 'Time (asc)',
      value: {
        'sort[id]': 'asc'
      }

    },
    {
      name: 'time_desk',
      icon: 'trending_down',
      label: 'Time (desc)',
      value: {
        'sort[id]': 'desc'
      }

    }
  ];
  public optionSelect: any[] = [
    {
      name: 'add_view',
      label: 'Add Queue',
      icon: 'library_add',
      show: this.permissions.can('queue', 'can_create_queue'),
      fn: () => {
        const dialog = this.dialog.open(ActionModalComponent, {
          width: '1000px',
          data: {
            icon: 'add_view',
            title: 'Create Queue',
            agents: this.agents
          }
        });
        dialog.afterClosed().subscribe((response) => {
          if (response) {
            this.toggleLoader();
            this.views.page = 0;
            this.getQueues();
          }
        });
      }
    },
    {
      name: 'refresh_dialer',
      label: 'Refresh Dialer',
      icon: 'restart_alt',
      show: this.permissions.can('queues', 'can_refresh_dialer'),
      fn: () => {
        if (this.selection.selected.length <= 0) {
          return;
        }
        const dialog = this.dialog.open(ConfirmModalComponent, {
          width: '450px',
          data: {
            title: 'Refresh Dialers',
            description: 'Are you sure want refresh dialers ' + this.selection.selected.length + ' queues?'
          }
        });
        dialog.afterClosed().subscribe((result) => {
          if (result) {
            const queueId = this.selection.selected.map((view) => {
              return view.id;
            });
            this.toggleLoader();
            this.queues.refreshDialerQueue({queue_id: queueId}).subscribe((response) => {
              this.snackBar.open(response.message, 'Dismiss', {
                duration: 3000,
              });
              const includes = queueId.includes(this.activeId);

              this.selection.clear();
              this.views.page = 0;
              this.toggleLoader();
              this.getQueues();
              this.toggleLoader();
              if (includes) {
                this.change.emit({
                  id: 0
                });
              }
            }, (resp) => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Queues can not be refreshed',
                  message: 'Refresh dialer was not possible to do as: There is no active dialer, All phone lists not included to dialer, Queues dont have phone lists'
                }
              });
              this.toggleLoader();
            });
          }
        });
      }
    },
    {
      label: 'Delete Selected',
      name: 'delete_selected',
      icon: 'delete_forever',
      show: this.permissions.can('queue', 'can_delete_queue'),
      fn: () => {
        if (this.selection.selected.length <= 0) {
          return;
        }
        const dialog = this.dialog.open(ConfirmModalComponent, {
          width: '450px',
          data: {
            title: 'Delete Selected Queues',
            description: 'Are you sure want delete ' + this.selection.selected.length + ' queues?'
          }
        });
        dialog.afterClosed().subscribe((result) => {
          if (result) {
            const queueId = this.selection.selected.map((view) => {
              return view.id;
            });
            this.toggleLoader();
            this.queues.deleteQueue({queue_id: queueId}).subscribe((response) => {
              this.snackBar.open(response.message, 'Dismiss', {
                duration: 3000,
              });
              const includes = queueId.includes(this.activeId);

              this.selection.clear();
              this.views.page = 0;
              this.toggleLoader();
              this.getQueues();
              this.toggleLoader();
              if (includes) {
                this.change.emit({
                  id: 0
                });
              }
            }, () => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Queues can not be deleted',
                  message: 'Please contact your Administrator!'
                }
              });
              this.toggleLoader();
            });
          }
        });

      }

    }
  ];

  public viewOption: any[] = [
    {
      name: 'edit_view',
      label: 'Edit',
      icon: 'edit',
      show: this.permissions.can('queue', 'can_edit_queue'),
      fn: (view) => {
        const dialog = this.dialog.open(ActionModalComponent, {
          width: '1000px',
          data: {
            view: view,
            agents: this.agents,
            icon: 'edit',
            title: 'Edit Queue',
            id: this.queueId,
            process_type: this.activeTab.id
          }
        });

        dialog.afterClosed().subscribe((response) => {
          if (response) {
            this.toggleLoader();
            this.views.page = 0;
            this.getQueues();
            if (this.activeId === view.id) {
              this.change.emit({
                id: view.id
              });
            }
          }
        });
      }
    },
    {
      name: 'clone_view',
      label: 'Clone',
      icon: 'file_copy',
      show: this.permissions.can('queues', 'can_clone_queue'),
      fn: (view) => {
        this.dialog.open(CloneQueueComponent, {
          width: '450px',
          data: view,
        }).afterClosed().subscribe((res) => {
          if (res) {
            this.toggleLoader();
            this.views.page = 0;
            this.getQueues();
          }
        });
      }
    },
    {
      name: 'delete_view',
      label: 'Delete',
      icon: 'delete_forever',
      show: this.permissions.can('queue', 'can_delete_queue'),
      fn: (view) => {
        const dialog = this.dialog.open(ConfirmModalComponent, {
          width: '450px',
          data: {
            title: '' + view.name + '',
            description: ' Are you sure want delete ' + view.name + ' queue?'
          }
        });
        dialog.afterClosed().subscribe((result) => {
          if (result) {
            const queueId = view.id;
            this.views.page = 0;
            this.toggleLoader();
            this.queues.deleteQueue({queue_id: queueId}).subscribe((response) => {
              this.snackBar.open(response.message, 'Dismiss', {
                duration: 3000,
              });
              this.views.page = 0;
              this.getQueues();
              if (this.activeId === view.id) {
                this.change.emit({
                  id: 0
                });
              }
            }, () => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Queue can not be deleted',
                  message: 'Please contact your Administrator!'
                }
              });
              this.toggleLoader();
            });
          }
        });
      }
    }
  ];


  constructor(
      public dialog: MatDialog,
      private queues: QueueService,
      private snackBar: MatSnackBar,
      private agentService: AgentService,
      private store: Store<any>,
      public permissions: Permissions
  ) {
    super();
    this.getQueues();
    this.store.pipe(select((state) => {
      return state.agents.allAgents;
    })).subscribe((res) => {
      this.agents = res;
    });
  }

  ngOnInit() {
    if (this.queueId) {
      this.activeId = this.queueId;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['queueId'] && changes['queueId'].currentValue) {
      this.activeId = changes['queueId'].currentValue;
    }
  }

  public setActiveTab(tab): void {
    if(this.activeTab.id !== tab.id) {
      this.activeTab = tab;

      this.getQueues();
    }
  }

  public changePage(event: any): void {
    this.views.page = event.pageIndex;
    this.views.page_size = event.pageSize;
    this.getQueues();
    this.toggleLoader();
  }

  public selectionChange(event, view) {
    if (!view) {
      return;
    }
    this.selection.toggle(view);
  }

  public selectSort(value: any): void {
    this.sort = value;
    this.views.page = 0;
    this.toggleLoader();
    this.getQueues();

  }

  public selectBulk(option: any): void {
    option.fn();
  }

  public selectOptionsView(option: any, view: any): void {
    option.fn(view);
  }

  public openView(event, view): void {
    this.activeId = view.id;
    this.change.emit({
      id: view.id
    });
  }

  protected getQueues(): void {
    this.showLoader();

    this.queues.getQueues(Object.assign({
      page: this.views.page,
      page_size: this.views.page_size,
      process_type: this.activeTab.id
    }, this.sort.value)).pipe(
        finalize(() => this.hideLoader())
    ).subscribe((response) => {
      this.views = Object.assign(this.views, response);
    });
  }

}
