import {Component, OnDestroy, OnInit} from '@angular/core';
import {Agent} from '../../services/agent/Agent';
import {select, Store} from '@ngrx/store';
import {GoalsService} from '../../services/goals/goals.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AgentGoalsEditComponent} from './agent-goals-edit/agent-goals-edit.component';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';

@Component({
    selector: 'app-agent-goals',
    templateUrl: './agent-goals.component.html',
    styleUrls: ['./agent-goals.component.less']
})
export class AgentGoalsComponent implements OnInit, OnDestroy {
    public getAvatar = getAvatar;

    public agents: Array<Agent> = [];

    public menuLoader: any = [];

    public options: any = [
        {
            icon: 'edit',
            name: 'Edit',
            show: this.permissions.can('goals', 'can_edit_user_goal'),
            fn: (i, agent, goal) => {

                for (const name of ['modal', 'edit']) {
                    if (this.subs[name]) {
                        this.subs[name].unsubscribe();
                    }
                }

                this.subs.modal = this.dialog.open(AgentGoalsEditComponent, {
                    data: goal ? goal : {},
                    width: '250px'
                }).afterClosed().subscribe((res) => {

                    if (!res) {
                        return;
                    }

                    this.menuLoader[i] = true;

                    if (goal) {
                        this.subs.edit = this.goalsService.update(goal.id, res).subscribe(this.edit(i), this.error(i));
                    } else {
                        this.subs.edit = this.goalsService.create(agent.id, res).subscribe(this.edit(i), this.error(i));
                    }

                });
            }
        }
    ];

    public goals: any = {};

    public loader: any = true;

    public subs: any = {
        agents: null,
        goals: null,
        modal: null,
        edit: null
    };

    constructor(
        private store: Store<any>,
        private goalsService: GoalsService,
        public dialog: MatDialog,
        public snack: MatSnackBar,
        public permissions: Permissions
    ) {
    }

    ngOnInit() {
        this.subs.agents = this.store.pipe(select('agents')).subscribe((res) => {
            this.loader = false;
            if (this.agents.length <= 0) {
                this.agents = res.allAgents;
            }
        });
        this.subs.goals = this.goalsService.get().subscribe((res) => {
            if (!Array.isArray(res)) {
                this.goals = res;
            }
        });
    }

    ngOnDestroy() {
        for (const name in this.subs) {
            if (this.subs.hasOwnProperty(name) && this.subs[name]) {
                this.subs[name].unsubscribe();
            }
        }
    }

    edit(i) {
        return (res) => {
            this.goals[res.agent_id] = res;
            this.menuLoader[i] = false;
            this.snack.open('Goal updated!', 'Dismiss', {
                duration: 3000
            });
        };
    }

    error(i) {
        return () => {
            this.menuLoader[i] = false;
            this.snack.open('There was an error updating your goal!', 'Dismiss', {
                duration: 3000
            });
        };
    }
}
