export const PROGRESS_BARS = [
    {
        name: 'All',
        progress: 'all_progress',
        total: 'all',
        called: 'all_called',
    },
    {
        name: 'Hot Incoming',
        progress: 'hot_incoming_progress',
        total: 'hot_incoming',
        called: 'hot_incoming_called',
    },
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'Fresh Leads Today (Reassigned)',
        progress: 'fresh_leads_reassign_today_progress',
        total: 'fresh_leads_reassign_today',
        called: 'fresh_leads_reassign_today_called',
    },
    {
        name: 'Processed',
        progress: 'processed_progress',
        total: 'processed',
        called: 'processed_called',
    },
    {
        name: 'Call Back',
        progress: 'call_back_progress',
        total: 'call_back',
        called: 'call_back_called',
    },
    {
        name: 'Swipes',
        progress: 'swipes_progress',
        total: 'swipes',
        called: 'swipes_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    }
];

export const PROGRESS_BARS_RETENTION = [
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'Assigned Today',
        progress: 'assigned_today_progress',
        total: 'assigned_today',
        called: 'assigned_today_called',
    },
    {
        name: 'Contact Me',
        progress: 'contact_me_progress',
        total: 'contact_me',
        called: 'contact_me_called',
    },
    {
        name: 'Up Sale',
        progress: 'up_sale_progress',
        total: 'up_sale',
        called: 'up_sale_called',
    },
    {
        name: 'Collection',
        progress: 'collection_progress',
        total: 'collection',
        called: 'collection_called',
    },
    {
        name: 'Active',
        progress: 'active_progress',
        total: 'active',
        called: 'active_called',
    },
    {
        name: 'General',
        progress: 'general_progress',
        total: 'general',
        called: 'general_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    },
];

export const PROGRESS_COLOR = {
    24: 'info-progress',
    50: 'success-progress',
    99: 'danger-progress',
    100: 'warning-progress'
};

export const JSON_FIELDS = ['products', 'calls', 'conversion_lang', 'schedule_times'];

export const LEADS_SECTION_TYPES = [
    {
        label: 'Fresh Leads Today',
        value: 'fresh_leads_today',
    },
    {
        label: 'Assigned Today',
        value: 'assigned_today',
    },
    {
        label: 'Contact Me',
        value: 'contact_me',
    },
    {
        label: 'Up Sale',
        value: 'up_sale',
    },
    {
        label: 'Collection',
        value: 'collection',
    },
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'General',
        value: 'general',
    },
    {
        label: 'Blocked',
        value: 'blocked',
    },
];

export const RETENTION_SECTION_TYPES = [
    {
        value: 'hot_incoming',
        label: 'Hot Incoming',
    },
    {
        value: 'fresh_leads_today',
        label: 'Fresh Leads Today',
    },
    {
        value: 'fresh_leads_reassign_today',
        label: 'Fresh Leads Today (Reassigned)',
    },
    {
        value: 'processed',
        label: 'Processed',
    },
    {
        value: 'call_back',
        label: 'Call Back',
    },
    {
        value: 'swipes',
        label: 'Swipes',
    },
    {
        value: 'blocked',
        label: 'Blocked',
    },
];

export const WIZARD_RETENTION_TYPES = [
    {
        value: 'blocked',
        label: 'Blocked',
    },
    {
        value: 'fresh_leads_today',
        label: 'Fresh Leads Today',
    },
    {
        label: 'Assigned Today',
        value: 'assigned_today',
    },
    {
        label: 'Contact Me',
        value: 'contact_me',
    },
    {
        label: 'Up Sale',
        value: 'up_sale',
    },
    {
        label: 'Collection',
        value: 'collection',
    },
    {
        label: 'General',
        value: 'general',
    },
    {
        label: 'Active',
        value: 'active',
    },
];
export const WIZARD_CONVERSION_TYPES = [
    {
        value: 'blocked',
        label: 'Blocked',
    },
    {
        value: 'hot_incoming',
        label: 'Hot Incoming',
    },
    {
        value: 'processed',
        label: 'Processed',
    },
    {
        value: 'fresh_leads_today',
        label: 'Fresh Leads Today',
    },
    {
        value: 'fresh_leads_reassign_today',
        label: 'Fresh Leads Today (Reassigned)',
    },
    {
        value: 'call_back',
        label: 'Call Back',
    },
    {
        value: 'swipes',
        label: 'Swipes',
    }
];
export const WIZARD_INTERVIEW_TYPES = [
    {
        value: 'blocked',
        label: 'Blocked',
    },
    {
        label: 'Assigned Today',
        value: 'assigned_today',
    },
    {
        value: 'fresh_leads_today',
        label: 'Fresh Leads Today',
    },
    {
        label: 'General',
        value: 'general',
    }
];
export const WIZARD_LATAM_TYPES = [
    {
        value: 'blocked',
        label: 'Blocked',
    },
    {
        value: 'fresh_leads_today',
        label: 'Fresh Leads Today',
    },
    {
        value: 'fresh_leads_reassign_today',
        label: 'Fresh Leads Today (Reassigned)',
    },
    {
        value: 'processed',
        label: 'Processed',
    },
    {
        value: 'call_back',
        label: 'Call Back',
    },
    {
        label: 'Collection',
        value: 'collection',
    },
];

export const WIZARD_TYPES_FILTER = {
    conversion: WIZARD_CONVERSION_TYPES,
    retention: WIZARD_RETENTION_TYPES,
    interview: WIZARD_INTERVIEW_TYPES,
    latam: WIZARD_LATAM_TYPES
};

export const MONTH_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const WIZARD_TYPE_RETENTION = [
    {
        name: 'All',
        progress: 'all_progress',
        total: 'all',
        called: 'all_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    },
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'Assigned Today',
        progress: 'assigned_today_progress',
        total: 'assigned_today',
        called: 'assigned_today_called',
    },
    {
        name: 'Contact Me',
        progress: 'contact_me_progress',
        total: 'contact_me',
        called: 'contact_me_called',
    },
    {
        name: 'Up Sale',
        progress: 'up_sale_progress',
        total: 'up_sale',
        called: 'up_sale_called',
    },
    {
        name: 'Collection',
        progress: 'collection_progress',
        total: 'collection',
        called: 'collection_called',
    },
    {
        name: 'General',
        progress: 'general_progress',
        total: 'general',
        called: 'general_called',
    },
    {
        name: 'Active',
        progress: 'active_progress',
        total: 'active',
        called: 'active_called',
    }
];

export const WIZARD_TYPE_CONVERSION = [
    {
        name: 'All',
        progress: 'all_progress',
        total: 'all',
        called: 'all_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    },
    {
        name: 'Hot Incoming',
        progress: 'hot_incoming_progress',
        total: 'hot_incoming',
        called: 'hot_incoming_called',
    },
    {
        name: 'Processed',
        progress: 'processed_progress',
        total: 'processed',
        called: 'processed_called',
    },
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'Fresh Leads Today (Reassigned)',
        progress: 'fresh_leads_reassign_today_progress',
        total: 'fresh_leads_reassign_today',
        called: 'fresh_leads_reassign_today_called',
    },
    {
        name: 'Call Back',
        progress: 'call_back_progress',
        total: 'call_back',
        called: 'call_back_called',
    },
    {
        name: 'Swipes',
        progress: 'swipes_progress',
        total: 'swipes',
        called: 'swipes_called',
    }
];

export const WIZARD_TYPE_INTERVIEW = [
    {
        name: 'All',
        progress: 'all_progress',
        total: 'all',
        called: 'all_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    },
    {
        name: 'Assigned Today',
        progress: 'assigned_today_progress',
        total: 'assigned_today',
        called: 'assigned_today_called',
    },
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'General',
        progress: 'general_progress',
        total: 'general',
        called: 'general_called',
    }
];

export const WIZARD_TYPE_LATAM = [
    {
        name: 'All',
        progress: 'all_progress',
        total: 'all',
        called: 'all_called',
    },
    {
        name: 'Blocked',
        progress: 'blocked_progress',
        total: 'blocked',
        called: 'blocked_called',
    },
    {
        name: 'Fresh Leads Today',
        progress: 'fresh_leads_today_progress',
        total: 'fresh_leads_today',
        called: 'fresh_leads_today_called',
    },
    {
        name: 'Fresh Leads Today (Reassigned)',
        progress: 'fresh_leads_reassign_today_progress',
        total: 'fresh_leads_reassign_today',
        called: 'fresh_leads_reassign_today_called',
    },
    {
        name: 'Processed',
        progress: 'processed_progress',
        total: 'processed',
        called: 'processed_called',
    },
    {
        name: 'Call Back',
        progress: 'call_back_progress',
        total: 'call_back',
        called: 'call_back_called',
    },
    {
        name: 'Collection',
        progress: 'collection_progress',
        total: 'collection',
        called: 'collection_called',
    }
];

export const  WIZARD_TYPES = {
    conversion: WIZARD_TYPE_CONVERSION,
    retention: WIZARD_TYPE_RETENTION,
    interview: WIZARD_TYPE_INTERVIEW,
    latam: WIZARD_TYPE_LATAM
};
