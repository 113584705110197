import {Action} from '@ngrx/store';

export enum ViewsActionTypes {
    SET_DATA = '[Leads] setLeads',
    UPDATE_DATA = '[Leads] updateData',
    DELETE_LEAD = '[Leads] deleteLead'
}

export class SetData implements Action {
    readonly type = ViewsActionTypes.SET_DATA;
    constructor(public payload: any) {}
}

export class UpdateData implements Action {
    readonly type = ViewsActionTypes.UPDATE_DATA;
    constructor(public payload: any) {}
}

export class DeleteLead implements Action {
    readonly type = ViewsActionTypes.DELETE_LEAD;
    constructor(public payload: any) {}
}

export type ViewsActionsUnion = SetData | UpdateData | DeleteLead;
