import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeleteUserComponent} from './delete-user.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';

@NgModule({
    declarations: [
        DeleteUserComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        LoaderModule
    ],
    exports: [
        DeleteUserComponent
    ]
})
export class DeleteUserModule {
}
