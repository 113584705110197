import {Component} from '@angular/core';
import {VERSION} from '../../../../environments/version';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent {

    public version = VERSION.tag;

}
