import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        from_at: new FormControl('', [
            Validators.required,
            this.startAtValidator.bind(this),
        ]),
        to_at: new FormControl('', [
            Validators.required,
            this.endAtValidator.bind(this)
        ]),
    });

    constructor(private self: MatDialogRef<DateRangePickerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

        for (const name in this.data) {

            if (!this.data.hasOwnProperty(name)) {
                continue;
            }

            const control = this.form.get(name);

            if (!control) {
                continue;
            }

            control.patchValue(this.data[name]);
        }
    }

    ngOnInit() {
    }

    submit() {
        if (this.form.valid) {
            this.self.close(this.form.value);
        }
    }

    private startAtValidator(control: AbstractControl) {

        if (!this.form) {
            return;
        }

        const to = this.getDateFromControl(this.form.get('to_at'));

        const from = this.getDateFromControl(control);

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"from\" must be smaller that \"too\"'
            };
        } else if (this.form.get('to_at').invalid) {
            this.form.get('to_at').updateValueAndValidity();
        }
    }

    private endAtValidator(control: AbstractControl) {

        if (!this.form) {
            return;
        }

        const to = this.getDateFromControl(control);

        const from = this.getDateFromControl(this.form.get('from_at'));

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"To\" must be bigger that \"From\"'
            };
        } else if (this.form.get('from_at').invalid) {
            this.form.get('from_at').updateValueAndValidity();
        }
    }

    private getDateFromControl(control: AbstractControl): null | Date {
        if (!control || !control.value) {
            return null;
        }
        return control.value as Date;
    }

}
