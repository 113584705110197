import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {PhoneListService} from '../../../services/phone-list/phone-list.service';

@Component({
  selector: 'app-create-edit-modal',
  templateUrl: './create-edit-modal.component.html',
  styleUrls: ['./create-edit-modal.component.less']
})
export class CreateEditModalComponent extends BaseComponent implements OnInit {

  public createList: FormGroup;

  private responseHandlers = {
    success: (response) => {
      this.toggleLoader();
      this.self.close('Created!');
      this.snackBar.open('List ' + response.name + ' was ' + (this.data['list'] ? 'updated' : 'created') + '!', 'Dismiss', {
        duration: 3000,
      });
    },
    error: () => {
      this.toggleLoader();
      if (this.data['list'] === 'created') {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'View can not be created',
            message: 'Connection with a SIP not working!'
          }
        });
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'View can not be Updated',
            message: 'View not Exist on SIP or Connection with a SIP not Working, Try Again Later!'
          }
        });
      }
    },
  };

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private self: MatDialogRef<any>,
              private phoneList: PhoneListService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.createList = new FormGroup({
      name: new FormControl(this.data['list'] ? this.data.list.name : '', [
        Validators.required,
        Validators.pattern(/^[a-zA-z0-9- ]{3,100}$/)
      ]),
      queue_id: new FormControl(this.data.queue_id, [])
    });
  }

  ngOnInit() {
  }

  public saveView(event): void {
    event.preventDefault();
    if (!this.createList.valid) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Validation Error',
          message: 'Please put correct name!!'
        }
      });
      return;
    }
    this.toggleLoader();
    if (this.data['list']) {
      this.phoneList.updatePhoneList(this.data.list.id, this.createList.value).subscribe(this.responseHandlers.success, this.responseHandlers.error);
    } else {
      this.phoneList.createPhoneList(this.createList.value).subscribe(this.responseHandlers.success, this.responseHandlers.error);
    }
  }

}
