import {Component, OnInit} from '@angular/core';
import BaseComponent from '../../../base/base.component';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ConfirmModalComponent} from '../../../confirm-modal/confirm-modal.component';
import SearchResponse from '../../../../search.response';
import {ShiftsService} from '../../../../services/shifts/shifts.service';
import {AgentService} from '../../../../services/agent/agent.service';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import {Permissions} from '../../../../helpers/Permissions';
import {getAvatar} from '../../../../helpers/Avagar';

@Component({
    selector: 'app-add-agent',
    templateUrl: './add-agent.component.html',
    styleUrls: ['./add-agent.component.scss']
})
export class AddAgentComponent extends BaseComponent implements OnInit {

    public getAvatar = getAvatar;

    public agents: SearchResponse = new SearchResponse();

    public auto: SearchResponse = new SearchResponse({
        page_size: 10
    });

    private timeout: any;

    public autoCompleteLoader: any = false;

    public displayedColumns: any = ['crm_username', 'config'];

    public ngClasses: any = {
        active: false
    };

    public searchAgentForm: FormGroup = new FormGroup({
        search: new FormControl('', [])
    });

    public info = {
        crm_username: {
            label: 'Full Name',
            name: 'crm_username',
            type: ['string'],
        }
    }

    public agentsList = [];

    public subscribers: any = {
        getAgents: null,
        changeSearch: null,
        addTo: null,
        deleteFrom: null
    }

    public options: any = [];

    constructor(
        private agentService: AgentService,
        private shiftsService: ShiftsService,
        private route: ActivatedRoute,
        private snack: MatSnackBar,
        private dialog: MatDialog,
        public permissions: Permissions
    ) {
        super();

        Object.assign(this.agents, {info: this.info} );
    }

    ngOnInit() {
        setTimeout(() => {
            this.ngClasses.active = true;
        }, 1);

        this.getAgentsList({
            user_groups_id: this.route.snapshot.params.id
        });

        // this.subscribers.changeSearch = 
        this.searchAgentForm.valueChanges.subscribe(result => {
            if (result && result.search) {
                this.autoCompleteChange(result.search);
            }
        });
    }

    public autoCompleteChange(result): void {
        this.autoCompleteLoader = true;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.getAutoCompleteData(result);
        }, 500);
    }

    public getAutoCompleteData(value) {
        if (!value) {
            this.options = [];
            this.autoCompleteLoader = false;
            return;
        }
        const options = {
            'page': 0,
            'page_size': 10,
            'sort': 'id=desc',
            'filter[general]': value
        }
        // @ts-ignore
        // this.subscribers.getSearchResult = 
        this.agentService.filterAgents(options).subscribe((res) => {
            if (res && res.data) {
                this.options = _filter(res.data, (item) => {
                    return !this.agentsList.some((el) => {
                        return item.id === el.id;
                    });
                });
                this.autoCompleteLoader = false;
            }
        });
    }

    private getAgentsList(params?) {
        // @ts-ignore
        // this.subscribers.getAgents = this.agentService.getAgents( ...Object.values(Object.assign({}, this.agents.getReqData(), params ? params : {})) ).subscribe((res) => {
        //         //     this.agents = Object.assign(this.agents, res);
        //         //     this.toggleLoader();
        //         // });

        // this.agents.addFilter('id', parseInt(this.route.snapshot.params.id, 10));
        this.subscribers.get = this.shiftsService.get(this.agents.getReqData()).subscribe((res) => {
            if (res && res.data && res.data.length) {
                this.agentsList = _find(res.data, (item) => {return item.id === parseInt(this.route.snapshot.params.id, 10)}).agents;
                this.agentsList = this.agentsList.map(ag => ag.agent);
            }
        }, err => {
            this.snack.open('Problem', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
        });
    }

    public addToGroup(event) {
        const agent = event.option.value;
        this.searchAgentForm.controls['search'].setValue('');
        this.subscribers.addTo = this.shiftsService.add({
            group: this.route.snapshot.params.id,
            user: agent.crm_id
        }).subscribe((res) => {
            this.snack.open('Agent added to shift', 'Dismiss', {
                duration: 3000
            });
            // this.agents.data = [agent, ...this.agents.data];
            this.getAgentsList();
            this.options = [];
        }, (err) => {
            this.snack.open('This agent has already been added to the shift!', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
            this.options = [];
        });
    }

    public deleteFromGroup(agent) {
        this.dialog.open(ConfirmModalComponent, {
            width: '400px',
            hasBackdrop: true,
            data: {
                title: 'Delete Agent',
                description: `Are you sure want to remove agent ${ agent.crm_username } from shift?`
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.subscribers.deleteFrom = this.shiftsService.delete({
                    group: this.route.snapshot.params.id,
                    user: agent.crm_id
                }).subscribe(res => {
                    this.snack.open('Agent deleted from shift', 'Dismiss', {
                        duration: 3000
                    });
                    this.agentsList = this.agentsList.filter(a => a.crm_id !== agent.crm_id);
                }, err => {
                    this.snack.open('Problem', 'Dismiss', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });
                });
            }
        });
    }

    public changeSort(event: any) {
        this.agents.addSort(event.active, event.direction);
        this.toggleLoader();
        this.getAgentsList({
            user_groups_id: this.route.snapshot.params.id
        });
    }

    public changePage(event: any): void {
        this.agents.page = event.pageIndex;
        this.agents.page_size = event.pageSize;
        this.toggleLoader();
        this.getAgentsList({
            user_groups_id: this.route.snapshot.params.id
        });
    }
}
