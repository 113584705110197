import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {AssignSettingService} from '../../../../services/assign-settings/assign-setting.service';

@Component({
  selector: 'app-new-bland',
  templateUrl: './new-bland.component.html',
  styleUrls: ['./new-bland.component.less']
})
export class NewBlandComponent implements OnInit {

  public loader = false;

  public blandForm: FormGroup;

  constructor(
      public self: MatDialogRef<NewBlandComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private service: AssignSettingService,
      private snack: MatSnackBar,
  ) { }

  ngOnInit() {
    this.blandForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      min: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
      ]),
      max: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
      ]),
      ageFresh: new FormControl('', [Validators.required]),
      ageReassign: new FormControl('', [Validators.required]),
    });

    if (this.data.bland) {
      this.blandForm.patchValue(this.data.bland);
    }
  }

  public submit(): void {
    console.log('-----', this.blandForm)
    if (this.blandForm.invalid) {
      return;
    }

    this.loader = true;

    const data = this.blandForm.getRawValue();

    const blandsList = this.data.list;

    if (this.data.index !== null) {
      blandsList.splice(this.data.index, 1, data);
    } else {
      blandsList.push(data);
    }

    const body = {
      setting_slug: this.data.slug,
      setting: {
        bland: blandsList
      }
    };

    this.service.updateAssignSetting(body).subscribe((res) => {
      const message = this.data.index !== undefined ? 'Bland was updated successfully!' : 'Bland was created successfully!';

      this.snack.open(message, 'Ok!', {
        duration: 1000,
      });

      this.self.close(this.data.list);

      this.loader = false;
    }, ({error}) => {
      this.handleErrors(error);

      this.loader = false;
    });
  }

  private handleErrors(error): void {
    for (const name in error) {
      if (this.blandForm.get(name)) {
        this.blandForm.get(name).setErrors({name: error[name]});
      }
    }
  }

}
