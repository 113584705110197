import { Pipe, PipeTransform } from '@angular/core';

const COLORS_CLASS = {
  24: 'info-progress',
  50: 'success-progress',
  99: 'danger-progress',
  100: 'warning-progress'
};

@Pipe({
  name: 'progressColor'
})
export class ProgressColorPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (value === undefined) {
      return null;
    }

    for (const percent in COLORS_CLASS) {
      if (value <= parseInt(percent, 10)) {
        return COLORS_CLASS[percent];
      }
    }

    return COLORS_CLASS['25'];
  }

}
