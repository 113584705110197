import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {ViewService} from '../../../services/view/view.service';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {QueueService} from '../../../services/queue/queue.service';
import {Agent} from '../../../services/agent/Agent';
import {PhoneListService} from 'src/app/services/phone-list/phone-list.service';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-action-modal',
    templateUrl: './action-modal.component.html',
    styleUrls: ['./action-modal.component.less']
})
export class ActionModalComponent extends BaseComponent implements OnInit {

    public editMode: boolean;

    public process_types = [
        {
            value: 0,
            label: 'Default Queue'
        },
        {
            value: 1,
            label: 'Direct Queue'
        }
    ];

    public specificHourDaily = [
        {
            value: 'daily',
            label: 'Daily'
        }
    ];

    public autoCleanUpTypes = [
        {
            value: 1,
            label: 'Specific Hours'
        }
    ];

    public fill_phone_list: any = {
        loaded: 0,
        res: null
    };
    public phone_list: any = {
        data: [],
        page_size: 100,
        page: 0,
        rows: 0,
    };
    public phone_list_loading = true;

    public createQueue: FormGroup;

    public settings: any = {
        conditions: []
    };

    public agents: Array<Agent>;

    public conditions: Array<any>;

    private responseHandlers = {
        success: (response) => {
            this.hideLoader();

            this.self.close('Created!');

            this.snackBar.open(response.message, 'Dismiss', {
                duration: 3000,
            });
        },
        error: ({error}) => {
            this.hideLoader();

            const errors = [];

            Object.keys(error).forEach(key => {
                if (['hours_from', 'hours_to', 'days'].includes(key)) {
                    this.createQueue.get('settings').get('operations').setErrors({name: error[key]});
                }

                if (this.createQueue.get(key)) {
                    this.createQueue.get(key).setErrors({name: error[key]});

                    errors.push(error[key]);
                }
            });

            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: 'View can not be created',
                    message: errors.join(', ') || 'Please contact your Administrator!'
                }
            });
        },
    };

    public dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    public dailerLeadsExclude = ['Answered', 'Exhausted', 'Unprocessed', 'Processing', 'Scheduled', 'Unprocessed+Processing+Scheduled', 'Error'];

    public delayFields = [];

    constructor(
        public dialog: MatDialog,
        private viewService: ViewService,
        private queues: QueueService,
        private snackBar: MatSnackBar,
        private self: MatDialogRef<any>,
        private phoneList: PhoneListService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();

        if (data.title.includes('Edit')) {
            this.editMode = true;
            this.createQueue = new FormGroup({
                name: new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^[a-zA-z0-9- ]{3,100}$/)
                ]),
                queue_conditions: new FormArray([], [
                    Validators.required
                ]),
                is_default: new FormControl(false, [
                    Validators.required
                ]),
                process_type: new FormControl(null, [
                    Validators.required
                ]),
                sync_status: new FormControl(false, [
                    Validators.required
                ]),
                sync_phone_list_id: new FormControl(null, [
                    Validators.required
                ])
            });

            let settings: FormGroup;

            if (this.data.process_type === 1) {
                settings = new FormGroup({
                    auto_clean_up: new FormGroup({
                        status: new FormControl(false, []),
                        type: new FormControl(1, []),
                        specific_hour: new FormGroup({
                            type: new FormControl('daily', []),
                            hour: new FormControl(null, []),
                        })
                    })
                });
            } else {
                settings = new FormGroup({
                    delay_active: new FormControl(false, []),
                    delay_field: new FormControl(null, []),
                    delay_time: new FormControl(null, []),

                    hold_delay_active: new FormControl(false, []),
                    hold_delay_time: new FormControl(null, []),

                    operation_time_active: new FormControl(false, []),
                    operations: new FormArray([], []),

                    dailer_leads_exclude: new FormControl([], []),
                });
            }

            this.createQueue.addControl('settings', settings);

        } else {
            this.createQueue = new FormGroup({
                name: new FormControl('', [
                    Validators.required,
                    Validators.pattern(/^[a-zA-z0-9- ]{3,100}$/)
                ]),
                queue_conditions: new FormArray([], [
                    Validators.required
                ]),
                process_type: new FormControl(0, [
                    Validators.required
                ]),
                is_default: new FormControl(false, [
                    Validators.required
                ])
            });
        }

        if (data.agents) {
            this.agents = data.agents;
        }

        this.initData();
    }

    public get operationsFormControls(): any {
        return (this.createQueue.get('settings').get('operations') as FormGroup).controls;
    }

    public create(data?): FormGroup {
        return new FormGroup({
            hours_from: new FormControl(data.hours_from || '', [Validators.required]),
            hours_to: new FormControl(data.hours_to || '', [Validators.required]),
            days: new FormControl(data.days || [], []),
        });
    }

    public add(data = {}): void {
        (this.createQueue.get('settings').get('operations') as FormArray).push(this.create(data));
    }

    public remove(index: number): void {
        (this.createQueue.get('settings').get('operations') as FormArray).removeAt(index);
    }

    ngOnInit() {
        if (this.data['view']) {
            if (this.data.process_type === 0) {
                this.createQueue.get('settings').get('delay_active').valueChanges.subscribe((val) => {
                    if (val) {
                        this.createQueue.get('settings').get('delay_field').setValidators([Validators.required]);
                        this.createQueue.get('settings').get('delay_time').setValidators([Validators.required]);
                    } else {
                        this.createQueue.get('settings').get('delay_field').clearValidators();
                        this.createQueue.get('settings').get('delay_time').clearValidators();
                    }

                    this.createQueue.get('settings').get('delay_field').updateValueAndValidity();
                    this.createQueue.get('settings').get('delay_time').updateValueAndValidity();
                });
            }
        }
    }

    public setDefault(event): void {
        this.createQueue.get('is_default').patchValue(event.checked);
    }

    public setSyncStatus(event): void {
        this.createQueue.get('sync_status').patchValue(event.checked);
    }

    public saveView(event): void {
        event.preventDefault();

        this.showLoader();

        const formData = this.createQueue.value;

        if (formData.settings && formData.settings.operations && formData.settings.operations.length) {
            formData.settings.operations = formData.settings.operations.map(i => {
                if (i && i.hours_from && i.hours_to) {
                    const hoursFrom = i.hours_from.getHours() <= 9 ? '0' + i.hours_from.getHours() : i.hours_from.getHours();
                    const minutesFrom = i.hours_from.getMinutes() <= 9 ? '0' + i.hours_from.getMinutes() : i.hours_from.getMinutes();

                    const hoursTo = i.hours_to.getHours() <= 9 ? '0' + i.hours_to.getHours() : i.hours_to.getHours();
                    const minutesTo = i.hours_to.getMinutes() <= 9 ? '0' + i.hours_to.getMinutes() : i.hours_to.getMinutes();

                    return {
                        hours_from: hoursFrom + ':' + minutesFrom,
                        hours_to: hoursTo + ':' + minutesTo,
                        days: i.days
                    };
                }
            });
        }

        if (this.data['view']) {
            this.queues.updateQueue(this.data.view.id, formData).subscribe(this.responseHandlers.success, this.responseHandlers.error);
        } else {
            this.queues.createQueue(this.createQueue.value).subscribe(this.responseHandlers.success, this.responseHandlers.error);
        }
    }

    private initData(): void {
        this.showLoader();

        if (this.editMode) {
            this.getPhoneList();
        }

        this.viewService.getSettings().subscribe((response) => {
            this.settings = response;

            this.delayFields = response.fields.filter(f => f.name.includes('time'));

            if (this.data['view']) {
                this.queues.getQueue(this.data.view.id).pipe(
                    finalize(() => this.hideLoader())
                ).subscribe((res) => {
                    this.fill_phone_list.res = res.sync_phone_list_id;
                    this.setFields(res);
                    this.fillPhoneList();
                });
            } else {
                this.hideLoader();
            }
        });
    }

    public getPhoneList() {
        this.phoneList.getPhoneLists(this.data.view.id, {
            page: this.phone_list.page,
            page_size: this.phone_list.page_size,
            ['sort[id]']: 'desc'
        }).subscribe((response) => {
            this.phone_list = Object.assign({}, this.phone_list, response);
            this.phone_list_loading = false;
            this.fillPhoneList();
        });
    }

    private setFields(view): void {
        this.createQueue.get('name').patchValue(view.name);
        this.createQueue.get('is_default').patchValue(view.default);
        this.conditions = view.conditions;
        if (this.editMode) {
            this.createQueue.get('sync_status').patchValue(view.sync_status);
        }

        const settings = JSON.parse(view.settings);
        if (settings) {
            this.createQueue.get('settings').patchValue(settings);

            this.createQueue.get('process_type').patchValue(settings.process_type);

            if (settings.operations && settings.operations.length) {
                settings.operations = settings.operations.map(i => {
                    if(i && i.hours_to && i.hours_from) {
                        const from = i.hours_from.split(':');
                        const formDate = new Date();
                        formDate.setHours(from[0] || 0);
                        formDate.setMinutes(from[1] || 0);

                        const to = i.hours_to.split(':');
                        const toDate = new Date();
                        toDate.setHours(to[0] || 0);
                        toDate.setMinutes(to[1] || 0);

                        this.add({
                            hours_from: formDate,
                            hours_to: toDate,
                            days: i.days
                        });
                    }
                });
            }
        }
    }

    private fillPhoneList() {
        this.fill_phone_list.loaded++;
        // if (this.fill_phone_list.loaded === 2) {
            this.createQueue.get('sync_phone_list_id').patchValue('' + this.fill_phone_list.res);
        // }
    }

}
