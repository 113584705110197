import {FiltersActionTypes, FiltersActionsUnion} from '../actions/filters-actions';

const initialState = {};

export function filtersReducer(state: any = initialState, action: FiltersActionsUnion) {
    switch (action.type) {
        case FiltersActionTypes.SET_FILTERS_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}
