import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {getAvatar} from '../../../helpers/Avagar';

@Component({
    selector: 'app-call-report-table',
    templateUrl: './call-report-table.component.html',
    styleUrls: ['./call-report-table.component.less']
})
export class CallReportTableComponent implements OnInit, OnChanges {

    public getAvatar = getAvatar;

    @Input() public data = [];

    @Input() public columnsToDisplay: any;

    @Input() public page: any;

    @Input() public page_size: any;

    @Input() public rows: any;

    @Input() public agents: any = {};

    @Output() public sorting: EventEmitter<any> = new EventEmitter<any>();

    @Output() public paging: EventEmitter<any> = new EventEmitter<any>();

    public columns: any = {
        def: [],
        ctd: []
    };

    constructor() {
    }

    ngOnInit() {
        this.initColumns();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columnsToDisplay && !changes.columnsToDisplay.firstChange) {
            this.initColumns();
        }
    }

    initColumns() {
        this.columns.def = Object.assign([], this.columnsToDisplay);
        this.columns.ctd = this.columns.def.map(c => c.name);
    }

    onPage(event) {
        this.paging.emit({
            page: event.pageIndex,
            page_size: event.pageSize,
        });
    }

    onSort(event) {
        this.sorting.emit({
            ['sort[' + event.active + ']']: event.direction
        });
    }

}
