import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AssignSuggestionService} from '../../../services/assign-suggestion/assign-suggestion.service';
import {select, Store} from '@ngrx/store';
import {ViewCountryInfoComponent} from '../../view-country-info/view-country-info.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {Permissions} from '../../../helpers/Permissions';
import * as FiltersActions from '../../../actions/filters-actions';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {ViewService} from '../../../services/view/view.service';

@Component({
  selector: 'app-suggestion-settings',
  templateUrl: './suggestion-settings.component.html',
  styleUrls: ['./suggestion-settings.component.scss']
})
export class SuggestionSettingsComponent extends Unsubscribe implements OnInit, OnDestroy {

  @Output() onsubmit = new EventEmitter();

  @Input() set error(error: string) {
    if (error) {
      this.handleBackendError(error);
    }
  }

  public loader: any = false;

  public formGroup: FormGroup;

  public languages = [
    {
      value: 'en',
      label: 'English'
    },
    {
      value: 'sp',
      label: 'Spanish'
    },
    {
      value: 'on',
      label: 'Online'
    },
    {
      value: 'seo',
      label: 'SEO'
    },
    {
      value: 'tv',
      label: 'TV'
    },
    {
      value: 'whv',
      label: 'WHV'
    },
  ];

  public selectedGmts = [];

  public priorities = [
    {
      value: 1,
      label: 'Mix Agents'
    },
    {
      value: 2,
      label: 'High Agents'
    },
    {
      value: 3,
      label: 'Low Agents'
    },
  ];

  public settingGmt = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Mid',
      value: 'mid',
    },
    {
      label: 'Low',
      value: 'low',
    }
  ];

  public marital_status = [
    {
      label: 'Married',
      value: 'Married',
    },
    {
      label: 'Single',
      value: 'Single',
    }
  ];

  public genders = [
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Male',
      value: 'Male',
    }
  ];

  public range_of_monthly_salary = [
    {
      label: '0-500',
      value: '0-500',
    },
    {
      label: '0-1000',
      value: '0-1000',
    },
    {
      label: '500-1000',
      value: '500-1000',
    },
    {
      label: '1000-2000',
      value: '1000-2000',
    },
    {
      label: '1000-2500',
      value: '1000-2500',
    },
    {
      label: '2000-3000',
      value: '2000-3000',
    },
    {
      label: '2500-5000',
      value: '2500-5000',
    },
    {
      label: '3000-5000',
      value: '3000-5000',
    },
    {
      label: '5000',
      value: '5000',
    },
    {
      label: 'Above 5000',
      value: 'Above 5000',
    }
  ];

  public speak_english = [
    {
      label: 'Basic',
      value: 'Basic'
    },
    {
      label: 'Moderate',
      value: 'Moderate'
    },
    {
      label: 'Poor',
      value: 'Poor'
    },
    {
      label: 'Proficient',
      value: 'Proficient'
    }
  ];

  public bestTime = [
    {
      value: 'low',
      label: 'Low'
    },
    {
      value: 'medium',
      label: 'Medium'
    },
    {
      value: 'high',
      label: 'High'
    },
  ];

  public contactCondition = [
    {
      value: 1,
      label: 'All'
    },
    {
      value: 2,
      label: 'Contact Me Only'
    },
    {
      value: 3,
      label: 'Exclude Contact Me'
    },
  ];

  public indexRate = [
    {
      value: 'Excellent',
      label: 'Excellent'
    },
    {
      value: 'Good',
      label: 'Good'
    },
    {
      value: 'Basic',
      label: 'Basic'
    },
    {
      value: 'Low',
      label: 'Low'
    }
  ];

  public types = [
    'Today',
    'Days',
    'Weeks',
    'Months',
    'Quartals',
  ];

  public call_types = [
    'Attempt',
    'Answered',
    'Effective',
  ];

  public countries = [];
  public nationalities = [];
  public agents = [];
  public agentsAll = [];
  public shifts = [];
  public regions = [];

  public audience = {
    freshLeads: 0,
    freshLeadsContact: 0,
    reassignedLeads: 0,
    reassignedLeadsContact: 0
  };

  private subs = {
    settings: null,
    audience: null,
  };

  public viewTypeNew = [];

  constructor(
      private assignSuggestionService: AssignSuggestionService,
      private viewService: ViewService,
      private store: Store<any>,
      private snack: MatSnackBar,
      public dialog: MatDialog,
      public permissions: Permissions
  ) {
    super();

    this.initForm();

  }

  private initForm() {
    this.formGroup = new FormGroup({
      pageSize: new FormControl(20, []),
      page: new FormControl(0, []),
      ageFresh: new FormControl('0m-30d', []),
      ageReassign: new FormControl('0m-30d', []),
      priority: new FormControl(1, []),
      lang: new FormControl('en', []),
      suggestionMode: new FormControl(2, []),
      suggestionFresh: new FormControl(2, []),
      suggestionReassign: new FormControl(2, []),
      agents: new FormControl([], []),
      shifts: new FormControl([], []),
      duplicate: new FormControl(0, []),
      country_limit_fresh: new FormControl('', []),
      country_limit_reassign: new FormControl('', []),
      agentScoresFrom: new FormControl('', []),
      agentScoresTo: new FormControl('', []),
      englishLevel: new FormControl([], []),
      englishCondition: new FormControl(0, []),
      genders: new FormControl([], []),
      range_of_monthly_salary: new FormControl([], []),
      marital_status: new FormControl([], []),
      contact_condition: new FormControl(1, []),
      age_from: new FormControl('', []),
      age_to: new FormControl('', []),
      callStatus: new FormControl([], []),
      call_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
        call_count: new FormControl('', []),
        condition: new FormControl(1, []),
        call_type: new FormControl('', []),
      }),
      availability_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', [Validators.min(0), Validators.max(100)]),
        enable: new FormControl(0, []),
      }),
      recycle_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
        recycle_count: new FormControl('', []),
        condition: new FormControl(0, []),
      }),
      duplicate_condition: new FormGroup({
        type: new FormControl('', []),
        type_count: new FormControl('', []),
      }),
      countries: new FormGroup({
        exclude: new FormControl(0, []),
        countries: new FormControl([], []),
        regions: new FormControl([], []),
      }),
      countries_birth: new FormGroup({
        exclude: new FormControl(0, []),
        countries: new FormControl([], []),
        regions: new FormControl([], []),
      }),
      gmt_settings: new FormGroup({
        gmts: new FormControl([], []),
        high: new FormControl(0, []),
        mid: new FormControl(0, []),
        low: new FormControl(0, []),
      }),
      nationality: new FormControl('', []),
      index_rate: new FormControl('', []),
    });
  }

  ngOnInit() {
    this.store.pipe(select('agents')).subscribe((res) => {
      if (this.agents.length > 0) {
        return;
      }
      this.agents = res.allAgents;
    });

    this.viewService.getAutoCompleteValues('nationality').pipe(
        takeUntil(this.destroy)
    ).subscribe((res: any) => {
      this.nationalities = res.nationality;
    });

    this.subs.settings = this.assignSuggestionService.getSetting('countries,shifts,regions,agents,suggestion_views').subscribe((res) => {
      this.loader = false;
      this.countries = res.countries;
      this.shifts = res.shifts;
      this.regions = res.regions;
      this.agentsAll = res.agents;

      this.viewTypeNew = res.suggestion_views.map(item => {
        return {
          value: item.prefix,
          label: item.title
        };
      });

      if (this.viewTypeNew.length) {
        this.formGroup.get('lang').setValue(this.viewTypeNew[0].value);
      }
    }, (err) => {
      this.loader = false;
    });
  }

  public selectGmts(e) {
    this.selectedGmts = e;
  }

  ngOnDestroy(): void {
    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        this.subs[name].unsubscribe();
      }
    }
  }

  public getBackendError(form: FormGroup, control: string) {
    if (form && form.get(control) && form.get(control).errors) {
      return form.get(control).errors['backend'];
    }
    return null;
  }

  public showListOfCountry(data: any) {
    let obj = this.formGroup.value;
    obj['list'] = data;
    let lead;

    const dialog = this.dialog.open(ViewCountryInfoComponent, {
      width: '600px',
      data: Object.assign({}, obj)
    });

    dialog.afterClosed().subscribe();
  }

  public handleBackendError(error) {
    for (const name in error.error) {
      if (error.error.hasOwnProperty(name) && this.formGroup.get(name)) {
        this.formGroup.get(name).setErrors({backend: error.error[name]});
      }
    }
  }

  public getAudience(): void {
    this.loader = true;
    const data = Object.assign({}, this.formGroup.value, {audience: 1});
    this.subs.settings = this.assignSuggestionService.getLeadsSuggestion(data).subscribe((res) => {
      this.loader = false;
      this.audience = res;
    }, (error) => {
      this.handleBackendError(error);
      this.loader = false;
    });
  }

  public saveConditionsToReport(): void {

    const confirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Notification',
        description: 'Are you sure you want to save new conditions?'
      }
    });
    confirm.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.loader = true;
      const data = Object.assign({}, this.formGroup.value, {audience: 0});
      this.subs.settings = this.assignSuggestionService.saveConditionToReport(data).subscribe((res) => {
        this.loader = false;
        this.snack.open('Conditions Was Saved', 'Dismiss', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }, (error) => {
        this.handleBackendError(error);
        this.loader = false;
      });
    });
  }

  public runCalculationNow(): void {

    const confirm = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Notification',
        description: 'Are you sure you want to Run calculation of the report? Data will replace saved info every 30 min!'
      }
    });
    confirm.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.subs.settings = this.assignSuggestionService.runCalculation([]).subscribe((res) => {
        this.snack.open('Calculation Was Run Successfully!', 'Dismiss', {
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      }, (error) => {
        this.snack.open(error.error.status, 'Dismiss', {
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      });
    });
  }

  public submit(): void {
    this.onsubmit.emit(this.formGroup.value);
  }

  public onChangeEvent(event) {
    event.open();
  }

  public reset(): void {
    this.initForm();
  }

  public onAgentsRemove(evt) {
    // remove selected shift when agent was removed
    const crm_id = evt.value;
    const shifts = this.formGroup.get('shifts').value;
    const agent = this.agentsAll.find(el => el.crm_id === crm_id);
    if (!agent) {
      return;
    }
    const agentShiftsId = agent.shifts.map(el => el.id);
    if (!agentShiftsId.length) {
      return;
    }
    agentShiftsId.forEach(shiftId => {
      let index: number;
      while (index !== -1) {
        index = shifts.findIndex(el => el === shiftId);
        if (index !== -1) {
          shifts.splice(index, 1);
        }
      }
    });
    this.formGroup.get('shifts').patchValue(shifts);
  }

  public onShiftsChange(evt: any): void {
    const agents = this.agentsAll;
    const shifts = evt;
    let selectedAgents = [];
    for (const shift of shifts) {
      for (let i = 0; i < agents.length; i++) {
        const ci = i;
        const index = agents[ci].shifts.findIndex((el) => {
          return el.id === shift;
        });
        if (index !== -1 && selectedAgents.findIndex(el => el.crm_id === agents[ci].crm_id) === -1) {
          selectedAgents.push(agents[ci]);
        }
      }
    }
    const selAg = [];
    for (let i = 0; i < selectedAgents.length; i++) {
      const elemI = selectedAgents[i];
      for (let j = 0; j < this.agents.length; j++) {
        const elemJ = this.agents[j];
        if (elemI.crm_id === elemJ.crm_id) {
          selAg.push(elemJ);
        }
      }
    }
    this.formGroup.get('agents').patchValue(selAg.map(el => el.crm_id));


  }
}

