import {Component, OnDestroy, OnInit} from '@angular/core';
import {AgentService} from '../../services/agent/agent.service';
import {ViewService} from '../../services/view/view.service';
import {Store} from '@ngrx/store';
import * as AgentsActions from '../../actions/agents-actions';
import * as ViewsActions from '../../actions/views-actions';
import Visibility from '../../helpers/Visibility';
import {ReassignComponent} from '../notifications/reassign/reassign.component';
import {AssignComponent} from '../notifications/assign/assign.component';
import {LeadService} from '../../services/lead/lead.service';
import {MatSnackBar} from '@angular/material';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})

export class DashboardComponent implements OnInit, OnDestroy {

    private firstLoad = true;

    private focusListener: any;

    private subscribers = {
        agent: {
            subscriber: null,
            fn: () => {
                // return this.agentService.getUpdates().subscribe((event) => {
                //     if (event.data.length > 0) {
                //         event.data.map((agent) => {
                //             this.store.dispatch(new AgentsActions.UpdateShiftAgents(agent));
                //         });
                //     }
                // });
            }
        },
        view: {
            subscriber: null,
            fn: () => {
                // return this.viewService.getUpdates().subscribe((event) => {
                //     if (event.data.length > 0) {
                //         this.store.dispatch(new ViewsActions.UpdateData(JSON.parse(event.data)));
                //     }
                // });
            }
        },
        call_activity: {
            subscriber: null,
            fn: () => {
                // return this.agentService.getCallUpdates().subscribe((event) => {
                //     if (event.data) {
                //         document.dispatchEvent(new CustomEvent('call_activity_' + event.data.crm_id, {detail: event.data.status}));
                //     }
                // });
            }
        }
    };

    public currItr: any = null;

    private notes: any = null;

    public notifications: any = {
        reassign: ReassignComponent,
        assign: AssignComponent,
    };

    private subscribersEvent: any;

    private tabActiveEvent: any;

    constructor(private agentService: AgentService,
                private viewService: ViewService,
                private lead: LeadService,
                private snackBar: MatSnackBar,
                private store: Store<any>,
                public permissions: Permissions
    ) {
    }

    ngOnInit() {
        // this.checkExistsSomeNotificationsForShowing();
        // if (!localStorage.getItem('notifications')) {
        //     this.notes = this.lead.notifications().subscribe((res) => {
        //         for (const n of res.data) {
        //             if (this.notifications[n.type]) {
        //                 switch (true) {
        //                     case 'assign' === n.type:
        //                         const time = parseInt(String(Date.now() / 1000), 10);
        //                         if (n.data.schedule_time <= time + 300 && n.data.schedule_time >= time) {
        //                             this.snackBar.openFromComponent(this.notifications[n.type], {
        //                                 data: n.data,
        //                                 verticalPosition: 'top',
        //                                 horizontalPosition: 'right',
        //                             });
        //                             break;
        //                         } else if (n.data.schedule_time > time) {
        //                             const oldNotes = JSON.parse(localStorage.getItem('notifications_assign') || '[]');
        //                             oldNotes.push({time: n.data.schedule_time, lead: n.data});
        //                             localStorage.setItem('notifications_assign', JSON.stringify(oldNotes));
        //                             this.checkExistsSomeNotificationsForShowing();
        //                             break;
        //                         }
        //                         break;
        //                     default:
        //                         console.log(n.type);
        //                         this.snackBar.openFromComponent(this.notifications[n.type], {
        //                             data: n.data,
        //                             verticalPosition: 'top',
        //                             horizontalPosition: 'right',
        //                         });
        //                 }
        //             }
        //         }
        //     });
        // }
        // window.addEventListener('focus', this.focusListener = () => {
        //     this.firstLoad = false;
        // });
        // document.addEventListener('toggleSubscription', this.subscribersEvent = (event: CustomEvent) => {
        //     const stream = this.subscribers[event.detail.stream];
        //     if (this.subscribers[event.detail.stream]) {
        //         if (event.detail.enable) {
        //             stream.subscriber = stream.fn();
        //         } else if (stream.subscriber) {
        //             stream.subscriber.unsubscribe();
        //         }
        //     }
        // });
        // // document.addEventListener(Visibility.getChangeEvent(), this.tabActiveEvent = () => {
        // //     if (!this.firstLoad) {
        // //         if (Visibility.getIsHidden()) {
        // //             this.unsubscribeAll();
        // //         } else {
        // //             this.subscribeAll();
        // //             document.dispatchEvent(new CustomEvent('viewGetData', {}));
        // //             document.dispatchEvent(new CustomEvent('agentsGetData', {}));
        // //         }
        // //     }
        // // });
    }

    ngOnDestroy() {
        // this.unsubscribeAll();
        // document.removeEventListener('toggleSubscription', this.subscribersEvent);
        // // document.removeEventListener(Visibility.getChangeEvent(), this.tabActiveEvent);
        // window.removeEventListener('focus', this.focusListener);
        // if (this.notes) {
        //     this.notes.unsubscribe();
        // }
    }

    private unsubscribeAll() {
        for (const name in this.subscribers) {
            if (this.subscribers.hasOwnProperty(name) && this.subscribers[name] && this.subscribers[name].subscriber !== null) {
                this.subscribers[name].subscriber.unsubscribe();
            }
        }
    }

    private subscribeAll() {
        for (const name of ['agent', 'call_activity', 'view']) {
            this.subscribers[name].subscriber = this.subscribers[name].fn();
        }
    }

    public checkExistsSomeNotificationsForShowing() {
        if (this.currItr != null) {
            return;
        }

        this.currItr = setInterval(
            () => {
                const notes = JSON.parse(localStorage.getItem('notifications_assign') || '[]');
                const currTime = parseInt(String(Date.now() / 1000), 10);

                if (notes.length === 0) {
                    clearInterval(this.currItr);
                    this.currItr = null;

                    return;
                }

                const timeWasSent = [];

                notes
                    .filter(not => not && not.time && not.time <= currTime + 300 && not.time >= currTime)
                    .forEach(not => {
                        this.snackBar.openFromComponent(this.notifications['assign'], {
                            data: not.lead,
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                        });
                        timeWasSent.push(not.time);
                    });

                const newNotes = notes.filter(not => timeWasSent.indexOf(not.time) === -1);

                if (notes.length !== newNotes.length) {
                    localStorage.setItem('notifications_assign', JSON.stringify(newNotes));
                }
            }
            , 10000);
    }
}
