import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AssignAllModalComponent } from './assign-all-modal.component';

@NgModule({
  declarations: [
    AssignAllModalComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    AssignAllModalComponent
  ],
})
export class AssignAllModalModule {}
