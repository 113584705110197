import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutocompleteComponent} from './autocomplete.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [AutocompleteComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [AutocompleteComponent]
})
export class AutocompleteModule {
}
