import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderFullComponent} from './loader-full.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        LoaderFullComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
    ],
    exports: [
        LoaderFullComponent
    ]
})
export class LoaderFullModule {
}
