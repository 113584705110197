import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-touched',
    templateUrl: './touched.component.html',
    styleUrls: ['./touched.component.less']
})

export class TouchedComponent implements OnInit, OnChanges {

    @Input() model: any;

    @Output() modelChange = new EventEmitter<any>();

    @Output() public change = new EventEmitter();

    public touchedControl: FormControl = new FormControl();

    public statuses: any = [
        {value: '', viewValue: '-------'},
        {value: 'touched', viewValue: 'Touched'},
        {value: 'not Touched', viewValue: 'Not Touched'},
        {value: 'empty', viewValue: 'Empty'},
        {value: 'not_empty', viewValue: 'Not empty'}
    ];

    constructor() {
    }

    ngOnInit() {
        if (this.model) {
            this.touchedControl.patchValue(this.model);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model'] && !changes.model.currentValue) {
            this.touchedControl.patchValue('');
        }
    }

    public setTouched(event) {

        const prevValue = this.model;

        this.model = event.value;

        this.modelChange.emit(this.model);

        if (prevValue !== this.model) {
            this.change.emit(this.model);
        }
    }

}
