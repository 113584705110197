import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderGeneralLayoutComponent} from './header-general-layout.component';
import {MatMenuModule, MatToolbarModule, MatButtonModule, MatIconModule} from '@angular/material';

@NgModule({
    declarations: [
        HeaderGeneralLayoutComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: [
        HeaderGeneralLayoutComponent
    ],
})

export class HeaderGeneralLayoutModule {
}
