import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class LeadsChartService {

    constructor(
        private api: ApiService
    ) {}

    public getLeadsChart(data: any): Observable<any> {
        return this.api.get('v1/leads-chart', {
            params: Object.assign({}, data['filter'] ? data.filter : {})
        });
    }
}
