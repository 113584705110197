import {Agent} from '../services/agent/Agent';

export default class AssignBlocker {

    static block(agent: Agent) {

        const enabled = localStorage.getItem('assign_block');

        if (!enabled) {
            return false;
        }

        const status = localStorage.getItem('call_activity_' + agent.crm_id);

        if (!status) {
            return false;
        }

        return status !== 'call:end';
    }

}
