import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
      private api: ApiService
  ) {}

  public getPermissions(role): Observable<any> {
    return this.api.get(`v1/role/${role}/permissions`, {});
  }

  public updateFieldPermission(id, body): Observable<any> {
    return this.api.post(`v1/role/${id}/permission`, {
      body
    });
  }
}
