import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NationalityComponent} from './nationality.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        NationalityComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [
        NationalityComponent
    ]
})
export class NationalityModule {
}
