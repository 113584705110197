import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Permissions } from 'src/app/helpers/Permissions';
import { BulkAssignService } from 'src/app/services/bulk-assign/bulk-assign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sheets-assign',
  templateUrl: './bulk-sheets.component.html',
  styleUrls: ['./bulk-sheets.component.less']
})
export class BulkAssignSheetsComponent  implements OnInit {

  @Input() set initReload(val: boolean) {
    if (val) {
       this.loader = true;
       this.getInfo();
    }
  }

  public activeId = 0;

  public group: any = null;

  public columnsToDisplay: Array<string>;

  public data: Array<any>;

  public rows: number;

  public loader = true;

  public paginator = {
    page: 0,
    pages: 0,
    rows: 0,
    page_size: 100,
    nextPage: 0,
  }

  public pages: number;

  public page_size: number;

  public page: number;

  public columnsToFilter: Array<string>;

  public month: any = ['January','February','March','April','May','June','July','August','September','October','November','December'];

  public options: any = [
    {
      icon: 'edit',
      label: '',
      show: true,
      fn: (row) => {
        this.router.navigate(['/bulk-assign/' + row.id]);
      }
    }
  ];

  public subscribers: any = {
    get: null
  };

  constructor(
    // private route: ActivatedRoute,
    public bulkAssignService: BulkAssignService,
    private router: Router,
    private snack: MatSnackBar,
    public permissions: Permissions) {
      
    this.columnsToDisplay = ['hash', 'files', 'upload_by', 'status', 'create_at', 'action'];
    
  }

  ngOnInit() {
    this.getInfo()
  }

  public getInfo(params?: any): void {
    this.bulkAssignService.getData(params).subscribe((res) => {

    this.data = res.data;

    this.paginator.page = res.page;
    this.paginator.pages = res.pages;
    this.paginator.rows = res.rows;
    this.loader = false;

    }, (err) => {
      const statusText = err.statusText;
      this.snack.open(statusText, 'Continue', {
        duration: 7000,
      });
      this.loader = false;
    })
  }


  public onChangePage(evt): void {
    this.paginator.nextPage = evt.pageIndex;
    this.getInfo({ page: this.paginator.nextPage });
  }

}
