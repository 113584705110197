import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import BaseComponent from '../base/base.component';
import {AmChartsService, AmChart} from '@amcharts/amcharts3-angular';
import {LeadsChartService} from '../../services/leads-charts/leads-chart.service';
import {DateConverter} from '../../helpers/DateConverter';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-leads-info-amchart',
    templateUrl: './leads-info-amchart.component.html',
    styleUrls: ['./leads-info-amchart.component.less']
})

export class LeadsInfoAmchartComponent extends BaseComponent implements OnInit {

    public loader = true;

    public isEmpty = false;

    public chartInfo: any = {
        'theme': 'light',
        'type': 'serial',
        'legend': {
            'equalWidths': false,
            'useGraphSettings': true,
            'valueAlign': 'right',
            'valueWidth': 0.5,
            'useMarkerColorForLabels': false,
            // 'borderAlpha': 0.1,
            'autoMargins': true,
            'useMarkerColorForValues': true
        },
        'dataProvider': [],
        'valueAxes': [{
            'unit': '',
            'position': 'left',
            'title': 'Leads',

        }],
        'startDuration': 0,
        'graphs': [{
            'balloonText': 'Total <b> [[value]]</b>',
            'fillAlphas': 0.5,
            'lineAlpha': 0.2,
            'title': 'Total Leads',
            'type': 'column',
            'fillColorsField': 'bgcolor',
            'valueField': 'total',
        }, {
            'balloonText': '[[campaign]] <b>([[value]] - [[longtitle]]%)</b>',
            'fillAlphas': 0.9,
            'lineAlpha': 0.2,
            'type': 'column',
            'title': 'Assigned Leads',
            'clustered': false,
            'columnWidth': 0.5,
            'valueField': 'value',
            'fillColorsField': 'color'

        },
            {
                'id': 'graph1',
                'balloonText': '<span style="font-size:12px;">Assigned Leads <br><span style="font-size:20px;">[[value]]</span></span>',
                'bullet': 'round',
                'lineThickness': 2,
                'bulletSize': 7,
                'bulletBorderAlpha': 1,
                'bulletColor': '#FFFFFF',
                'useLineColorForBulletBorder': true,
                'bulletBorderThickness': 2,
                'fillAlphas': 0,
                'lineAlpha': 1,
                'title': 'Assigned ',
                'valueField': 'value',
                'dashLengthField': 'dashLengthLine'
            },
            {
                'id': 'graph2',
                'balloonText': '<span style="font-size:12px;">Total Leads <br><span style="font-size:20px;">[[value]]</span></span>',
                'bullet': 'square',
                'lineThickness': 2,
                'bulletSize': 7,
                'bulletBorderAlpha': 2,
                'bulletColor': '#fff',
                'useLineColorForBulletBorder': true,
                'bulletBorderThickness': 2,
                'fillAlphas': 0,
                'lineAlpha': 1,
                'title': 'Total  ',
                'valueField': 'total',
                'dashLengthField': 'dashLengthLine'
            }],
        'chartCursor': {
            'cursorAlpha': 0.1,
            'cursorColor': '#000000',
            'fullWidth': true,
            'valueBalloonsEnabled': false,
            'oneBalloonOnly': false,
        },
        'plotAreaFillAlphas': 0.1,
        'categoryField': 'campaign',
        'categoryAxis': {
            'gridPosition': 'start',
            'fontSize': 10,
            'labelRotation': 8
        },
        'export': {
            'enabled': false
        }

    };

    public selectedRange: any = {
        id: 'today',
        name: 'Today',
        icon: 'calendar_today',
        value: {
            start: DateConverter.addDays(new Date, 0, true),
            end: DateConverter.addDays(new Date, 0, true)
        }
    };

    public dateRangeSelect: any[] = [
        {
            id: 'today',
            name: 'Today',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, 0, true)
            }
        },
        {
            id: 'yesterday',
            name: 'Yesterday',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, -1, true),
                end: DateConverter.addDays(new Date, -1, true)
            }
        },
        {
            id: 'last_7',
            name: 'Last 7 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -7, true)
            }
        },
        {
            id: 'last_14',
            name: 'Last 14 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -14, true)
            }
        },
        {
            id: 'last_30',
            name: 'Last 30 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -30, true)
            }
        },
        {
            id: 'last_60',
            name: 'Last 60 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -60, true)
            }
        },
        {
            id: 'last_90',
            name: 'Last 90 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -90, true)
            }
        },
        {
            id: 'this_month',
            name: 'This Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date, 1, 0, true),
                end: DateConverter.addMonth(new Date, 0, 1, true)
            }
        },
        {
            id: 'last_month',
            name: 'Last Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date, 0, 0, true),
                end: DateConverter.addMonth(new Date, -1, 1, true)
            }
        },
    ];

    private chart: AmChart;

    public chartData: any = {
        filter: {},
    };

    constructor(private AmCharts: AmChartsService,
                private leadChartInfo: LeadsChartService,
                private dialog: MatDialog) {
        super();

        this.getLeadCharts();
    }

    ngOnInit() {
    }

    public selectDate(range: any): void {
        this.selectedRange = range;
        this.toggleLoader();
        this.getLeadCharts();
    }

    public openDatePicker(): void {
        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: this.selectedRange.id === 'custom' ? this.selectedRange : null
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedRange = result;
                this.toggleLoader();
                this.getLeadCharts();
            }
        });
    }

    private getLeadCharts(): void {

        this.chartData.filter = {
            'filter[date][from]': this.selectedRange.value.end,
            'filter[date][to]': this.selectedRange.value.start
        };

        this.leadChartInfo.getLeadsChart(this.chartData).subscribe((response) => {
            this.isEmpty = !((response.data.length > 0) && (response.data.filter((row) => {
                return row.value > 0;
            }).length > 0));


            if (!this.isEmpty) {
                this.getChartData(response.data);
            } else if (this.chart) {
                this.AmCharts.destroyChart(this.chart);
            }
            this.toggleLoader();
        });
    }

    private getChartData(data): void {
        const dataProvider = data.map((row) => {
            return {
                'campaign': row.label,
                'value': row.value,
                'total': row.total,
                'color': row.color,
                'bgcolor': row.bgcolor,
                'longtitle': Math.round((row.value / row.total) * 100).toFixed(1),
            };
        });

        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
            this.chart = null;
        }

        this.chart = this.AmCharts.makeChart('chartdiv', Object.assign(this.chartInfo, {
            dataProvider: dataProvider
        }));
    }
}
