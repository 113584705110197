import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallReportComponent} from './call-report.component';
import {
    MatButtonModule, MatButtonToggleModule, MatDialogModule,
    MatDividerModule, MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule, MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {CallReportTableModule} from './call-report-table/call-report-table.module';
import {CallReportChartModule} from './call-report-chart/call-report-chart.module';
import {CallReportFilterModule} from './call-report-filter/call-report-filter.module';
import {CallReportColumnsModule} from './call-report-columns/call-report-columns.module';
import {CallReportColumnsComponent} from './call-report-columns/call-report-columns.component';

@NgModule({
    declarations: [
        CallReportComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatExpansionModule,
        CallReportTableModule,
        MatButtonToggleModule,
        MatTooltipModule,
        CallReportChartModule,
        CallReportFilterModule,
        CallReportColumnsModule
    ],
    entryComponents: [
        DataPicketCampaignsComponent,
        CallReportColumnsComponent
    ],
    exports: [
        CallReportComponent
    ]
})
export class CallReportModule {
}
