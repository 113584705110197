import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QueueTableComponent} from './queue-table.component';
import {
    MatBottomSheetModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {QueueTableSkeletonModule} from '../queue-table-skeleton/queue-table-skeleton.module';
import {PhoneListModule} from '../phone-list/phone-list.module';
import {SyncListModule} from '../sync-list/sync-list.module';

@NgModule({
    declarations: [
        QueueTableComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        QueueTableSkeletonModule,
        PhoneListModule,
        MatDialogModule,
        SyncListModule,
        MatBottomSheetModule
    ],
    exports: [
        QueueTableComponent
    ]
})
export class QueueTableModule {
}
