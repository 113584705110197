import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SyncListComponent} from './sync-list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatDividerModule, MatFormFieldModule,
    MatIconModule, MatInputModule,
    MatListModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatSelectModule, MatSnackBarModule
} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';
import {ConfirmModalModule} from '../../confirm-modal/confirm-modal.module';
import {ErrorDialogModule} from '../../general/error-dialog/error-dialog.module';
import {ProgressLoaderModule} from '../../general/progress-loader/progress-loader.module';

@NgModule({
    declarations: [
        SyncListComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatListModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        ConfirmModalModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        ErrorDialogModule,
        MatSnackBarModule,
        ProgressLoaderModule,
        MatProgressSpinnerModule
    ],
    exports: [
        SyncListComponent
    ],
    entryComponents: [
        SyncListComponent
    ]
})
export class SyncListModule {
}
