import {Component, OnDestroy, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import SearchResponse from '../../search.response';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {RolesService} from '../../services/roles/roles.service';
import {AddRoleComponent} from './add-role/add-role.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

    public roles: SearchResponse = new SearchResponse();

    public displayedColumns: any[] = ['icon', 'name', 'description', 'created_at', 'updated_at', 'config'];

    public info = {
        'created_at': {
            'label': 'Created At',
            'name': 'created_at',
            'type': [
                'datetime'
            ],
            'view': 'CreatedAt'
        },
        'description': {
            'label': 'Description',
            'name': 'description',
            'type': [
                'string'
            ],
            'view': 'Description'
        },
        'icon': {
            'label': 'Icon',
            'name': 'icon',
            'type': [
                'string'
            ],
            'view': 'Icon'
        },
        'id': {
            'label': 'ID',
            'name': 'id',
            'type': [
                'string'
            ],
            'view': 'Id'
        },
        'name': {
            'label': 'Name',
            'name': 'name',
            'type': [
                'string'
            ],
            'view': 'Name'
        },
        'updated_at': {
            'label': 'Updated At',
            'name': 'updated_at',
            'type': [
                'datetime'
            ],
            'view': 'UpdatedAt'
        }
    };

    public selection = new SelectionModel<any>(true, []);

    public loader: any = true;

    public subscribers: any = {
        deleteSelected: null,
        getAll: null
    };

    public rolesMenu: any = [
        {
            icon: 'add_circle_outline',
            label: 'Add Role',
            show:  this.permissions.can('role', 'can_create_role'),
            fn: () => {
                const modal = this.dialog.open(AddRoleComponent, {
                    width: '500px'
                });
                modal.afterClosed().subscribe((res) => {
                    if (res) {
                        this.snack.open('New role is created!', 'Dismiss', {
                            duration: 3000
                        });
                        this.roles.data = [res, ...this.roles.data];
                    }
                });
            }
        }
    ];

    public options: any = [
        {
            icon: 'edit',
            label: 'Edit',
            show: this.permissions.can('role', 'can_edit_role'),
            fn: (row, i) => {
                const modal = this.dialog.open(AddRoleComponent, {
                    width: '500px',
                    data: row
                });
                modal.afterClosed().subscribe((res) => {
                    if (res) {
                        this.snack.open('The action was successful!', 'Dismiss', {
                            duration: 3000
                        });

                        this.roles.data[i] = res;

                        this.roles.data = [...this.roles.data];

                    }
                });
            }
        },
        {
            icon: 'how_to_reg',
            label: 'Permissions',
            show: this.permissions.can('role', 'can_view_permissions_role'),
            fn: (row, i) => {
                this.router.navigate([`roles/${row.id}/permissions`], { queryParams: {name: row.name} });
            }
        },
        {
            icon: 'delete',
            label: 'Delete',
            show: this.permissions.can('role', 'can_delete_role'),
            fn: (row, i) => {
                this.dialog.open(ConfirmModalComponent, {
                    width: '400px',
                    hasBackdrop: true,
                    data: {
                        title: 'Delete Role',
                        description: `Are you sure want delete '${ row.name }' role?`
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.subscribers.deleteSelected = this.rolesService.deleteRole([row.id]).subscribe(() => {
                            this.snack.open('Role Successfully Deleted!', 'Dismiss', {
                                duration: 3000
                            });

                            this.roles.data.splice(i, 1);

                            this.roles.data = [...this.roles.data];

                        }, error => {
                            this.snack.open(error.error.error, 'Dismiss', {
                                duration: 3000,
                                panelClass: ['error-snackbar']
                            });
                        });
                    }
                });
            }
        }
    ];

    constructor(
        private dialog: MatDialog,
        private snack: MatSnackBar,
        private router: Router,
        private rolesService: RolesService,
        public permissions: Permissions
    ) {
    }

    ngOnInit() {
        Object.assign(this.roles, {info: this.info});

        this.getRolesList();
    }

    ngOnDestroy() {
        for (const name in this.subscribers) {
            if (this.subscribers.hasOwnProperty(name) && this.subscribers[name]) {
                this.subscribers[name].unsubscribe();
            }
        }
    }

    public toggleLoader(params?: any) {
        this.loader = !this.loader;
    }

    public changePage(event: any): void {
        this.selection.clear();
        this.roles.page = event.pageIndex;
        this.roles.page_size = event.pageSize;
        this.toggleLoader();
        this.getRolesList();
    }

    public changeSort(event: any) {
        this.selection.clear();
        this.roles.addSort(event.active, event.direction);
        this.toggleLoader();
        this.getRolesList();
    }

    public getRolesList() {
        this.subscribers.getAll = this.rolesService.getRoles(this.roles.getReqData()).subscribe(res => {
            this.roles = Object.assign(this.roles, res);
            this.toggleLoader();
        });
    }


}
