import {Component, OnInit} from '@angular/core';
import {CreateViewComponent} from '../create-view/create-view.component';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ViewService} from '../../services/view/view.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ErrorDialogComponent} from '../general/error-dialog/error-dialog.component';
import {AgentService} from '../../services/agent/agent.service';
import {Store} from '@ngrx/store';
import {Permissions} from '../../helpers/Permissions';
import {AmChartsService} from '@amcharts/amcharts3-angular';

@Component({
  selector: 'app-edit-view',
  templateUrl: '../create-view/create-view.component.html',
  styleUrls: ['./edit-view.component.less', '../create-view/create-view.component.less']
})

export class EditViewComponent extends CreateViewComponent implements OnInit {

  public title = 'Edit view';

  public icon = 'rate_review';

  public viewId: number | string;

  constructor(
      protected viewService: ViewService,
      protected dialog: MatDialog,
      protected router: Router,
      protected route: ActivatedRoute,
      protected snackBar: MatSnackBar,
      protected agentService: AgentService,
      protected store: Store<any>,
      protected AmCharts: AmChartsService,
      public permissions: Permissions,
  ) {
    super(viewService, dialog, router, snackBar, route, agentService, store, AmCharts, permissions);
  }

  protected initData(): void {
    this.toggleLoader();
    this.getAgents();
    this.viewId = this.route.snapshot.paramMap.get('id');
    this.viewService.getView(this.viewId).subscribe((data: any) => {
      this.setViewData(data);
      this.toggleLoader();
    }, () => {
      this.dialog.open(ErrorDialogComponent, {
        width: '250px',
        data: {
          title: 'Not found',
          message: 'View not found!',
          redirect: ''
        }
      });
    });
  }

  protected setViewData(data: any): void {
    this.createView.get('name').patchValue(data.view.name);
    this.createView.get('is_default').patchValue(data.view.default ? 1 : 0);
    this.createView.get('in_tab').patchValue(data.view.in_tab ? 1 : 0);
    this.createView.get('in_tab_count').patchValue(data.view.in_tab_count ? 1 : 0);
    this.createView.get('type').patchValue(data.view.type);
    this.is_default = data.view.default;
    this.in_tab = data.view.in_tab;
    this.in_tab_count = data.view.in_tab_count;

    if (data.view['fields']) {
      for (let i = 0; i < data.view.fields.length; i++) {
        this.fields.push({
          name: data.view.fields[i].name,
          label: data.view.fields[i].label
        });
      }
      this.createView.get('view_fields').patchValue(this.fields.map((field) => field.name));
    }

    if (data.view['conditions']) {
      this.conditions = data.view['conditions'];
    }

    this.settings = data.settings;
  }

  protected handleSave(): void {
    this.createView.value.view_conditions.reverse().forEach(condition => {
      if (typeof condition.value === 'number') {
        if (condition.value < 0 || condition.value > 2147483647) {
          condition.value = 1;
        }
        condition.value = condition.value.toString();
      }
    });

    this.viewService.editVIew(this.viewId, this.createView.value).subscribe(() => {
      this.router.navigate(['/dashboard/' + this.viewId]);

      this.snackBar.open('The \'' + this.createView.get('name').value + '\' view was successfully edited!', 'Dismiss', {
        duration: 3000,
      });

      this.toggleLoader();
    }, this.handleErrors.bind(this));
  }
}
