import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-holidays-edit',
    templateUrl: './holidays-edit.component.html',
    styleUrls: ['./holidays-edit.component.less']
})
export class HolidaysEditComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        from_at: new FormControl('', [
            Validators.required,
            this.startAtValidator.bind(this),
        ]),
        to_at: new FormControl('', [
            Validators.required,
            this.endAtValidator.bind(this)
        ]),
        label: new FormControl('', [
            Validators.required,
        ])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private self: MatDialogRef<HolidaysEditComponent>
    ) {
    }

    ngOnInit() {
        if (this.data) {
            this.form.get('label').patchValue(this.data.label);
            this.form.get('from_at').patchValue(new Date((this.data.from_at * 1000) + ((new Date()).getTimezoneOffset() * 60 * 1000)));
            this.form.get('to_at').patchValue(new Date((this.data.to_at * 1000) + ((new Date()).getTimezoneOffset() * 60 * 1000)));
        }
    }


    submit() {
        if (this.form.valid) {
            this.self.close(this.data ? Object.assign({}, this.data, this.form.value) : this.form.value);
        }
    }

    private startAtValidator(control: AbstractControl) {

        if (!this.form) {
            return;
        }

        const to = this.getDateFromControl(this.form.get('to_at'));

        const from = this.getDateFromControl(control);

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"from\" must be smaller that \"too\"'
            };
        } else if (this.form.get('to_at').invalid) {
            this.form.get('to_at').updateValueAndValidity();
        }
    }

    private endAtValidator(control: AbstractControl) {

        if (!this.form) {
            return;
        }

        const to = this.getDateFromControl(control);

        const from = this.getDateFromControl(this.form.get('from_at'));

        if (to === null || from === null) {
            return;
        }

        if (from.getTime() > to.getTime()) {
            return {
                endDate: 'date \"To\" must be bigger that \"From\"'
            };
        } else if (this.form.get('from_at').invalid) {
            this.form.get('from_at').updateValueAndValidity();
        }
    }

    private getDateFromControl(control: AbstractControl): null | Date {
        if (!control || !control.value) {
            return null;
        }
        return control.value as Date;
    }

}
