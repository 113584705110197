import {Component, OnInit} from '@angular/core';
import {LeadsInfoAmchartComponent} from '../leads-info-amchart/leads-info-amchart.component';
import {LeadsInfoTableComponent} from '../leads-info-table/leads-info-table.component';
import {MatDialog} from '@angular/material';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-view-statistics',
    templateUrl: './view-statistics.component.html',
    styleUrls: ['./view-statistics.component.less']
})
export class ViewStatisticsComponent implements OnInit {

    public menu: any = [
        {
            label: 'Stats chart',
            component: LeadsInfoAmchartComponent,
            show: this.permissions.can('leads_chart', 'can_get_leads_chart')
        },
        {
            label: 'Campaigns table',
            component: LeadsInfoTableComponent,
            show: this.permissions.can('campaign-statistic', 'can_get_campaign-statistic')
        }
    ];

    constructor(
        private dialog: MatDialog,
        public permissions: Permissions
    ) {
    }

    ngOnInit() {
    }

    public openModal(menuItem) {
        this.dialog.open(menuItem.component, {
            width: '800px',
        });
    }

}
