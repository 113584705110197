import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/index';

// import Centrifuge from 'centrifuge';

import {EventEmitter} from '@angular/core';
import {Cookies} from '../../helpers/Cookies';

declare var Centrifuge: any;

// declare var SockJS: any;

@Injectable({
    providedIn: 'root'
})
export class CentrifugeService {

    constructor() {
    }

    private handler: any;
    private debug: boolean;
    private connected = false;
    private chs = {};
    private lis = {};

    stateEmitter = new EventEmitter<any>();

    getStates(): Observable<any> {
        return this.stateEmitter;
    }

    connect(): Observable<any> {
        return new Observable((observer) => {
            if (this.connected) {
                observer.next();
                return {
                    unsubscribe: () => {
                        if (this.handler) {
                            this.handler.disconnect();
                        }
                        observer.complete();
                    }
                };
                throw new Error('Centrifuge is already connected.');
            }

            this.handler = new Centrifuge(
                environment.centURL,
                {
                    debug: !environment.production,
                    // sockjs: SockJS
                }
            );

            const token = Cookies.get('centrifugo');

            this.handler.setToken(token);

            this.debug = !environment.production;

            this.handler
                .on('connect', data => {
                    this.connected = true;
                    if (this.debug) {
                        console.log('Connected to Centrifugo', data);
                    }
                    this.stateEmitter.emit({type: 'state', state: 'connected', info: data});
                    observer.next();
                })
                .on('disconnect', data => {
                    this.connected = false;
                    if (this.debug) {
                        console.log('Disconnected from Centrifugo', data);
                    }
                    this.stateEmitter.emit({type: 'state', state: 'disconnected', info: data});
                    delete this.handler;
                    observer.error();
                })
                .on('error', error => {
                    if (this.debug) {
                        console.error('Error Centrifugo :', error);
                    }
                    this.stateEmitter.emit({type: 'error', info: error});
                });

            this.handler.connect();

            return {
                unsubscribe: () => {
                    if (this.handler) {
                        this.handler.disconnect();
                    }
                    observer.complete();
                }
            };
        });
    }

    disconnect(): void {
        this.handler.disconnect();
    }

    unsubscribe(channel: string): void {
        if (this.lis[channel]) {
            this.chs[channel].unsubscribe();
            console.log('Unsubscribed from ' + channel);
            delete this.lis[channel];
        }
    }

    listen(channel: string): Observable<any> {
        return new Observable((observer) => {
            this.connect().subscribe(() => {
                if (!this.lis[channel]) {
                    this.lis[channel] = [];
                }

                this.lis[channel].push(observer);

                if (!this.chs[channel]) {
                    this.chs[channel] = this.handler.subscribe(channel)
                        .on('publish', message => {
                            if (this.debug) {
                                console.log('Published to \'' + channel + '\' :', message);
                            }

                            // message.data = JSON.parse(new TextDecoder('utf-8').decode(message.data));
                            if (this.lis[channel]) {
                                this.lis[channel].forEach(obs => obs.next(message));
                            }
                        })
                        .on('subscribe', data => {
                            if (this.debug) {
                                console.log('Subscribed to \'' + channel + '\' :', data);
                            }
                        })
                        .on('error', error => {
                            if (this.debug) {
                                console.log('Centrifugo Subscribe error :', error);
                            }
                            this.stateEmitter.emit({type: 'error', info: error});

                            this.lis[channel].forEach(obs => obs.error(error));
                            this.lis[channel].forEach(obs => obs.complete());
                        });
                } else {
                    this.handler.subscribe(channel);
                }
            });

            return {
                unsubscribe: () => {
                    if (this.chs[channel]) {
                        this.chs[channel].unsubscribe();
                        console.log('Unsubscribed from ' + channel);
                    }
                    if (this.lis[channel]) {
                        this.lis[channel].forEach(obs => obs.complete());

                        delete this.lis[channel];
                    }
                    observer.complete();
                }
            };
        });
    }

}
