import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elemLeftPos'
})
export class ElemLeftPosPipe implements PipeTransform {

  transform(elem: any): string {
    return 100 * (elem.from.h + elem.from.m / 60) / 24 + '%';
  }

}
