import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentsAssignComponent} from './agents-assign.component';
import {
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
} from '@angular/material';
import {AssignStatusModalModule} from '../assign-status-modal/assign-status-modal.module';
import {AssignStatusModalComponent} from '../assign-status-modal/assign-status-modal.component';

@NgModule({
    declarations: [
        AgentsAssignComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        AssignStatusModalModule
    ],
    exports: [
        AgentsAssignComponent
    ],
    entryComponents: [
        AssignStatusModalComponent
    ]
})
export class AgentsAssignModule {
}
