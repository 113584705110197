import {Agent} from '../agent/Agent';

export class Lead {
    id: number;
    crm_id: number;
    lead_number: string;
    email: string;
    first_name: string;
    last_name: string;
    country_of_birth: string;
    assign_time: number;
    contact_me_time: number;
    quote: string;
    main_occupation: string;
    country_of_residence: string;
    modified_time: number;
    status_reason: string;
    assigned_to: number;
    touched: string;
    created_time: number;
    activity_time_1: number;
    activity_time_2: number;
    activity_time_3: number;
    birthday: string;
    campaign_source: string;
    speak_french: string;
    main_language: string;
    marital_status: string;
    gender: string;
    above_years: string;
    dialer_campaign: string;
    speak_english: string;
    education: string;
    online_status: string;
    dependent: string;
    contact_me: string;
    available_funds: string;
    single_or_married: string;
    range_of_monthly_salary: string;
    disqualified: string;
    have_a_job: string;
    attempt: string;
    visa_type: string;
    nationality: string;
    preferred_destination: string;
    currently_employed: string;
    occupation: string;
    years_of_employment: string;
    completed_high_school: string;
    other_education: string;
    choose_currency: string;
    net_worth: string;
    campaign: string;
    keyword: string;
    adgroup: string;
    adid: string;
    source: string;
    adposition: string;
    placement: string;
    gclid: string;
    device: string;
    geoid: string;
    utm_term: string;
    devicemodel: string;
    matchtype: string;
    previous_status: string;
    previous_owner: string;
    phone: string;
    age: string;
    conversion_rate: string;
    funnel_status_reason: string;
    created_at: number;
    updated_at: number;
    agent: Agent;
}
