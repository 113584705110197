import {Component, NgZone, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import BaseComponent from '../base/base.component';
import {ViewService} from '../../services/view/view.service';
import {MatBottomSheet, MatDialog} from '@angular/material';
import {Agent} from '../../services/agent/Agent';
import {AgentsSheetComponent} from '../agents-sheet/agents-sheet.component';
import {AgentService} from '../../services/agent/agent.service';
import {LeadService} from '../../services/lead/lead.service';
import {ErrorDialogComponent} from '../general/error-dialog/error-dialog.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {SelectionModel} from '@angular/cdk/collections';
import {select, Store} from '@ngrx/store';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';
import {GetBoardId, GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_LEADS} from '../../constants/user';
import {CentrifugeService} from '../../services/centrifuge/centrifuge.service';

const defaultPregres = {
    percent: 0,
    message: 'Please wait ...',
    mode: 'determinate'
};


@Component({
    selector: 'app-re-assign-pool',
    templateUrl: './re-assign-pool.component.html',
    styleUrls: ['./re-assign-pool.component.less']
})

export class ReAssignPoolComponent extends BaseComponent implements OnInit {

    public MAX_REASSIGN = 1000;
    public PROGRESS_DURATION = 5000;

    public getAvatar = getAvatar;

    public displayedColumns: Array<string> = [];

    public defaults: any = false;

    public reAssignCondition: FormGroup;

    public conditionLoader = false;

    public reassignLoader = false;

    public progress: any = Object.assign({}, defaultPregres);

    private reassignTimer = null;

    public fullLoader = false;

    public fieldDataLoader = false;

    public poolButton: any = true;

    public selection = new SelectionModel<any>(true, []);

    public settings: any = {
        conditions: []
    };

    public errors = [];
    public assignStatusList = null;

    public conditions: Array<any> = [];

    public agents: Agent[] = [];

    public selectedAgent: Agent = null;

    public view: any = {
        data: [],
        fields: [],
        page: 0,
        pages: 0,
        rows: 0,
        page_size: 100,
        sort: {}
    };

    public pageSizeRange: number[] = [100, 150, 200];

    private withShift: any;

    private roleType: number;
    private boardId: number;

    constructor(
        protected viewService: ViewService,
        private bottomSheet: MatBottomSheet,
        private agentService: AgentService,
        private leadService: LeadService,
        private dialog: MatDialog,
        private store: Store<any>,
        public permissions: Permissions,
        private centrifugeService: CentrifugeService,
        private ngZone: NgZone
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            filter(data => !!data),
            takeUntil(this.destroy)
        ).subscribe((type: number) => {
            this.roleType = type;
        });

        this.store.pipe(
            select(GetBoardId),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((id: number) => {
            this.boardId = id;
        });

        this.store.pipe(
            select((state) => {
                return state.agents.allAgentsNew;
            }),
            takeUntil(this.destroy)
        ).subscribe((res) => {
            this.agents = res;

            if (this.agents.length > 0 && !this.selectedAgent) {
                for (let i = 0; i < this.agents.length; i++) {
                    if (this.roleType === TYPE_LEADS) {
                        if (this.agents[i].crm_id === Agent.ADMIN_ID) {
                            this.selectedAgent = this.agents[i];
                            break;
                        }
                    } else {
                        if (this.agents[i].crm_id === Agent.RETENTION_REASSIGN) {
                            this.selectedAgent = this.agents[i];
                            break;
                        }
                    }
                }

                if (this.reAssignCondition && this.selectedAgent) {
                    this.reAssignCondition.get('agent_id').setValue(this.selectedAgent.id);
                }
            }
        });

        this.reAssignCondition = new FormGroup({
            view_conditions: new FormArray([], [
                Validators.required
            ]),

            agent_id: new FormControl((this.selectedAgent ? this.selectedAgent.id : ''), [
                Validators.required
            ])
        });

        this.initData();
    }

    ngOnInit() {
        let activeChanel: any = localStorage.getItem('reassignChanel');
        if (activeChanel) {
            activeChanel = JSON.parse(activeChanel);

            this.subscribeToCentrifugo(activeChanel.chanel, activeChanel.count);
        }
    }

    private subscribeToCentrifugo(chanel: string, count: number): void {
        this.centrifugeService.listen(chanel).pipe(
            takeUntil(this.destroy)
        ).subscribe(({data}) => {
            this.ngZone.run(() => {
                let left = count;

                if (data.process === 'started') {
                    this.reassignLoader = true;

                    const delay = this.PROGRESS_DURATION / count;

                    this.reassignTimer = setInterval(() => {
                        left -= 1;

                        this.progress.percent = Math.round(100 / count * (count - left));

                        if (left > 0) {
                            this.progress.message = 'Assigned ' + left + ' of ' + count + ' leads!';
                        } else {
                            this.progress = {
                                message: 'Working on it ...',
                                mode: 'indeterminate'
                            };

                            clearInterval(this.reassignTimer);
                        }
                    }, delay);

                    localStorage.setItem('reassignChanel', JSON.stringify({
                        chanel: chanel,
                        count: count
                    }));

                } else if (
                    data.process === 'stopped' ||
                    data.process === 'finished'
                ) {
                    if (data.process === 'finished') {
                        this.assignStatusList = data.message;
                    }

                    if (data.process === 'stopped' && Object.keys(data.message).length) {
                        Object.keys(data.message).forEach(key => {
                            if (data.message[key] instanceof Array) {
                                this.errors = this.errors.concat(data.message[key]);
                            } else {
                                this.errors.push(data.message[key]);
                            }
                        });
                    }

                    this.centrifugeService.unsubscribe(chanel);

                    this.closeAssignProgress();

                }
            });
        });
    }

    private closeErrors(): void {
        this.errors = [];
    }

    private closeStatusList(): void {
        this.assignStatusList = null;
    }

    public closeAssignProgress(): void {
        this.progress = Object.assign({}, defaultPregres);

        clearInterval(this.reassignTimer);

        localStorage.removeItem('reassignChanel');

        this.reassignLoader = false;
    }

    public setDefaults() {
        if (!this.defaults) {
            if (this.roleType === TYPE_LEADS) {

                this.conditions = [
                    {
                        condition: 'and',
                        field_name: 'status_reason',
                        operator: 'not_in',
                        value: JSON.stringify(['Qualified', 'Disqualified']),
                    },
                    {
                        condition: 'and',
                        field_name: 'assigned_to',
                        operator: 'not_equal',
                        value: '58'
                    },
                    {
                        condition: 'and',
                        field_name: 'assigned_to',
                        operator: 'not_equal',
                        value: '1'
                    },
                    {
                        condition: 'and',
                        field_name: 'assign_time',
                        operator: 'date_range',
                        value: 'last_7_days'
                    },
                    {
                        condition: 'and',
                        field_name: 'schedule_time',
                        operator: 'lest_than_period',
                        value: '-1',
                        group: 0
                    },
                ];

            } else {

                this.conditions = [
                    {
                        condition: 'and',
                        field_name: 'assigned_user_crm_id',
                        operator: 'not_in',
                        value: '[383,58,1]',
                        group: 0
                    },
                ];

            }

            this.defaults = true;
        }
    }

    public toggleLoader(name) {
        if (typeof this[name] !== 'undefined') {
            this[name] = !this[name];
        }
    }

    public getVewConditions(): FormGroup {
        return this.reAssignCondition.get('view_conditions') as FormGroup;
    }

    public getData(event?): void {
        if (event) {
            event.preventDefault();
        }

        if (!this.validateConditions()) {
            return null;
        }

        this.view.page = 0;

        this.getViewData('conditionLoader');
    }

    public chooseAgent(): void {

        const bottomSheet = this.bottomSheet.open(AgentsSheetComponent, {
            data: {
                agents: this.agents,
                selected: this.selectedAgent,
                multiple: false
            }
        });

        bottomSheet.afterDismissed().subscribe((result) => {
            if (result) {
                this.selectedAgent = result;
                this.reAssignCondition.get('agent_id').patchValue(result.id);
            }
        });
    }

    public changeSort(event): void {
        this.view.sort = {};
        if (event.direction.length > 0) {
            this.view.sort = {[event.active]: event.direction};
        }
        this.getViewData('fieldDataLoader');
    }

    public changePage(event): void {
        this.view.page = event.pageIndex;
        this.view.page_size = event.pageSize;
        this.getViewData('fieldDataLoader');
    }

    public reassignPool(count): any {
        if (!this.validateConditions()) {
            return null;
        }

        const confirm = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: 'Are you sure?',
                description: 'You want to reassign ' + count + ' lead(s) to ' + this.selectedAgent.crm_username + '!'
            }
        });

        confirm.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            const leads = this.selection.selected.map((lead) => {
                return lead.id;
            });

            this.leadService.getReassignToken(Object.assign(this.reAssignCondition.value, {
                type: this.roleType,
                leads: leads.length > 0 ? leads : []
            })).subscribe((res) => {
                this.reassignLoader = true;

                this.closeErrors();
                this.closeStatusList();

                this.subscribeToCentrifugo(res.chanel, leads.length);
            });
        });
    }

    public isAllSelected(): boolean {
        return this.selection.selected.length === this.view.data.length;
    }

    public masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.view.data.forEach(lead => this.selection.select(lead));
    }

    public selectionChange(event, lead) {
        if (!lead) {
            return;
        }

        if (this.withShift) {
            this.updateSelection(lead);
        }

        this.selection.toggle(lead);

        this.withShift = false;
    }

    public selectLead(event): any {
        this.withShift = event.shiftKey;
        event.stopPropagation();
    }

    private updateSelection(lead): any {

        let selected;

        let position;

        const loop = {
            start: 0,
            finish: 0
        };

        for (let i = 0; i < this.view.data.length; i++) {
            if (!selected && this.selection.isSelected(this.view.data[i])) {
                selected = i;
            }
            if (lead.id === this.view.data[i].id) {
                position = i;
            }
        }

        if ((selected === position) || selected === undefined) {
            return null;
        }

        if (selected < position) {
            loop.start = selected;
            loop.finish = position;
        } else {
            loop.start = position;
            loop.finish = selected;
        }

        for (let i = loop.start; i < loop.finish; i++) {
            if (!this.selection.isSelected(this.view.data[i])) {
                this.selection.toggle(this.view.data[i]);
            }
        }
    }

    private validateConditions() {

        if (!this.reAssignCondition.valid) {
            this.dialog.open(ErrorDialogComponent, {
                width: '250px',
                data: {
                    title: 'Validation error!',
                    message: 'Select at least one condition!'
                }
            });
        }

        this.poolButton = !this.reAssignCondition.valid;

        return this.reAssignCondition.valid;
    }

    private initData(): void {
        this.toggleLoader('fieldDataLoader');
        this.viewService.getSettings().pipe(
            takeUntil(this.destroy)
        ).subscribe((response) => {
            this.settings = response;
            this.toggleLoader('fieldDataLoader');
        });
    }

    private getViewData(loader?: string) {
        if (loader) {
            this.toggleLoader(loader);
        }
        const data = {
            ...this.reAssignCondition.value,
            type: this.roleType,
            board_id: this.boardId
        };

        this.leadService.getLeads(Object.assign({}, data, {
            page: this.view.page,
            page_size: this.view.page_size,
            sort: this.view.sort
        })).pipe(
            takeUntil(this.destroy)
        ).subscribe((response) => {
            this.selection.clear();
            this.view = Object.assign(this.view, response);
            this.displayedColumns = [
                'id'
            ];

            for (const field of this.view.fields) {
                this.displayedColumns.push(field.name);
            }

            this.poolButton = !(this.view.rows > 0);

            if (loader) {
                this.toggleLoader(loader);
            }
        });
    }

}
