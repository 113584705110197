import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../services/settings/settings.service';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.less']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {

    public loader: any = true;

    public settings: any = [];

    public loaders: any = [];

    public icons: any = {
        monthly_goal: 'monetization_on',
        weekly_goal: 'monetization_on',
        daily_goal: 'monetization_on',
        default: 'settings_input_component',
    };

    public subs: any = {
        settings: null,
        update: null,
    };

    constructor(
        private settingsService: SettingsService,
        private snack: MatSnackBar
    ) {
        this.subs.settings = this.settingsService.get().subscribe((res) => {
            this.settings = res;
            this.loader = !this.loader;
        });
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        for (const name in this.subs) {
            if (this.subs.hasOwnProperty(name) && this.subs[name]) {
                this.subs[name].unsubscribe();
            }
        }
    }

    edit(event, index) {

        this.loaders[index] = true;

        this.subs.update = this.settingsService.update(event).subscribe((res) => {

            this.settings[index] = res;

            this.loaders[index] = false;

            this.snack.open('Setting was successfully updated!', 'Dismiss', {
                duration: 3000
            });
        }, () => {

            this.loaders[index] = false;

            this.snack.open('There was an error updating your setting!', 'Dismiss', {
                duration: 3000
            });
        });

    }

}
