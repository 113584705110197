import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallReportColumnsComponent} from './call-report-columns.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [CallReportColumnsComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule
    ],
    exports: [CallReportColumnsComponent]
})
export class CallReportColumnsModule {
}
