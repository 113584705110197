import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader-full',
    templateUrl: './loader-full.component.html',
    styleUrls: ['./loader-full.component.less']
})
export class LoaderFullComponent implements OnInit {

    @Input() show: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
