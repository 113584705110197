import {Component, OnInit, Input} from '@angular/core';
import {AgentService} from '../../../../services/agent/agent.service';
import {Marker} from '../../../../helpers/Marker';
import {ViewLeadInfoComponent} from '../../../view-lead-info/view-lead-info.component';
import {AssignLeadsModalComponent} from '../../../assign-leads-modal/assign-leads-modal.component';
import {MatDialog} from '@angular/material';
import {TYPE_LEADS} from '../../../../constants/user';

@Component({
  selector: 'app-agent-table',
  templateUrl: './agent-table.component.html',
  styleUrls: ['./agent-table.component.scss']
})
export class AgentTableComponent implements OnInit {

  @Input() data: any;

  @Input() role: number;

  @Input() agentId: object;

  public displayedColumns: any = [
    'lead_number',
    'assigned_to',
    'country_of_residence',
    'age_condition',
    // 'priority',
    'best_time',
    'ai_prediction',
    // 'actions'
  ];

  public displayedColumnsLabels: any = {
    'lead_number': 'Lead Number',
    'assigned_to': 'Assigned To',
    'country_of_residence': 'Country of Residence',
    'age_condition': 'Ages Condition',
    'priority': 'Agent Priority',
    'best_time': 'Best Time',
    'ai_prediction': 'AI Prediction',
    // 'actions': 'Actions',
  };

  constructor(
      private agentService: AgentService,
      public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.role === TYPE_LEADS) {
      this.displayedColumns.splice(3, 0, 'priority');
    }
  }

  public assignLead(e, userId): void {
    if (e) {

      localStorage.setItem('assign_system', '1');

      this.agentService.assignLead(e[0].id, [userId], 'Assign AI System').subscribe(() => {
        localStorage.setItem('assign_system', '0');
      });

    }
  }

  public viewLeadDetails(lead: any): void {
    Marker.mark('newLeads', [lead.id], 'view-lead');

    const dialog = this.dialog.open(ViewLeadInfoComponent, {
      width: '600px',
      data: Object.assign({}, lead)
    });

    dialog.afterClosed().subscribe(() => {
      Marker.mark('newLeads', [lead.id], 'viewed-lead');
    });
  }

  public bestTime(lead) {
    if (lead.best_time) {
      return 'Status: ' + lead.best_time.priority + ' ( ' + this.getTimeCondition(lead.best_time.from) + ' - ' + this.getTimeCondition(lead.best_time.to) + ')';
    }
    return '';

  }

  public getTimeCondition(time) {
    if (time > 0 && time < 12) {
      return time + ' am';
    }
    return time + ' pm';
  }

  public getAgents(agents, agentId) {

    let newList = [];

    let i = 0;

    agents.forEach(function (element) {

      if (parseInt(element.agent_id) === parseInt(agentId)) {

        if (i === 6) {

          newList.splice(5, 1, element);

        } else {

          newList.push(element);

        }

        i++;

      } else {

        if (i <= 5) {

          i++;

          newList.push(element);

        }

      }

    });

    newList.sort((a, b) => (a.total_point > b.total_point) ? -1 : 1);

    return newList;

  }

}
