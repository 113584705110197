import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-queue-table-skeleton',
    templateUrl: './queue-table-skeleton.component.html',
    styleUrls: ['./queue-table-skeleton.component.less']
})
export class QueueTableSkeletonComponent implements OnInit {

    @Input() public pageSize: number;

    @Input() public numberOfRows: number;

    @Input() public fieldsLength: number;

    @Input() public active: any;

    public headerRows: Array<null> = Array.from({
        length: 1
    }, () => {
        return null;
    });

    public getRowsArray(): Array<null> {
        return Array.from({
            length: this.numberOfRows ? this.numberOfRows : this.pageSize
        }, () => {
            return null;
        });
    }

    constructor() {}

    ngOnInit() {
    }

    public getTableWidth(): any {
        const table = document.getElementById('queue-table-content');
        const header = document.getElementById('queue-table-content-wrap');
        if (table && header) {
            return {
                'height.px': header.offsetHeight > table.offsetHeight ? header.offsetHeight : table.offsetHeight
            };
        }
        return {};
    }
}
