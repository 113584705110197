import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HolidaysEditComponent} from './holidays-edit.component';
import {MatButtonModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [HolidaysEditComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
    ],
    exports: [HolidaysEditComponent]
})
export class HolidaysEditModule {
}
