import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    constructor(
        private api: ApiService
    ) {
    }

    public get(): Observable<any> {
        return this.api.get('v1/settings', {});
    }

    public update(params): Observable<any> {
        return this.api.post('v1/settings/' + params.name, {
            body: {
                value: params.value,
                name: params.name,
                type: params.type,
                label: params.label
            }
        });
    }

    public saveIpControl(body): Observable<any> {
        return this.api.post('v1/settings/ip-control', {body});
    }

    public getSettings(params): Observable<any> {
        return this.api.get('v1/settings/info', {params});
    }
}
