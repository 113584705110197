import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Cookies} from '../../helpers/Cookies';

@Injectable({
    providedIn: 'root'
})

export class BoardGuardService implements CanActivate {

    private board_id;

    constructor(private router: Router) {
        this.board_id = localStorage.getItem('board_id');
    }

    canActivate() {
        const auth  = !!this.board_id;

        if (!auth) {
            this.router.navigate(['/board/select']);
        }

        return auth;
    }

}
