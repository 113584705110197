import {Component, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {CampaignStatisticService} from '../../services/campaign-statistic/campaign-statistic.service';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {MatDialog} from '@angular/material';
import {DateConverter} from '../../helpers/DateConverter';
import CampaignStatistic from '../../services/campaign-statistic/campaign-statistic';

@Component({
    selector: 'app-leads-info-table',
    templateUrl: './leads-info-table.component.html',
    styleUrls: ['./leads-info-table.component.less']
})
export class LeadsInfoTableComponent extends BaseComponent implements OnInit {

    public loader = true;

    public selectedRange: any = {
        id: 'today',
        name: 'Today',
        icon: 'calendar_today',
        value: {
            start: DateConverter.addDays(new Date, 0, true),
            end: DateConverter.addDays(new Date, 0, true)
        }
    };

    public dateRangeSelect: any[] = [
        {
            id: 'today',
            name: 'Today',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, 0, true)
            }
        },
        {
            id: 'yesterday',
            name: 'Yesterday',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, -1, true),
                end: DateConverter.addDays(new Date, - 1, true)
            }
        },
        {
            id: 'last_7',
            name: 'Last 7 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -7, true)
            }
        },
        {
            id: 'last_14',
            name: 'Last 14 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -14, true)
            }
        },
        {
            id: 'last_30',
            name: 'Last 30 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -30, true)
            }
        },
        {
            id: 'last_60',
            name: 'Last 60 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -60, true)
            }
        },
        {
            id: 'last_90',
            name: 'Last 90 Days',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addDays(new Date, 0, true),
                end: DateConverter.addDays(new Date, -90, true)
            }
        },
        {
            id: 'this_month',
            name: 'This Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date, 1, 0, true),
                end: DateConverter.addMonth(new Date, 0, 1, true)
            }
        },
        {
            id: 'last_month',
            name: 'Last Month',
            icon: 'calendar_today',
            value: {
                start: DateConverter.addMonth(new Date,  0, 0, true),
                end: DateConverter.addMonth(new Date, -1, 1, true )
            }
        },
    ];

    public columnsToDisplay: Array<string> = [
        'name',
        'leads',
        'assigned',
        'percentage',
    ];

    public campaigns: CampaignStatistic[] = [];

    public campaignsData: any = {
        page_size: 5,
        pages: 0,
        page: 0,
        rows: 0,
        sort: {},
        filter: {},
    };


    constructor(
        private campSService: CampaignStatisticService,
        private dialog: MatDialog
    ) {
        super();

        this.geStatistics();
    }

    ngOnInit() {
    }

    public selectDate(range: any): void {
        this.selectedRange = range;
        this.toggleLoader();
        this.geStatistics();
    }

    public addSort(event) {
        this.campaignsData.sort = event.active && event.direction ? {['sort[' + event.active + ']']: event.direction} : {};
        this.toggleLoader();
        this.geStatistics();
    }

    public changePage(event): void {
        this.campaignsData.page = event.pageIndex;
        this.campaignsData.page_size = event.pageSize;
        this.toggleLoader();
        this.geStatistics();
    }

    public getTotal(fieldName: string): number {
        return this.campaigns.map(t => t[fieldName]).reduce((acc, value) => acc + value, 0);
    }

    public getPercentageTotal(): string {
        const leads = this.getTotal('leads');
        const assigned = this.getTotal('assigned');
        if (leads <= 0 || assigned <= 0) {
            return '0 %';
        }
        const percent = (assigned / leads) * 100;
        if (percent < 1 && percent > 0) {
            return (percent).toFixed(3) + '%';
        }
        return Math.round(percent) + '%';
    }

    public openDatePicker(): void {
        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: this.selectedRange.id === 'custom' ? this.selectedRange : null
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedRange = result;
                this.toggleLoader();
                this.geStatistics();
            }
        });
    }

    private geStatistics(): void {
        this.campaignsData.filter = {
            'filter[date][from]': this.selectedRange.value.end,
            'filter[date][to]': this.selectedRange.value.start
        };
        this.campSService.getStatistics(this.campaignsData).subscribe((response) => {
            this.campaigns = response.data;
            this.campaignsData = Object.assign(this.campaignsData, response);
            this.toggleLoader();
        });
    }
}
