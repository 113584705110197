import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';
import {MatDialog} from '@angular/material';
import {DateConverter} from '../../../helpers/DateConverter';

@Component({
    selector: 'app-date-period-input',
    templateUrl: './date-period-input.component.html',
    styleUrls: ['./date-period-input.component.less']
})
export class DatePeriodInputComponent implements OnInit, OnChanges {

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    @Input() public value: string;

    public dateInput: string;

    public rawValue: any = {
        from: '',
        to: ''
    };

    public numericValue: any = {
        start: 0,
        end: 0
    };

    constructor(
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.setValue(this.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.firstChange && changes.value.currentValue !== undefined) {
            this.setValue(changes.value.currentValue);
        }
    }

    public open() {
        const dialogRef = this.dialog.open(DataPicketCampaignsComponent, {
            width: '500px',
            data: {
                id: 'custom',
                value: {
                    rawEnd: new Date(this.rawValue.start),
                    rawStart: new Date(this.rawValue.end)
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.rawValue = {
                    start: result.value.end,
                    end: result.value.start
                };
                this.setDateInput();
                this.setNumericValues();
                this.dispatchChange();
            }
        });
    }

    public clear() {
        this.clearData();
        this.dispatchChange();
    }

    public dispatchChange() {
        this.change.emit({
            value: this.dateInput ? JSON.stringify(this.numericValue) : null
        });
    }

    private setValue(value: string) {
        if (value) {
            this.numericValue = JSON.parse(value);
            this.convertToRaw();
            this.setDateInput();
        } else {
            this.clearData();
        }
    }

    private clearData() {
        this.rawValue = {
            start: '',
            end: ''
        };
        this.dateInput = '';
        this.numericValue = {
            start: 0,
            end: 0
        };
    }

    private setDateInput() {
        this.dateInput = this.rawValue.start + '/' + this.rawValue.end;
    }

    private setNumericValues() {
        this.numericValue = {
            start: Math.floor(((new Date()).getTime() - (new Date(this.rawValue.start)).getTime()) / (1000 * 60 * 60 * 24)) * -1,
            end: Math.floor(((new Date()).getTime() - (new Date(this.rawValue.end)).getTime()) / (1000 * 60 * 60 * 24)) * -1
        };
    }

    private convertToRaw() {
        this.rawValue = {
            start: DateConverter.addDays(new Date(), this.numericValue.start, true),
            end: DateConverter.addDays(new Date(), this.numericValue.end, true)
        };
    }

}
