import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePeriodInputComponent} from './date-period-input.component';
import {MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTooltipModule} from '@angular/material';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [DatePeriodInputComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        FormsModule,
        MatTooltipModule
    ],
    exports: [
        DatePeriodInputComponent
    ],
    entryComponents: [
        DataPicketCampaignsComponent
    ]
})
export class DatePeriodInputModule {
}
