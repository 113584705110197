import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {LeadService} from '../../services/lead/lead.service';

@Component({
    selector: 'app-assign-leads-modal',
    templateUrl: './assign-leads-modal.component.html',
    styleUrls: ['./assign-leads-modal.component.less']
})
export class AssignLeadsModalComponent implements OnInit {

    public loader: any = false;

    public assignableLeadGroup: FormGroup;

    public options: any = [
        {
            value: 'Assignable',
            label: 'Make all leads Assignable',
        },
        {
            value: 'Not Assignable',
            label: 'Make all leads Not Assignable'
        }
    ];

    constructor(private self: MatDialogRef<AssignLeadsModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                private leadService: LeadService,
                private snackBar: MatSnackBar) {
    }

    ngOnInit() {
        this.assignableLeadGroup = new FormGroup({
            select: new FormControl('', [
                Validators.required,
            ])
        });
    }

    public changeStatus() {
        const dialog = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                title: 'Change Lead Status',
                description: 'Are you sure want change status to ' + this.assignableLeadGroup.value.select + '  ?'
            }
        });

        dialog.afterClosed().subscribe((response) => {
            if (response) {
                this.loader = true;
                this.leadService.changeStatus({
                    status: this.assignableLeadGroup.value.select,
                    leadIds: this.data,
                }).subscribe((res) => {
                    this.loader = false;
                    if (res) {
                        this.self.close(true);
                        this.snackBar.open('Leads statuses was successfully changed to ' + this.assignableLeadGroup.value.select + ' !', 'Dismiss', {
                            duration: 5000,
                        });
                    }
                }, () => {
                    this.snackBar.open('Error, Please contact your Administrator!', 'Ok', {
                        duration: 5000,
                    });
                });
            }
        });
    }
}
