import {Component, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AgentService} from '../../services/agent/agent.service';
import {Observable, pipe} from 'rxjs';
import {UserService} from '../../services/user/user.service';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {GetBoardId} from '../../reducers/selectors/login.selector';
import {RolesService} from '../../services/roles/roles.service';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material';
import {ipRex} from '../../constants/regex';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.less']
})

export class CreateUserComponent extends BaseComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public autoCompleteLoader: any = false;

  public agents: Observable<any>;

  public boardId: number;

  public roles: any[] = [];

  public newIp = new FormControl('', [Validators.pattern(ipRex)])

  public createUser: FormGroup = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern(/^[a-zA-z0-9-]{3,100}$/)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)
    ]),
    confirm_password: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)
    ]),
    full_name: new FormControl('', [
      Validators.required,
    ]),
    crm_username: new FormControl('', [
      Validators.required,
    ]),
    role_id: new FormControl('', [
      Validators.required,
    ]),
    crm_id: new FormControl('', []),
    board_id: new FormControl('', []),
    ips: new FormControl([], []),
  });

  private timeout: any;

  private activeAgent: any = {
    crm_username: ''
  };

  public options: any = [];

  constructor(
      private rolesService: RolesService,
      private agentService: AgentService,
      private userService: UserService,
      private snackBar: MatSnackBar,
      private self: MatDialogRef<any>,
      private store: Store<any>
  ) {
    super();

    const roles = localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : [];

    if (!roles.length) {
      this.rolesService.getRoles().pipe(
          takeUntil(this.destroy)
      ).subscribe((response) => {
        this.roles = response.data;
        localStorage.setItem('roles', JSON.stringify(this.roles));
      });

    } else {
      this.roles = roles;
    }

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.createUser.get('board_id').setValue(id);
    });

  }

  ngOnInit() {
    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.boardId = id;
    });

    this.createUser.get('crm_username').valueChanges.pipe(
        takeUntil(this.destroy)
    ).subscribe((value) => {
      if (value) {
        if (this.activeAgent && value !== this.activeAgent.crm_username) {
          this.createUser.get('crm_id').setValue('');
          this.createUser.get('crm_id').updateValueAndValidity();
        }
      }
    });
  }

  public autoCompleteChange(event): void {
    // if (event.data !== null) {
      this.autoCompleteLoader = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getAutoCompleteData();
      }, 500);
    // }
  }

  public getAutoCompleteData(): void {
    this.agents = new Observable<any>((observer) => {
      this.getAgents(this.createUser.get('crm_username').value).pipe(
          takeUntil(this.destroy)
      ).subscribe((response) => {
        this.autoCompleteLoader = false;
        observer.next(response.data);
        observer.complete();
      });
      return {
        unsubscribe() {
        }
      };
    });
  }

  public autoCompleteSelected(event, agent): void {
    if (agent && event.isUserInput) {
      this.activeAgent = agent;
      this.createUser.get('crm_id').setValue(agent.crm_id);
    }
  }

  public addUser(): any {

    console.log('-----', this.createUser.getRawValue())

    if (!this.createUser.valid) {
      return;
    }

    this.showLoader();

    this.userService.createUser(this.createUser.value).pipe(
        takeUntil(this.destroy)
    ).subscribe(() => {
      this.self.close('Updated!');
      this.snackBar.open('User have been created!', 'Dismiss', {
        duration: 3000,
      });
      this.hideLoader();
    }, (error) => {
      for (const name in error.error) {
        if (error.error.hasOwnProperty(name)) {
          this.createUser.get(name).setErrors({backend: error.error[name]});
        }
      }
      this.hideLoader();
    });
  }

  private getAgents(name): Observable<any> {
    if (!name) {
      this.options = [];
      return;
    }
    const options = {
      'type': '',
      'board_id': this.boardId,
      'page': 0,
      'page_size': 10,
      'sort': 'id=desc',
      'filter[general]': name
    };
    return this.agentService.filterAgents(options);
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    if (this.newIp.valid) {
      if ((value || '').trim()) {
        this.createUser.get('ips').value.push(value.trim());
      }

      if (input) {
        input.value = '';
      }
    }
  }

  public remove(index: number): void {
    this.createUser.get('ips').value.splice(index, 1);
  }

}
