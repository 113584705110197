import {LoginActionsType, LoginActionUnion} from '../actions/login.actions';
import {ROLE_TYPES, TYPE_ADMIN} from '../constants/user';

export const LOGIN_FEATURE_NAME = 'Login';

export interface LoginState {
    id?: number;
    email?: string;
    username?: string;
    full_name?: string;
    created_at?: number;
    updated_at?: number;
    crm_id?: number;
    role?: string;
    role_id?: number;
    agent?: {
        id: number,
        crm_id: number,
        email: string,
        username: string,
        user_hash: string,
        first_name: string,
        last_name: string,
        role: string,
        shift: number,
        created_at: number,
        updated_at: number,
        hidden: number,
        is_dialer: null,
        avatar: string,
        crm_username: string
    };
    token?: string;
    permissions?: string[];
    view_settings?: null;
    ai_manager?: false;
    manage_view?: false;
    type?: number;
    role_type?: number;
    board_id?: number;
    new_crm_tokens?: null;
    active_board?: null;
}

const initialState: LoginState = {};

export function LoginReducer(state = initialState, action: LoginActionUnion) {
    switch (action.type) {
        case LoginActionsType.SET_LOGIN: {

            const role_type: string = localStorage.getItem('role_type');
            let type = 1;

            if (role_type) {

                type = parseInt(role_type, 10);

            } else if (action.payload.type !== TYPE_ADMIN) {

                type = ROLE_TYPES[action.payload.type];

            }

            return {
                ...state,
                ...action.payload,
                role_type: type
            };
        }
        case LoginActionsType.SET_ROLE_TYPE: {
            return {
                ...state,
                role_type: action.payload
            };
        }
        case LoginActionsType.SET_BOARD_ID: {
            return {
                ...state,
                board_id: action.payload
            };
        }
        case LoginActionsType.SET_ACTIVE_BOARD: {
            return {
                ...state,
                active_board: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

