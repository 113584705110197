import { NgModule } from '@angular/core';
import { ElemRightPosPipe } from './elem-right-pos.pipe';

@NgModule({
  declarations: [ElemRightPosPipe],
  exports: [
    ElemRightPosPipe
  ]
})
export class ElemRightPosPipeModule { }
