import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import BaseComponent from '../base/base.component';
import {ViewService} from '../../services/view/view.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {ListViewsComponent} from '../list-views/list-views.component';
import {select, Store} from '@ngrx/store';
import {AgentService} from '../../services/agent/agent.service';
import {LeadService} from '../../services/lead/lead.service';
import {Agent} from '../../services/agent/Agent';
import * as ViewsActions from '../../actions/views-actions';
import * as FiltersActions from '../../actions/filters-actions';
import {initialState, initialState as viewsInitialState} from '../../reducers/views-reducer';
import {ViewLeadInfoComponent} from '../view-lead-info/view-lead-info.component';
import {Marker} from '../../helpers/Marker';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ActivatedRoute, Router} from '@angular/router';
import {AssignLeadsModalComponent} from '../assign-leads-modal/assign-leads-modal.component';
import {Permissions} from '../../helpers/Permissions';
import {filter, takeUntil} from 'rxjs/operators';
import {TYPE_LEADS} from '../../constants/user';
import {JSON_FIELDS} from '../../helpers/constants';
import store from 'store';
import {CountrySplitModalComponent} from './country-split-modal/country-split-modal.component';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})

export class ViewComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('table') public table: any;

  @ViewChild('viewport') public viewport: CdkVirtualScrollViewport;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  public selection = new SelectionModel<any>(true, []);

  public viewId: number = null;

  public notFound = false;

  public models: any = {};

  public loader = true;

  public pageSizeRange: number[] = [100, 150, 200];

  public view: any = initialState;

  public jsonFields = JSON_FIELDS;

  public columnsToDisplay: Array<string>;

  public columnsToFilter: Array<string>;

  public agents: Agent[] = [];

  public reverse: any = 0;

  public agentStyle: any;

  public tabs = [];

  private filterchange: any;

  private withShift: any;

  private dropArea: HTMLElement;

  private dropListener: any;

  private selectionListener: any;

  private viewGetData: any;

  public tabsIndex = null;

  public gridHeight = window.innerHeight - 280 - 64 - 153;

  public selectedGmt;

  public selectedAge;

  public selectedAttempts;

  public selectedAttemptsAll;

  public attemptsMenuBtn = [
    {
      label: 'All',
      value: 'all',
      fn: () => {
        this.selectedAttemptsAll = 'All';
        localStorage.setItem('attempts_all', 'All');

        delete this.view.filter.mega_attempts_all;
        delete this.view.filter.mega_attempts;

        this.selectedAttempts = null;

        this.getViewData();
      },
    },
    {
      label: 'Today',
      value: 'today',
      fn: () => {
        this.selectedAttemptsAll = 'Today';
        localStorage.setItem('attempts_all', 'Today');
        this.view.filter['mega_attempts_all'] = 'Today';
        this.getViewData();
      },
    },
    {
      label: 'Week',
      value: 'week',
      fn: () => {
        this.selectedAttemptsAll = 'Week';
        localStorage.setItem('attempts_all', 'Week');
        this.view.filter['mega_attempts_all'] = 'Week';
        this.getViewData();
      },
    },
    {
      label: 'Month',
      value: 'month',
      fn: () => {
        this.selectedAttemptsAll = 'Month';
        localStorage.setItem('attempts_all', 'Month');
        this.view.filter['mega_attempts_all'] = 'Month';
        this.getViewData();
      },
    },
  ];

  public subs: any = {
    route: null,
    agents: null,
    views: null,
  };

  public toolbarMenu = [
    {
      id: 'assignable',
      label: 'Assignable Leads',
      icon: 'brightness_auto',
      fn: () => {
        const selectedId = this.selection.selected.map((sel) => {
          return sel.id;
        });

        const dialog = this.dialog.open(AssignLeadsModalComponent, {
          width: '600px',
          data: selectedId
        });
      }
    },
    // {
    //   id: 'сountry_split',
    //   label: 'Сountry Split',
    //   icon: 'bar_chart',
    //   fn: () => {
    //     this.dialog.open(CountrySplitModalComponent, {
    //       width: '700px',
    //     });
    //   }
    // },
  ];

  public userType: number;

  public roleType: number;

  public leadsType = TYPE_LEADS;

  constructor(
      private viewService: ViewService,
      public dialog: MatDialog,
      private store: Store<any>,
      private agentService: AgentService,
      private leadService: LeadService,
      private cdr: ChangeDetectorRef,
      private router: Router,
      private route: ActivatedRoute,
      public permissions: Permissions
  ) {
    super();

    this.store.pipe(
        select('login'),
        takeUntil(this.destroy),
        filter(data => !! data)
    ).subscribe((data: any) => {
        this.userType = data.type;

        this.roleType = data.role_type;

        const view = parseInt(localStorage.getItem('default_view'), 10);

        this.viewId = view;
    });

    if (this.route.snapshot.params['id'] !== 'default') {
      this.viewId = parseInt(this.route.snapshot.params['id'], 10);
    }

    localStorage.setItem('newLeads', '');

    this.selectedAttemptsAll = 'All';

    this.subs.agents = this.store.pipe(select('agents')).subscribe((value: any) => {
      this.agents = value.agents;
    });

    this.subs.views = this.store.pipe(select('views')).subscribe((value: any) => {
      this.dataSource.data = value.data;

      let selected = [];

      if (value.id !== this.viewId) {
        if (this.viewport) {
          this.viewport.scrollToOffset(0);
        }
        this.selection.clear();
      } else {
        selected = this.selection.selected.map(l => l.id);
      }

      this.view = Object.assign(this.view, value);

      if (selected.length > 0) {

        this.selection.clear();

        for (const id of selected) {

          const lead = this.view.data.find(l => parseInt(l.id, 10) === parseInt(id, 10));

          this.selection.select(lead);
        }
      }
    });

    this.subs.route = this.route.params.subscribe((params) => {
      if (params['id'] !== 'default' && this.viewId !== parseInt(params['id'], 10)) {
        this.viewId = parseInt(params['id'], 10);

        this.toggleLoader();

        this.setInitial();
      }
    });

    this.setInitial();
  }

  ngOnInit() {
    document.addEventListener('filterchange', this.filterchange = () => {
      this.changeFilter();
    });

    document.addEventListener('dragend', this.dropListener = (event) => {
      this.dragEnd(event);
    });

    document.addEventListener('viewGetData', this.viewGetData = () => {
      this.getViewData();
    });

    document.addEventListener('clearselection', this.selectionListener = () => {
      this.selection.clear();
    });

    this.dropArea = document.getElementById('drop-area');

    this.dataTabs();
  }

  ngOnDestroy() {

    for (const name of ['filterchange', 'viewGetData']) {
      document.removeEventListener(name, this[name]);
    }

    document.removeEventListener('dragend', this.dragEnd);

    document.removeEventListener('clearselection', this.selectionListener);

    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        this.subs[name].unsubscribe();
      }
    }
  }

  public dataTabs(params?: any) {
    this.viewService.getViewDataTab(params).subscribe((response) => {
      this.tabs = response.data;

      const tabsIndex = this.tabs.findIndex(x => x.id === this.viewId);

      this.tabsIndex  = tabsIndex >= 0 ? tabsIndex : 999;

      if (!this.viewId && response.data.length > 0) {
        this.viewId = response.data[0].id;

        this.setInitial();
      }
    });
  }

  public selectionChange(event, lead) {
    if (!lead) {
      return;
    }

    if (this.withShift) {
      this.updateSelection(lead);
    }

    this.selection.toggle(lead);

    this.cdr.detectChanges();

    this.withShift = false;
  }

  public selectLead(event): any {
    this.withShift = event.shiftKey;
    event.stopPropagation();
  }

  public tabChanged(event, tabs): any {
    this.router.navigate(['/dashboard/' + tabs[event.index].id]);
  }

  private updateSelection(lead): any {

    let selected;

    let position;

    const loop = {
      start: 0,
      finish: 0
    };

    for (let i = 0; i < this.view.data.length; i++) {
      if (!selected && this.selection.isSelected(this.view.data[i])) {
        selected = i;
      }
      if (lead.id === this.view.data[i].id) {
        position = i;
      }
    }

    if ((selected === position) || selected === undefined) {
      return null;
    }

    if (selected < position) {
      loop.start = selected;
      loop.finish = position;
    } else {
      loop.start = position;
      loop.finish = selected;
    }

    for (let i = loop.start; i < loop.finish; i++) {
      if (!this.selection.isSelected(this.view.data[i])) {
        this.selection.toggle(this.view.data[i]);
      }
    }
  }

  public changePage(event): void {
    this.view.page = event.pageIndex;
    this.view.page_size = event.pageSize;
    this.selection.clear();
    this.viewport.scrollToOffset(0);
    this.store.dispatch(new ViewsActions.SetData({
      page: event.pageIndex,
      page_size: event.pageSize
    }));
    this.getViewData();
  }

  public changeSort(event): void {
    this.view.sort = {};
    if (event.direction.length > 0) {
      this.view.sort = {['sort[' + event.active + ']']: event.direction};
    }
    this.viewport.scrollToOffset(0);
    this.selection.clear();
    this.store.dispatch(new ViewsActions.SetData({sort: this.view.sort}));
    this.getViewData();
  }

  fieldsTrack(index, item) {
    return item.name;
  }

  public changeFilter(): void {
    this.view.filter = {};
    this.view.page = 0;
    for (const name in this.models) {
      if (this.models.hasOwnProperty(name) && this.models[name].toString().length > 0) {
        this.view.filter['filter[' + name + ']'] = this.models[name];
      }
    }
    this.viewport.scrollToOffset(0);
    this.selection.clear();
    this.store.dispatch(new ViewsActions.SetData({
      page: 0,
      filter: this.view.filter
    }));
    this.getViewData();
  }

  public clearFilters(): void {
    this.view.filter = {};
    this.view.page = 0;
    for (const name in this.models) {
      if (this.models.hasOwnProperty(name)) {
        this.models[name] = '';
      }
    }
    localStorage.setItem('filter_view_' + this.viewId, '');
    this.viewport.scrollToOffset(0);
    this.selection.clear();
    this.store.dispatch(new ViewsActions.SetData({
      filter: {},
      page: 0
    }));
    this.getViewData();
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length >= this.view.data.length;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.view.data.forEach(lead => this.selection.select(lead));
  }

  public showViews(): void {
    const dialog = this.dialog.open(ListViewsComponent, {
      width: '450px',
      data: {
        type: this.roleType
      }
    });
    dialog.afterClosed().subscribe(this.afterClosed.bind(this));
  }

  public afterClosed(data: any): void {
    if (data && data.selectedView) {
      this.router.navigate(['/dashboard/' + data.selectedView.id]);
    }
    if (data && data.deletedViews.length > 0 && data.deletedViews.includes(this.viewId)) {
      this.router.navigate(['/dashboard/default']);
    }
  }

  public dragStart(event: any, lead: any): any {

    if (!this.permissions.can('agent', 'can_assign_from_view_agent')) {
      event.stopPropagation();

      return;
    }

    this.dropArea = document.getElementById('drop-area');

    if (this.dropArea) {
      this.dropArea.classList.add('active');
    }

    const selected = this.selection.selected.filter((value) => {
      return value.id === lead.id;
    });

    if (!(selected.length > 0)) {
      if (this.selection.selected.length > 0) {
        event.dataTransfer.setData('lead', JSON.stringify(this.selection.selected));
      } else {
        event.dataTransfer.setData('lead', JSON.stringify([lead]));
      }
    } else {
      event.dataTransfer.setData('lead', JSON.stringify(this.selection.selected));
    }
  }

  public dragEnd(event: any): void {

    this.dropArea = document.getElementById('drop-area');

    if (this.dropArea) {
      this.dropArea.classList.remove('active');
    }
  }

  public toggleReverse(): void {
    this.reverse = this.reverse === 0 ? 1 : 0;
    this.getViewData();
  }

  public detectIsNew(lead: any): any {
    const newLeads = localStorage.getItem('newLeads') ? JSON.parse(localStorage.getItem('newLeads')) : {};

    if (lead.is_new && !newLeads[lead.id]) {
      newLeads[lead.id] = 'new-lead';
      localStorage.setItem('newLeads', JSON.stringify(newLeads));
    }

    if (lead.waiting_to_contact && !newLeads[lead.id]) {
      newLeads[lead.id] = 'waiting-to-contact-lead';
      localStorage.setItem('newLeads', JSON.stringify(newLeads));
    }

    if (lead.untouched && !newLeads[lead.id]) {
      newLeads[lead.id] = lead.untouched + '-lead';
      localStorage.setItem('newLeads', JSON.stringify(newLeads));
    }

    if (!newLeads[lead.id]) {
      return {};
    }

    return {[newLeads[lead.id]]: true};
  }

  public viewLeadDetails(lead: any): void {

    Marker.mark('newLeads', [lead.id], 'view-lead');

    const dialog = this.dialog.open(ViewLeadInfoComponent, {
      width: '600px',
      data: Object.assign({}, lead)
    });

    dialog.afterClosed().subscribe(() => {
      Marker.mark('newLeads', [lead.id], 'viewed-lead');
    });
  }

  private setInitial(): void {
    if (this.viewId) {

      this.columnsToDisplay = ['id', 'view'];

      this.columnsToFilter = [];

      this.models = {};

      this.store.dispatch(new ViewsActions.SetData(viewsInitialState));

      document.dispatchEvent(new CustomEvent('toggleSubscription', {
        detail: {
          enable: false,
          stream: 'view'
        }
      }));

      this.view.sort = {};

      this.view.filter = {};

      this.view.page = 0;

      this.viewService.getViewData(this.viewId, Object.assign({
        page: this.view.page,
        reverse: 0,
        add_filter: 1,
        page_size: this.view.page_size,
      }, this.view.sort, this.view.filter)).subscribe((response) => {

        this.viewId = response.id;

        if (response['filters']) {
          response['filter'] = {};
          for (const name in response['filters']) {
            if (response.filters.hasOwnProperty(name)) {
              response.filter['filter[' + name + ']'] = response.filters[name];
            }
          }
        }

        this.store.dispatch(new ViewsActions.SetData(response));

        if (this.view.fields.length > 0) {
          for (let i = 0; i < this.view.fields.length; i++) {
            this.columnsToDisplay.push(this.view.fields[i].name);
            this.columnsToFilter.push(this.view.fields[i].name);
            this.models[this.view.fields[i].name] = '';
            if (response.filters[this.view.fields[i].name]) {
              this.models[this.view.fields[i].name] = response.filters[this.view.fields[i].name];
            }
          }
        }

        this.getAutoComplete();

        setTimeout(() => {
          this.loader = false;
          // this.resizable.init(this.view.id);

          document.dispatchEvent(new CustomEvent('toggleSubscription', {
            detail: {
              enable: true,
              stream: 'view'
            }
          }));
        }, 100);
      }, () => {
        this.loader = false;

        this.notFound = true;
      });
    }
  }

  private getAutoComplete(): void {
    const autocomplete = store.get(`autocomplete_${this.viewId}`);
    if (autocomplete) {
      this.store.dispatch(new FiltersActions.SetFieldsData(autocomplete));
    } else {
      this.viewService.getAutoCompleteValues(this.columnsToDisplay.join()).subscribe((resp) => {
        store.set(`autocomplete_${this.viewId}`, resp);

        this.store.dispatch(new FiltersActions.SetFieldsData(resp));
      });
    }
  }

  private getViewData(): void {
    if (this.viewId) {
      this.toggleLoader();

      document.dispatchEvent(new CustomEvent('toggleSubscription', {
        detail: {
          enable: false,
          stream: 'view'
        }
      }));

      this.viewService.getViewData(this.viewId, Object.assign({
        page: this.view.page,
        page_size: this.view.page_size,
        reverse: this.reverse,
      }, this.view.sort, this.view.filter)).subscribe((response) => {
        this.viewId = response.id;
        this.store.dispatch(new ViewsActions.SetData(response));

        document.dispatchEvent(new CustomEvent('toggleSubscription', {
          detail: {
            enable: true,
            stream: 'view'
          }
        }));
        this.loader = false;
        // this.resizable.init(this.view.id);
      }, () => {
        this.loader = false;
        this.notFound = true;
      });
    }
  }

  public refresh() {
    this.selection.clear();
    this.getViewData();
    this.dataTabs({update: 'true'});
  }


  public getTableWidth(): any {
    const table = document.getElementById('view-table');
    const header = document.getElementById('header-row');
    if (table && header) {
      return {
        'width.px': header.offsetWidth > table.offsetWidth ? header.offsetWidth : table.offsetWidth
      };
    }
    return {};
  }

  public setMegaFilters() {
    // this.selectedGmt = localStorage.getItem('mega_filter_gmt');
    // if (!this.selectedGmt) {
    //   this.selectedGmt = 'all';
    // }
    //
    // this.selectedAge = localStorage.getItem('mega_filter_age');
    // if (!this.selectedAge) {
    //   this.selectedAge = 'all';
    // }
    //
    // this.selectedAttempts = localStorage.getItem('mega_filter_attempts');
    // if (!this.selectedAttempts) {
    //   this.selectedAttempts = 'all';
    // }
    //
    // this.selectedAttemptsAll = localStorage.getItem('attempts_all');
    // if (!this.selectedAttemptsAll) {
    //   this.selectedAttemptsAll = 'All';
    // }
    //
    // if (this.selectedGmt !== 'all') {
    //   this.view.filter['mega_gmt'] = this.selectedGmt;
    // } else {
    //   delete this.view.filter.mega_gmt;
    // }
    //
    // if (this.selectedAge !== 'all') {
    //   this.view.filter['mega_age'] = this.selectedAge;
    // } else {
    //   delete this.view.filter.mega_age;
    // }
    //
    // if (this.selectedAttempts !== 'all') {
    //   this.view.filter['mega_attempts'] = this.selectedAttempts;
    // } else {
    //   delete this.view.filter.mega_attempts;
    // }

    if (this.selectedAttempts !== 'All') {
      this.view.filter['mega_attempts_all'] = this.selectedAttemptsAll;
    } else {
      delete this.view.filter.mega_attempts_all;
      delete this.view.filter.mega_attempts;

      this.selectedAttempts = null;
    }

  }

  public addGmtFilter(filter: string) {
    this.selectedGmt = filter;
    localStorage.setItem('mega_filter_gmt', filter);

    if (filter !== 'all') {
      this.view.filter['mega_gmt'] = filter;
    } else {
      delete this.view.filter.mega_gmt;
    }

    this.getViewData();
  }

  public addAgeFilter(filter: string) {
    this.selectedAge = filter;
    localStorage.setItem('mega_filter_age', filter);

    if (filter !== 'all') {
      this.view.filter['mega_age'] = filter;
    } else {
      delete this.view.filter.mega_age;
    }
    this.getViewData();
  }

  public addAttemptsFilter(filter: string) {
    this.selectedAttempts = filter;
    localStorage.setItem('mega_filter_attempts', filter);

    this.view.filter['mega_attempts'] = filter;

    if (this.selectedAttemptsAll === 'All') {
      this.view.filter['mega_attempts_all'] = 'Today';
      this.selectedAttemptsAll = 'Today';
    }
    this.getViewData();
  }
}
