import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentGoalsComponent} from './agent-goals.component';
import {
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatMenuModule, MatProgressSpinnerModule, MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgentGoalsEditModule} from './agent-goals-edit/agent-goals-edit.module';
import {AgentGoalsEditComponent} from './agent-goals-edit/agent-goals-edit.component';

@NgModule({
    declarations: [AgentGoalsComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatListModule,
        MatDividerModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        AgentGoalsEditModule,
        MatSnackBarModule
    ],
    exports: [AgentGoalsComponent],
    entryComponents: [
        AgentGoalsEditComponent
    ]
})
export class AgentGoalsModule {
}
