import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewStatisticsComponent} from './view-statistics.component';
import {MatButtonModule, MatDialogModule, MatIconModule, MatMenuModule} from '@angular/material';
import {LeadsInfoTableComponent} from '../leads-info-table/leads-info-table.component';
import {LeadsInfoAmchartComponent} from '../leads-info-amchart/leads-info-amchart.component';

@NgModule({
    declarations: [ViewStatisticsComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDialogModule
    ],
    exports: [ViewStatisticsComponent],
    entryComponents: [
        LeadsInfoTableComponent,
        LeadsInfoAmchartComponent
    ]
})
export class ViewStatisticsModule {
}
