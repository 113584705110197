import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  constructor(
      private api: ApiService
  ) {}

  public getBoards(params): Observable<any> {
    return this.api.get(`v1/board/boards`, {params});
  }

  public getBoard(id): Observable<any> {
    return this.api.get(`v1/board/${id}/info`, {});
  }

  public getManageBoards(params?): Observable<any> {
    return this.api.get(`v1/board`, {params});
  }

  public addBoards(body): Observable<any> {
    return this.api.post(`v1/board`, {body});
  }

  public editBoards(id, body): Observable<any> {
    return this.api.post(`v1/board/${id}/update`, {body});
  }

  public deleteBoards(id): Observable<any> {
    return this.api.delete(`v1/board/${id}/delete`, {});
  }

  // Users

  public getUsersForBoard(id, params?): Observable<any> {
    return this.api.get(`v1/board/${id}/user-list`, {params});
  }

  public addUserToBoard(body): Observable<any> {
    return this.api.post(`v1/board/user`, {body});
  }

  public deleteUserFromBoard(body): Observable<any> {
    return this.api.post(`v1/board/user-delete`, {body});
  }
}
