import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import BaseComponent from '../base/base.component';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatDialog, MatSnackBar} from '@angular/material';
import {Observable} from 'rxjs';
import {debounceTime, filter, finalize, map, startWith, takeUntil} from 'rxjs/operators';
import {ViewService} from '../../services/view/view.service';
import {ErrorDialogComponent} from '../general/error-dialog/error-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AgentService} from '../../services/agent/agent.service';
import {Agent} from '../../services/agent/Agent';
import {select, Store} from '@ngrx/store';
import {GetBoardId, GetRoleType} from '../../reducers/selectors/login.selector';
import {AmChartsService} from '@amcharts/amcharts3-angular';
import {Permissions} from '../../helpers/Permissions';

@Component({
  selector: 'app-create-view',
  templateUrl: './create-view.component.html',
  styleUrls: ['./create-view.component.less']
})

export class CreateViewComponent extends BaseComponent implements OnInit {

  @ViewChild('fieldInput') fieldInput: ElementRef;

  public title = 'Create new view';

  public icon = 'view_list';

  public createView: FormGroup;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public filteredFields: Observable<any[]>;

  public conditions: Array<any>;

  public fields: any[] = [];

  public settings: any = {
    fields: [],
    conditions: []
  };

  public viewFields: FormControl;

  public is_default: any = false;

  public in_tab: any = false;

  public in_tab_count: any = false;

  public ai_system: any = false;

  public manage_view: any = false;

  public agents: Array<Agent | null> = [];

  protected dialogFields: string[] = ['view_conditions', 'view_fields'];

  public audienceLoader = false;

  public chartOptions = {
    'type': 'funnel',
    'theme': 'none',
    'dataProvider': [ ],
    'balloon': {
      'fixedPosition': true
    },
    'valueField': 'value',
    'titleField': 'title',
    'marginRight': 240,
    'marginLeft': 50,
    'startX': -500,
    'rotate': false,
    'labelPosition': 'right',
    'balloonText': `<h4>[[label]]</h4><table><tr><th>Field</th><th>Operator</th></tr>[[html]]</table>`,
    'labelText': '[[label]]: [[value]]',
  };

  public audience: any = null;

  constructor(
      protected viewService: ViewService,
      protected dialog: MatDialog,
      protected router: Router,
      protected snackBar: MatSnackBar,
      protected route: ActivatedRoute,
      protected agentService: AgentService,
      protected store: Store<any>,
      protected AmCharts: AmChartsService,
      public permissions: Permissions
  ) {
    super();

    this.createView = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-z0-9- ]{3,100}$/)
      ]),
      is_default: new FormControl(0, [
        Validators.required
      ]),
      in_tab: new FormControl(0, [
        Validators.required
      ]),
      in_tab_count: new FormControl(0, [
        Validators.required
      ]),
      ai_system: new FormControl(0, [
        Validators.required
      ]),
      view_fields: new FormControl('', [
        Validators.required
      ]),
      view_conditions: new FormArray([]),
      type: new FormControl(''),
      board_id: new FormControl(''),
    });

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.createView.get('type').setValue(type);
    });

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.createView.get('board_id').setValue(id);
    });

    this.manage_view = (parseInt(localStorage.getItem('manage_view')) === 1);

    this.viewFields = new FormControl();

    this.initData();
  }

  ngOnInit() {
    this.filteredFields = this.viewFields.valueChanges.pipe(
      startWith(null),
      map((field: any) => (typeof field === 'string') && field.length > 0 ? this.filter(field) : this.settings.fields));
  }


  private getAudience(): void {
    const data = {
      view_conditions: this.createView.get('view_conditions').value,
      type: this.createView.get('type').value,
      board_id: this.createView.get('board_id').value
    };

    this.audienceLoader = true;

    this.viewService.audience(data).pipe(
        finalize(() => this.audienceLoader = false)
    ).subscribe((val: any) => {
      val.result['group_view'] = val.result['group_' + (Object.keys(val.fields).length - 1)];

      this.audience = val;

      const chartData = [];

      Object.keys(val.result).forEach(key => {
        if (val.fields[key]) {
          chartData.push({
            label: val.fields[key].name,
            value: val.result[key],
            html: this.buildLabelHtml(val.fields[key].conditions)
          });
        }
      });

      this.chartOptions.dataProvider = chartData;

      setTimeout(() => {
        this.AmCharts.makeChart('general', this.chartOptions);
      }, 1000);

    });
  }

  protected findFieldByName(name: string): null | any {

    if (!name) {
      return null;
    }

    for (let i = 0; i < this.settings.fields.length; i++) {
      if (name === this.settings.fields[i].name) {
        return this.settings.fields[i];
      }
    }

    return null;
  }

  public getFieldOperatorName(operatorName: string, operators: any[]): string {

    if (!operatorName) {
      return null;
    }

    const operator = operators.find(i => i.name === operatorName);

    if (!operator) {
      return '';
    }

    return operator.label;
  }

  private buildLabelHtml(conditions): string {
    let html = '';

    conditions.forEach(i => {
      const field = this.findFieldByName(i.field_name);

      html += '<tr>';
      html += '<td>' + field.label + '</td>';
      html += '<td>' + this.getFieldOperatorName(i.operator, field.operators) + '</td>';
      // html += '<td>' + i.value + '</td>';
      html += '</tr>';
    });
    return html;
  }

  public fieldRemoved(fieldName: string): void {
    const index = this.findFieldIndex(fieldName);

    if (index !== null) {
      this.fields.splice(index, 1);
      this.createView.get('view_fields').patchValue(this.fields.map((field) => field.name));
    }
  }

  public fieldSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.findFieldIndex(event.option.value.name) === null) {
      this.fields.push(event.option.value);
      this.createView.get('view_fields').patchValue(this.fields.map((field) => field.name));
      this.fieldInput.nativeElement.value = '';
      this.viewFields.setValue('');
    }
  }

  public setDefault(event): void {
    this.createView.get('is_default').patchValue(event.checked ? 1 : 0);
  }

  public setTopList(event): void {
    this.createView.get('in_tab').patchValue(event.checked ? 1 : 0);
  }

  public setCountVisible(event): void {
    this.createView.get('in_tab_count').patchValue(event.checked ? 1 : 0);
  }

  public setAiSystem(event): void {
    this.createView.get('ai_system').patchValue(event.checked ? 1 : 0);
  }

  public saveView(event): void {
    event.preventDefault();

    if (!this.createView.get('view_fields').valid) {
      this.dialog.open(ErrorDialogComponent, {
        width: '250px',
        data: {
          title: 'Validation error',
          message: 'Select at least one field!'
        }
      });
    }

    if (!this.createView.valid) {
      return null;
    }

    this.toggleLoader();
    this.handleSave();
  }

  public getVewConditions(): FormGroup {
    return this.createView.get('view_conditions') as FormGroup;
  }

  public dropFiled(event, dropIndex): void {

    const dragIndex = parseInt(event.dataTransfer.getData('field'), 10);

    const dragField = Object.assign({}, this.fields[dragIndex]);

    const dropField = Object.assign({}, this.fields[dropIndex]);

    if ((dragIndex + 1) === dropIndex) {

      this.fields[dropIndex] = dragField;

      this.fields[dragIndex] = dropField;

    } else {

      delete this.fields[dragIndex];

      this.fields.splice(dropIndex, 0, dragField);

      this.fields = this.fields.filter((item) => {
        return item;
      });

    }

    this.createView.get('view_fields').patchValue(this.fields.map((item) => item.name));
  }

  public dragOver(event): void {
    event.preventDefault();
  }

  public dragStart(event, index): void {
    event.dataTransfer.setData('field', index);
  }

  protected initData(): void {
    this.toggleLoader();

    this.getAgents();

    this.viewService.getSettings().subscribe((response) => {
      this.settings = response;
      this.toggleLoader();
    });
  }

  protected getAgents(): void {
    this.store.pipe(select((state) => {
      return state.agents.allAgentsNew;
    })).subscribe((res) => {
      this.agents = res;
    });
  }

  protected handleSave(): void {
    this.viewService.createView(this.createView.value).subscribe((res) => {
      this.router.navigate([`/dashboard/${res.id}`]);

      this.snackBar.open('New view have been created!', 'Dismiss', {
        duration: 3000,
      });
      this.toggleLoader();
    }, this.handleErrors.bind(this));
  }

  protected handleErrors(error): void {
    for (const name in error.error) {
      if (error.error.hasOwnProperty(name)) {
        const input = this.createView.get(name);
        if (this.dialogFields.includes(name)) {
          this.dialog.open(ErrorDialogComponent, {
            width: '250px',
            data: {
              title: 'Validation error',
              message: error.error[name]
            }
          });
        } else if (input) {
          input.setErrors({backend: error.error[name]});
        }
        this.toggleLoader();
      }
    }
  }

  protected findFieldIndex(name: string): null | number {

    for (let i = 0; i < this.fields.length; i++) {
      if (name === this.fields[i].name) {
        return i;
      }
    }

    return null;
  }

  protected filter(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.settings.fields.filter(field => field.label.toLowerCase().indexOf(filterValue) === 0);
  }
}
