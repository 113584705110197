import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountryOfResidenceComponent} from './country-of-residence.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        CountryOfResidenceComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [
        CountryOfResidenceComponent,
    ],

})
export class CountryOfResidenceModule {
}
