import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreaterThanOrEqualToTimeComponent } from './greater-than-or-equal-to-time.component';
import {MatDatepickerModule, MatIconModule, MatInputModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {OwlDateTimeModule} from 'ng-pick-datetime';

@NgModule({
    declarations: [GreaterThanOrEqualToTimeComponent],
    exports: [
        GreaterThanOrEqualToTimeComponent
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        FormsModule,
        MatInputModule,
        OwlDateTimeModule,
        MatIconModule
    ]
})
export class GreaterThanOrEqualToTimeModule { }
