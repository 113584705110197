import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReshuffleModalComponent} from './reshuffle-modal.component';
import {DatePeriodModule} from '../../filters/date-period/date-period.module';
import {MatExpansionModule, MatSelectModule} from '@angular/material';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {AgentTableModule} from '../../assign-suggestion/suggestion-table/agent-table/agent-table.module';
import {MatTabsModule} from '@angular/material/tabs';
import {LoaderModule} from '../../general/loader/loader.module';
import {AutocompleteModule} from '../../filters/autocomplete/autocomplete.module';
import {AssignedToModule} from '../../filters/assigned-to/assigned-to.module';
import {DateRangeSelectModule} from '../../filters/date-range-select/date-range-select.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ProgressLoaderModule} from '../../general/progress-loader/progress-loader.module';
import {RolesModule} from '../../reassign-progress/reassign-progress.module';


@NgModule({
    declarations: [
        ReshuffleModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,

        DatePeriodModule,
        MatSelectModule,
        NgSelectModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatButtonModule,

        AgentTableModule,
        MatTabsModule,
        LoaderModule,
        AutocompleteModule,
        AssignedToModule,
        MatFormFieldModule,
        DateRangeSelectModule,
        MatInputModule,
        MatToolbarModule,
        ProgressLoaderModule,
        RolesModule

    ],
    exports: [
        ReshuffleModalComponent
    ]
})
export class ReshuffleModalModule {
}
