import {Component, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../../base/base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {ActivatedRoute} from '@angular/router';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-gtm-countries',
  templateUrl: './gtm-countries.component.html',
  styleUrls: ['./gtm-countries.component.less']
})
export class GtmCountriesComponent extends BaseComponent implements OnInit, OnDestroy {

  public ngClasses: any = {
    active: false
  };

  public subscribers: any = {
    settings: null
  };

  public loader: any = false;

  public form: FormGroup;

  public all_countries: any = [
    'Kraine',
    'Kenia'
  ];

  public low_countries: any = [];

  public mid_countries: any = [];

  public high_countries: any = [];

  constructor(private route: ActivatedRoute,
              private service: AssignSettingService,
              private snack: MatSnackBar) {
    super();
    this.loader = true;

    this.subscribers.settings = this.service.getAssignSetting({
      'setting': 'gmt_countries,countries'
    }).subscribe((res) => {
      if (res && res.gmt_countries) {
        if (res.gmt_countries.high) {
          this.high_countries = res.gmt_countries.high;
        }
        if (res.gmt_countries.mid) {
          this.mid_countries = res.gmt_countries.mid;
        }
        if (res.gmt_countries.low) {
          this.low_countries = res.gmt_countries.low;
        }
      }
      if (res && res.countries) {
        this.all_countries = Object.values(res.countries);

        this.all_countries = this.all_countries.filter(el => !this.high_countries.includes(el) && !this.mid_countries.includes(el) && ! this.low_countries.includes(el))
      }
      this.loader = false;
    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      this.loader = false;
    });
  }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.saveSettings();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  public saveSettings() {
    this.loader = true;
    const body = {
      setting_slug: 'gmt_countries',
      setting: {
        high: this.high_countries,
        mid: this.mid_countries,
        low: this.low_countries,
      },
    };
    this.loader = false;

    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('Updated', 'Ok!', {
        duration: 1000,
      });
    }, err => {
      this.snack.open('Something Went Wrong', 'Ok!', {
        duration: 1000,
      });
    });
  }

}
