import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GoalsService {

    constructor(
        private api: ApiService
    ) {
    }

    public get(): Observable<any> {
        return this.api.get('v1/agent-goals', {});
    }

    public create(id, params): Observable<any> {
        return this.api.post('v1/agent-goals/' + id + '/create', {
            body: params
        });
    }

    public update(id, params): Observable<any> {
        return this.api.post('v1/agent-goals/' + id + '/update', {
            body: params,
        });
    }
}
