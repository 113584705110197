import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.less']
})

export class ErrorDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public self: MatDialogRef<any>,
        public router: Router
    ) {
        if (this.data && this.data['redirect']) {
            this.self.beforeClose().subscribe(() => {
                this.router.navigate([this.data.redirect]).then(() => {});
            });
        }
    }

    ngOnInit() {}

}
