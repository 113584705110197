import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {View} from '../../services/view/View';
import {ViewService} from '../../services/view/view.service';
import BaseComponent from '../base/base.component';

@Component({
    selector: 'app-delete-view',
    templateUrl: './delete-view.component.html',
    styleUrls: ['./delete-view.component.less']
})

export class DeleteViewComponent extends BaseComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public view: View,
        private self: MatDialogRef<any>,
        private viewService: ViewService,
        private snackBar: MatSnackBar
    ) {
        super();
    }

    ngOnInit() {}

    deleteView() {
        this.toggleLoader();
        this.viewService.deleteView(this.view.id).subscribe(() => {
            this.self.close('Updated!');
            this.snackBar.open('View have been deleted!', 'Dismiss', {
                duration: 3000,
            });
            this.toggleLoader();
        }, () => {
            this.self.close();
            this.snackBar.open('There was an error contact administration!', 'Dismiss', {
                duration: 3000,
            });
            this.hideLoader();
        });
    }
}
