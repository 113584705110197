import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import {Unsubscribe} from '../../../classes/unsubscribe';

const datePeriod = /^[ ]*([0-9]+[ymwdhis])([ ][0-9]+[ymwdhis])*[ ]*$/i;

@Component({
  selector: 'app-date-period',
  templateUrl: './date-period.component.html',
  styleUrls: ['./date-period.component.scss'],
  providers: [
      {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePeriodComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePeriodComponent),
      multi: true,
    }
  ]
})
export class DatePeriodComponent extends Unsubscribe implements OnInit, Validator {

  public periodControl: FormControl = new FormControl('', [Validators.pattern(datePeriod)]);

  @Input() set errors(err) {
    if (err) {
      this.periodControl.setErrors(err);
    }
  }

  @Input() set required(val) {
    if (val) {
      const validators = [];

      validators.push(Validators.required);

      if (this.periodControl.validator) {
        validators.push(this.periodControl.validator);
      }

      this.periodControl.setValidators(validators);
    }
  }

  @Input() set disabled(val: boolean) {
    val ? this.periodControl.disable() : this.periodControl.enable();
  }

  @Input() set availablePeriods(val) {
    const validators = [];

    if (val) {
      validators.push(this.datePeriod(val));
    }

    if (this.periodControl.validator) {
      validators.push(this.periodControl.validator);
    }

    this.periodControl.setValidators(validators);

    if (val && val.length > 0) {
      this.warnTooltip = val.length > 1 ? 'You need to set date in the format 1y 10m' : 'You need to set date in the format 1y';
      this.tooltip = val.length > 1 ? 'Use the format: 1y 10m' : 'Use the format: 1y';
      this.periods = val.length > 1 ? ['y = years', 'm = months'] : ['y = years'];
    } else {
      this.warnTooltip = 'You need to set date in the format 3w 20d 5h 21m';
      this.tooltip = 'Use the format: 3w 20d 5h 21m';
      this.periods = [
        'y = years',
        'm = months',
        'w = weeks',
        'd = days',
        'h = hours',
        'i = minutes',
        's = second',
      ];
    }
  }

  @Input() label: string;

  @Input() returnSeconds = false;

  @Input() returnAnyValue: boolean;

  @Input() tooltipPosition: {
    top?: string,
    right?: string,
    bottom?: string
    left?: string
  };

  public warnTooltip: string;
  public tooltip: string;
  public periods: string[];

  private onChange: any = (value: []) => {};
  private onTouched: any = (value: []) => {};

  ngOnInit(): void {}

  public datePeriod(datePeriods?: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value ? control.value.trim() : '';

      if (value === '') {
        return null;
      }

      if (!datePeriod.test(value)) {
        return { period: true };
      }

      const periods = datePeriods && (datePeriods.length > 0) ? datePeriods : ['y', 'm', 'w', 'd', 'h', 'i', 's'];
      const parts = value.trim().split(' ');

      if (periods.length === 1) {
        return parts.length === 1 && parts[0].at(-1) === periods[0] ? null : { period: true };
      }

      for (const part of parts) {
        const char = part[part.length - 1];

        if (!periods.includes(char)) {
          return { period: true };
        }

        periods.splice(0, periods.findIndex(period => period === char) + 1);
      }
    };
  }

  private convertToSeconds(value): number {
    const times = value.split(' ');
    let vInt = 0;

    times.map(t => {
      if (t.includes('y')) {
        t = t.replace('y', '');
        const y = parseInt(t, 10);

        vInt += y * 60 * 60 * 24 * 365;
      } else if (t.includes('m')) {
        t = t.replace('m', '');
        const m = parseInt(t, 10);

        vInt += m * 60 * 60 * 24 * 30;
      } else if (t.includes('w')) {
        t = t.replace('w', '');
        const w = parseInt(t, 10);

        vInt += w * 60 * 60 * 24 * 7;
      } else if (t.includes('d')) {
        t = t.replace('d', '');
        const d = parseInt(t, 10);

        vInt += d * 60 * 60 * 24;
      } else if (t.includes('h')) {
        t = t.replace('h', '');
        const h = parseInt(t, 10);

        vInt += h * 60 * 60;
      } else if (t.includes('i')) {
        t = t.replace('i', '');
        const i = parseInt(t, 10);

        vInt += i * 60;
      } else if (t.includes('s')) {
        t = t.replace('s', '');
        const s = parseInt(t, 10);

        vInt += s;
      }
    });

    return vInt;
  }

  private convertToPeriod(value): string {
    value = Number(value);

    const y = Math.trunc(value / 31536000);
    const m = Math.trunc(value % 31536000 / 2592000);
    const w = Math.trunc(value % 31536000 % 2592000 / 604800);
    const d = Math.trunc(value % 31536000 % 2592000 % 604800 / 86400);
    const h = Math.trunc(value % 31536000 % 2592000 % 604800 % 86400 / 3600);
    const i = Math.trunc(value % 31536000 % 2592000 % 604800 % 86400 % 3600 / 60);
    const s = Math.trunc(value % 31536000 % 2592000 % 604800 % 86400 % 3600 % 60);

    const yDisplay = y > 0 ? y + 'y ' : '';
    const mDisplay = m > 0 ? m + 'm ' : '';
    const wDisplay = w > 0 ? w + 'w ' : '';
    const dDisplay = d > 0 ? d + 'd ' : '';
    const hDisplay = h > 0 ? h + 'h ' : '';
    const iDisplay = i > 0 ? i + 'i ' : '';
    const sDisplay = s > 0 ? s + 's ' : '';

    return  yDisplay + mDisplay + wDisplay + dDisplay + hDisplay + iDisplay + sDisplay;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.periodControl.invalid ? {invalid: true} : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (this.returnSeconds) {
      this.periodControl.setValue(this.convertToPeriod(value));
    } else {
      this.periodControl.setValue(value);
    }

    this.periodControl.valueChanges.pipe(
      takeUntil(this.destroy),
      distinctUntilChanged()
    ).subscribe(val => {
      if (this.returnSeconds) {
        this.onChange(this.convertToSeconds(val));
        this.onTouched();
      } else if (this.returnAnyValue || !this.periodControl.invalid) {
        this.onChange(val && !Array.isArray(val) ? val.trim() : val);
        this.onTouched();
      }
    });
  }
}
