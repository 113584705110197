import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignComponent} from './assign.component';
import {MatButtonModule, MatDividerModule, MatIconModule, MatListModule} from '@angular/material';

@NgModule({
    declarations: [AssignComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatDividerModule
    ],
    exports: [AssignComponent]
})
export class AssignModule {
}
