import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountryOfBirthComponent} from './country-of-birth.component';
import {MultipleSelectorComponent} from '../../general/multiple-selector/multiple-selector.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        CountryOfBirthComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [
        CountryOfBirthComponent,
    ],
    entryComponents: [
        MultipleSelectorComponent,
    ]
})
export class CountryOfBirthModule {
}
