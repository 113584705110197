import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalSettingsComponent} from './personal-settings.component';
import {MatDialogModule, MatDividerModule, MatIconModule, MatListModule, MatSlideToggleModule} from '@angular/material';

@NgModule({
    declarations: [PersonalSettingsComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatDividerModule
    ],
    exports: [
        PersonalSettingsComponent,
    ]
})
export class PersonalSettingsModule {
}
