import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './header.component';
import {
    MatButtonModule, MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule, MatSnackBarModule,
    MatToolbarModule
} from '@angular/material';
import {DataPicketCampaignsComponent} from '../../data-picket-campaigns/data-picket-campaigns.component';
import {ErrorDialogComponent} from '../error-dialog/error-dialog.component';
import {PersonalSettingsModule} from '../../personal-settings/personal-settings.module';
import {PersonalSettingsComponent} from '../../personal-settings/personal-settings.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        PersonalSettingsModule,
        MatSlideToggleModule,
    ],
    exports: [
        HeaderComponent
    ],
    entryComponents: [
        DataPicketCampaignsComponent,
        ErrorDialogComponent,
        PersonalSettingsComponent
    ]
})

export class HeaderModule {
}
