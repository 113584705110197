import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadsInfoTableComponent} from './leads-info-table.component';
import {LoaderModule} from '../general/loader/loader.module';
import {
    MatButtonModule,
    MatCardModule, MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule, MatSortModule,
    MatTableModule
} from '@angular/material';
import {DataPicketCampaignsModule} from '../data-picket-campaigns/data-picket-campaigns.module';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';

@NgModule({
    declarations: [
        LeadsInfoTableComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        MatCardModule,
        MatExpansionModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        MatFormFieldModule,
        DataPicketCampaignsModule,
        MatMenuModule,
        MatChipsModule,
        MatSortModule
    ],
    exports: [
        LeadsInfoTableComponent
    ],
    entryComponents: [
        DataPicketCampaignsComponent
    ]
})
export class LeadsInfoTableModule {
}
