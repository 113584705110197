import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AmChartsService} from '@amcharts/amcharts3-angular';
import {AgentService} from '../../../services/agent/agent.service';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {filter, takeUntil} from 'rxjs/operators';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTabGroup, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {select, Store} from '@ngrx/store';
import {GetActiveBoard, GetRoleType} from '../../../reducers/selectors/login.selector';
import {TYPE_APPLICATIONS, TYPE_LEADS} from '../../../constants/user';
import {Agent} from '../../../services/agent/Agent';
import {LEADS_SECTION_TYPES, RETENTION_SECTION_TYPES, WIZARD_TYPES, WIZARD_TYPES_FILTER} from '../../../helpers/constants';
import * as FiltersActions from '../../../actions/filters-actions';
import {ViewService} from '../../../services/view/view.service';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {ReshuffleModalComponent} from '../reshuffle-modal/reshuffle-modal.component';
import {Permissions} from '../../../helpers/Permissions';

@Component({
    selector: 'app-loads-table',
    templateUrl: './loads-table.component.html',
    styleUrls: ['./loads-table.component.less']
})
export class LoadsTableComponent extends Unsubscribe implements OnInit, OnDestroy {

    @Input() type = '';

    @Input() set agentIds(val) {
        if (val) {
            this._agentIds = val;

            this.filterData = {
                assigned_to: this._agentIds.join(','),
                type: this.type
            };

            setTimeout(() => {
                this.getLeads();
            }, 700);
        }
    }

    public _agentIds: number[];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChild(MatTabGroup) tabs: MatTabGroup;

    public leadsTypes = [];

    public chart = null;

    public allAgents = [];

    public loader = false;

    public filterData: any = {};

    public dataSource: MatTableDataSource<any> = new MatTableDataSource();

    public assignLeadsCount: number = null;
    public assignLoader = false;

    public columnsToDisplay: string[] = [
        'id',
        'lead_number',
        'full_name',
        'called_today',
        // 'crm_id',
        'type',
        'assign_time',
        'assigned_to',
        'country_of_birth',
        'country_of_residence',
    ];

    public selection = new SelectionModel<any>(true, []);

    public leads = null;

    public roleType: number;

    public reassignAllAgent = null;

    public statusChart: any = {
        'type': 'pie',
        'theme': 'light',
        'dataProvider': [],
        'valueField': 'value',
        'titleField': 'country',
        'outlineAlpha': 0.4,
        'balloonText': '[[title]]<br><span style="font-size:16px"><b>[[value]]</b> ([[percents]]%)</span>',
        'marginRight': 0,
        'groupPercent': 5,
        'marginLeft': 0,
        'marginTop': 0,
        'marginBottom': 0,
        'autoMarginOffset': 0,
    };

    constructor(
        private amCharts: AmChartsService,
        private agentService: AgentService,
        private viewService: ViewService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public permissions: Permissions,
        private store: Store<any>
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.roleType = type;

            if (this.roleType === TYPE_APPLICATIONS) {
                this.columnsToDisplay.splice(4, 0, 'program');
            }
        });

        this.store.pipe(
            select(GetActiveBoard),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((board: any) => {
            const types = WIZARD_TYPES_FILTER[board.wizard_type];

            this.leadsTypes = [{label: 'All', value: ''}, ...types];
        });

        this.viewService.getAutoCompleteValues(this.columnsToDisplay.join()).pipe(
            takeUntil(this.destroy)
        ).subscribe((resp) => {
            this.store.dispatch(new FiltersActions.SetFieldsData(resp));
        });

        this.store.pipe(
            select((state) => {
                return state.agents.allAgentsNew;
            }),
            takeUntil(this.destroy)
        ).subscribe((res) => {
            this.allAgents = res;
            const agents = res;
            if (agents.length > 0 && !this.reassignAllAgent) {
                for (let i = 0; i < agents.length; i++) {
                    if (this.roleType === TYPE_LEADS) {
                        if (agents[i].crm_id === Agent.ADMIN_ID) {
                            this.reassignAllAgent = agents[i];
                            break;
                        }
                    } else {
                        if (agents[i].crm_id === Agent.RETENTION_REASSIGN) {
                            this.reassignAllAgent = agents[i];
                            break;
                        }
                    }
                }
            }
        });
    }

    ngOnInit() {
        // this.getLeads();
    }

    ngOnDestroy() {
        if (this.chart) {
            this.amCharts.destroyChart(this.chart);
        }
    }

    private getLeads(): void {
        this.loader = true;

        this.agentService.getLeadInfo({
            agent_ids: this._agentIds,
            type: this.roleType,
            action_type: this.type || ''
        }).pipe(
            takeUntil(this.destroy)
        ).subscribe((res) => {
            this.loader = false;

            this.leads = res;

            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            if (!res.entities.type) {
                this.dataSource.data = res.entities;

                this.filterChange();
            }

            this.initChart();
        }, () => {
            this.loader = false;
        });
    }

    public refresh(): void {
        this.getLeads();
    }

    public filterChange(): void {
      let data = this.leads.entities;

      if (data.type) {
          return;
      }

      Object.keys(this.filterData).forEach(key => {
        if (key === 'assign_time') {

          const time = this.filterData[key].split(' ');

          if (time.length > 1) {
            const endDate = new Date(time[1]).setUTCHours(23, 59, 59, 999);
            const startDate = new Date(time[0]);
            data = data.filter(lead => {
              return new Date(lead[key] * 1000) >= new Date(startDate) && new Date(lead[key] * 1000) <= new Date(endDate);
            });
          }

        } else if (
          key === 'assigned_to' ||
          key === 'country_of_birth' ||
          key === 'country_of_residence' ||
          key === 'program'
        ) {
          const val = this.filterData[key].split(',');

          if (val.length && val[0]) {
            data = data.filter(lead => val.includes(lead[key].toString()));
          }
        } else if (key === 'called_today') {
            if (this.filterData[key] !== null) {
                data = data.filter(lead => lead[key] === this.filterData[key]);
            }
        } else {
          const value = this.filterData[key].trim().toLowerCase();
          data = data.filter(lead => lead[key].toLowerCase().indexOf(value) !== -1);
        }
      });

      this.dataSource.data = data;

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    public selectionChange(row?): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }

        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    public masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(lead => this.selection.select(lead));
    }

    public isAllSelected(): boolean {
        return this.selection.selected.length === this.dataSource.data.length;
    }

    public getAgent(lead): void {
        const agent = this.allAgents.find(ag => ag.crm_id === lead.assigned_to);
        return Object.assign({}, lead, {agent: agent});
    }

    public assignLead(): void {
        const userId = this.selection.selected.map(i => i.id);

        if (userId.length) {
            this.assignLeadsCount = userId.length;
            this.assignLoader = true;
            this.agentService.assignLead(this.reassignAllAgent.id, userId, 'Assign AI System').pipe(
                takeUntil(this.destroy)
            ).subscribe(() => {
                this.assignLoader = false;
                this.snackBar.open('New lead(s) was assigned to \'' + this.reassignAllAgent.crm_username + '\'!', 'Dismiss', {
                    duration: 3000,
                });
            });
        } else {
              this.snackBar.open('You need to select at least one agent.', 'Ok', {
                  duration: 3000,
              });
        }
    }

    public assignAll(): void {
        this.dialog.open(ConfirmModalComponent, {
            width: '400px',
            hasBackdrop: true,
            data: {
                title: 'Reassign All',
                description: `Are you sure you want to reassign all leads?`
            }
        }).afterClosed().subscribe((result) => {
            const userId = this.dataSource.data.map(lead => lead.id);
            this.assignLeadsCount = userId.length;
            this.assignLoader = true;
            this.agentService.assignLead(this.reassignAllAgent.id, userId, 'Assign AI System').pipe(
                takeUntil(this.destroy)
            ).subscribe(() => {
                this.assignLoader = false;
                this.snackBar.open('New lead(s) was assigned to \'' + this.reassignAllAgent.crm_username + '\'!', 'Dismiss', {
                    duration: 3000,
                });
            });
        });
    }

    public reshuffle(): void {
        this.dialog.open(ReshuffleModalComponent, {
            width: '1300px',
            hasBackdrop: true,
            data: {
                agents: this._agentIds,
                type: this.type,
                leads: this.leads.entities,
                filters: this.filterData
            }
        }).afterClosed().subscribe((result) => {

        });
    }

    public selectedIndex(event): void {
        setTimeout(() => {
            this.initChart();
        }, 700);
    }

    public initChart(): void {
        if (this.tabs.selectedIndex === 1) {
            const countries = {};

            this.dataSource.data.forEach(lead => {
                if (!countries[lead.country_of_residence]) {
                    countries[lead.country_of_residence] = {
                        country: lead.country_of_residence,
                        value: 1
                    };
                } else {
                    countries[lead.country_of_residence].value += 1;
                }
            });

            const data = Object.keys(countries).map(key => countries[key]);

            this.statusChart.dataProvider = data;
            this.chart = this.amCharts.makeChart('countries-chart', this.statusChart);

            // if (!this.chart) {
            //
            //     this.statusChart.dataProvider = data;
            //     this.chart = this.amCharts.makeChart('countries-chart', this.statusChart);
            //
            // } else {
            //
            //     this.amCharts.updateChart(this.chart, () => {
            //         this.chart.dataProvider = data;
            //     });
            //
            // }
        }
    }
}
