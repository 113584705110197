import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignReportComponent} from './assign-report.component';
import {
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {FreshChartModule} from './fresh-chart/fresh-chart.module';
import {ContactChartModule} from './contact-chart/contact-chart.module';

@NgModule({
    declarations: [
        AssignReportComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatChipsModule,
        LoaderModule,
        AmChartsModule,
        MatButtonModule,
        MatToolbarModule,
        MatDividerModule,
        FreshChartModule,
        ContactChartModule


    ],
    exports: [
        AssignReportComponent
    ]
})
export class AssignReportModule {
}
