import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { JsonFieldComponent } from './json-field.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
    declarations: [
        JsonFieldComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: [
        JsonFieldComponent
    ]
})

export class JsonFieldModule {
}
