import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {LeadService} from '../../services/lead/lead.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import {AssignSuggestionService} from '../../services/assign-suggestion/assign-suggestion.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-view-country-info',
    templateUrl: './view-country-info.component.html',
    styleUrls: ['./view-country-info.component.less']
})

export class ViewCountryInfoComponent extends BaseComponent implements OnInit, OnDestroy {

    public loader: any = false;

    public leadData = [];

    public showData: any = false;

    public subscriber: Subscription;

    public listener: any;

    public title: any;

    public showErrorMess: any = true;

    public text: string = 'Please check Shift or Agents, you must to select some data!';

    constructor(
        private leadService: LeadService,
        private assignSuggestionService: AssignSuggestionService,
        @Inject(MAT_DIALOG_DATA) public lead: any,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        document.addEventListener('click', this.listener = () => {
            this.cdr.detectChanges();
        });

        if (this.lead.shifts.length > 0 || this.lead.agents.length > 0) {
            this.showErrorMess = false;
            this.loader = true;

            this.subscriber = this.assignSuggestionService.getCountries(this.lead).subscribe((res) => {
                const data = res;
                const arr = [];

                for (let key in data) {
                    arr.push(data[key]);
                }

                if (this.lead.list === 'freshLeads') {
                    this.title = 'Number of Suggestion Fresh:';
                } else if (this.lead.list === 'reassignedLeads') {
                    this.title = 'Number of Suggestion Reassign:';
                }

                this.leadData = arr;
                this.loader = false;
                this.showData = true;
                this.cdr.detectChanges();
            });
        }
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
        if (this.listener) {
            document.removeEventListener('click', this.listener);
        }
    }
}
