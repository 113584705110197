import {Component, Inject, OnInit} from '@angular/core';
import {Agent} from '../../services/agent/Agent';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {getAvatar} from '../../helpers/Avagar';

@Component({
    selector: 'app-agents-sheet',
    templateUrl: './agents-sheet.component.html',
    styleUrls: ['./agents-sheet.component.less']
})

export class AgentsSheetComponent implements OnInit {

    public getAvatar = getAvatar;

    public readonly shifts: any = {
        day: Agent.DAY_SHIFT,
        night: Agent.NIGHT_SHIFT,
    };

    public agents: Agent[] = [];

    public selected: any;

    public multiple: any = false;

    public newSelected: Agent = null;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
                private self: MatBottomSheetRef<AgentsSheetComponent>) {
        this.agents = this.data.agents;
        this.selected = this.data.selected;
        this.multiple = this.data.multiple;
        this.self.backdropClick().subscribe(() => {
            if (!this.multiple) {
                this.self.dismiss(this.newSelected);
            } else {
                this.self.dismiss(this.selected);
            }
        });
    }

    ngOnInit() {
        this.agents.map((agent: Agent, index) => {
            agent['is_hidden'] = false;
        });
    }

    public selectAgent(agent: Agent): void {
        if (this.multiple) {
            const index = this.getIndex(agent);

            if (index === -1) {
                this.selected.push(agent);
            } else {
                this.selected.splice(index, 1);
            }
        } else {
            this.newSelected = agent;
            this.self.dismiss(this.newSelected);
        }
    }

    public getIsActive(agent: Agent): any {
        if (this.multiple) {
            return this.getIndex(agent) >= 0;
        }
        return this.selected ? this.getId(this.selected.id) === this.getId(agent.id) : false;
    }

    public sortAgents(): void {

        const selected = this.multiple ? this.selected.map((agent: Agent) => {
            return agent.id;
        }) : [this.selected.id];

        if (selected.length > 0) {
            this.agents.sort((a, b) => {

                if (selected.includes(a.id) && !selected.includes(b.id)) {
                    return -1;
                }

                if (!selected.includes(a.id) && selected.includes(b.id)) {
                    return 1;
                }

                return 0;
            });
        }
    }

    public searchInput(event): void {
        this.agents.map((agent: Agent, index) => {
            agent['is_hidden'] = !!agent.crm_username.toLowerCase().search(event.target.value.toLowerCase());
        });
    }

    public clearAgents(): void {
        this.selected = this.multiple ? [] : null;
    }

    public shiftAgents(shift): void {

        this.clearAgents();

        for (const agent of this.agents) {
            if (agent.shift === shift && agent.hidden !== Agent.HIDDEN) {
                this.selectAgent(agent);
            }
        }

        this.sortAgents();
    }

    private getIndex(agent: Agent) {
        return this.selected.findIndex((x: Agent) => {
            return x.id === agent.id;
        });
    }

    private getId(id: any): number {
        if (typeof id !== 'number') {
            return parseInt(id, 10);
        }
        return id;
    }

}
