export const MAIN_ROLE = [
    {
        label: 'Conversion',
        value: 0
    },
    {
        label: 'Retention',
        value: 1
    },
    {
        label: 'Admin',
        value: 2
    }
];

export const TYPE_CONVERSION = 0;
export const TYPE_RETENTION = 1;
export const TYPE_ADMIN = 2;

export const TYPE_LEADS = 1;
export const TYPE_APPLICATIONS = 2;

export enum TYPES_ROLE {
    'Conversion' = TYPE_LEADS,
    'Retention' = TYPE_APPLICATIONS,
}

export const AGENT_MAIN_ROLE = [
    {
        label: 'Conversion',
        value: TYPE_LEADS
    },
    {
        label: 'Retention',
        value: TYPE_APPLICATIONS
    }
];

export const ROLE_TYPES = {
    [TYPE_CONVERSION]: TYPE_LEADS,
    [TYPE_RETENTION]: TYPE_APPLICATIONS,
};

export const AI_TYPE = {
    CONVERSION: 1,
    RETENTION: 2,
    WHV: 3,
    INTERVIEW: 4
};

export const AI_TYPES = [
    {
        label: 'Conversion',
        value: AI_TYPE.CONVERSION
    },
    {
        label: 'Retention',
        value: AI_TYPE.RETENTION
    },
    {
        label: 'Working Holiday',
        value: AI_TYPE.WHV
    },
    {
        label: 'Interview',
        value: AI_TYPE.INTERVIEW
    }
];



