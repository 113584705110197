import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentsComponent} from './agents.component';
import {
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule, MatTableModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {CallActivityModule} from '../call-activity/call-activity.module';
import {AgentListItemComponent} from "../agent-list-item/agent-list-item.component";
import {RouterModule} from "@angular/router";
import {ProgressColorPipeModule} from '../../pipes/progress-color/progress-color.pipe.module';

@NgModule({
    declarations: [
        AgentsComponent,
        AgentListItemComponent
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        LoaderModule,
        MatProgressBarModule,
        MatTooltipModule,
        CallActivityModule,
        MatExpansionModule,
        MatTableModule,
        RouterModule,
        ProgressColorPipeModule
    ],
    exports: [
        AgentsComponent
    ],

})

export class AgentsModule {
}
