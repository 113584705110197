import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {ShiftsService} from '../../../services/shifts/shifts.service';
import {select, Store} from '@ngrx/store';
import {GetRoleType} from '../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../../classes/unsubscribe';

@Component({
  selector: 'app-agents-filter',
  templateUrl: './agents-filter.component.html',
  styleUrls: ['./agents-filter.component.less']
})
export class AgentsFilterComponent extends Unsubscribe implements OnInit {

  @Output() apply: EventEmitter<any> = new EventEmitter();

  @Input() set agentIds(val) {
    this.selectedAgentIds = val;

    if (this.filterForm) {
      this.filterForm.get('agents').setValue(this.selectedAgentIds);
    }
  }

  public shifts = [];

  public agents = [];

  public roleType: number;

  public filterForm: FormGroup;

  public selectedAgentIds: number[] = [];

  constructor(
      private shiftsService: ShiftsService,
      private snackBar: MatSnackBar,
      private store: Store<any>
  ) {
    super();

    this.store.pipe(
        select(GetRoleType),
        filter(res => !!res),
        takeUntil(this.destroy)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.store.pipe(
        select('agents'),
        takeUntil(this.destroy)
    ).subscribe((res) => {
      this.agents = res.allAgents;
    });
  }

  ngOnInit() {
    // this.selectedAgentIds = this.agentIds;

    this.filterForm = new FormGroup({
      agents: new FormControl(this.selectedAgentIds || []),
      shifts: new FormControl([])
    });

    this.getShifts();
  }

  public getShifts() {
    this.shiftsService.getShifts({
      'filter[type]': this.roleType
    }).subscribe((res) => {
      this.shifts = res.data.filter(shift => shift.active === true);
    });
  }

  public onShiftsChange(evt: any): void {
    const agents = [];

    this.shifts.forEach(shift => {
      if (evt.includes(shift.id)) {
        shift.agents.forEach(agent => agents.push(agent.agent_crm_id));
      }
    });

    this.filterForm.get('agents').patchValue(agents);
  }

  public submit(): void {
    if (this.filterForm.getRawValue().agents.length) {

      this.apply.emit(this.filterForm.getRawValue().agents);

    } else {
      this.filterForm.get('agents').markAsTouched();
      this.filterForm.get('agents').markAsDirty();

      this.snackBar.open('You need to select at least one agent.', 'Ok', {
        duration: 3000,
      });
    }
  }

}
