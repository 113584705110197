import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentGoalsEditComponent} from './agent-goals-edit.component';
import {MatButtonModule, MatDialogModule, MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [AgentGoalsEditComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule
    ],
    exports: [
        AgentGoalsEditComponent
    ]
})
export class AgentGoalsEditModule {
}
