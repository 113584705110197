import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHuman'
})
export class SecondsToHumanPipe implements PipeTransform {

  transform(seconds: number): string {
    if (seconds < 0) { return 'less than 0 seconds'; }
    if (seconds === 0) { return '0 seconds'; }
    // https://stackoverflow.com/questions/8211744/convert-time-interval-given-in-seconds-into-more-human-readable-form
    let numyears = Math.floor(seconds / 31536000);
    let numdays = Math.floor((seconds % 31536000) / 86400); 
    let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    let res = '';
    if (numyears) { res += numyears + " year" + (numyears === 1 ? ' ' : 's '); }
    if (numdays) { res += numdays + " day" + (numdays === 1 ? ' ' : 's '); }
    if (numhours) { res += numhours + " hour" + (numhours === 1 ? ' ' : 's '); }
    if (numminutes) { res += numminutes + " minute" + (numminutes === 1 ? ' ' : 's '); }
    if (numseconds) { res += numseconds + " second" + (numseconds === 1 ? '' : 's'); }
    if (res) { return res.trim(); }
    return res;
  }

}

