import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {DateConverter} from '../../../helpers/DateConverter';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-ai-setting-retention',
  templateUrl: './ai-setting-retention.component.html',
  styleUrls: ['./ai-setting-retention.component.less']
})
export class AiSettingRetentionComponent extends BaseComponent implements OnInit, OnDestroy {

  public ngClasses: any = {
    active: false
  };

  public subscribers: any = {
    settings: null
  };

  public loader: any = false;

  public typeModelOptions = [
    {
      label: 'Combo',
      value: 'combo'
    },
    {
      label: 'Separate',
      value: 'sep'
    }
  ];

  public form: FormGroup;

  public DefaultValueStart = null;

  public DefaultValueEnd = null;

  public fields = [
    {
      label: 'Country of residence',
      value: 'country_of_residence'
    },
    {
      label: 'Country of birth',
      value: 'country_of_birth'
    },
    {
      label: 'Visa Type',
      value: 'visa_types'
    },
    {
      label: 'Type',
      value: 'type'
    },
    {
      label: 'Client Age',
      value: 'client_age'
    },
    {
      label: 'Client Gender',
      value: 'client_gender'
    },
    {
      label: 'Lifetime',
      value: 'lifetime'
    },
    {
      label: 'Marital Status',
      value: 'marital_stat'
    }
  ];

  public assignSettings;

  constructor(
    private route: ActivatedRoute,
    private service: AssignSettingService,
    private snack: MatSnackBar
  ) {
    super();

    this.subscribers.settings = this.service.getAssignSetting({
      'setting': 'ai_configuration_retention'
    }).subscribe((res) => {

      this.assignSettings = res;

      this.setValues(res);

    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      data_set_from: new FormControl('', [
        Validators.required
      ]),
      data_set_to: new FormControl('', [
        Validators.required
      ]),
      quantile: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      type_model: new FormControl('', [
        Validators.required
      ]),
      min_count_agents_output: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1000)
      ]),
      risk: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      disq: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      bonus_points: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(90)
      ]),
      weights: new FormGroup({
        conversion_rate: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        avg_of_sales: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        eff_call_to_swipe: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        answer_to_eff_call: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        index: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ])
      }),
      weights_of_features: new FormGroup({
        country_of_residence: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        country_of_birth: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        visa_types: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        type: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        client_age: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        client_gender: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        lifetime: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ]),
        marital_stat: new FormControl('', [
          Validators.required,
          Validators.min(0),
          Validators.max(1)
        ])
      }),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public setStartDate(e): void {
    const date = DateConverter.toDateString(e.value);

    this.form.get('data_set_from').setValue(date + ' 00:00:00');
  }

  public setEndDate(e): void {
    const date = DateConverter.toDateString(e.value);

    this.form.get('data_set_to').setValue(date + ' 59:59:59');
  }

  private setValues(params) {
    if (params) {
      for (const name in params) {
        if (params.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).patchValue(params[name]);

          if (!params[name]) {
            this.form.get(name).markAsTouched();
          }

          if (name === 'data_set_from') {
            this.DefaultValueStart = params[name] ? params[name].split(' ')[0].replaceAll('-', '/') : params[name];
          }
          if (name === 'data_set_to') {
            this.DefaultValueEnd = params[name] ? params[name].split(' ')[0].replaceAll('-', '/') : params[name];
          }
        }

        if (name === 'field_positions') {
          this.fields = this.fields.sort((a, b) => {
            const field = params[name];
            if (field[a.value] < field[b.value]) {
              return -1;
            }

            if (field[a.value] < field[b.value]) {
              return 1;
            }

            return 0;
          });
        }
      }
    }
  }

  private getFormData(): any {
    const field_positions = {};

    this.fields.map((field, i) => {
      field_positions[field.value] = i + 1;
    });

    return  {
      'setting_slug': 'ai_configuration_retention',
      'setting': {
        ...this.form.value,
        field_positions: field_positions
      },
    };
  }

  public submit(event): void {
    event.preventDefault();

    this.loader = true;

    this.subscribers.edit = this.service.updateAssignSetting(this.getFormData()).subscribe(result => {
      if (result) {
        this.snack.open('Settings Successfully Updated!', 'Dismiss', {
          duration: 3000
        });
        this.loader = false;
      }
    }, error => {
      this.loader = false;

      for (const name in error.error) {
        if (error.error.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('weights').get(name)) {
          this.form.get('weights').get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('weights_of_features').get(name)) {
          this.form.get('weights_of_features').get(name).setErrors({
            name: error.error[name]
          });
        }
      }
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
  }

}
