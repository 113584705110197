import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DatePeriodComponent } from './date-period.component';

@NgModule({
  declarations: [DatePeriodComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [DatePeriodComponent]
})
export class DatePeriodModule { }
