import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-no-result',
    templateUrl: './no-result.component.html',
    styleUrls: ['./no-result.component.less']
})

export class NoResultComponent implements OnInit {

    @Input() message: string;

    @Input() img: string;

    constructor() {}

    ngOnInit() {}

}
