import {Component, OnInit} from '@angular/core';
import BaseComponent from '../base/base.component';
import {UserService} from '../../services/user/user.service';
import {MatDialog} from '@angular/material';
import {CreateUserComponent} from '../create-user/create-user.component';
import {DeleteUserComponent} from '../delete-user/delete-user.component';
import {User} from '../../services/user/user';
import {EditUserComponent} from '../edit-user/edit-user.component';
import {DateConverter} from '../../helpers/DateConverter';
import {Permissions} from '../../helpers/Permissions';
import {MAIN_ROLE, TYPE_ADMIN} from '../../constants/user';
import _ from 'lodash';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {AddRoleComponent} from '../roles/add-role/add-role.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.less']
})

export class UsersComponent extends BaseComponent implements OnInit {

    public roles = [{value: '', label: 'All'}, ...MAIN_ROLE];

    public userType: number;
    public roleType: number;

    public loader = true;

    public columnsToDisplay: Array<string>;

    public columnsToFilter: Array<string>;

    public users: Array<User>;

    public rows: number;

    public pages: number;

    public page_size: number;

    public page: number;

    public sort: any;

    public filter: any;

    public filterData: any;

    public dateFields: any;

    public menu = null;

    public options = null;

    public menuLoader = false;

    constructor(
        private userService: UserService,
        public dialog: MatDialog,
        public permissions: Permissions,
        private store: Store<any>
    ) {
        super();

        this.store.pipe(
            select('login'),
            takeUntil(this.destroy)
        ).subscribe((data: any) => {
            if (data && data.type !== undefined) {
                this.userType = data.type;
                this.roleType = data.role_type;
            }
        });

        this.users = [];

        this.columnsToDisplay = ['full_name', 'username', 'email', 'boards', 'ips', 'created_at', 'updated_at', 'actions'];

        this.columnsToFilter = ['full_name', 'username', 'email', 'boards', 'ips', 'created_at', 'updated_at'];

        if (this.userType === TYPE_ADMIN) {
            this.columnsToDisplay.splice(2, 0, 'type');
            this.columnsToFilter.splice(2, 0, 'type');
        }

        this.filterData = {};

        this.page_size = 10;

        this.dateFields = {
            created_at: '',
            updated_at: '',
        };

        this.setDefaults();

        this.getUsers();

        localStorage.setItem('roles', JSON.stringify([]));
    }

    ngOnInit() {
        this.menu = [
            {
                icon: 'person_add',
                label: 'Create new user',
                show:  this.permissions.can('user', 'can_create_user'),
                fn: () => {
                    this.createUser();
                }
            }
        ];

        this.options = [
            {
                icon: 'edit',
                label: 'Edit',
                show: this.permissions.can('user', 'can_edit_user'),
                fn: (row, i) => {
                    this.editUser(row);
                }
            },
            {
                icon: 'delete',
                label: 'Delete',
                show: this.permissions.can('user', 'can_delete_user'),
                fn: (row, i) => {
                    this.deleteUser(row);
                }
            }
        ];
    }

    public getRoleName(value): string {
        if (this.roles) {
            return this.roles.find(role => role.value === value).label;
        }

        return value;
    }

    dateChange(event) {
        if (event.target.value) {
            const date = new Date(event.target.value);
            this.filterData[event.targetElement.name] = DateConverter.toDateString(date);
        }

        this.filterChange();
    }

    addSort(event) {
        this.sort = event.active && event.direction ? {['sort[' + event.active + ']']: event.direction} : {};
        this.getUsers();
    }

    changePage(event) {
        this.page = event.pageIndex;
        this.page_size = event.pageSize;
        this.getUsers();
    }

    filterChange() {
        this.filter = {};
        this.page = 0;

        for (const name in this.filterData) {
            if (!this.filterData.hasOwnProperty(name)) {
                continue;
            }
            if (!_.isNil(this.filterData[name])) {
                this.filter['filter[' + name + ']'] = this.filterData[name];
            }
        }

        this.getUsers();
    }

    clearFilters() {
        this.setDefaults();
        this.getUsers();
    }

    private setDefaults() {
        this.rows = 0;

        this.pages = 0;

        this.page = 0;

        this.sort = {['sort[created_at]']: 'desc'};

        this.filter = {};

        for (const name of this.columnsToFilter) {
            this.filterData[name] = '';
        }

        if (this.userType !== TYPE_ADMIN) {
            this.filterData['type'] = this.userType;
            this.filter['filter[type]'] = this.filterData.type;
        }

        for (const name in this.dateFields) {
            if (this.dateFields.hasOwnProperty(name)) {
                this.dateFields[name] = '';
            }
        }
    }


    public createUser() {
        const dialog = this.dialog.open(CreateUserComponent, {
            width: '450px'
        });
        dialog.afterClosed().subscribe((result) => {
            if (result && result.length > 0) {
                this.clearFilters();
            }
        });
    }

    public deleteUser(user) {
        const dialog = this.dialog.open(DeleteUserComponent, {
            width: '250px',
            data: user
        });
        dialog.afterClosed().subscribe((result) => {
            if (result && result.length > 0) {
                this.getUsers();
            }
        });
    }

    public editUser(user) {
        const dialog = this.dialog.open(EditUserComponent, {
            width: '450px',
            data: _.cloneDeep(user)
        });
        dialog.afterClosed().subscribe((result) => {
            if (result && result.length > 0) {
                this.getUsers();
            }
        });
    }

    public menuSelect(option) {
        option.fn();
    }

    private getUsers() {
        this.loader = true;
        this.userService.getUsers(this.page, this.page_size, this.sort, this.filter)
            .subscribe((response) => this.handleResponse(response));
    }

    private handleResponse(response) {
        this.users = response.data;
        this.rows = response.rows;
        this.pages = response.pages;
        this.page = response.page;
        this.loader = false;
    }

}
