import { Component } from '@angular/core';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {ViewService} from '../../../services/view/view.service';
import {GetBoardId, GetRoleType} from '../../../reducers/selectors/login.selector';
import BaseComponent from '../../base/base.component';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {CreateViewControlComponent} from '../create-view-control/create-view-control.component';
import {TYPE_APPLICATIONS} from '../../../constants/user';
import _ from 'lodash';
import SearchResponse from '../../../search.response';

const SuggestionViews = 'suggestion_views_retention';

@Component({
  selector: 'app-views-control-retention',
  templateUrl: './views-control-retention.component.html',
  styleUrls: ['./views-control-retention.component.less']
})
export class ViewsControlRetentionComponent extends BaseComponent {

  public typeApplications = TYPE_APPLICATIONS;

  public rolesMenu: any = [
    {
      icon: 'edit',
      label: 'Edit',
      show:  true,
      fn: (view, index) => {
        this.create(view, index);
      }
    },
    {
      icon: 'delete',
      label: 'Delete',
      show:  true,
      fn: (view, index) => {
        const data = [...this.dataSource.data];
        data.splice(index, 1);
        this.dataSource.data = data;

        this.save();
      }
    }
  ];

  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  public columnsToDisplay: string[] = [
    'title',
    'prefix',
    'freshEn',
    'reassignedEn',
    'actions'
  ];

  public settingSlug: string;

  public views = [];

  private roleType: number;
  private boardId: number;

  constructor(
      private service: AssignSettingService,
      private snack: MatSnackBar,
      private viewService: ViewService,
      private store: Store<any>,
      private dialog: MatDialog
  ) {
    super();

    this.loader = true;

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;

      this.settingSlug = this.roleType === TYPE_APPLICATIONS ? 'suggestion_views_retention' : 'suggestion_views';
    });

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        // tslint:disable-next-line:no-shadowed-variable
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.boardId = id;
    });

    this.viewService.getViews({
      page_size: 100,
      'filter[type]': this.roleType
    }).subscribe(res => {
      if (res && res.data) {
        this.views = res.data;
      }

      this.getSetting();

    }, error => {

      this.snack.open('Can not get Views', 'Ok!', {
        duration: 2000,
      });

      this.loader = false;
    });
  }

  private getSetting(): void {
    this.loader = true;

    this.service.getAssignSetting({setting: this.settingSlug}).subscribe(res => {
      this.dataSource.data = res;

      this.loader = false;
    }, err => {
      this.snack.open('Something Went Wrong!', 'Ok!', {
        duration: 1000
      });

      this.loader = false;
    });
  }

  public save() {
    const body = {
      setting_slug: this.settingSlug,
      setting: {
        views: this.dataSource.data
      }
    };

    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('View control was deleted successfully!', 'Ok!', {
        duration: 1000,
      });
    });
  }

  public getViews(id): string {
    const selected = this.views.find(view => view.id === id);
    return selected ? selected.name : id;
  }

  public create(view = null, index = null): void {
    this.dialog.open(CreateViewControlComponent, {
      width: '450px',
      data: {
        index: index,
        view: view,
        views: this.views,
        role: this.roleType,
        boardId: this.boardId,
        slug: this.settingSlug,
        list: this.dataSource.data,
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        // this.dataSource.data = res;
        this.getSetting();
      }
    });
  }
}
