import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Unsubscribe} from '../../../classes/unsubscribe';

@Component({
  selector: 'app-section-info-modal',
  templateUrl: './section-info-modal.component.html',
  styleUrls: ['./section-info-modal.component.less']
})
export class SectionInfoModalComponent implements OnInit {

  public selectedAgentIds: number[] = [];

  public tabs = [
    {
      label: 'Leads',
      id: 'leads',
    },
    {
      label: 'Assign',
      id: 'assign',
    },
    {
      label: 'Assigners',
      id: 'assigners',
    },
    {
      label: 'Assignee',
      id: 'assignee',
    }
  ];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.selectedAgentIds = this.data.agentIds;
  }

  public applyAgents(data): void {
    this.selectedAgentIds = data;
  }

  public fieldsTrack(index, item) {
    return item.id;
  }

}
