import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';

// @ts-ignore
import icons from '../../../helpers/icons-list.json';

@Component({
    selector: 'app-icon-select-modal',
    templateUrl: './icon-select-modal.component.html',
    styleUrls: ['./icon-select-modal.component.scss']
})
export class IconSelectModalComponent implements OnInit {

    public loader: boolean = false;

    public icons: any = icons;

    public subscribers = {
        getIcons: null
    };

    constructor(
        public self: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {}

    public select(icon) {
        this.self.close(icon);
    }
}
