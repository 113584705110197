import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-personal-settings',
    templateUrl: './personal-settings.component.html',
    styleUrls: ['./personal-settings.component.less']
})
export class PersonalSettingsComponent implements OnInit {

    public assign_block: any = false;

    public toggles: any = [
        {
            icon: 'phonelink_erase',
            name: 'assign_block',
            label: 'Block assign when agent on a call?',
            value: false,
        },
        {
            icon: 'notifications_off',
            name: 'notifications',
            label: 'Disable notifications?',
            value: false
        }
    ];

    constructor() {
    }

    ngOnInit() {
        for (const toggle of this.toggles) {
            if (localStorage.getItem(toggle.name)) {
                toggle.value = true;
            }
        }
    }

    public toggle(event, i) {

        this.toggles[i].value = event.checked;

        localStorage.setItem(this.toggles[i].name, event.checked ? 'true' : '');
    }

}
