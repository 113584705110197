import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VisaTypeComponent} from './visa-type.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        VisaTypeComponent
    ],
    imports: [
        CommonModule,
        MatBadgeModule,
        MatButtonModule
    ],
    exports: [
        VisaTypeComponent
    ]
})
export class VisaTypeModule {
}
