import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralSettingsNumberComponent} from './general-settings-number.component';
import {MatButtonModule, MatDialogModule, MatIconModule} from '@angular/material';
import {GeneralSettingsNumberEditModule} from './general-settings-number-edit/general-settings-number-edit.module';
import {GeneralSettingsNumberEditComponent} from './general-settings-number-edit/general-settings-number-edit.component';

@NgModule({
    declarations: [GeneralSettingsNumberComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        GeneralSettingsNumberEditModule
    ],
    exports: [GeneralSettingsNumberComponent],
    entryComponents: [GeneralSettingsNumberEditComponent]
})
export class GeneralSettingsNumberModule {
}
