import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateConverter} from '../../../helpers/DateConverter';

@Component({
  selector: 'app-greater-than-or-equal-to-time',
  templateUrl: './greater-than-or-equal-to-time.component.html',
  styleUrls: ['./greater-than-or-equal-to-time.component.less']
})
export class GreaterThanOrEqualToTimeComponent implements OnInit, OnChanges {

  @Output() public change: EventEmitter<any> = new EventEmitter<any>();

  @Input() public value: string;

  public dateInput: any;

  constructor() {
  }

  ngOnInit() {
    this.setValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !changes.value.firstChange && changes.value.currentValue !== undefined) {
      this.setValue();
    }
  }

  public dateChange() {
    if (this.dateInput) {
      const date = this.dateInput;

      const [month, day, year, hour, minutes, seconds] = [
        DateConverter.toZeroNumber(date.getMonth() + 1),
        DateConverter.toZeroNumber(date.getDate()),
        date.getFullYear(),
        DateConverter.toZeroNumber(date.getHours()),
        DateConverter.toZeroNumber(date.getMinutes()),
        DateConverter.toZeroNumber(date.getSeconds()),
      ];

      this.change.emit({
        value: `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
      });
    }
  }

  private setValue() {
    if (this.value !== undefined && this.value !== null) {
      this.dateInput = new Date(this.value);
    }
  }
}
