export class Marker {

    static mark(key, keys, className): void {

        const entities = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {};

        for (const ent of keys) {
            if (typeof ent === 'object') {
                entities[ent.id] = className;
            } else {
                entities[ent] = className;
            }
        }

        localStorage.setItem(key, JSON.stringify(entities));
    }
}
