import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {MatDialog} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {Agent} from '../../services/agent/Agent';
import {CallActivityService} from '../../services/call-activity/call-activity.service';
import {DateConverter} from '../../helpers/DateConverter';
import {CallReportColumnsComponent} from './call-report-columns/call-report-columns.component';
import {environment} from '../../../environments/environment';
import {Cookies} from '../../helpers/Cookies';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-call-report',
    templateUrl: './call-report.component.html',
    styleUrls: ['./call-report.component.less']
})
export class CallReportComponent implements OnInit, OnDestroy {

    public available = [
        {
            name: 'lead_number',
            label: 'Lead Number',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'assigned_to',
            label: 'Agent',
            value: [],
            type: 'agent',
            deletable: false,
        },
        {
            name: 'status_reason',
            label: 'Status Reason',
            value: '',
            type: '',
            deletable: true,
        },
        {
            name: 'birthday',
            label: 'Birthday',
            value: '',
            type: '',
            deletable: true,
        },
        {
            name: 'country_of_residence',
            label: 'Country Of Residence',
            value: '',
            type: '',
            deletable: true,
        },
        {
            name: 'country_of_birth',
            label: 'Country Of Birth',
            value: '',
            type: '',
            deletable: true,
        },
        {
            name: 'attempts',
            label: 'Attempts',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'average_calls',
            label: 'Average Calls',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'total',
            label: 'Total Calls',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'answers',
            label: 'Answers',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'effective',
            label: 'Effective',
            value: '',
            type: '',
            deletable: false,
        },
        {
            name: 'disqualified',
            label: 'Disqualified',
            value: '',
            type: '',
        },
        {
            name: 'keyword',
            label: 'Keyword',
            value: '',
            type: '',
        },
        {
            name: 'first_name',
            label: 'First Name',
            value: '',
            type: '',
        },
        {
            name: 'last_name',
            label: 'Last Name',
            value: '',
            type: '',
        },
        {
            name: 'last_event_time',
            label: 'Last Event Time',
            value: '',
            type: 'datetime',
        },
        {
            name: 'created_time',
            label: 'Created Time',
            value: '',
            type: 'datetime'
        },
        {
            name: 'converted',
            label: 'Converted',
            value: '',
            type: 'bool'
        }
    ];

    public columns: any = [
        {
            name: 'lead_number',
            label: 'Lead Number',
            value: '',
            type: ''
        },
        {
            name: 'assigned_to',
            label: 'Agent',
            value: [],
            type: 'agent'
        },
        {
            name: 'status_reason',
            label: 'Status Reason',
            value: '',
            type: ''
        },
        {
            name: 'birthday',
            label: 'Birthday',
            value: '',
            type: ''
        },
        {
            name: 'country_of_residence',
            label: 'Country Of Residence',
            value: '',
            type: ''
        },
        {
            name: 'country_of_birth',
            label: 'Country Of Birth',
            value: '',
            type: ''
        },
        {
            name: 'attempts',
            label: 'Attempts',
            value: '',
            type: ''
        },
        {
            name: 'answers',
            label: 'Answers',
            value: '',
            type: ''
        },
        {
            name: 'effective',
            label: 'Effective',
            value: '',
            type: ''
        }
    ];

    public agents: any = [];

    public agentsMap: any = {};

    public view = 'table';

    public loader: any = false;

    public init = {
        chart: false,
        table: true
    };

    public subs: any = {
        date: null,
        table: null,
        chart: null,
        agents: null,
        columns: null
    };

    public range: any = {
        from: new Date(),
        to: new Date()
    };

    public options: any = [
        {
            icon: 'date_range',
            name: 'Range',
            show: this.permissions.can('call', 'can_range_call_report'),
            fn: () => {

                if (this.subs.date) {
                    this.subs.date.unsubscribe();
                }

                this.subs.date = this.dialog.open(DataPicketCampaignsComponent, {
                    data: {
                        value: {
                            rawEnd: this.range.from,
                            rawStart: this.range.to
                        }
                    },
                }).afterClosed().subscribe((date) => {

                    if (!date) {
                        return;
                    }

                    this.range.from = date.value.rawEnd;

                    this.range.to = date.value.rawStart;

                    this.build();

                });
            }
        },
        {
            icon: 'update',
            name: 'Update',
            show: this.permissions.can('call', 'can_update_call_report'),
            fn: () => {
                this.build();
            }
        },
        {
            icon: 'view_column',
            name: 'Columns',
            show: this.permissions.can('call', 'can_manage_columns_at_call_report'),
            fn: () => {

                if (this.subs.columns) {
                    this.subs.columns.unsubscribe();
                }

                this.subs.columns = this.dialog.open(CallReportColumnsComponent, {
                    data: {
                        available: Object.assign([], this.available),
                        columns: Object.assign([], this.columns),
                    },
                    width: '500px'
                }).afterClosed().subscribe((cols) => {
                    if (cols) {
                        this.columns = cols;
                        localStorage.setItem('report_columns', JSON.stringify(cols.map(c => c.name)));
                    }
                });
            }
        },
        {
            icon: 'import_export',
            name: 'Export',
            show: this.permissions.can('call', 'can_export_call_report'),
            fn: () => {
                const params = {
                    from: DateConverter.startOfDay(this.range.from).getTime() / 1000,
                    to: DateConverter.endOfDay(this.range.to).getTime() / 1000,
                };
                window.open(environment.apiURL + 'v1/call-activity/report/export?access-token=' + Cookies.get('token')
                    + '&from=' + params.from + '&to=' + params.to);
            }
        }
    ];

    public params: any = {
        page: 0,
        page_size: 100,
        pages: 0,
        rows: 0,
        data: [],
    };

    public sort: any = {};

    public filter: any = {};

    public builder: any = {
        table: () => {

            const req = Object.assign({}, {
                page: this.params.page,
                page_size: this.params.page_size,
                from: DateConverter.startOfDay(this.range.from).getTime() / 1000,
                to: DateConverter.endOfDay(this.range.to).getTime() / 1000,
            }, this.filter, this.sort);

            this.subs.table = this.callActivity.report.table(req).subscribe((res) => {

                this.params = Object.assign({}, this.params, res);

                this.loader = !this.loader;
            });
        },
        chart: () => {

            const req = Object.assign({
                from: DateConverter.startOfDay(this.range.from).getTime() / 1000,
                to: DateConverter.endOfDay(this.range.to).getTime() / 1000,
            }, this.filter, this.sort);

            this.subs.chart = this.callActivity.report.chart(req).subscribe((res) => {

                this.chart = res;

                this.loader = !this.loader;
            });
        }
    };

    public chart: any = [];

    constructor(
        private dialog: MatDialog,
        private store: Store<any>,
        private callActivity: CallActivityService,
        public permissions: Permissions
    ) {
        let col;

        if (col = localStorage.getItem('report_columns')) {
            this.columns = [];
            col = JSON.parse(col);
            for (const c of col) {
                const add = this.available.find(a => a.name === c);
                if (add) {
                    this.columns.push(add);
                }
            }
        }
    }

    ngOnInit() {

        this.build();

        this.subs.agents = this.store.pipe(select('agents')).subscribe((res) => {

            if (this.agents.length > 0) {
                return;
            }

            this.agents = res.allAgents;

            for (const agent of this.agents) {
                this.agentsMap[agent.crm_id] = agent;
            }

            const index = this.columns.findIndex(c => c.name === 'assigned_to');

            if (index !== -1) {
                this.columns[index].value = this.agents.map(a => {
                    return {
                        label: a.crm_username,
                        value: a.crm_id,
                    };
                });
            }
        });
    }

    ngOnDestroy() {
        for (const name in this.subs) {
            if (this.subs.hasOwnProperty(name) && this.subs[name]) {
                this.subs[name].unsubscribe();
            }
        }
    }

    build() {
        this.loader = !this.loader;
        this.builder[this.view]();
    }

    onSort(event) {
        this.sort = Object.assign({}, event);
        this.params.page = 0;
        this.build();
    }

    onPage(event) {
        this.params = Object.assign(this.params, event);
        this.build();
    }

    onFilter(event) {
        this.filter = Object.assign({}, event);
        this.params.page = 0;
        this.build();
    }

    toggleView(event) {

        this.view = event.value;

        if (this.init[this.view]) {
            return;
        }

        this.init[this.view] = true;

        this.build();
    }
}
