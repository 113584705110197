import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SuggestionSettingsComponent} from './suggestion-settings.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {LoaderModule} from '../../general/loader/loader.module';
import { ViewCountryInfoModule } from '../../view-country-info/view-country-info.module';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    declarations: [
        SuggestionSettingsComponent,
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        LoaderModule,
        ViewCountryInfoModule,
        MatExpansionModule
    ],
    exports: [
        SuggestionSettingsComponent
    ],
})
export class SuggestionSettingsModule {}
