import { Component, OnDestroy, OnInit } from '@angular/core';
import BaseComponent from '../../base/base.component';
import { Permissions } from '../../../helpers/Permissions';
import {filter, takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {GetRoleType} from '../../../reducers/selectors/login.selector';
import {TYPE_LEADS} from '../../../constants/user';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.less']
})
export class SideNavComponent extends BaseComponent implements OnInit, OnDestroy {

    public navigation: Array<any>;

    // private roleActions: any = {
        // '/users': {
        //     name: 'Users',
        //     icon: 'account_circle',
        //     path: '/users',
        //     parent: 'users',
        //     show: this.permissions.can('user', 'can_get_user')
        // },
        // '/settings': {
        //     name: 'Settings',
        //     icon: 'settings',
        //     path: '/settings',
        //     parent: '',
        //     show: this.permissions.can('agent_settings', 'can_view_settings')
        // },
        // '/assign-report': {
        //     name: 'Assign',
        //     icon: 'assignment',
        //     path: '/assign-report',
        //     parent: 'reports',
        //     show: this.permissions.can('assign', 'can_view_assign_report')
        // },
        // '/queue': {
        //     name: 'Queue',
        //     icon: 'layers',
        //     path: '/queue',
        //     parent: '',
        //     show: this.permissions.can('queue', 'can_get_queue')
        // }
    // };

    private login: any;

    private userType: number;

    constructor(
        public permissions: Permissions,
        private store: Store<any>
    ) {
        super();

        this.store.pipe(
            select(GetRoleType),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.userType = type;
        });

        this.setNavigation();
    }

    ngOnInit() {
        // this.setNavigation();
    }

    ngOnDestroy(): void {
        document.removeEventListener('login', this.login);
    }

    private setNavigation() {
        this.navigation = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                icon: 'assignment',
                path: '/dashboard/default',
                show: this.permissions.can('view', 'can_get_data_view')
            },
            {
                id: 'agents',
                name: 'Agents',
                icon: 'group',
                path: '',
                show: this.permissions.can('shift', ['can_hide_agent_shift', 'can_get_shift', 'can_get_shift'])
                    || this.permissions.can('agent', 'can_roles_agent'),
                children: [
                    {
                        name: 'Settings',
                        icon: 'build',
                        path: '/agent-settings',
                        show: this.permissions.can('shift', 'can_hide_agent_shift')
                    },
                    // {
                    //     name: 'Goals',
                    //     icon: 'rowing',
                    //     path: '/agent-goals',
                    //     show: this.permissions.can('goals', 'can_view_golas_list')
                    // },
                    {
                        name: 'Shifts',
                        icon: 'brightness_high',
                        path: '/shifts',
                        show: this.permissions.can('shift', 'can_get_shift')
                    },
                    {
                        name: 'Shift management',
                        icon: 'library_books',
                        path: '/agent-shifts',
                        show: this.permissions.can('shift', 'can_get_shift')
                    },
                    {
                        name: 'Department Roles',
                        icon: 'manage_accounts',
                        path: '/department-roles',
                        show: this.permissions.can('agent', 'can_roles_agent')
                    },
                    // {
                    //     name: 'Statistics',
                    //     icon: 'accessibility_new',
                    //     path: '/agent-statistics'
                    //
                    // },
                ]
            },
            // {
            //     id: 'reports',
            //     name: 'Reports',
            //     icon: 'bar_chart',
            //     path: '',
            //     show: this.userType === TYPE_LEADS,
            //     children: [
            //         {
            //             id: 'contact_report',
            //             name: 'Contact',
            //             icon: 'pie_chart',
            //             path: '/contact-report',
            //             show: true
            //         },
            //         {
            //             id: 'call_report',
            //             name: 'Call',
            //             icon: 'perm_phone_msg',
            //             path: '/call-report',
            //             show: this.permissions.can('call', 'can_view_call_report')
            //         },
            //         {
            //             id: 'assign_report',
            //             name: 'Assign',
            //             icon: 'assignment',
            //             path: '/assign-report',
            //             show: this.permissions.can('assign', 'can_view_assign_report')
            //         },
            //     ]
            // },
            // {
            //     id: 'history',
            //     name: 'History',
            //     icon: 'history',
            //     path: '/history',
            //     show: this.permissions.can('history', 'can_view_history_list') && this.userType === TYPE_LEADS
            // },
            {
                id: 'reassign-pull',
                name: 'Reassign Scripts',
                icon: 'code',
                path: '/reassign-scripts',
                show: this.permissions.can('reassign', 'can_get_reassign')
            },
            {
                id: 'reshuffles',
                name: 'Auto Reshuffles',
                icon: 'autorenew',
                path: '/reshuffles',
                show: this.permissions.can('reshuffle', 'can_get_reshuffle')
            },
            {
                id: 'pending-reshuffles',
                name: 'Pending Reshuffles',
                icon: 'history',
                path: '/pending-reshuffles',
                show: this.permissions.can('pending_assign', 'can_get_pending_assign')
            },
            {
                id: 'auto-assign',
                name: 'Auto Assign',
                icon: 'move_up',
                path: '/auto-assign',
                show: this.permissions.can('auto_assign', 'can_get_auto_assign')
            },
            {
                id: 'hold',
                name: 'Hold',
                icon: 'pause_circle_outline',
                path: '/hold',
                show: this.permissions.can('hold', 'can_get_hold')
            },
            {
                id: 'reassign_pool',
                name: 'Re-assign Pool',
                icon: 'dns',
                path: '/re-assign-pool',
                show: this.permissions.can('assign', 'can_reassign_pool_assign')
            },
            {
                id: 'ai-assign',
                name: 'AI Assign',
                icon: 'diversity_2',
                path: '/ai-assign',
                show: this.permissions.can('ai_assign', 'can_get_ai_assign')
            },
            // {
            //     id: 'settings',
            //     name: 'Settings',
            //     icon: 'settings',
            //     path: '/general-settings',
            // },
            // {
            //     id: 'settings',
            //     name: 'Holidays',
            //     icon: 'beach_access',
            //     path: '/holidays',
            //     show: this.permissions.can('holidays', 'can_view_holidays_list') && this.userType === TYPE_LEADS
            // },
            {
                id: 'users',
                name: 'Managing Users',
                icon: 'group',
                path: '',
                show: this.permissions.can('role', 'can_get_role') || this.permissions.can('user', 'can_get_user'),
                children: [
                    {
                        name: 'Roles',
                        icon: 'security',
                        path: '/roles',
                        show: this.permissions.can('role', 'can_get_role')
                    },
                    {
                        name: 'Users',
                        icon: 'account_circle',
                        path: '/users',
                        parent: 'users',
                        show: this.permissions.can('user', 'can_get_user')
                    },
                ]
            },
            {
                name: 'Queue',
                icon: 'layers',
                path: '/queue',
                parent: '',
                show: this.permissions.can('queue', 'can_get_queue')
            },
            {
                id: 'ai_suggestion',
                name: 'AI System',
                icon: 'group',
                path: '',
                show: this.permissions.can('assign', ['can_setting_assign', 'can_leads_suggestion_assign', 'can_applications_suggestion_assign']),
                children: [
                    {
                        name: 'AI Settings',
                        icon: 'security',
                        path: '/ai-settings',
                        show: this.permissions.can('assign', 'can_setting_assign')
                    },
                    {
                        name: 'AI Suggestion',
                        icon: 'account_circle',
                        path: '/ai-suggestion',
                        parent: 'ai_suggestion',
                        show: this.permissions.can('assign', ['can_leads_suggestion_assign', 'can_applications_suggestion_assign'])
                    },
                    // {
                    //     name: 'Bulk Assign',
                    //     icon: 'library_books',
                    //     path: '/bulk-assign',
                    //     show: this.permissions.can('ai_suggestion', 'can_view_assign_leads_suggestion') && this.userType === TYPE_LEADS
                    // }
                ]
            },
            // {
            //     id: 'settings',
            //     name: 'Settings',
            //     icon: 'settings',
            //     path: '',
            //     show: true,
            //     children: [
            //         {
            //             id: 'ip_control',
            //             name: 'IP Control',
            //             icon: 'wifi',
            //             path: '/ip-control',
            //             show: this.permissions.can('assign', 'can_reassign_pool_assign')
            //         }
            //     ]
            // },
        ];

        // if (localStorage.getItem('permissions')) {
        //     const permissions = JSON.parse(localStorage.getItem('permissions'));
        //     for (const action of permissions) {
        //         if (this.roleActions[action]) {
        //             if (!this.roleActions[action].parent) {
        //                 this.navigation.push(this.roleActions[action]);
        //             } else {
        //                 const menu = this.navigation.find(n => n.id === this.roleActions[action].parent);
        //                 if (menu) {
        //                     menu.children.push(this.roleActions[action]);
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
