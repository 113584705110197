export default class TokenGenerator {

    static token: string;

    static getToken() {
        if (!TokenGenerator.token) {
            TokenGenerator.token = TokenGenerator.createRandomString(32);
        }

        return TokenGenerator.token;
    }

    static createRandomString(length) {

        let str = '';

        while (str.length < length) {
            str += Math.random().toString(36).substr(2);
        }

        return str.substr(0, length);
    }
}
