import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AssignSuggestionService } from 'src/app/services/assign-suggestion/assign-suggestion.service';
import { BulkAssignService } from 'src/app/services/bulk-assign/bulk-assign.service';

@Component({
  selector: 'app-bulk-assign-form',
  templateUrl: './bulk-assign-form.component.html',
  styleUrls: ['./bulk-assign-form.component.less']
})
export class BulkAssignFormComponent implements OnInit {

  @Input() data: any = [];

  @Input() agentId: object;

  @Output() onLoaded: EventEmitter<any> = new EventEmitter();

  public show = false;

  public loading = false;

  public file: any;

  constructor(
    public snackBar: MatSnackBar,
    public suggestionService: AssignSuggestionService,
    public bulkAssignService: BulkAssignService) {
  }

  ngOnInit() {}

  public onChangeFile(evt) {
    const files = evt.target.files;
    if (files.length) { 
      const file = files[0];
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.file = files[0]; 
      } else {
        this.snackBar.open('Incorrect file type! must be *.xls or *.xlsx', 'Continue', {
          duration: 7000,
          panelClass: ['error-snackbar']
        });
      }
    }
  }

  public submit(evt: any): void {
    evt.preventDefault()
    if (this.file) {
      this.loading = true;
      const file = this.file;
      const formData = new FormData();

      formData.append("file", new Blob([file], {type: file.type}), file.name);
      this.bulkAssignService.sendFile(formData).subscribe((res) => {
        this.file = null;
        this.loading = false;
        this.onLoaded.emit(true);
      }, (err) => {
        this.loading = false;
        const statusText = err.statusText;
        this.snackBar.open(err.message || statusText, 'Continue', {
          duration: 7000,
        });
      })
    }
  }
}
