import {Component, OnInit} from '@angular/core';
import {HistoryService} from '../../services/history/history.service';
import {select, Store} from '@ngrx/store';
import {DateConverter} from '../../helpers/DateConverter';
import {ViewLeadInfoComponent} from '../view-lead-info/view-lead-info.component';
import {MatBottomSheet, MatDialog, MatSnackBar} from '@angular/material';
import {AgentsSheetComponent} from '../agents-sheet/agents-sheet.component';
import {AgentService} from '../../services/agent/agent.service';
import {SelectionModel} from '@angular/cdk/collections';
import {FilterModalComponent} from '../filters/filter-modal/filter-modal.component';
import {Agent} from '../../services/agent/Agent';
import {Permissions} from '../../helpers/Permissions';
import {getAvatar} from '../../helpers/Avagar';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.less']
})
export class HistoryComponent implements OnInit {

    public getAvatar = getAvatar;

    public loader: any = true;

    public subs: any = {
        history: null,
        agents: null
    };

    public menuLoader: any = [];

    public bulkLoader: any;

    public bulkOptions: any = [
        {
            icon: 'assignment_ind',
            name: 'Assign',
            fn: () => {

                const ids = this.selection.selected.map((hist) => {
                    return hist.lead.id;
                }).filter((v, i, a) => a.indexOf(v) === i);

                if (ids.length <= 0) {
                    return;
                }

                this.bulkLoader = true;

                const sub = this.bottomSheet.open(AgentsSheetComponent, {
                    data: {
                        agents: this.agentsArr,
                        selected: [],
                        multiple: false
                    }
                }).afterDismissed().subscribe((res) => {

                    if (res && ids.length > 0) {
                        const assign = this.assingner.assignLead(res.id, ids, 'Assign System').subscribe(() => {
                            this.snackBar.open('New lead(s) was assigned to \'' + res.crm_username + '\'!', 'Dismiss', {
                                duration: 3000,
                            });
                            if (assign) {
                                assign.unsubscribe();
                            }
                            this.bulkLoader = false;
                        }, () => {
                            this.snackBar.open('Error assigning to \'' + res.crm_username + '\'!', 'Dismiss', {
                                duration: 3000,
                            });
                            if (assign) {
                                assign.unsubscribe();
                            }
                            this.bulkLoader = false;
                        });
                    } else {
                        this.bulkLoader = false;
                    }

                    if (sub) {
                        sub.unsubscribe();
                    }
                });
            }
        },
    ];

    public selection = new SelectionModel<any>(true, []);

    public options: any = [
        {
            icon: 'remove_red_eye',
            name: 'Lead',
            show: this.permissions.can('history', 'can_view_lead'),
            fn: (i, hist) => {
                this.menuLoader[i] = true;
                this.dialog.open(ViewLeadInfoComponent, {
                    width: '600px',
                    data: Object.assign({}, hist.lead)
                }).afterClosed().subscribe(() => {
                    this.menuLoader[i] = false;
                });
            }
        },
        {
            icon: 'assignment_ind',
            name: 'Assign',
            show: this.permissions.can('history', 'can_assign'),
            fn: (i, hist) => {

                this.menuLoader[i] = true;

                const sub = this.bottomSheet.open(AgentsSheetComponent, {
                    data: {
                        agents: this.agentsArr,
                        selected: [],
                        multiple: false
                    }
                }).afterDismissed().subscribe((res) => {

                    if (res) {
                        const assign = this.assingner.assignLead(res.id, [hist.lead.id], 'Assign System').subscribe(() => {
                            this.snackBar.open('New lead(s) was assigned to \'' + res.crm_username + '\'!', 'Dismiss', {
                                duration: 3000,
                            });
                            if (assign) {
                                assign.unsubscribe();
                            }
                            this.menuLoader[i] = false;
                        }, () => {
                            this.snackBar.open('Error assigning to \'' + res.crm_username + '\'!', 'Dismiss', {
                                duration: 3000,
                            });
                            if (assign) {
                                assign.unsubscribe();
                            }
                            this.menuLoader[i] = false;
                        });
                    } else {
                        this.menuLoader[i] = false;
                    }

                    if (sub) {
                        sub.unsubscribe();
                    }
                });
            }
        },
    ];

    public params: any = {
        page: 0,
        page_size: 100,
        pages: 0,
        rows: 0,
        data: [],
    };

    public agents = {};

    public agentsArr = [];

    public typeFilter = [
        {
            label: 'Create',
            name: 'type',
            value: 'create',
        },
        {
            label: 'Assign',
            name: 'type',
            value: 'assign',
        }
    ];

    public statusFilter = [
        {
            label: 'Assigned',
            name: 'status',
            value: 'assigned',
        },
        {
            label: 'Not assigned',
            name: 'status',
            value: 'not_assigned',
        }
    ];

    public customFilter = [
        {
            label: 'Lead number',
            name: 'lead_number',
            value: '',
            color: '#78909C',
        },
        {
            label: 'Birthday',
            name: 'birthday',
            color: '#29B6F6',
        },
        {
            label: 'Country Of Residence',
            name: 'country_of_residence',
            color: '#66BB6A',
        },
        {
            label: 'Country Of Birth',
            name: 'country_of_birth',
            color: '#9CCC65',
        },
        {
            label: 'Status Reason',
            name: 'status_reason',
            color: '#EF5350',
        }
    ];

    public additionalFilter: any = [
        {
            label: 'Lead Number',
            name: 'lead_number',
            value: '',
            color: '',
        },
        {
            label: 'Assigned To',
            name: 'assigned_to',
            value: [],
            color: '',
        }
    ];

    public activeFilter = [];

    public timeFilter = [
        {
            label: 'Today',
            name: 'created_at',
            value: DateConverter.toDateString(new Date()) + ' ' + DateConverter.toDateString(new Date()),
        },
        {
            label: 'Yesterday',
            name: 'created_at',
            value: DateConverter.addDays(new Date(), -1, true) + ' ' + DateConverter.addDays(new Date(), -1, true),
        },
        {
            label: 'Last 7 days',
            name: 'created_at',
            value: DateConverter.addDays(new Date(), -7, true) + ' ' + DateConverter.addDays(new Date(), 0, true),
        },
        {
            label: 'Last 14 days',
            name: 'created_at',
            value: DateConverter.addDays(new Date(), -14, true) + ' ' + DateConverter.addDays(new Date(), 0, true),
        },
        {
            label: 'Last 30 days',
            name: 'created_at',
            value: DateConverter.addDays(new Date(), -30, true) + ' ' + DateConverter.addDays(new Date(), 0, true),
        }
    ];

    public activeSort = {
        name: null,
        value: null
    };

    public sorts: any = [
        {
            name: 'created_at',
            value: 'asc',
            label: 'Created Time',
        },
        {
            name: 'type',
            value: 'asc',
            label: 'Action type',
        },
        {
            name: 'user_id',
            value: 'asc',
            label: 'User',
        },
        {
            name: 'lead_id',
            value: 'asc',
            label: 'Lead',
        }
    ];

    public sort: any = {};

    public filter: any = {};

    constructor(
        private history: HistoryService,
        private store: Store<any>,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
        private assingner: AgentService,
        private snackBar: MatSnackBar,
        public permissions: Permissions
    ) {
        this.agent();
        this.get();
    }

    ngOnInit() {
    }

    public paging(event) {
        this.params.page = event.pageIndex;
        this.params.page_size = event.pageSize;
        this.loader = !this.loader;
        this.get();
    }

    public clearSorting() {

        this.sort = {};

        this.activeSort = {
            name: null,
            value: null
        };

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }


    public clearFilter(event) {

        delete this.filter['filter[' + event.name + ']'];

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }

    public filtering(event) {

        this.filter['filter[' + event.name + ']'] = event.value;

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }

    public sorting(event, toggle?: any, clear?: any) {

        if (!event.value) {
            return;
        }

        this.sort = {['sort[' + event.name + ']']: toggle ? event.value === 'desc' ? 'asc' : 'desc' : event.value};

        this.activeSort = {
            name: event.name,
            value: toggle ? event.value === 'desc' ? 'asc' : 'desc' : event.value
        };

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }

    public clearActiveFilter() {

        for (const f of this.activeFilter) {
            if (this.filter['filter[' + f.name + ']']) {
                delete this.filter['filter[' + f.name + ']'];
            }
        }

        this.activeFilter = [];

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }

    public editActiveFilter(i, opt) {
        this.dialog.open(FilterModalComponent, {
            width: '600px',
            data: {
                names: Object.assign([], this.customFilter, this.additionalFilter),
                filter: Object.assign({}, opt),
            }
        }).afterClosed().subscribe((res) => {

            if (!res) {
                return;
            }

            let filters = [];

            if (this.filter['filter[' + res.name + ']']) {
                filters = JSON.parse(this.filter['filter[' + res.name + ']']);
            }

            const index = filters.indexOf(opt.value);

            if (index === -1) {
                return;
            }

            this.activeFilter[i] = res;

            filters[index] = res.value;

            this.filter['filter[' + res.name + ']'] = JSON.stringify(filters);

            this.params.page = 0;

            this.loader = !this.loader;

            this.get();

        });
    }

    public deleteActiveFilter(i, opt) {

        let filters = [];

        if (this.filter['filter[' + opt.name + ']']) {
            filters = JSON.parse(this.filter['filter[' + opt.name + ']']);
        }

        const index = filters.indexOf(opt.value);

        if (index === -1) {
            return;
        }

        filters.splice(index, 1);

        this.activeFilter.splice(i, 1);

        this.filter['filter[' + opt.name + ']'] = JSON.stringify(filters);

        this.params.page = 0;

        this.loader = !this.loader;

        this.get();
    }

    public addActiveFilter() {
        this.dialog.open(FilterModalComponent, {
            width: '600px',
            data: {
                names: Object.assign([], this.customFilter, this.additionalFilter),
                filter: null,
            }
        }).afterClosed().subscribe((res) => {

            if (!res) {
                return;
            }

            this.activeFilter.push(res);

            let filters = [];

            if (this.filter['filter[' + res.name + ']']) {
                filters = JSON.parse(this.filter['filter[' + res.name + ']']);
            }

            filters.push(res.value);

            this.filter['filter[' + res.name + ']'] = JSON.stringify(filters);

            this.params.page = 0;

            this.loader = !this.loader;

            this.get();
        });
    }

    private get(callback?: any) {

        if (this.subs.history) {
            this.subs.history.unsubscribe();
        }

        this.subs.history = this.history.get(Object.assign({}, {
            page: this.params.page,
            page_size: this.params.page_size,
        }, this.filter, this.sort)).subscribe((res) => {
            this.loader = !this.loader;
            this.params = Object.assign(this.params, res);
            if (callback) {
                callback();
            }
        });
    }

    private agent() {
        this.subs.agents = this.store.pipe(select('agents')).subscribe((res) => {
            if (Object.keys(this.agents).length > 0) {
                return;
            }
            this.agentsArr = res.allAgents;
            for (const agent of res.allAgents) {
                this.agents[agent.crm_id] = agent;
            }
            for (let i = 0; i < this.additionalFilter.length; i++) {
                if (['assigned_to'].includes(this.additionalFilter[i].name)) {
                    this.additionalFilter[i].value = this.agentsArr.map((a: Agent) => {
                        return {
                            label: a.crm_username,
                            value: a.crm_id
                        };
                    });
                }
            }
        });
    }

    masterToggle() {
        this.params.data.forEach(row => this.selection.select(row));
    }
}
