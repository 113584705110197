import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  constructor(private api: ApiService) {
  }

  public getQueues(params: any): Observable<any> {
    return this.api.get('v1/queue', {
      params: params
    });
  }

  public createQueue(params: any): Observable<any> {
    return this.api.post('v1/queue/create', {
      body: params
    });
  }

  public deleteQueue(params: any): Observable<any> {
    return this.api.delete('v1/queue/delete', {
      body: params
    });
  }

  public refreshDialerQueue(params: any): Observable<any> {
    return this.api.post('v1/queue/refresh-dialer', {
      body: params
    });
  }

  public getQueue(id: number): Observable<any> {
    return this.api.get('v1/queue/' + id, {
      params: {}
    });
  }

  public updateQueue(id: number, params: any): Observable<any> {
    return this.api.post('v1/queue/' + id + '/update', {
      body: params
    });
  }

  public getQueueLeads(id: number, params: any): Observable<any> {
    return this.api.get('v1/queue/' + id + '/leads', {
      params: params
    });
  }

  public cloneQueue(id: number, params: any): Observable<any> {
    return this.api.post('v1/queue/' + id + '/clone', {
      body: params
    });
  }

}
