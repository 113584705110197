import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatTableModule,
    MatButtonModule, MatIconModule,
} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {AssignInfoTableComponent} from './assign-info-table.component';
import {LoaderModule} from '../../general/loader/loader.module';
import {OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
    datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timePickerInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
};
@NgModule({
    declarations: [
        AssignInfoTableComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        MatTableModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatIconModule
    ],
    exports: [
        AssignInfoTableComponent
    ],
    // providers: [
    //     {
    //         provide: OWL_DATE_TIME_FORMATS,
    //         useValue: MY_NATIVE_FORMATS
    //     }
    // ],
})
export class AssignInfoTableModule {
}
