import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreviousOwnerComponent} from './previous-owner.component';
import {MatBadgeModule, MatButtonModule} from '@angular/material';

@NgModule({
    declarations: [
        PreviousOwnerComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule
    ],
    exports: [
        PreviousOwnerComponent
    ]
})
export class PreviousOwnerModule {
}
