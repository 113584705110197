import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import {finalize} from 'rxjs/operators';

const SettingRegions = 'region_split';

@Component({
  selector: 'app-region-split',
  templateUrl: './region-split.component.html',
  styleUrls: ['./region-split.component.less']
})
export class RegionSplitComponent extends BaseComponent implements OnInit {

  public ngClasses: any = {
    active: false
  };

  public calculationTypes = [
    {
      label: 'Percent',
      value: 'percent'
    }
  ];

  public settings = [];
  public regions = [];

  constructor(
      private route: ActivatedRoute,
      private service: AssignSettingService,
      private snack: MatSnackBar
  ) {
    super();

    this.loader = true;

    this.service.getAssignSetting({
      'setting': 'region_split,regions'
    }).pipe(
        finalize(() => this.loader = false)
    ).subscribe((res) => {
      if (res && res.region_split) {
        this.settings = res.region_split;
      }

      if (res && res.regions) {
        this.regions = res.regions;
      }
    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.ngClasses.active = true;
    }, 100);
  }

  public addSetting() {
    this.settings.push({
      region: '',
      splits: {
        value: 2,
        amount: null,
        default: 1,
        calculation_type: 'percent'
      }
    });
  }

  public save() {
    this.loader = true;

    let hasErr = false;

    _forEach(this.settings, el => {
      if (el.region === '') {
        this.snack.open('Selected Region is invalid', 'Ok!', {
          duration: 1000,
        });
        hasErr = true;
        return;
      }

      Object.keys(el.splits).forEach(key => {
        if (key !== 'calculation_type' && el.splits[key] < 0 || el.splits[key] > 100) {
            this.snack.open(key + ' is invalid', 'Ok!', {
              duration: 1000,
            });

            hasErr = true;
            return;
        }
      });
    });

    if (hasErr) {
      this.loader = false;
      return;
    }

    const body = {
      setting_slug: SettingRegions,
      setting: {
        regions: this.settings
      }
    };

    this.service.updateAssignSetting(body).pipe(
        finalize(() => this.loader = false)
    ).subscribe(res => {
      this.snack.open('Updated!', 'Ok!', {
        duration: 1000,
      });
    }, error => {
      this.snack.open(_get(error, ['error', SettingRegions], 'Something Went Wrong!'), 'Ok!', {
        duration: 2000,
      });
    });
  }

  public deleteSetting(index) {
    this.settings.splice(index, 1);
  }
}
