import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {
    MatTableModule,
    MatSortModule,
    MatIconModule
} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';
import { LoadsTableComponent } from './loads-table.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {AssignedToModule} from '../../filters/assigned-to/assigned-to.module';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {DateRangeSelectModule} from '../../filters/date-range-select/date-range-select.module';
import {AutocompleteModule} from '../../filters/autocomplete/autocomplete.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AgentsAssignModule} from '../../agents-assign/agents-assign.module';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {RolesModule} from '../../reassign-progress/reassign-progress.module';
import {ReshuffleModalModule} from '../reshuffle-modal/reshuffle-modal.module';
import {ReshuffleModalComponent} from '../reshuffle-modal/reshuffle-modal.component';


@NgModule({
    declarations: [
        LoadsTableComponent
    ],
    imports: [
        CommonModule,
        AmChartsModule,
        LoaderModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        AssignedToModule,
        DateRangeSelectModule,
        AutocompleteModule,
        MatSnackBarModule,
        AgentsAssignModule,
        MatSelectModule,
        MatIconModule,
        MatTabsModule,
        RolesModule,
        ReshuffleModalModule
    ],
    exports: [
        LoadsTableComponent
    ],
    entryComponents: [
        ReshuffleModalComponent
    ]
})
export class LoadsTableModule {
}
