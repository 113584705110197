import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignedToComponent} from './assigned-to.component';
import {MatBottomSheetModule, MatButtonModule, MatIconModule} from '@angular/material';
import {AgentsSheetComponent} from '../../agents-sheet/agents-sheet.component';

@NgModule({
    declarations: [
        AssignedToComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatBottomSheetModule
    ],
    exports: [
        AssignedToComponent
    ],
    entryComponents: [
        AgentsSheetComponent
    ]
})

export class AssignedToModule {}
