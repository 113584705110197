import { Component, OnInit, Input } from '@angular/core';
import { AssignSuggestionService } from 'src/app/services/assign-suggestion/assign-suggestion.service';


@Component({
  selector: 'app-fresh-leads-available',
  templateUrl: './fresh-leads-available.component.html',
  styleUrls: ['./fresh-leads-available.component.scss']
})
export class FreshLeadsAvailableComponent implements OnInit {

  public data: any;
  public show = false;
  public loader = true;
  public displayedColumns = ['time', 'seo', 'online', 'english', 'spanish', 'whv', 'tv'];

  constructor(public suggestionService: AssignSuggestionService) { }

  ngOnInit() {
    this.getData();
  }

  public getData() {
    this.loader = true;
    this.suggestionService.getAssignableStats().toPromise().then((res) => {
      if (res && res.stats) {
        let data = {
          time: res.time * 1000,
          seo: res.stats.seo,
          online: res.stats.online,
          english: res.stats.english,
          spanish: res.stats.spanish,
          whv: res.stats.whv,
          tv: res.stats.tv,
        };
        this.data = [data];
        this.show = true;
      }
      this.loader = false
    });
  }
}