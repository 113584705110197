import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoComponent} from './info.component';
import {MatButtonModule, MatIconModule, MatInputModule, MatRadioModule, MatSelectModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../../../general/loader/loader.module';
import {IconSelectModalComponent} from '../../../general/icon-select-modal/icon-select-modal.component';

@NgModule({
    declarations: [InfoComponent],
    imports: [
        CommonModule,
        LoaderModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule
    ],
    exports: [
        InfoComponent
    ],
    entryComponents: [
        IconSelectModalComponent
    ]
})
export class InfoModule {
}
