import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataPicketCampaignsComponent} from './data-picket-campaigns.component';
import {
    MatButtonModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
    MatInputModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        DataPicketCampaignsComponent
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    exports: [
        DataPicketCampaignsComponent
    ]

})
export class DataPicketCampaignsModule {
}
