import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReassignComponent} from './reassign.component';
import {MatButtonModule, MatDividerModule, MatIconModule, MatListModule} from '@angular/material';

@NgModule({
    declarations: [ReassignComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatDividerModule
    ],
    exports: [ReassignComponent]
})
export class ReassignModule {
}
