import {Action} from '@ngrx/store';

export enum LoginActionsType {
    SET_LOGIN = '[Login] setLogin',
    SET_ROLE_TYPE = '[Login] set role type',
    SET_BOARD_ID = '[Login] set board id',
    SET_ACTIVE_BOARD = '[Login] set active board',
}

export class SetLogin implements Action {
    readonly type = LoginActionsType.SET_LOGIN;
    constructor(public payload: any) {}
}

export class SetRoleType implements Action {
    readonly type = LoginActionsType.SET_ROLE_TYPE;
    constructor(public payload: any) {}
}

export class SetBoardId implements Action {
    readonly type = LoginActionsType.SET_BOARD_ID;
    constructor(public payload: any) {}
}

export class SetActiveBoard implements Action {
    readonly type = LoginActionsType.SET_ACTIVE_BOARD;
    constructor(public payload: any) {}
}

export type LoginActionUnion = SetLogin | SetRoleType | SetBoardId | SetActiveBoard;
