import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralSettingsNumberEditComponent} from './general-settings-number-edit.component';
import {MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [GeneralSettingsNumberEditComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule
    ],
    exports: [GeneralSettingsNumberEditComponent]
})
export class GeneralSettingsNumberEditModule {
}
