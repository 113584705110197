import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {QueueTableComponent} from './queue-table/queue-table.component';

@Component({
    selector: 'app-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.less']
})
export class QueueComponent implements OnInit {

    public id: number;

    @ViewChild('table') public table: QueueTableComponent;

    constructor() {
    }

    ngOnInit() {}

    public viewChange(event): void {
        this.table.setInitial(event.id);
    }

    public tableChange(event): void {
        this.id = event.id;
    }

}
