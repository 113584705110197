import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QueueComponent} from './queue.component';
import {QueueViewsModule} from './queue-views/queue-views.module';
import {QueueTableModule} from './queue-table/queue-table.module';
import {ConfirmModalModule} from '../confirm-modal/confirm-modal.module';

@NgModule({
    declarations: [
        QueueComponent
    ],
    imports: [
        CommonModule,
        QueueViewsModule,
        QueueTableModule,
        ConfirmModalModule
    ],
    exports: [
        QueueComponent
    ]
})
export class QueueModule {
}
