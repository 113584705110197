import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewTableSkeletonComponent} from './view-table-skeleton.component';

@NgModule({
    declarations: [
        ViewTableSkeletonComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ViewTableSkeletonComponent
    ]
})
export class ViewTableSkeletonModule {
}
