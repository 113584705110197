import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CloneViewComponent} from './clone-view.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatSnackBarModule} from '@angular/material';

@NgModule({
    declarations: [
        CloneViewComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSnackBarModule
    ],
    exports: [
        CloneViewComponent
    ]
})
export class CloneViewModule {
}
