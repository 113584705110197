import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatSnackBar} from '@angular/material';
import _ from 'lodash';
import BaseComponent from '../../base/base.component';

const SettingGMTSettings = 'assign_suggestion_gmt_settings';

@Component({
  selector: 'app-gtm-hours',
  templateUrl: './gtm-hours.component.html',
  styleUrls: ['./gtm-hours.component.less']
})
export class GtmHoursComponent extends BaseComponent implements OnInit {

  public ngClasses: any = {
    active: false
  };

  public settings = [];

  public timezone: string;

  public timeZones: string[];

  public hourAuto = [];

  public settingGmt = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Mid',
      value: 'mid',
    },
    {
      label: 'Low',
      value: 'low',
    }
  ];


  constructor(
    private route: ActivatedRoute,
    private service: AssignSettingService,
    private snack: MatSnackBar
  ) {
    super();
    this.loader = true;
    this.service.getAssignSetting({setting: 'assign_suggestion_gmt_settings,time_zone'}).subscribe(res => {
      if (res && res.time_zone) {
        this.timeZones = Object.values(res.time_zone);
      }
      if (res && res.assign_suggestion_gmt_settings) {
        this.timezone = res.assign_suggestion_gmt_settings.timeZone;
        if (res.assign_suggestion_gmt_settings.settings) {
          this.setSettings(res.assign_suggestion_gmt_settings.settings);
        }
      }
      this.loader = false;
    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      this.loader = false;
    });
  }

  ngOnInit() {
    this.setAuto();
    setTimeout(() => {
      this.ngClasses.active = true;
    }, 100);
  }

  public addSetting() {
    this.settings.push({time_from: 0, time_to: 0, selected_gmt: [], settings: null});
  }

  public deleteSetting(index) {
    this.settings.splice(index, 1);
  }

  public save() {
    this.loader = true;
    let hasErr = false;

    _.forEach(this.settings, el => {
      if (el.time_from < 0 || el.time_to <= 0) {
        this.snack.open('Time From or Time To is invalid', 'Ok!', {
          duration: 1000,
        });
        hasErr = true;
        return;
      }
      if (el.selected_gmt.length <= 0) {
        this.snack.open('Selected GMT is invalid', 'Ok!', {
          duration: 1000,
        });
        hasErr = true;
        return;
      }
    });

    if (hasErr) {
      this.loader = false;
      return;
    }

    const body = {
      setting_slug: SettingGMTSettings,
      setting: {
        gmt_settings: {
          timeZone: this.timezone,
          settings: this.getSetting()
        }
      }
    };

    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('Updated!', 'Ok!', {
        duration: 1000,
      });
      this.loader = false;
    }, error => {
      this.snack.open(_.get(error, ['error', 'gmt_settings'], 'Something Went Wrong!'), 'Ok!', {
        duration: 2000,
      });
      this.loader = false;
    });
    this.loader = false;
  }

  private setAuto() {
    this.hourAuto = [];
    for (let i = 0; i <= 23; i++) {
      this.hourAuto.push({label: i, value: i});
    }
  }

  public selectedGMTChange(index) {
    const obj = {};
    _.forEach(this.settings[index].selected_gmt, el => {
      obj[el] = 0;
    });

    // @ts-ignore
    this.settings[index].settings = obj;
  }

  public getSetting() {
    const settings = [];
    _.forEach(this.settings, el => {
      const setting = {
        time_from: el.time_from,
        time_to: el.time_to,
        gmt: el.selected_gmt.join(','),
        percentage: this.buildPercentageOut(el.settings)
      };
      settings.push(setting);
    });

    return settings;
  }

  public setSettings(settings) {
    this.settings = [];

    _.forEach(settings, el => {
      const item = {
        time_from: el.time_from,
        time_to: el.time_to,
        selected_gmt: el.gmt.split(','),
        settings: this.buildPercentage(el.percentage)
      };
      this.settings.push(item);
    });
    console.log(this.settings);
  }

  public buildPercentage(percentage) {
    const a = {};
    _.forEach(percentage, (el, key) => {
      a[key] = el.value;
    });

    return a;
  }

  public buildPercentageOut(percentages) {
    const a  = {};
    _.forEach(percentages, (el, key) => {
        if (el > 0) {
          a[key] = {
            value: el,
            search: key.charAt(0).toUpperCase() + key.slice(1) + ' GMT'
          };
        }
    });

    return a;
  }
}
