import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { InfoComponent } from './info/info.component';
import { AddAgentComponent } from './add-agent/add-agent.component';
import SearchResponse from '../../../search.response';
import { ShiftsService } from '../../../services/shifts/shifts.service';
import BaseComponent from '../../base/base.component';
import { Permissions } from '../../../helpers/Permissions';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-edit-group',
    templateUrl: './edit-shift.component.html',
    styleUrls: ['./edit-shift.component.scss']
})
export class EditShiftComponent extends BaseComponent {

    public groups: SearchResponse = new SearchResponse({
        page_size: 5
    });

    public group: any = null;

    public subscribers: any = {
        get: null
    };

    public id: any;

    public selectedPortal: Portal<any> = new ComponentPortal(InfoComponent);

    public activeId = 0;

    public portals: any = [
        {
            label: 'Edit',
            icon: 'edit',
            show: true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(InfoComponent);
                this.activeId = i;
            }
        },
        {
            label: 'Add agent',
            icon: 'person_add',
            show: this.permissions.can('shift', [
                'can_add_agent_shift',
                'can_delete_agent_shift',
                'can_mass_delete_shift'
            ]),
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(AddAgentComponent);
                this.activeId = i;
            }
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private shiftsService: ShiftsService,
        private snack: MatSnackBar,
        public permissions: Permissions
    ) {
        super();

        this.groups.addFilter('id', parseInt(this.route.snapshot.params.id, 10));

        this.shiftsService.get(this.groups.getReqData()).pipe(
            takeUntil(this.destroy)
        ).subscribe((res) => {
            if (res.data.length) {
                this.group = res.data[0];
            }
        }, err => {
            this.snack.open('Problem', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
        });
    }

}
