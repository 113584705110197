import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewLeadInfoComponent} from './view-lead-info.component';
import {MatDividerModule, MatListModule, MatTabsModule} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {JsonFieldModule} from '../view/json-field/json-field.module';

@NgModule({
    declarations: [
        ViewLeadInfoComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        LoaderModule,
        MatListModule,
        MatDividerModule,
        JsonFieldModule
    ],
    exports: [
        ViewLeadInfoComponent
    ]
})

export class ViewLeadInfoModule {}
