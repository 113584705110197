import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallReportChartComponent} from './call-report-chart.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';

@NgModule({
    declarations: [CallReportChartComponent],
    imports: [
        CommonModule,
        AmChartsModule
    ],
    exports: [CallReportChartComponent]
})
export class CallReportChartModule {
}
