import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader.component';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material';

@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        CommonModule,
        // BrowserAnimationsModule,
        MatProgressSpinnerModule
    ],
    exports: [
        LoaderComponent
    ]
})

export class LoaderModule {}
