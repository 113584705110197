import {Component, OnDestroy, OnInit} from '@angular/core';
import {HolidaysService} from '../../services/holidays/holidays.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {HolidaysEditComponent} from './holidays-edit/holidays-edit.component';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-holidays',
    templateUrl: './holidays.component.html',
    styleUrls: ['./holidays.component.less']
})
export class HolidaysComponent implements OnInit, OnDestroy {

    public holidays = [];

    public loader = true;

    public timezone = (new Date()).getTimezoneOffset() * 60 * 1000;

    public loaders = [];

    private subs: any = {
        holidays: null,
        modal: null,
        edit: null,
        del: null
    };

    public menuOptions = [
        {
            icon: 'add_circle_outline',
            label: 'Add',
            fn: () => {
                if (this.subs.modal) {
                    this.subs.modal.unsubscribe();
                }

                this.subs.modal = this.dialog.open(HolidaysEditComponent, {
                    width: '400px',
                }).afterClosed().subscribe((res) => {

                    if (!res) {
                        return;
                    }

                    this.loader = !this.loader;

                    this.subs.edit = this.holidaysService.create(res).subscribe((hol) => {
                        this.holidays.push(hol);
                        this.snack.open('Holiday was created!', 'Dismiss', {
                            duration: 3000
                        });
                        this.loader = !this.loader;
                    }, () => {
                        this.snack.open('Error creating the holiday', 'Dismiss', {
                            duration: 3000
                        });
                        this.loader = !this.loader;
                    });
                });
            }
        }
    ];

    public actions: any = [
        {
            icon: 'edit',
            label: 'Edit',
            show: this.permissions.can('holidays', 'can_update_holiday'),
            fn: (h, i) => {

                if (this.subs.modal) {
                    this.subs.modal.unsubscribe();
                }

                this.subs.modal = this.dialog.open(HolidaysEditComponent, {
                    width: '400px',
                    data: h,
                }).afterClosed().subscribe((holiday) => {
                    if (!holiday) {
                        return;
                    }

                    this.loaders[i] = true;

                    this.subs.edit = this.holidaysService.update(holiday).subscribe((r) => {

                        this.holidays[i] = r;

                        this.snack.open('Holiday was updated!', 'Dismiss', {
                            duration: 3000
                        });

                        this.loaders[i] = false;

                    }, () => {

                        this.snack.open('There was an error updating your holiday!', 'Dismiss', {
                            duration: 3000
                        });

                        this.loaders[i] = false;
                    });

                });
            }
        },
        {
            icon: 'delete',
            label: 'Delete',
            show: this.permissions.can('holidays', 'can_delete_holiday'),
            fn: (h, i) => {

                if (this.subs.modal) {
                    this.subs.modal.unsubscribe();
                }

                this.subs.modal = this.dialog.open(ConfirmModalComponent, {
                    width: '400px',
                    data: {
                        title: 'Are you sure you want to delete this holiday?'
                    }
                }).afterClosed().subscribe((c) => {

                    if (!c) {
                        return;
                    }

                    this.loaders[i] = true;

                    this.subs.del = this.holidaysService.delete(h).subscribe(() => {

                        this.loaders[i] = false;

                        this.snack.open('Holiday was deleted!', 'Dismiss', {
                            duration: 3000
                        });

                        this.holidays.splice(i, 1);

                    }, () => {

                        this.loaders[i] = false;

                        this.snack.open('There was an error deleting your holiday!', 'Dismiss', {
                            duration: 3000
                        });
                    });
                });
            }
        },
    ];

    constructor(
        private holidaysService: HolidaysService,
        private dialog: MatDialog,
        private snack: MatSnackBar,
        public permissions: Permissions
    ) {
        this.subs.holidays = this.holidaysService.get().subscribe((res) => {
            this.holidays = res;
            this.loader = !this.loader;
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        for (const name in this.subs) {
            if (this.subs.hasOwnProperty(name) && this.subs[name]) {
                this.subs[name].unsubscribe();
            }
        }
    }

}
