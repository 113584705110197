import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[matBadgeIcon]'
})
export class CallActivityBlockedIconDirective {

    private _item;

    @Input() set matBadgeIcon(val: string) {
        this._item = val;

        const badge = this.el.nativeElement.querySelector('.mat-badge-content');
        badge.style.display = Boolean(val) ? 'flex' : 'none';
        badge.style.alignItems = 'center';
        badge.style.justifyContent = 'center';
        badge.style.background = 'rgb(229, 57, 53)';
        badge.style.right = '-7px';
        badge.style.width = '15px';
        badge.style.height = '15px';
        badge.style.lineHeight = '16px';

        badge.innerHTML = `<i class="material-icons" style="font-size: 13px">${this._item}</i>`;
    };

    constructor(private el: ElementRef) {
    }
}
