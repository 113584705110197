import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallActivityComponent} from './call-activity.component';
import {MatBadgeModule, MatButtonModule, MatDialogModule, MatIconModule} from '@angular/material';
import {CallActivityInfoComponent} from '../call-activity-info/call-activity-info.component';
import {CallActivityBlockedIconDirective} from "./call-activity-blocked-icon.directive";

@NgModule({
    declarations: [
        CallActivityComponent,
        CallActivityBlockedIconDirective
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatBadgeModule
    ],
    exports: [
        CallActivityComponent
    ],
    entryComponents: [
        CallActivityInfoComponent
    ]
})
export class CallActivityModule {
}
