// tslint:disable: forin
// tslint:disable: no-shadowed-variable

import { Component, Input, OnInit } from '@angular/core';

const Red = '#e62e30 ';
const Yellow = '#ffd334 ';
const Green = '#01ad23 ';

const RedYellow = '#f38132 ';
const YellowGreen = '#80c02c ';
const GreenRed = Yellow;

@Component({
  selector: 'app-call-status',
  templateUrl: './call-status.component.html',
  styleUrls: ['./call-status.component.scss']
})
export class CallStatusComponent implements OnInit {
  @Input() time_range: any;

  public statuses = {
    unknow: { value: 'unknow', label: 'Unknow' },
    low: { value: 'low', label: 'Low' },
    medium: { value: 'medium', label: 'Medium' },
    high: { value: 'high', label: 'High' },
  }

  public status = { value: 'unknow', label: 'Unknow' };

  public UTCOffset = { h: 0, m: 0 };

  public elems = [];

  public gradient = 'none';

  public currLeft = '0%';
  public currentTime = '';

  constructor(
  ) {
    this.status = this.statuses.unknow;
  }

  ngOnInit() {
    const UTCOffsetMinutes = -new Date().getTimezoneOffset()
    this.UTCOffset = {
      h: Math.trunc(UTCOffsetMinutes / 60),
      m: UTCOffsetMinutes % 60
    };
    let time_range = this.time_range.map((el) => {
      return {
        from: { h: el.from, m: 0 },
        priority: el.priority,
        to: { h: el.to, m: 59 },
      };
    }) || [];

    const shiftedElems = [];

    for (let i = 0; i < time_range.length; i++) {
      const ci = i, el = time_range[ci];
      el.from = {
        h: el.from.h + this.UTCOffset.h,
        m: el.from.m + this.UTCOffset.m,
      }
      el.to = {
        h: el.to.h + this.UTCOffset.h,
        m: el.to.m + this.UTCOffset.m,
      }

      if (el.from.m >= 60) { el.from.m %= 60; el.from.h++; }
      if (el.from.m < 0) { el.from.m -= -60; el.from.h--; }
      if (el.to.m >= 60) { el.to.m %= 60; el.to.h++; }
      if (el.to.m < 0) { el.to.m -= -60; el.to.h--; }

      // move whole element
      if (el.from.h >= 24 && el.to.h >= 24) {
        el.from.h %= 24;
        el.to.h %= 24;
      }
      if (el.from.h < 0 && el.to.h < 0) {
        el.from.h = 24 + el.from.h;
        el.to.h = 24 + el.to.h;
      }

      // move part of element
      if (el.to.h >= 24) {
        shiftedElems.push({
          from: { h: 0, m: 0 },
          priority: el.priority,
          to: { h: el.to.h % 24, m: el.to.m },
        });
        el.to = { h: 23, m: 59 };
      }
      if (el.from.h < 0) {
        shiftedElems.push({
          from: { h: 24 + el.from.h, m: el.from.m },
          priority: el.priority,
          to: { h: 23, m: 59 },
        });
        el.from = { h: 0, m: 0 };
      }
    }

    time_range = [...time_range, ...shiftedElems];

    // sort elements
    time_range = time_range.sort((firstEl, secondEl) => {
      return (firstEl.from.h * 100 + (firstEl.from.m / 60 * 100)) - (secondEl.from.h * 100 + (secondEl.from.m / 60 * 100));
    });

    // splice the same elements
    let count = 0;
    while (count !== time_range.length) {
      const elA = time_range[count];
      const elB = time_range[count + 1];
      if (elB) {
        if (elA.priority === elB.priority) {
          time_range[count].to = elB.to;
          time_range.splice(count + 1, 1);
        } else {
          count++;
        }
      } else {
        break;
      }
    }

    this.elems = time_range;

    this.setBgGradient();

    this.setCurrentTime();
    setInterval(() => {
      this.setCurrentTime();
    }, 30000);

    // Only for test currentTimePosition
    // this.setCurrentFTime();
    // setInterval(() => {
    //   this.setCurrentFTime();
    // }, 16)
  }

  // Only for test currentTimePosition
  // public CF = { h: 0 , m: 0 }
  // public setCurrentFTime() {
  //   this.CF.m++;
  //   if (this.CF.m >= 60) {
  //     this.CF.m = 0;
  //     this.CF.h++;
  //   }
  //   if (this.CF.h === 24) {
  //     this.CF.h = 0;
  //   }
  //   const MPercentage = ((1 / 24) * ((this.CF.m / 60) * 100));
  //   this.currentTime = (this.CF.h < 10 ? '0' + this.CF.h : this.CF.h) + ':' + (this.CF.m < 10 ? '0' + this.CF.m : this.CF.m);
  //   this.currLeft = (((this.CF.h / 24)) * 100 + MPercentage) + '%';
  //   this.setStatus(this.CF.h, this.CF.m);
  // }

  public setCurrentTime(): void {
    const date = new Date();
    const H = date.getHours();
    const M = date.getMinutes();
    const MPercentage = ((1 / 24) * ((M / 60) * 100));
    this.currentTime = (H < 10 ? '0' + H : H) + ':' + (M < 10 ? '0' + M : M);
    this.currLeft = (((H / 24)) * 100 + MPercentage) + '%';
    this.setStatus(H, M);
  }

  public setStatus(H: number, M: number): void {
    const index = this.elems.findIndex((el) => {
      if (H >= el.from.h && H < el.to.h) { return true; }
      if (H === el.from.h && H < el.to.h && M >= el.from.m) { return true; }
      if (H >= el.from.h && H === el.to.h && M < el.to.m) { return true; }
      return false;
    })
    if (index > -1) {
      if (this.statuses[this.elems[index].priority]) {
        this.status = this.statuses[this.elems[index].priority];
      } else {
        this.status = this.statuses.unknow;
      }
    }
  }

  public setBgGradient(): void {
    let str = 'linear-gradient(90deg, ';
    const lastIndex = this.elems.length - 1;

    for (let i = 0; i < this.elems.length; i++) {
      const ci = i; const elem = this.elems[ci];
      const color = this.getColor(elem.priority);
      const L = 100 * (elem.from.h + elem.from.m / 60) / 24;
      const R = 100 * (elem.to.h + elem.to.m / 60) / 24;
      const C = (L + R) / 2
      const CL = (C + L) / 2;
      const CR = (C + R) / 2;
      const prevIndex = this.getPrevElIndex(this.elems, ci);

      str += this.getHalfColor(elem.priority, this.elems[prevIndex].priority) + L + '%, '
      str += color + CL + '%, ' + color + CR + '%, ';
      if (ci === lastIndex) {
        str += this.getHalfColor(elem.priority, this.elems[0].priority) + R + '%)';
      }
    }
    this.gradient = str;
  }

  public getColor(priority: string): string {
    switch (priority) {
      case 'low': return Red;
      case 'medium': return Yellow;
      case 'high': return Green;
      default: return 'transparent ';
    }
  }

  public getPrevElIndex(arr: Array<any>, currI: number): number {
    if (currI <= 0) {
      return arr.length - 1;
    } else {
      return currI - 1;
    }
  }

  public getHalfColor(priority1: string, priority2: string): string {
    if (priority1 === priority2) { return this.getColor(priority1); }
    if (priority1 === 'low' && priority2 === 'medium' || priority2 === 'low' && priority1 === 'medium') {
      return RedYellow;
    }
    if (priority1 === 'medium' && priority2 === 'high' || priority2 === 'medium' && priority1 === 'high') {
      return YellowGreen;
    }
    if (priority1 === 'high' && priority2 === 'low' || priority2 === 'high' && priority1 === 'low') {
      return GreenRed;
    }
    return 'transparent ';
  }

}
