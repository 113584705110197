import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallActivityInfoComponent} from './call-activity-info.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {LoadsTableModule} from './loads-table/loads-table.module';
import {MatTabsModule} from '@angular/material/tabs';
import { AssignInfoTableComponent } from './assign-info-table/assign-info-table.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {AssignInfoTableModule} from './assign-info-table/assign-info-table.module';

@NgModule({
    declarations: [
        CallActivityInfoComponent,
    ],
    imports: [
        CommonModule,
        AmChartsModule,
        MatDialogModule,
        LoaderModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        AssignInfoTableModule,
        LoadsTableModule
    ],
    exports: [
        CallActivityInfoComponent
    ]
})
export class CallActivityInfoModule {
}
