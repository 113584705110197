import { Component, OnInit, Input } from '@angular/core';
import { AssignSuggestionService } from 'src/app/services/assign-suggestion/assign-suggestion.service';


@Component({
  selector: 'app-bi-statistic',
  templateUrl: './bi-statistic.component.html',
  styleUrls: ['./bi-statistic.component.less']
})
export class BiStatisticComponent implements OnInit {

  public data: any;
  public show = false;
  public loader = true;
  public displayedColumns: any = ['status', 'run_by', 'start_time', 'end_time', 'durations'];
  // public month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(public suggestionService: AssignSuggestionService) { }

  ngOnInit() {
    this.getData();
  }

  public getData() {
    this.loader = true;
    this.suggestionService.getStatickInfo().toPromise().then((res) => {
      if (res && res.start_time) {
        let data = {
          status: res.status,
          run_by: res.run_by.full_name,
          start_time: res.start_time * 1000,
          end_time: res.end_time * 1000,
          durations: res.durations
        };
        this.data = [data];
        this.show = true;
      }
      this.loader = false
    });
  }

}