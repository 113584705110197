import { Component, OnInit } from '@angular/core';
import BaseComponent from '../base/base.component';

@Component({
  selector: 'app-total-sales',
  templateUrl: './total-sales.component.html',
  styleUrls: ['./total-sales.component.less']
})
export class TotalSalesComponent extends BaseComponent implements OnInit {

  constructor() {
      super();
  }

  ngOnInit() {
  }

}
