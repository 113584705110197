import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {getAvatar} from 'src/app/helpers/Avagar';
import {BulkAssignIdService} from 'src/app/services/bulk-assign-id/bulk-assign-id.service';

@Component({
  selector: 'app-bulk-assing-id',
  templateUrl: './bulk-assing-id.component.html',
  styleUrls: ['./bulk-assing-id.component.less']
})
export class BulkAssignIdComponent implements OnInit {

  public getAvatar = getAvatar;

  public id: number;

  public loading = true;

  public columnsToDisplay = ['lead_number', 'agent_name', 'status'];

  public data = [];

  public paginator = {
    page: 0,
    pages: 0,
    rows: 0,
    page_size: 100,
    nextPage: 0,
  };

  public filter = {
    lead_number: [],
    agent: [],
    status: [],
  };
  public filterBefore = {
    status: []
  };

  public partLoading = {
    agents: true,
  };

  public agents: Array<any> = [];

  public agentsSelect: Array<string> = [];

  public filterQuery = {};

  public statuses = ['Uploaded', 'Processing', 'Processed'];

  constructor(private store: Store<any>,
              private route: ActivatedRoute,
              private snack: MatSnackBar,
              private bulkAssignIdService: BulkAssignIdService) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if (this.id > -1) {
      this.getData();
    }
    this.store.pipe(select('agents')).subscribe((res) => {
      if (!res.allAgents.length) {
        return;
      }
      if (this.agents.length) {
        return;
      }
      this.agents = res.allAgents;
      this.agentsSelect = res.allAgents.map(el => el.first_name + ' ' + el.last_name);
      this.partLoading.agents = false;
    });
  }

  public getData(page = 0, size = 100): void {
    this.loading = true;
    let rawParam = `?page=${page}&page_size=${size}`;
    if (Object.keys(this.filterQuery).length !== 0) {
      rawParam += `&${this.filterQuery}`;
    }
    this.bulkAssignIdService.get(this.id, rawParam).subscribe((res) => {
      if (res) {
        const data = res.data.map((el) => {
          return {
            id: el.id,
            lead_number: el.lead_number,
            agent_name: el.agent.full_name,
            status: el.status
          };
        });
        this.data = data;

        this.paginator.page = res.page;
        this.paginator.pages = res.pages;
        this.paginator.rows = res.rows;
        this.loading = false;
      }
    }, (err) => {
      const statusText = err.statusText;
      this.snack.open(statusText, 'Continue', {
        duration: 7000,
      });
      this.loading = false;
    });
  }

  public onChangePage(evt): void {
    this.paginator.nextPage = evt.pageIndex;
    this.paginator.page_size = evt.pageSize;
    this.getData(this.paginator.nextPage, this.paginator.page_size);
  }

  public onFilterLeadNumber(evt): void {
    this.filter.lead_number = evt.original;
    this.onFilter();
  }

  public onFilterAgent(evt): void {
    this.filter.agent = evt;
    this.onFilter();
  }

  public onToggleOpenStatus(evt): void {
    if (!evt && !this.sameStatuses()) {
      this.filterBefore.status = Object.assign([], this.filter.status);
      this.onFilter();
    }
  }

  public onChangeFilterStatus(evt): void {
    // just array with strings
    this.filter.status = evt.value;
  }

  public sameStatuses(): boolean {
    if (this.filter.status.length !== this.filterBefore.status.length) {
      return false;
    }
    for (const elem of this.filter.status) {
      if (!this.filterBefore.status.find(el => el === elem)) {
        return false;
      }
    }
    return true;
  }


  public onFilter(): void {
    let filterArr = [];
    if (this.filter.lead_number.length) {
      filterArr.push('filter[lead_number][]=' + this.filter.lead_number.join('&filter[lead_number][]='));
    }
    if (this.filter.agent.length) {
      filterArr.push('filter[agent_id][]=' + this.getAgentIdArr(this.filter.agent).join('&filter[agent_id][]='));
    }
    if (this.filter.status.length) {
      filterArr.push('filter[status][]=' + this.filter.status.map(el => this.getStatusS(el)).join('&filter[status][]='));
    }
    const filter = filterArr.join('&');
    this.filterQuery = filter;
    this.paginator.nextPage = 0;

    this.getData();
  }

  private getAgentIdArr(vals: Array<any>): Array<number> {
    let res = [];
    res = vals.map(el => el.crm_id);
    return res;
  }

  public getAgentCrmIdByName(name: string): number {
    const index = this.agents.findIndex((el) => {
      const full_name = (el.first_name + ' ' + el.last_name).toLocaleLowerCase();
      return full_name.includes(name.toLowerCase());
    });
    if (index !== -1) {
      return this.agents[index].crm_id;
    }
  }

  private getStatusS(val: string): number {
    switch (val) {
      case 'Uploaded':
        return 0;
      case 'Processing':
        return 1;
      case 'Processed':
        return 2;
      default:
        return +val;
    }
  }


}
