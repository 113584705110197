import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

    constructor(private api: ApiService) {
    }

    public getRoles(params?): Observable<any> {
        return this.api.get('v1/role', {
            params
        });
    }

    public addRole({ payload }): Observable<any> {
        return this.api.post('v1/role/create', {
            body: payload
        });
    }

    public updateRole({ payload, id }): Observable<any> {
        return this.api.post(`v1/role/${id}/edit`, {
            body: payload
        });
    }

    public deleteRole(id): Observable<any> {
        return this.api.delete(`v1/role/${id}/delete`, {});
    }

}
