import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddRoleComponent} from './add-role.component';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule
} from '@angular/material';
import {LoaderModule} from '../../general/loader/loader.module';
import {IconSelectModalModule} from '../icon-select-modal/icon-select-modal.module';
import {IconSelectModalComponent} from '../icon-select-modal/icon-select-modal.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [AddRoleComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        LoaderModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        IconSelectModalModule,
        MatRippleModule
    ],
    exports: [
        AddRoleComponent
    ],
    entryComponents: [
        IconSelectModalComponent
    ]

})
export class AddRoleModule {
}
