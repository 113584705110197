import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {OpportunitiesService} from '../../services/opportunities/opportunities.service';
import {MatDialog} from '@angular/material';
import {DataPicketCampaignsComponent} from '../data-picket-campaigns/data-picket-campaigns.component';
import {DateConverter} from '../../helpers/DateConverter';
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-contact-report',
    templateUrl: './contact-report.component.html',
    styleUrls: ['./contact-report.component.less']
})
export class ContactReportComponent implements OnInit, AfterViewInit, OnDestroy {

    private chart: AmChart;

    private req: any;

    private modal: any;

    public data: any = [];

    public range: any = {
        from: new Date(),
        to: new Date()
    };

    public loader: any = true;

    public options: any = [
        {
            icon: 'date_range',
            name: 'Range',
            show: this.permissions.can('contact', 'can_range_contact_report'),
            fn: () => {

                if (this.modal) {
                    this.modal.unsubscribe();
                }

                this.modal = this.dialog.open(DataPicketCampaignsComponent, {
                    data: {
                        value: {
                            rawEnd: this.range.from,
                            rawStart: this.range.to
                        }
                    },
                }).afterClosed().subscribe((date) => {

                    if (!date) {
                        return;
                    }

                    this.range.from = date.value.rawEnd;

                    this.range.to = date.value.rawStart;

                    this.loader = !this.loader;

                    this.build();

                });
            }
        },
        {
            icon: 'update',
            name: 'Update',
            show: this.permissions.can('contact', 'can_update_contact_report'),
            fn: () => {

                this.loader = !this.loader;

                this.build('update');
            }
        }
    ];

    constructor(private AmCharts: AmChartsService,
                private opportunity: OpportunitiesService,
                private dialog: MatDialog,
                public permissions: Permissions
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.build();
    }

    build(action?: any) {

        if (this.req) {
            this.req.unsubscribe();
        }

        this.req = this.opportunity.contactReport({
            from: DateConverter.startOfDay(this.range.from).getTime() / 1000,
            to: DateConverter.endOfDay(this.range.to).getTime() / 1000,
            action: action ? action : 'get',
        }).subscribe((res) => {

            this.data = res;

            if (this.chart) {
                this.AmCharts.destroyChart(this.chart);
            }

            this.chart = this.AmCharts.makeChart('contact-report-chart', {
                'type': 'pie',
                'startDuration': 0,
                'theme': 'none',
                'addClassNames': true,
                'legend': {
                    'position': 'right',
                    'marginRight': 100,
                    'autoMargins': false
                },
                'innerRadius': '30%',
                'defs': {
                    'filter': [{
                        'id': 'shadow',
                        'width': '200%',
                        'height': '200%',
                        'feOffset': {
                            'result': 'offOut',
                            'in': 'SourceAlpha',
                            'dx': 0,
                            'dy': 0
                        },
                        'feGaussianBlur': {
                            'result': 'blurOut',
                            'in': 'offOut',
                            'stdDeviation': 5
                        },
                        'feBlend': {
                            'in': 'SourceGraphic',
                            'in2': 'blurOut',
                            'mode': 'normal'
                        }
                    }]
                },
                'dataProvider': res,
                'valueField': 'value',
                'titleField': 'label',
                'export': {
                    'enabled': false
                }
            });

            this.loader = !this.loader;
        });
    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
        if (this.req) {
            this.req.unsubscribe();
        }
        if (this.modal) {
            this.modal.unsubscribe();
        }
    }
}
