import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-call-report-columns',
    templateUrl: './call-report-columns.component.html',
    styleUrls: ['./call-report-columns.component.less']
})
export class CallReportColumnsComponent implements OnInit {


    public form: FormGroup = new FormGroup({
        columns: new FormControl('', {}),
    });

    public ctd: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private self: MatDialogRef<CallReportColumnsComponent>
    ) {
        this.ctd = this.data.columns.map(c => c.name);
    }

    ngOnInit() {
    }

    add(event) {

        if (!event.value) {
            return;
        }

        this.form.get('columns').patchValue(null);

        const index = this.data.columns.findIndex(c => c.name === event.value.name);

        if (index !== -1) {
            return;
        }

        this.data.columns.push(event.value);
        this.ctd.push(event.value.name);
    }

    delete(i: number) {
        this.data.columns.splice(i, 1);
        this.ctd.splice(i, 1);
    }

    apply() {
        this.self.close(this.data.columns);
    }


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.columns, event.previousIndex, event.currentIndex);
        moveItemInArray(this.ctd, event.previousIndex, event.currentIndex);
    }
}
