import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material';
import {Agent} from '../../services/agent/Agent';
import {Lead} from "../../services/view/Lead";
import {AmChartsService} from '@amcharts/amcharts3-angular';
import BaseComponent from '../base/base.component';
import {CallActivityService} from '../../services/call-activity/call-activity.service';
import {AgentService} from '../../services/agent/agent.service';
import {OpportunitiesService} from '../../services/opportunities/opportunities.service';
import {LeadService} from "../../services/lead/lead.service";
import {Permissions} from '../../helpers/Permissions';

@Component({
    selector: 'app-call-activity-info',
    templateUrl: './call-activity-info.component.html',
    styleUrls: ['./call-activity-info.component.less']
})

export class CallActivityInfoComponent extends BaseComponent implements OnInit {

    public modalTabs = [
        {
            label: 'Birth Country',
            id: 'country_of_birth',
            groupLabel: 'Country',
            info: {
                page_size: 5,
                page: 0,
                pages: 0,
                rows: 0,
                sort: {},
                data: []
            }
        },
        {
            label: 'Residence Country',
            id: 'country_of_residence',
            groupLabel: 'Country',
            info: {
                page_size: 5,
                page: 0,
                pages: 0,
                rows: 0,
                sort: {},
                data: []
            }
        },
        {
            label: 'Campaigns',
            id: 'source',
            groupLabel: 'Campaign',
            info: {
                page_size: 5,
                page: 0,
                pages: 0,
                rows: 0,
                sort: {},
                data: []
            }
        },
    ];

    public notFound: any = {
        call_activity: false,
        sales_table: [
            false,
            false,
            false
        ]
    };

    public tabs = [
        {
            label: 'Leads',
            id: 'leads',
        },
        {
            label: 'Assign',
            id: 'assign',
        },
        {
            label: 'Assigners',
            id: 'assigners',
        },
        {
            label: 'Assignee',
            id: 'assignee',
        },
        // {
        //     label: 'Call activity',
        //     id: 'call-chart',
        // },
        // {
        //     label: 'Assign statistic',
        //     id: 'assign-data',
        // },
        // {
        //     label: 'Blocked leads',
        //     id: 'blocked-leads',
        // }
    ];

    public displayedColumns: string[] = ['first_name', 'last_name', 'email', 'country_of_residence', 'actions'];

    public blockedLeads: MatTableDataSource<Lead>;

    @Output() onUnblockAllLeads = new EventEmitter<any>(true);

    public columnsToDisplay: Array<string>;

    public agent: Agent;

    public id: string;

    public activeIndex: Number;

    private chartInfo: any = {
        'titles': [],
        'type': 'pie',
        'startDuration': 0,
        'theme': 'light',
        'addClassNames': true,
        'legend': {
            'position': 'right',
            'marginRight': 100,
            'autoMargins': false
        },
        'innerRadius': '30%',
        'defs': {
            'filter': [{
                'id': 'shadow',
                'width': '200%',
                'height': '200%',
                'feOffset': {
                    'result': 'offOut',
                    'in': 'SourceAlpha',
                    'dx': 0,
                    'dy': 0
                },
                'feGaussianBlur': {
                    'result': 'blurOut',
                    'in': 'offOut',
                    'stdDeviation': 5
                },
                'feBlend': {
                    'in': 'SourceGraphic',
                    'in2': 'blurOut',
                    'mode': 'normal'
                }
            }]
        },
        'dataProvider': [],
        'valueField': 'count',
        'titleField': 'call_status',
        'export': {
            'enabled': false
        }
    };

    public statusChart: any = {
        'theme': 'light',
        'type': 'serial',
        'dataProvider': [],

        'valueAxes': [{
            'title': 'Total Touched and Assigned Leads'
        }],
        'graphs': [{
            'balloonText': '<span style="font-size:14px;">Total: <b>[[value]]</b> Leads <br>[[category]]</span>',
            'fillAlphas': 1,
            'lineAlpha': 0,
            'title': 'Total',
            'type': 'column',
            'valueField': 'value'
        }],
        'depth3D': 0,
        'angle': 10,
        'rotate': true,
        'categoryField': 'label',
        'categoryAxis': {
            'gridPosition': 'start',
            'fillAlpha': 0,
            'position': 'left'
        },
        'export': {
            'enabled': false
        }
    };

    public dataFunc = {
        'call-chart': {
            func: () => {
                this.toggleLoader();
                this.callActivity.getCallActivity(this.agent.id).subscribe((response) => {
                    console.log(response);
                    if (response.data.length > 0) {
                        this.chartInfo.dataProvider = response.data;
                        this.chartInfo.titles = [
                            {
                                'text': 'Call info'
                            }, {
                                'text': 'Total of ' + response.total.count + ' calls',
                                'bold': false
                            }
                        ];
                        this.amCharts.makeChart('call-chart', this.chartInfo);
                    } else {
                        this.notFound.call_activity = true;
                    }
                    this.toggleLoader();
                });
            },
            done: false,
        },
        'assign-data': {
            func: () => {
                // this.toggleLoader();

                // let loader = this.modalTabs.length + 1;

                // this.agentService.getLeadStatistics(this.agent.id).subscribe((response) => {
                //     this.statusChart.dataProvider = response;
                //     this.amCharts.makeChart('status-chart', this.statusChart);
                //     loader--;
                //     if (!loader) {
                //         this.toggleLoader();
                //     }
                // });
                //
                // for (let i = 0; i < this.modalTabs.length; i++) {
                //     this.getTableData(i, () => {
                //         loader--;
                //         if (!loader) {
                //             this.toggleLoader();
                //         }
                //     });
                // }

            },
            done: false,
        },
        'blocked-leads': {
            func: () => {
                this.toggleLoader();

                this.leadService.getBlockedLeads(this.agent.id).subscribe((response) => {
                    this.blockedLeads = new MatTableDataSource<Lead>(response);
                    this.toggleLoader();

                    if(this.blockedLeads.data.length === 0){
                        this.onUnblockAllLeads.emit(this.agent.id);
                    }
                });
            },
            done: false,
        }
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private amCharts: AmChartsService,
        private callActivity: CallActivityService,
        private opportunitiesService: OpportunitiesService,
        private agentService: AgentService,
        private leadService: LeadService,
        public permissions: Permissions
    ) {
        super();
        this.agent = this.data.agent;
        this.id = this.data.id;
        this.activeIndex = this.getIndex(this.id);
        this.columnsToDisplay = ['group', 'leads', 'sales', 'amount', 'conversion'];

    }

    ngOnInit() {
        this.selectTab();
    }


    public changePage(event, id): void {
        this.modalTabs[id].info.page = event.pageIndex;
        this.modalTabs[id].info.page_size = event.pageSize;
        this.toggleLoader();
        this.getTableData(id);
    }

    public changeSort(event, id): void {
        this.modalTabs[id].info.sort = {};
        if (event.direction.length > 0) {
            this.modalTabs[id].info.sort = {['sort[' + event.active + ']']: event.direction};
        }
        this.toggleLoader();
        this.getTableData(id);
    }

    public changeTab(event): void {
        this.id = this.tabs[event.index].id;
        this.selectTab();
    }

    public unblock(leadId: any): void {
        this.toggleLoader();
        this.leadService.cleaningCounterAttempts({agentId: this.agent.id, leadId: leadId}).subscribe(() => {
            const index = this.blockedLeads.data.findIndex(lead => lead && lead.id === leadId);
            if (index !== -1) {
                this.blockedLeads.data.splice(index, 1);
                this.blockedLeads = new MatTableDataSource<Lead>(this.blockedLeads.data);

                if(this.blockedLeads.data.length === 0){
                    this.onUnblockAllLeads.emit(this.agent.id);
                }
            }

            this.toggleLoader();
        });
    }

    private selectTab(): void {
        if (this.dataFunc[this.id] && !this.dataFunc[this.id].done) {
            this.dataFunc[this.id].done = true;
            this.dataFunc[this.id].func();
        }
    }

    private getIndex(id: string): number {

        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].id === id) {
                return i;
            }
        }

        return 0;
    }

    private getTableData(id, callback?): void {
        // this.toggleLoader();
        // this.opportunitiesService.getAgentStatistics(this.agent.id, Object.assign({
        //     page: this.modalTabs[id].info.page,
        //     page_size: this.modalTabs[id].info.page_size,
        //     field: this.modalTabs[id].id
        // }, this.modalTabs[id].info.sort)).subscribe((response) => {
        //     this.modalTabs[id].info = Object.assign(this.modalTabs[id].info, response);
        //     if (response.data <= 0) {
        //         this.notFound.sales_table[id] = true;
        //     }
        //     if (callback) {
        //         callback();
        //     } else {
        //         this.toggleLoader();
        //     }
        // });
    }

}
