import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultipleSelectorComponent} from './multiple-selector.component';
import {MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        MultipleSelectorComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [
        MultipleSelectorComponent
    ]
})
export class MultipleSelectorModule {
}
