import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {GeneralSettingsNumberEditComponent} from './general-settings-number-edit/general-settings-number-edit.component';

@Component({
    selector: 'app-general-settings-number',
    templateUrl: './general-settings-number.component.html',
    styleUrls: ['./general-settings-number.component.less']
})
export class GeneralSettingsNumberComponent implements OnInit, OnDestroy {

    @Input() public setting: any;

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    public subs: any = {
        modal: null,
    };

    constructor(
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        for (const name in this.subs) {
            if (this.subs.hasOwnProperty(name) && this.subs[name]) {
                this.subs[name].unsubscribe();
            }
        }
    }

    edit() {

        if (this.subs.modal) {
            this.subs.modal.unsubscribe();
        }

        this.subs.modal = this.dialog.open(GeneralSettingsNumberEditComponent, {
            data: Object.assign({}, this.setting),
            width: '250px',
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.change.emit(res);
            }
        });
    }
}
