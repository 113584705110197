import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material';
import {getAvatar} from '../../../helpers/Avagar';

@Component({
    selector: 'app-reassign',
    templateUrl: './reassign.component.html',
    styleUrls: ['./reassign.component.less']
})
export class ReassignComponent implements OnInit {
    public getAvatar = getAvatar;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public self: MatSnackBarRef<ReassignComponent>,
    ) {
    }

    ngOnInit() {
    }

}
