import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import SearchResponse from '../../search.response';

import {AddGroupComponent} from './add-group/add-group.component';
import {ShiftsService} from '../../services/shifts/shifts.service';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {Permissions} from '../../helpers/Permissions';
import {select, Store} from '@ngrx/store';
import {GetBoardId, GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../../classes/unsubscribe';

// import {CompleteModalComponent} from '../../general/complete-modal/complete-modal.component';

@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.less']
})
export class ShiftsComponent extends Unsubscribe implements OnInit, OnDestroy {

  public groups: SearchResponse = new SearchResponse();

  public displayedColumns: any[] = ['ids', 'icon', 'name', 'description', 'active', 'created_at', 'updated_at', 'config'];

  public selection = new SelectionModel<any>(true, []);

  public loader: any = true;

  public subscribers: any = {
    deleteSelected: null,
    getAll: null
  };

  public groupMenu: any = [
    {
      icon: 'add_circle_outline',
      label: 'Add Shift',
      show: this.permissions.can('shift', 'can_create_shift'),
      fn: () => {
        const modal = this.dialog.open(AddGroupComponent, {
          width: '500px',
          data: {
            type: this.roleType,
            board_id: this.boardId,
          }
        });
        modal.afterClosed().subscribe((res) => {
          if (res) {
            this.snack.open('New shift is created!', 'Dismiss', {
              duration: 3000
            });
            this.groups.data = [res, ...this.groups.data];
          }
        });
      }
    },
    {
      icon: 'delete',
      label: 'Delete selected',
      show: this.permissions.can('shift', 'can_delete_shift') && this.permissions.can('shift', 'can_mass_delete_shift'),
      fn: () => {
        if (this.selection.selected.length <= 0) {
          this.snack.open('please select at least 1 shift', 'Dismiss', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          return;
        }

        const selectedId = this.selection.selected.map((sel) => {
          return sel.id;
        });

        this.dialog.open(ConfirmModalComponent, {
          width: '400px',
          hasBackdrop: true,
          data: {
            title: 'Delete All',
            description: 'Are you sure want delete ' + this.selection.selected.length + ' shift ?'
          }
        }).afterClosed().subscribe((result) => {
          if (result) {
            this.subscribers.deleteSelected = this.shiftsService.deleteShiftArray(selectedId).subscribe(() => {
              this.snack.open('Shift Successfully Deleted!', 'Dismiss', {
                duration: 3000
              });
              this.getGroupsList();
              this.selection.clear();
              this.toggleLoader();
            }, error => {
              this.snack.open('Problem', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            });
          }
        });
      }
    },
  ];

  public options: any = [
    {
      icon: 'edit',
      label: 'Edit',
      show: this.permissions.can('shift', 'can_edit_shift'),
      fn: (row) => {
        this.router.navigate(['/shifts/' + row.id + '/edit']);
      }
    },
    // {
    //   icon: 'date_range',
    //   label: 'Calendar',
    //   fn: (row) => {
    //     this.router.navigate(['shifts/calendar/' + row.id], {
    //       queryParams: {
    //         name: row.name,
    //         icon: row.icon,
    //         related_to: 'user_groups',
    //         related_id: row.id
    //       }
    //     });
    //   }
    // },
    {
      icon: 'delete',
      label: 'Delete',
      show: this.permissions.can('shift', 'can_delete_shift'),
      fn: (row, i) => {
        this.dialog.open(ConfirmModalComponent, {
          width: '400px',
          hasBackdrop: true,
          data: {
            title: 'Delete shift',
            description: `Are you sure want delete '${ row.name }' shift?`
          }
        }).afterClosed().subscribe((result) => {
          if (result) {
            this.subscribers.deleteSelected = this.shiftsService.deleteShift([row.id]).subscribe(() => {
              // this.dialog.open(CompleteModalComponent, {
              //   width: '300px',
              //   data: {
              //     info: 'Group Successfully Deleted!'
              //   }
              // });
              this.selection.clear();
              this.groups.data.splice(i, 1);
              this.groups.data = [...this.groups.data];
            }, error => {
              this.snack.open('Problem', 'Dismiss', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            });
          }
        });
      }
    }
  ];

  public info = {
    active: {
      'label': 'Active',
      'name': 'active',
      'type': ['bool'],
      'values': [],
      'view': 'Active'
    },
    created_at: {
      'label': 'Created At',
      'name': 'created_at',
      'type': [
        'datetime'
      ],
      'values': [],
      'view': 'CreatedAt'
    },
    description: {
      'label': 'Description',
      'name': 'description',
      'type': [
        'string'
      ],
      'values': [],
      'view': 'Description'
    },
    goal: {
      'label': 'Goal',
      'name': 'goal',
      'type': [
        'int'
      ],
      'values': [],
      'view': 'Goal'
    },
    icon: {
      'label': 'Icon',
      'name': 'icon',
      'type': [
        'string'
      ],
      'values': [],
      'view': 'Icon'
    },
    id: {
      'label': 'ID',
      'name': 'id',
      'type': [
        'int'
      ],
      'values': [],
      'view': 'Id'
    },
    name: {
      'label': 'Name',
      'name': 'name',
      'type': [
        'string'
      ],
      'values': [],
      'view': 'Name'
    },
    updated_at: {
      'label': 'Updated At',
      'name': 'updated_at',
      'type': [
        'datetime'
      ],
      'values': [],
      'view': 'UpdatedAt'
    }
  };

  private roleType: number;
  private boardId: number;

  constructor(
      private dialog: MatDialog,
      private shiftsService: ShiftsService,
      private snack: MatSnackBar,
      private router: Router,
      public permissions: Permissions,
      private store: Store<any>
  ) {
    super();

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        // tslint:disable-next-line:no-shadowed-variable
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.boardId = id;
    });

    this.displayedColumns = this.displayedColumns.filter(item => {
      if (!this.permissions.can('shift', 'can_delete_shift') && item === 'ids') return false;

      if (!this.permissions.can('shift', ['can_edit_shift', 'can_delete_shift']) && item === 'config') return false;

      return true;
    });
  }

  ngOnInit() {
    Object.assign(this.groups, { info: this.info });

    // this.groups.addFilter('type', this.roleType);

    this.getGroupsList();
  }

  ngOnDestroy() {
    for (const name in this.subscribers) {
      if (this.subscribers.hasOwnProperty(name) && this.subscribers[name]) {
        this.subscribers[name].unsubscribe();
      }
    }
  }

  public toggleLoader(params?: any) {
    this.loader = !this.loader;
  }

  private getGroupsList() {
    this.subscribers.getAll = this.shiftsService.get(this.groups.getReqData()).subscribe((res) => {
      this.groups = Object.assign(this.groups, res);
      this.toggleLoader();
    });
  }

  public changePage(event: any): void {
    this.selection.clear();
    this.groups.page = event.pageIndex;
    this.groups.page_size = event.pageSize;
    this.toggleLoader();
    this.getGroupsList();
  }

  public changeSort(event: any) {
    this.selection.clear();
    this.groups.addSort(event.active, event.direction);
    this.toggleLoader();
    this.getGroupsList();
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.groups.data.length;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.groups.data.forEach(reward => this.selection.select(reward));
  }
}
