import {Component, OnDestroy, OnInit} from '@angular/core';
import BaseComponent from '../../base/base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-ai-setting-whv',
  templateUrl: './ai-setting-whv.component.html',
  styleUrls: ['./ai-setting-whv.component.less']
})
export class AiSettingWhvComponent extends BaseComponent implements OnInit, OnDestroy  {

  public ngClasses: any = {
    active: false
  };

  public subscribers: any = {
    settings: null
  };

  public loader: any = false;

  public form: FormGroup;

  public DefaultValueStart = null;
  public DefaultValueEnd = null;

  constructor(
      private route: ActivatedRoute,
      private service: AssignSettingService,
      private snack: MatSnackBar
  ) {
    super();

    this.subscribers.settings = this.service.getAssignSetting({
      'setting': 'ai_configuration_whv'
    }).subscribe((res) => {

      this.setValues(res);

    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      quantile: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      risk: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      disq: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(1)
      ]),
      bonus_points: new FormControl('', [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(90)
      ]),
      weights: new FormGroup({
        conversion_rate: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        avg_of_sales: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        eff_call_to_swipe: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        answer_to_eff_call: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ]),
        index: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10000)
        ])
      }),
      weights_of_features: new FormGroup({
        country_of_residence: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        country_of_birth: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        client_age: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        client_gender: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ]),
        lifetime: new FormControl('', [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(1)
        ])
      }),
    });
  }

  private setValues(params) {
    if (params) {
      for (const name in params) {
        if (params.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).patchValue(params[name]);

          if (!params[name]) {
            this.form.get(name).markAsTouched();
          }

          if (name === 'data_set_from') {
            this.DefaultValueStart = params[name] ? params[name].split(' ')[0].replaceAll('-', '/') : params[name];
          }
          if (name === 'data_set_to') {
            this.DefaultValueEnd = params[name] ? params[name].split(' ')[0].replaceAll('-', '/') : params[name];
          }
        }
      }
    }
  }

  public submit(event): void {
    event.preventDefault();

    this.loader = true;

    const params = {
      'setting_slug': 'ai_configuration_whv',
      'setting': this.form.value,
    };

    this.subscribers.edit = this.service.updateAssignSetting(params).subscribe(result => {
      if (result) {
        this.snack.open('Settings Successfully Updated!', 'Dismiss', {
          duration: 3000
        });
        this.loader = false;
      }
    }, error => {
      this.loader = false;

      for (const name in error.error) {
        if (error.error.hasOwnProperty(name) && this.form.get(name)) {
          this.form.get(name).setErrors({
            name: error.error[name]
          });
        }

        if (this.form.get('weights').get(name)) {
          this.form.get('weights').get(name).setErrors({
            name: error.error[name]
          });
        }
        if (this.form.get('weights_of_features').get(name)) {
          this.form.get('weights_of_features').get(name).setErrors({
            name: error.error[name]
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
