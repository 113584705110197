import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import {ViewService} from '../../../services/view/view.service';
import _ from 'lodash';
import {select, Store} from '@ngrx/store';
import {GetRoleType} from '../../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';

const SuggestionViews = 'suggestion_views';

@Component({
  selector: 'app-views-control',
  templateUrl: './views-control.component.html',
  styleUrls: ['./views-control.component.less']
})
export class ViewsControlComponent extends BaseComponent implements OnInit {

  public ngClasses: any = {
    active: false
  };

  public views = [];

  public viewsControlFormGroup: FormGroup = new FormGroup({
    freshLeadsEn: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsEn: new FormControl('', [
      Validators.required,
    ]),
    freshLeadsSp: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsSp: new FormControl('', [
      Validators.required,
    ]),
    freshLeadsOn: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsOn: new FormControl('', [
      Validators.required,
    ]),
    freshLeadsSeo: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsSeo: new FormControl('', [
      Validators.required,
    ]),
    freshLeadsTv: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsTv: new FormControl('', [
      Validators.required,
    ]),
    freshLeadsWhv: new FormControl('', [
      Validators.required,
    ]),
    reassignedLeadsWhv: new FormControl('', [
      Validators.required,
    ]),
  });

  private roleType: number;

  constructor(
    private service: AssignSettingService,
    private snack: MatSnackBar,
    private viewService: ViewService,
    private store: Store<any>
  ) {
    super();

    this.loader = true;

    this.store.pipe(
        select(GetRoleType),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((type: number) => {
      this.roleType = type;
    });

    this.viewService.getViews({
      page_size: 100,
      'filter[type]': this.roleType
    }).subscribe(res => {
      if (res && res.data) {
        this.views = res.data;
      }
      this.service.getAssignSetting({setting: SuggestionViews}).subscribe(res => {
        this.setValues(res);
        this.loader = false;
      }, err => {
        this.snack.open('Something Went Wrong!', 'Ok!', {
          duration: 1000
        });
        this.loader = false;
      });
    }, error => {
      this.snack.open('Can not get Views', 'Ok!', {
        duration: 2000,
      });
      this.loader = false;
    });

  }

  ngOnInit() {
  }

  private setValues(params) {
    for (const name in params) {
      if (params.hasOwnProperty(name) && this.viewsControlFormGroup.get(name)) {
        this.viewsControlFormGroup.get(name).patchValue(params[name]);
        if (!params[name]) {
          this.viewsControlFormGroup.get(name).markAsTouched();
        }
      }
    }
  }

  public save() {
    this.loader = true;
    const body = {
      setting_slug: SuggestionViews,
      setting: this.viewsControlFormGroup.value
    };

    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('Updated!', 'Ok!', {
        duration: 1000,
      });
      this.loader = false;
    }, err => {
      this.snack.open(_.get(err, ['error', 'gmt_settings'], 'Something Went Wrong!'), 'Ok!', {
        duration: 2000,
      });
      this.loader = false;
    });
    this.loader = false;
  }
}
